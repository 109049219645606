.wrapper {
  display: flex;
  flex-wrap: nowrap;
}

.button {
  align-items: center;
  background-color: transparent;
  border-radius: 0px;
  border: 1px solid #8f9295;
  color: #bdc0c2;
  display: flex;
  flex: 0 1 100%;
  height: 40px;
  justify-content: center;
  padding: 0px 16px 0px 16px;
  position: relative;
  cursor: pointer;
  z-index: 1;
}

.button:disabled {
  cursor: not-allowed;
}

.button:focus {
  outline: none;
}

.button:not(:first-child) {
  margin-left: -1px;
}

.button:first-of-type {
  border-radius: 4px 0px 0px 4px;
}

.button:last-of-type {
  border-radius: 0px 4px 4px 0px;
}

.selected {
  border-color: #fff;
  color: #fff;
  z-index: 2;
}
