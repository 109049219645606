@value colors: "~views/colors.css";
@value easilBlack, easilBlue, easilBlueDeep, easilGray, easilGrayXXXLight, hoverColor from colors;
@value globalVars: "~views/style.css";
@value layoutWidth from globalVars;
/* CONTAINER */

.searchContainer {
  align-items: center;
  display: flex;
  margin: 0 auto;
  width: layoutWidth;

}

.iconBoundingBox {
  align-items: center;
  display: flex;
  height: 24px;
  justify-content: center;
  width: 24px;
  margin-right: 8px;
}

/* SPACER */

.searchSpacer {
  border-left: 1px solid easilGrayXXXLight;
  height: 24px;
  margin: 0px 4px 0px 4px;
}

/* TEXT BUTTON */

.searchTextButton {
  align-items: center;
  display: flex;
  height: 48px;
  padding: 12px 24px 12px 16px;
  width: max-content !important;
}

.searchTextButton > svg {
  display: block;
  margin-right: 8px;
}

/* INPUT */

.searchInput {
  flex: 1 1 auto;
  margin-right: 8px;
  position: relative;
  height: 48px;
}

.searchInput > input {
  background: #eef0f1;
  border: none;
  border-radius: 8px;
  color: easilBlack;
  font-size: 16px;
  line-height: 24px;
  padding: 12px 12px 12px 48px;
  width: 100%;
}

.searchInput > input::placeholder {
  color: easilGray;
}

.searchInputIcon {
  left: 15px;
  outline: none;
  pointer-events: none;
  position: absolute;
  top: 15px;
}

.searchInputClear {
  align-items: center;
  background: easilBlueDeep;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  height: 28px;
  justify-content: center;
  position: absolute;
  right: 11px;
  top: 11px;
  width: 28px;
}

.searchInputClear:hover {
  background: easilBlue;
}
