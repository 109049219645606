@value colors: "~views/style.css";
@value easilRed from colors;

.modal {
  width: 500px;
  height: 100vh;
  max-height: 452px;
  border-radius: 8px;
  background-color: #ffffff;
  padding: 32px;
}

.inputTitle {
  font-family: Roboto;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: #7a7d81;
  margin-bottom: 8px;
}

.input {
  width: 436px;
  height: 40px;
  border-radius: 4px;
  border: solid 1px #d1d4d6;
  background-color: #ffffff;
}

.inputContainer {
  margin-bottom: 16px;
}

.inputContainer:last-of-type {
  margin-bottom: 32px;
}

.buttons {
  margin-top: 0px;
}

.buttons > button {
  height: 40px;
}

.error {
  color: easilRed;
  margin-bottom: 5px;
  margin-top: -5px;
}
