@value colors: "~views/colors.css";
@value easilBlack, easilGrayXLight from colors;

.row {
  display: flex;
  /* this is to fix chrome redraw issues, forces chrome to use gpu hardware acceleration*/
  transform: translate3d(0, 0, 0);
  justify-content: flex-start;
  overflow: hidden;
}

.viewBox {
  position: relative;
}

.buttons {
  position: absolute;
  background-color: white;
  cursor: pointer;
  top: calc(50% - 24px);
  height: 48px;
  width: 48px;
  border-radius: 100%;
  border: 1px solid easilGrayXLight;
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttons[data-direction="left"] {
  right: calc(100% + 24px);
}

.buttons[data-direction="right"] {
  left: calc(100% + 24px);
}

.designSizeCard {
  margin: 0px 25px 0px 0px;
}

.designSizeCard:last-of-type {
  margin-right: 0px;
}
