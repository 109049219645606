@value colors: "~views/style.css";
@value easilBlueDeep, easilGrayXLight, easilGrayXDark, easilRed from colors;

.folderSelectionContent {
  width: 586px;
  height: 200px;
  border-radius: 4px;
  border: solid 1px easilGrayXLight;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 12px;
  overflow-y: auto;
}

.folderSelectionContentItem {
  width: 562px;
  min-height: 36px;
  height: 36px;
  border-radius: 4px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 6px;
  font-family: Roboto;
  font-size: 15px;
  line-height: 1.6;
  color: easilGrayXDark;
  cursor: pointer;
}

.folderSelectionContentItem[data-is-selected="true"] {
  background-color: #e3f1ff;
}

.folderSelectionContentItem[data-is-sub="true"] {
  margin-left : 16px;
  width: 544px;
}

.uploadFolderIconWrapper {
  height: 24px;
  width: 24px;
  margin-right: 12px;
}

.folderSelectionFolderTitle {
  width: 100%;
  font-family: Roboto;
  font-size: 15px;
  line-height: 1.6;
  color: easilGrayXDark;
}

.folderSelectionContentTickWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding-top: 5px;
  margin-right: 12px;
  margin-left: auto;
}

.uploadFolderName {
  width: 100%;
  max-width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

