@value height: 48px;

.actionbar {
  height: height;
  position: relative;
  width: 100%;
  z-index: 6; /* This needs to sit above selection boxes and canvas pages */
  background-color: white;
  box-shadow: 0px 1px 2px rgba(15,15,16,.04);
  padding: 0px 20px 0px 24px;
  display: flex;
  align-items: center;
}

.actionButton > div::after {
  border-bottom-color: transparent !important;
  border-radius: 4px;
  font-size: 12px;
  color: white;
}

.actionButton[data-not-allowed="true"] {
  opacity: 0.2;
}

.actionbarColumns {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.topLeftIcons {
  display: flex;
  align-items: center;
}

.topRightIcons {
  display: flex;
  margin-left: auto;
}

.topRightIcons > div {
  margin: 0px 4px;
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.topRightIcons > div:hover {
  border-radius: 4px;
  border: solid 1px #8F9295 !important;
  cursor: pointer;
}
