.resizeBox {
  pointer-events: none;
  z-index: 5;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
}

.resizeBox:after {
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  clear: both;
  content: ' ';
  pointer-events: none;
  border: 2px solid #3184FC;
  background-color: transparent;
  border-radius: 4px;
}