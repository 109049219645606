.smartImageLabel {
  padding-right: 4px !important;
  width: auto;
  height: 24px;
  margin: 0 0 2px 0;
  padding: 3px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  background-color: #e5e8eb;
}

.smartImageModal {
  width: 450px;
  height: auto;
  padding: 24px;
  border-radius: 8px;
  background-color: #fff;
}

.smartImageModalHeader {
  font-family: Roboto;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2c2e2f;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.modalImageWrapper {
  margin-bottom: 26px;
}

.modalImage {
  width: 100%;
  height: 100%;
  max-width: 386px;
  max-height: 300px;
  border-radius: 10px;
  background-color: #eeeeee;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.modalButtons {
  margin-top: 24px;
}

.modalForm {
  width: 100%;
}

.modalFormLabel {
  margin-bottom: 8px;
}

.modalFormInput {
  border-radius: 4px;
  margin-right: 0;
  line-height: 40px;
}

.modalFormField {
  display: flex;
  width: 100%;
}

.modalFormFieldCreateFolderNameInput {
  max-width: 300px;
}

.deleteSmartImageModal {
  width: 550px;
}

.deleteSmartImageModal > div:first-of-type {
  margin-bottom: 24px;
}

.transferIdButton {
  display: flex;
  justify-content: center;
  align-items: center;
}

.transferIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
}

.userPrepend {
  border-radius: 4px 0px 0px 4px;
  border: 1px solid #d1d4d6;
  border-right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 8px 8px 12px;
  color: #2c2e2f;
  font-size: 14px;
  font-weight: 500;
}
