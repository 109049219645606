
@value colors: "~views/colors.css";
@value easilBlack from colors;

@value basicBackgroundColor: #eef0f1;
@value plusBackgroundColor: #8fd3ef;
@value edgeBackgroundColor: easilBlack;

.wrapper {
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.wrapperTop {
  height: 362px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #f5f5f8;
  box-shadow: inset 0 1px 0 0 rgba(28, 29, 30, 0.04);
  padding-top: 40px;
  margin-bottom: -230px;
}

.wrapperBottom {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.wrapperBottomCards {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.planDescriptionGrid {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  margin-top: 60px;
}

.planGridHeaders {
  width: 1024px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #ffffff;
  padding-bottom: 13px;
  border-bottom: 1px solid #d1d4d6;
}

.planGridHeader {
  font-family: Roboto;
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2c2e2f;
}

.planGridHeader:first-of-type {
  width: 405px;
  font-size: 24px;
}

.planGridHeader:not(:first-of-type) {
  width: 100%;
  text-align: center;
  flex: 1;
}

.planSectionHeader {
  composes: planGridHeader;
  font-size: 22px;
  text-align: left !important;
  width: 1024px !important;
  margin-top: 32px;
  padding-bottom: 13px;
  border-bottom: 1px solid #d1d4d6;
}

.planSectionEmptyHeader {
  height: 32px;
  border-bottom: 1px solid #d1d4d6;
  width: 1024px
}

.planFeatureRow {
  width: 1024px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #ffffff;
  padding: 12px 0px;
  border-bottom: 1px solid #d1d4d6;
}

.planFeatureRowLeft {
  font-family: Roboto;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  color: #2c2e2f;
  min-width: 405px;
  display: flex;
  align-items: flex-end;
}

.planFeatureRowRight {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.planFeatureDetailsLabel {
  width: 100%;
  flex: 1;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  color: #2c2e2f
}

.comparisonTopText {
  white-space: pre;
  font-size: 24px;
  line-height: 1.33;
  text-align: center;
  color: #2c2e2f;
  margin-bottom: 24px;
}

.planCardWrapper {
  display: flex;
  width: 320px;
  border: solid 1px #e2e5e8;
  background-color: #ffffff;
  margin: 0px 16px 4.35px;
  border-radius: 5.5px;
  flex-direction: column;
  align-items: center;
}

.planCardHeader {
  width: 322px;
  border-radius: 8px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 32px 24px;
  flex-direction: column;
  color: #2c2e2f;
  height: 100%;
}

.planTitleBox {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  min-height: 55px;
}

.planTitleBox > svg + svg {
  margin-top: -9px;
}

.planTitle {
  font-size: 24px;
  line-height: 1.25;
  margin-bottom: 19px;
}

.largePricePoint {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  margin-top: 24px;
}

.largePricePointTop {
  display: flex;
  flex-direction: row;
  min-height: 44px;
  max-height: 44px;
  width: 100%;
  justify-content: flex-start;
}

.largePricePointCurrencySymbol {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  font-size: 40px;
  margin-left: 4px;
  font-family: Roboto;
  font-weight: bold;
}

.largePricePointValue {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  font-family: Roboto;
  font-size: 40px;
  font-weight: bold;
}

.largePricePointCurrency {
  font-size: 24px;
  line-height: 3;
  display: flex;
  flex-direction: column;
  height: 100%;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

.billingInfo {
  display: flex;
  flex-direction: column;
  white-space: break-spaces;
  margin-top: 7px;
  color: #2c2e2f;
  font-family: Roboto;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2c2e2f;
  line-height: 1.54;
}

.savingText {
  font-family: Roboto;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  font-weight: 500;
  color: #1dcda3;
  display: contents;
}

.planDescription {
  height: 72px;
  min-height: 72px;
  width: 100%;
  margin-top: 9px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #484a4c;
  white-space: break-spaces;
}

.planCardFeature {
  height: 36px;
  display: flex;
  align-items: center;
  font-size: 15px;
  line-height: 1.6;
  color: #57595d;
  opacity: 1;
}

.planCardFeature[data-is-available="false"] {
  opacity: 0.48;
}

.planCardFeature[data-is-available="false"] > svg {
  visibility: hidden;
}

.planCardFeature > svg {
  margin-right: 12px;
}

.testimonials {
  width: 100%;
}

.upgradeButtonWrapper {
  width: 100%;
  margin-top: 21px;
  min-height: 44px;
  height: 100%;
  display: flex;
  align-items: flex-end;
}

.upgradeButton {
  display: flex;
  justify-content: center !important;
  align-items: center;
  margin-bottom: 0px !important;
}

.planCardFeatureList {
  margin: 24px 0px;
}

.infoToolTip {
  flex-direction: column;
}