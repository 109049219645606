@value colors: "../style.css";
@value (
  easilBlack,
  easilGray,
  easilGrayXLight,
  easilGrayXDark,
  easilBlueDark,
  easilRed,
  easilBlue
) from colors;

/* The container */
.container {
  display: block;
  position: relative;
  padding-left: 28px;
  margin-bottom: 3px;
  cursor: pointer;
  font-size: 14px;
  line-height: 20px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  border: 2px solid easilGrayXLight;
  border-radius: 4px;
  background-color: white;
}

.checkmark:hover, .checkmark[data-is-hovered="true"] {
  border-color: easilGray;
}

/* When the radio button is checked, add a blue background */
.container input:checked ~ .checkmark {
  border: none;
  background-color: easilBlue;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container .checkmark:after {
  background-image: url(./check.svg);
  background-repeat: no-repeat;
  color: white;
  top: 5px;
  left: 4px;
  width: 8px;
  height: 8px;
}

.container .checkmark[data-is-minus="true"]:after {
  background-image: url(./minus.svg);
}

.container .checkmark[data-double-scale="true"]:after {
  background-image: url(./thickCheck.svg);
  transform: scale(1);
}
