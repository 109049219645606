@value iconBoxSize: 24px;
@value popoutWidth: 350px;


.count {
  position: absolute;
  top: -11px;
  left: 7px;
  width: auto;
  padding: 0px 8px;
  height: 20px;
  background-color: #fa4d50;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.67;
  letter-spacing: normal;
  color: #ffffff;
}

.iconBox {
  position: relative;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.popout {
  z-index: 10;
  cursor: auto;
  left: calc(-1 * ((popoutWidth/2) - (iconBoxSize/2)));
  position: absolute;
  top: calc(100% + 12px);
  width: popoutWidth;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(21, 21, 22, 0.08);
  background-color: #ffffff;
}

.popout:after {
  content: "";
  width: 100%;
  height: 20px;
  position: absolute;
  bottom: 0;
  background: linear-gradient(to top, rgb(255, 255, 255) 50%, rgba(255, 255, 255, 0) 100%);
  border-radius: inherit;
}
.popout:before {
  content: ' ';
  width: 100%;
  height: 20px;
  position: absolute;
  top: 61px;
  left: 0px;
  background: linear-gradient(to bottom, rgb(255, 255, 255) 50%, rgba(255, 255, 255, 0) 100%);
  z-index: 10;
  border-radius: inherit;
}

.popoutHeader {
  width: 92px;
  height: 20px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.25;
  color: #2c2e2f;
  margin: 20px auto 20px 26px;
}

.notificationList {
  padding: 20px 20px 0px;
  overflow: auto;
  max-height: 350px;
  margin-bottom: 8px;
}
