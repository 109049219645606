@value colors: "../../colors.css";
@value easilBlue, easilGrayXLight, easilBlueDeep, easilBlack, easilGray from colors;

.wrapper {
  background-color: #fff;
  border: solid 1px easilGrayXLight;
  border-radius: 4px;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.08);
  padding: 16px;
  position: relative;
  width: 300px;
}

.colorsWrapper {
  display: flex;
  flex-direction: row;
  height: 204px;
  justify-content: space-between;
  margin: 16px 0;
  user-select: none;
}

.swatchesWrapper {
  width: 20px;
}

.swatches {
  display: flex;
  flex-direction: column;
}

.swatch {
  cursor: pointer;
  flex: 0 1 100%;
}

.gradientWrapper {
  border-radius: 4px;
  flex: 0 1 196px;
  position: relative;
  width: 100%;
  cursor: pointer;
  overflow: hidden;
}

.hue {
  border-radius: 4px;
  height: 204px;
  overflow: hidden;
  position: relative;
  width: 20px;
  cursor: pointer;
}

.hue > div > div > div {
  cursor: grabbing;
}

.saturationPointer {
  box-shadow: 0 1px 2px 0 rgba(0,0,0,.64);
  border: 2px solid #ffffff;
  border-radius: 50%;
  height: 12px;
  width: 12px;
  transform: translate(-6px, -6px);
  cursor: grabbing;
}

.pointer {
  background-color: transparent;
  border: 2px solid #fff;
  border-radius: 2px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.12);
  height: 6px;
  transform: translateX(2px);
  width: 16px;
}

.fieldsWrapper {
  display: flex;
  justify-content: space-between;
}

.fieldsWrapper[data-is-disabled="true"] {
  pointer-events: none;
  opacity: 0.5;
}

/* targeting the color eye-dropper div when rendered */
.colorEyeDropperField {
  flex: 0.5;
  margin-left: 8px;
  width: 100%;
  height: 32px;
}

.fieldSingle {
  flex: 1;
  margin-left: 8px;
}

.fieldDouble {
  flex: 2;
}

.fieldDouble input,
.fieldSingle input {
  width: 100%;
  height: 32px;
  box-shadow: 0 2px 3px 0 rgba(15, 15, 16, 0.08);
  border: solid 1px easilGrayXLight;
  border-radius: 4px;
  text-align: center;
}

.fieldDouble label,
.fieldSingle label {
  display: block;
  text-align: center;
  color: easilGray;
  padding-top: 4px;
  text-transform: capitalize;
}

.pickerHeader {
  display: flex;
  padding-bottom: 10px;
  justify-content: space-between;
  align-items: center;
}

.pickerHeader > div {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: easilBlack;
}

.eyeDropperButtonHex button {
  width: 32px;
  border-radius: 4px;
  box-shadow: 0 2px 3px 0 rgba(15, 15, 16, 0.08);
  border: solid 1px easilGrayXLight;
  padding: 0;
}
