@value colors: "~views/style.css";
@value easilBlueDeep, easilGrayXLight, easilGrayXDark, easilRed from colors;

.headerButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: max-content;
  height: 46px;
  padding: 12px 24px 12px 16px;
}

.headerButtonDisabled {
  composes: headerButton;
  color: #3184fc;
  cursor: default;
}

.iconBoundingBox {
  align-items: center;
  display: flex;
  height: 24px;
  justify-content: center;
  width: 24px;
  margin-right: 8px;
}

.uploadModalTo {
  width: 650px;
  height: 392px;
  border-radius: 8px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 32px;
}

.uploadModalDetails {
  width: 650px;
  max-height: 526px;
  border-radius: 8px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 32px;
}

.transferModalDetails {
  composes: uploadModalDetails;
  width: 836px;
  height: 545px;
  max-height: none;
}

.transferDetailsModal {
  composes: uploadModalDetails;
  width: 836px;
}

.transferUploadHeader {
  display: flex;
  align-items: center;
}
.transferUploadHeader > div:first-child {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.transferModalDetails > div:first-child {
  margin-bottom: 32px;
}

.uploadContent {
  width: 594px;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  /* padding: 12px; */
}

.tansferContent {
  composes: uploadContent;
  width: 100%;
}

.uploadContentSubText {
  font-family: Roboto;
  font-size: 15px;
  line-height: 1.6;
  color: #57595d;
}

.uploadUploadingTo {
  font-family: Roboto;
  font-size: 15px;
  line-height: 1.6;
  color: #7a7d81;
  margin-top: 24px;
}

.uploadLocation {
  color: #57595d;
}

.uploadTable {
  width: 100%;
  max-height: 240px;
  overflow-y: auto;
  margin-top: 13px;
  margin-bottom: 32px;
}

.uploadError {
  margin: 0px 0px 10px 17px;
  color: easilRed
}

.uploadTableRow {
  height: 48px;
  width: 100%;
  border-top: 1px solid #e2e5e8;
  display: flex;
  align-items: center;
  padding-left: 16px;
}

.uploadImageIconWrapper {
  width: 24px;
  height: 24px;
}

.uploadTableRow:last-of-type {
  border-bottom: 1px solid #e2e5e8;
}

.uploadRowLeft {
  display: flex;
  flex-direction: row;
  flex: 1;
}

.uploadRowRight {
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  margin-right: 16px;
}

.uploadRowRight > div {
  margin-right: 33px;
  display: flex;
}

.uploadRowRight:last-child {
  margin-right: 0px;
}

.selectButtons > button {
  height: 40px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
}

.uploadButtons {
  margin-top: 0px;
}

.uploadButtons > button {
  height: 40px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
}

.uploadRowText {
  font-family: Roboto;
  font-size: 15px;
  line-height: 1.6;
  color: #57595d;
  margin-left: 12px;
  width: 340px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* .folderSelectionContent {
  width: 586px;
  height: 200px;
  border-radius: 4px;
  border: solid 1px easilGrayXLight;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 12px;
  overflow-y: auto;
} */

/* .folderSelectionContentItem {
  width: 562px;
  min-height: 36px;
  height: 36px;
  border-radius: 4px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 6px;
  font-family: Roboto;
  font-size: 15px;
  line-height: 1.6;
  color: easilGrayXDark;
  cursor: pointer;
} */

/* .folderSelectionContentItem[data-is-selected="true"] {
  background-color: #e3f1ff;
}

.folderSelectionContentItem[data-is-sub="true"] {
  margin-left : 16px;
  width: 544px;
} */

/* .uploadFolderIconWrapper {
  height: 24px;
  width: 24px;
  margin-right: 12px;
} */

/* .folderSelectionContentTickWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding-top: 5px;
  margin-right: 12px;
  margin-left: auto;
} */

/* .folderSelectionFolderTitle {
  width: 100%;
  font-family: Roboto;
  font-size: 15px;
  line-height: 1.6;
  color: easilGrayXDark;
} */

.inputfile {
  display: none;
}

/* .uploadFolderName {
  width: 100%;
  max-width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
} */

.smartImageTransferContent {
  width: 100% !important;
  height: 361px !important;
}

.smartImageTransferContent > * {
  width: 100% !important;
}

.transferButtons {
  margin-top: 24px;
}