
.billingSectionWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 8px 0px;
}

.billingSubSection {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.billingSubSection:last-of-type {
  margin-bottom: 40px;
}

.billingTitle {
  margin-top: 48px;
}

.shippingTitle {
  margin-top: 40px;
}

.checkbox {
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
}

.checkbox > span {
  height: 20px;
  width: 20px;
  border-radius: 4px;
  border: solid 1px #d1d4d6;
}

.checkbox > span::after {
  background-position-x: center;
  background-position-y: center;
  background-size: contain;
  height: 9px !important;
  width: 11px !important;
}

.nextButtonWrapper {
  display: flex;
  justify-content: flex-end;
  width: 680px;
  margin-top: 24px;
}

.loading, .empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
  width: 680px;
}

.info {
  align-items: flex-start !important;
  margin-top: 24px;
}

.info > div {
  font-size: 14px;
  line-height: 1.57 !important;
  color: #2c2e2f;
}