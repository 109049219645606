.confirmationModal {
  width: 550px;
  border-radius: 8px;
  background-color: #fff;
  padding: 32px;
}

.confirmationModalContent {
  white-space: pre-line;
  font-family: Roboto;
  font-size: 15px;
  line-height: 24px;
  color: #57595d;
}

.hideButtons {
  display: none
}