@value colors: "~views/colors.css";
@value easilBlack from colors;

.toolTip {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  font-size: 12px;
  line-height: 20px;
  padding: 5px 12px !important;
  color: white;
  background-color: easilBlack !important;
  text-align: center;
  opacity: 1 !important;
  white-space: pre-line;
}

/* our design decided align left multiline tooltips */
.toolTip > span {
  text-align: left !important;
}
.toolTip::after {
  border-left-color: transparent !important;
  border-bottom-color: transparent !important;
}
