@value colors: "../../colors.css";
@value easilBlue from colors;

.switch {
  position: relative;
  display: block;
}

.switch input {display:none;}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 76%;
  width: 43%;
  left: 8%;
  bottom: 12.5%;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2d70cf;
  /*background-color: easilBlue;*/
}

input:checked + .slider:before {
  transform: translateX(100%);
}

.switch > svg {
  fill: #fff;
  pointer-events: none;
  position: absolute;
}
