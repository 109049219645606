@value colors: "../style.css";
@value easilBlack, easilGrayXLight from colors;

.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  font-size: 32px;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  line-height: 24px;
  font-weight: bold;
  background-color: easilBlack;
  border: solid 2px white;
  color: white;
  background-position: center;
  background-size: cover;
}

.avatar[data-has-double-border="true"] {
  border: 2px solid;
}

.light {
  background-color: white;
  color: easilBlack;
  border: solid 2px easilBlack;
}

.dark {
  background-color: easilBlack;
  color: white;
  border: none;
}

.gray {
  background-color: easilGrayXLight;
  color: white;
  border: solid 2px white;
}

.chars {
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  user-select: none;
}