@value colors: "../style.css";
@value easilGrayXLight, easilGrayXXLight, easilRed, easilBlack from colors;

.fontName {
  margin-right: -4px;
  max-width: 160px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fontDropZone {
  align-items: center;
  background-color: easilGrayXXLight;
  border: 1px dashed easilGrayXLight;
  border-radius: 4px;
  display: flex;
  min-height: 88px;
  padding: 24px;
  cursor: pointer;
  flex-direction: column;

  font-family: Roboto;
  font-size: 14px;
  text-align: center;
  color: #57595d;

  max-height: 60vh;
  overflow-y: scroll;
}

.fontDropZone .remove {
  cursor: pointer;
  margin-left: 12px;
}

.uploadedFontMeta {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  text-align: center;
  line-height: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 14px;
  width: 100%;
  min-height: 20px;
}

.uploadedFontMeta:first-of-type {
  margin-top:0;
}

.uploadedFontMeta > span {
  min-height: 15px;
  max-width: calc(100% - 32px);
}

.uploadedFontMeta > div {
  margin: 0;
}

.dropzone {
  width: 128px;
  height: 128px;
  border-radius: 50%;
  background-color: easilGrayXXLight;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropzone img {
  width: 128px;
  height: 128px;
  border-radius: 50%;
}

.dropzone[data-is-dragover="true"] {
  border: 2px dashed black;
}

.component {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon {
  pointer-events: none;
}

.inputfile {
  display: none
}

.fontUploadContent > label {
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
}

.fontUploadSubText {
  font-family: Roboto;
  font-size: 11px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: 0.08px;
  text-align: center;
  color: #d1d4d6;
}

.error {
  margin-top: 8px;
  text-align: center;
  color: easilRed;
  font-size: 12px;
  animation: fade 0s ease-in 5s forwards;
  animation-fill-mode: forwards;
}

@keyframes fade {
  to {
    width:0;
    height:0;
    overflow:hidden;
  }
}
@-webkit-keyframes fade {
  to {
    width:0;
    height:0;
    visibility:hidden;
  }
}
