@import url(https://fonts.googleapis.com/css?family=Montserrat);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700);
/* TODO: review if Montserrat is part of defautl fonts in the editor */

html {
  display: flex;
  flex-direction: column;
}

html,
body,
#root {
  height: 100vh
}

/* Hide UserEngage widget Icon. More efficient than handling in userChat.js */
#usercom-launcher-dot-frame {
  visibility: hidden;
}

.usercom-launcher-counter {
  visibility: hidden;
}




.style_wrapper__3Tozs {
  display: flex;
  flex-direction: row;
}

.style_leftPanel__kr4aa {
  flex: 1 0 80%;
}

.style_rightPanel__3FpKu {
  flex: 1 1 6px;
  min-height: 140px;
  border-left: 1px solid rgba(255, 255, 255, 0.16);
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: auto;
}

.style_rightPanel__3FpKu > svg {
  cursor: pointer;
  pointer-events: all;
}

.style_rightPanel__3FpKu > svg:hover {
  opacity: 0.64;
}

.style_toastContentWrapper__PTq-7 {
  margin: 16px;
  display: flex;
  flex-direction: row;
  height: 95px;
}

.style_toastContentLeft__2c9Zd {
  padding-right: 20px;
}

.style_toastContentRight__3vxnK {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: baseline;
}

.style_toastContentRight__3vxnK > :first-child {
  margin: 0px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: rgba(255, 255, 255, 0.8);
}

.style_toastContentRight__3vxnK > :first-child > a {
  font-weight: 500;
  cursor: pointer;
  pointer-events: auto;
  color: #fff;
}

.style_toastContentRight__3vxnK > :first-child > a:hover {
  text-decoration: underline;
}

.style_toastContentRight__3vxnK > button {
  pointer-events: auto;
}

.style_toastContentRight__3vxnK > button:hover {
  opacity: 0.90;
}

.style_withoutButtonWrapper__P7lxA {
  height: 84px;
}

.style_withoutButtonWrapper__P7lxA > div:last-of-type {
  min-height: 84px;
}

.style_iconCircle__3ashP {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 50%;
}

.style_button__3MZLn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #3184fc;
  height: 40px;
  border-radius: 8px;
  border: solid 1px #3184fc;
  padding: 8px 24px 8px 24px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  cursor: pointer;
}

.style_button__3MZLn[data-disabled="true"] {
  border: solid 1px #D1D4D6;
  background-color: #D1D4D6;
  color: white;
  cursor: not-allowed;
}

.style_button__3MZLn[data-is-full-width="true"], .style_button__3MZLn[data-is-extra-wide="true"] {
  width: 100%;
  padding: 8px 32px 8px 32px;
}

.style_button__3MZLn[data-is-hoverable="true"]:hover {
  opacity: 0.9;
}

.style_button__3MZLn:focus {
  outline: none;
}

.style_gray__1m6Mu {
  border: solid 1px #D1D4D6;
  background-color: white;
  color: #57595D;
}

.style_grayBackgroundWhiteFont__2e8ah {
  background-color: #a1a7ab;
  color: white;
  border: solid 1px #a1a7ab;
}

.style_grayBackgroundXDarkGrayFont__1Vp3i {
  background-color: #e5e8eb;
  color: #57595D;
  border: 1px solid #e5e8eb;
}

.style_grayBackgroundXDarkGrayFont__1Vp3i:hover {
  background-color: #d9dcdf;
  color: #57595D;
  border: 1px solid #d9dcdf;
}

.style_grayBackgroundXDarkGrayFontDisabled__293L2 {
  color: #57595D !important;
  border: 1px solid #e5e8eb !important;
  background-color: #e5e8eb !important;
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
}

.style_grayBorderOnly__1zdEL {
  border: solid 1px #D1D4D6;
  background-color: transparent;
  color: #2c2e2f;
}

.style_whiteBorderWhiteFont__3S2Sz {
  border: solid 2px white;
  background-color: transparent;
  color: white;
}

.style_whiteBorderWhiteFontWithTransition__2eA4Y {
  border: solid 2px white;
  background-color: rgba(255, 255, 255, 0);
  color: white;
  transition: color 100ms ease;
  transition: background-color 100ms ease;
}

.style_whiteBorderWhiteFontWithTransition__2eA4Y:hover {
  border: solid 2px white;
  background-color: rgba(255, 255, 255, 1);
  color: #2c2e2f;
}

.style_xLightGrayBorderWhiteFont__Zk4EC {
  border: solid 1px #D1D4D6;
  background-color: transparent;
  color: white;
}

.style_xLightGrayBorderXDarkGrayFont__2A4_s {
  border: solid 1px #D1D4D6;
  background-color: transparent;
  color: #57595D;
}

.style_grayBorderWhiteFont__5IXTN {
  border: solid 1px #56585c;
  background-color: transparent;
  color: white;
}

.style_grayBorderWhiteFontDisabled__1FMvn {
  border: solid 1px #56585c !important;
  background-color: transparent !important;
  color: white !important;
  opacity: 0.48;
}

.style_blue__BhfKB {
  border: solid 1px white;
  background-color: #3184fc;
  color: white;
}

.style_blueTall__1r_rq {
  height: 40px;
  border: solid 1px white;
  background-color: #3184fc;
  color: white;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.6;
}

.style_blueSolid__3-_56 {
  border: solid 1px #3184fc;
  background-color: #3184fc;
  color: white;
}

.style_blueSolid__3-_56:hover {
  background-color: #2e7def;
  border: solid 1px #2e7def;
}

.style_blueSolidWithTransition__1eSKC {
  border: none;
  background-color: #2C6FCFff;
  color: white;
  transition: background-color 100ms ease;
}

.style_blueDarkSolid__1_-Ns {
  border: solid 1px #2D70CF;
  background-color: #2D70CF;
  color: white;
}

.style_blueSolidWithTransition__1eSKC:hover {
  background-color: #2C6FCFe1;
}

.style_greenDarkSolidWithTransition__2oZb2 {
  border: none;
  background-color: #11b992ff;
  color: white;
  line-height: 1;
  transition: background-color 100ms ease;
}

.style_greenDarkSolidWithTransition__2oZb2:hover {
  background-color: #11b992e1;
}

.style_blueDeepNoBackgroundNoBorder__3_KyJ {
  color: easilBlueDeep;
  border: none;
}

.style_blueSolidNoBackgroundNoBorder__NOqKZ {
  background-color: rgba(0, 0, 0, 0);
  color: #3184fc;
  border: none;
}

.style_blueSolidNoBackgroundNoBorder__NOqKZ:hover {
  background-color: #e5e8eb;
}

.style_blueOutline__aLLs0 {
  border: solid 1px #3184fc;
  background-color: white;
  color: #3184fc;
}

.style_whiteSolidBlueFontNoBorder__2z3o_ {
  border: none;
  background-color: white;
  color: #3184fc;
}

.style_royalBlue__1UCLi {
  border: solid 1px white;
  background-color: #4766dfff;
  color: white;
}

.style_royalBlueSolid__3AgPE {
  border: solid 1px #4766dfff;
  background-color: #4766dfff;
  color: white;
}

.style_royalBlueOutline__1wgt- {
  border: solid 1px #4766dfff;
  background-color: white;
  color: #4766dfff;
}

.style_royalBlueWithTransition__1oWqe {
  border: none;
  background-color: #4766dfff;
  color: white;
  line-height: 1;
  transition: background-color 100ms ease;
}

.style_royalBlueWithTransition__1oWqe:hover {
  background-color: #4766DFE1;
}

.style_blackSolid__2NZ06 {
  border: solid 1px #333333;
  background-color: #333333;
  color: white;
}

.style_blueLight__3rIRP {
  border: solid 1px #3184fc;
  background-color: white;
  color: #3184fc;
  transition: 300ms all ease-in-out;
}

.style_blueLight__3rIRP:hover {
  background-color: #3184fc;
  color: #fff;
}

.style_red__3SjPf {
  border: solid 1px white;
  background-color: #E64826;
  color: white;
}

.style_redSolid__2bAKS {
  border: solid 1px #E64826;
  background-color: #E64826;
  color: white;
}

.style_redSolid__2bAKS:hover {
  border: solid 1px #da4424;
  background-color: #da4424;
}

.style_redLight__3PENN {
  border: solid 1px #E64826;
  background-color: white;
  color: #E64826;
}

.style_white__SKu-z {
  border: solid 1px white;
  background-color: #fff;
  color: #2c2e2f;
}

.style_greenSolid__3AruK {
  border: solid 1px #1dcda3;
  background-color: #1dcda3;
  color: white;
}

.style_greenSolid__3AruK:hover {
  border: solid 1px #1bc29a;
  background-color: #1bc29a;
}

.style_greenMidSolid__38v1b {
  border: solid 1px #14c79d;
  background-color: #14c79d;
  color: white;
}

.style_actionbar__ndxy1 {
  height: 32px;
  border: solid 1px #D1D4D6;
  background-color: transparent;
  color: #57595D;
  width: 100%;
}

.style_actionbarNarrow__2nN9O {
  height: 32px;
  border: solid 1px #D1D4D6;
  background-color: transparent;
  color: #57595D;
  padding: 0px 8px 0px 8px;
}

.style_actionbarNarrow__2nN9O[data-is-active="true"] {
  border: solid 1px #57595D;
}

.style_actionbar__ndxy1[data-disabled="true"] {
  border: solid 1px #D1D4D6;
  background-color: transparent;
  color: #D1D4D6;
  cursor: not-allowed;
}

.style_canvas__kIMRL {
  height: 40px;
  border-radius: 8px;
  border: solid 1px #c5c8ca;
  background-color: transparent;
  line-height: 2.86;
  letter-spacing: normal;
  color: #a1a6aa;
  box-sizing: content-box;
  width: auto !important;
}

.style_disabledBlue__1CM-c[data-disabled="true"] {
  background-color: #3184fc;
  border-color: #3184fc;
  opacity: 1;
}

.style_loginSignupButtons__6k-Ok {
  padding: 0 24px !important;
  height: 48px;
}

.style_facebook__2hNqd {
  color: #ffffff;
  background-color: #1877f2;
  border-color: #1877f2;
}

.style_facebook__2hNqd:hover {
  background-color: #2e7def;
  border-color: #2e7def;
}

.style_google__2gawu {
  color: rgba(0, 0, 0, 0.54);
  background-color: #ffffff;
  border: none;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.24), 0 0 1px 0 rgba(0, 0, 0, 0.12);
}

.style_google__2gawu:hover {
  background-color: #f5f5f6;
}

.style_NoBackgroundNoBorder__l0lcW {
  background-color: rgba(0, 0, 0, 0);
  border: none;
}

.style_greyFontBorderTransparentBackground__3bfoT {
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #ffffff;
  color: #ffffff;
}

/*
  Standard more button styling
  MoreButton component should be preferred to directly referencing this style
*/
.style_loadMoreButton__2Xvwa {
  min-width: 211px !important;
  width: auto !important;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.5;
  color: white;
  justify-content: center !important;
  margin-top: 16px;
  background-color: #3184FC;
  transition: background-color 0.15s ease;
}

.style_loadMoreButton__2Xvwa:hover {
  background-color: #2E7DEF;
}

.style_loader__20GGm {
  border-radius: 50%;
  width: 64px;
  height: 64px;
  margin: auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 4px solid rgba(209, 212, 214, 0.5);
  border-right: 4px solid rgba(209, 212, 214, 0.5);
  border-bottom: 4px solid rgba(209, 212, 214, 0.5);
  border-left: 4px solid #D1D4D6;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: style_load8__xK7kj 1.1s infinite linear;
  animation: style_load8__xK7kj 1.1s infinite linear;
  overflow: hidden;
}
@-webkit-keyframes style_load8__xK7kj {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes style_load8__xK7kj {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.libStyles_toasterToast__7EViX {
  height: auto;
  width: 450px;
  box-shadow: 0 2px 4px 0 rgba(15, 15, 16, 0.12) !important;
  background-color: #272829 !important;
  border-radius: 8px !important;
  padding: 0 !important;
  pointer-events: none;/* this prevents default onClick for the toast which causes it to close */
}
.styleSelectionBox_dot__3XSF1 {
  height: 12px;
  width: 12px;
  border-radius: 100%;
  border: 1px solid #3184FC;
  background-color : #fff;
  z-index: 5;
  pointer-events: auto;
  box-shadow: 0 0 4px rgba(15,15,16,.08);
}

.styleSelectionBox_croppingDot__3abw3 {
  position: absolute;
  height: 20px;
  width: 20px;
}

.styleSelectionBox_dotDummy__3tUnE {
  cursor: default;
  pointer-events:  none;
}

/* hide resize handles when textbox is in edit mode */
.styleSelectionBox_dot__3XSF1[data-is-editing="true"], .styleSelectionBox_dotWrapper__2laOx[data-is-editing="true"] {
  display: none;
}

.styleSelectionBox_croppingDot__3abw3[data-position="NW"] {
  cursor: nwse-resize;
  top: -10px;
  left: -10px;
}

.styleSelectionBox_dotWrapper__2laOx[data-position="NW"] {
  cursor: nwse-resize;
  top: -8px;
  left: -8px;
}

.styleSelectionBox_dotWrapper__2laOx[data-position="NW"][data-min-height="true"] {
  top: -12px;
}

.styleSelectionBox_dotWrapper__2laOx[data-position="NW"][data-min-width="true"] {
  left: -12px;
}

.styleSelectionBox_dotWrapper__2laOx[data-position="NW"][data-min-height="true"][data-x-handles="true"] {
  top: -22px;
}

.styleSelectionBox_dotWrapper__2laOx[data-position="NW"][data-min-width="true"][data-y-handles="true"] {
  left: -22px;
}

/* reassign handle position when crop handles are too close */
.styleSelectionBox_dotWrapper__2laOx[data-position="NW"][data-x-proximity="true"] {
  left: -22px;
}
.styleSelectionBox_dotWrapper__2laOx[data-position="NW"][data-y-proximity="true"] {
  top: -22px;
}

.styleSelectionBox_croppingDot__3abw3[data-position="NE"] {
  cursor: nesw-resize;
  top: -10px;
  right: -10px;
}

.styleSelectionBox_dotWrapper__2laOx[data-position="NE"] {
  cursor: nesw-resize;
  top: -8px;
  right: -8px;
}

.styleSelectionBox_dotWrapper__2laOx[data-position="NE"][data-min-height="true"] {
  top: -12px;
}

.styleSelectionBox_dotWrapper__2laOx[data-position="NE"][data-min-width="true"] {
  right: -12px;
}

.styleSelectionBox_dotWrapper__2laOx[data-position="NE"][data-min-height="true"][data-x-handles="true"] {
  top: -22px;
}

.styleSelectionBox_dotWrapper__2laOx[data-position="NE"][data-min-width="true"][data-y-handles="true"] {
  right: -22px;
}

/* reassign handle position when crop handles are too close */
.styleSelectionBox_dotWrapper__2laOx[data-position="NE"][data-x-proximity="true"] {
  right: -22px;
}
.styleSelectionBox_dotWrapper__2laOx[data-position="NE"][data-y-proximity="true"] {
  top: -22px;
}

.styleSelectionBox_croppingDot__3abw3[data-position="SW"] {
  cursor:  nesw-resize;
  bottom: -10px;
  left: -10px;
}

.styleSelectionBox_dotWrapper__2laOx[data-position="SW"] {
  cursor:  nesw-resize;
  bottom: -8px;
  left: -8px;
}

.styleSelectionBox_dotWrapper__2laOx[data-position="SE"][data-min-height="true"] {
  bottom: -12px;
}

.styleSelectionBox_dotWrapper__2laOx[data-position="SE"][data-min-width="true"] {
  right: -12px;
}

.styleSelectionBox_dotWrapper__2laOx[data-position="SE"][data-min-height="true"][data-x-handles="true"] {
  bottom: -22px;
}

.styleSelectionBox_dotWrapper__2laOx[data-position="SE"][data-min-width="true"][data-y-handles="true"] {
  right: -22px;
}

/* reassign handle position when crop handles are too close */
.styleSelectionBox_dotWrapper__2laOx[data-position="SE"][data-x-proximity="true"] {
  right: -22px;
}
.styleSelectionBox_dotWrapper__2laOx[data-position="SE"][data-y-proximity="true"] {
  bottom: -22px;
}

.styleSelectionBox_dotWrapper__2laOx[data-position="SW"][data-min-height="true"] {
  bottom: -12px;
}


.styleSelectionBox_dotWrapper__2laOx[data-position="SW"][data-min-width="true"] {
  left: -12px;
}

.styleSelectionBox_dotWrapper__2laOx[data-position="SW"][data-min-height="true"][data-x-handles="true"] {
  bottom: -22px;
}

.styleSelectionBox_dotWrapper__2laOx[data-position="SW"][data-min-width="true"][data-y-handles="true"] {
  left: -22px;
}

/* reassign handle position when crop handles are too close */
.styleSelectionBox_dotWrapper__2laOx[data-position="SW"][data-x-proximity="true"] {
  left: -22px;
}
.styleSelectionBox_dotWrapper__2laOx[data-position="SW"][data-y-proximity="true"] {
  bottom: -22px;
}

.styleSelectionBox_croppingDot__3abw3[data-position="SE"] {
  cursor: nwse-resize;
  bottom: -10px;
  right: -10px;
}

.styleSelectionBox_dotWrapper__2laOx[data-position="SE"] {
  cursor: nwse-resize;
  bottom: -8px;
  right: -8px;
}

.styleSelectionBox_croppingDot__3abw3[data-position="E"], .styleSelectionBox_dot__3XSF1[data-position="E"] {
  top: calc(50% - 8px);
  right: calc(100% - 3px);
  cursor: ew-resize;
  width: 6px;
  height: 16px;
  border-radius: 50px;
  box-shadow: 0 0 4px rgba(15,15,16,.08);
}

.styleSelectionBox_dotWrapper__2laOx[data-position="E"] {
  top: calc(50% - 10px);
  right: calc(100% - 8px);
  cursor: ew-resize;
  width: 16px;
  height: 20px;
  border-radius: 50px;
  box-shadow: 0 0 4px rgba(15,15,16,.08);
}

.styleSelectionBox_croppingDot__3abw3[data-position="W"], .styleSelectionBox_dot__3XSF1[data-position="W"] {
  top: calc(50% - 8px);
  right: calc(0% - 3px);
  cursor: ew-resize;
  width: 6px;
  height: 16px;
  border-radius: 50px;
  box-shadow: 0 0 4px rgba(15,15,16,.08);
}

.styleSelectionBox_dotWrapper__2laOx[data-position="W"] {
  top: calc(50% - 10px);
  right: calc(0% - 8px);
  cursor: ew-resize;
  width: 16px;
  height: 20px;
  border-radius: 50px;
  box-shadow: 0 0 4px rgba(15,15,16,.08);
}

.styleSelectionBox_croppingDot__3abw3[data-position="N"], .styleSelectionBox_dot__3XSF1[data-position="N"] {
  top: calc(0% - 3px);
  right: calc(50% - 8px);
  cursor: ns-resize;
  width: 16px;
  height: 6px;
  border-radius: 50px;
  box-shadow: 0 0 4px rgba(15,15,16,.08);
}

.styleSelectionBox_dotWrapper__2laOx[data-position="N"] {
  top: calc(0% - 8px);
  right: calc(50% - 10px);
  cursor: ns-resize;
  width: 20px;
  height: 16px;
  border-radius: 50px;
  box-shadow: 0 0 4px rgba(15,15,16,.08);
}

.styleSelectionBox_croppingDot__3abw3[data-position="S"], .styleSelectionBox_dot__3XSF1[data-position="S"] {
  top: calc(100% - 3px);
  right: calc(50% - 8px);
  cursor: ns-resize;
  width: 16px;
  height: 6px;
  border-radius: 50px;
  box-shadow: 0 0 4px rgba(15,15,16,.08);
}

.styleSelectionBox_dotWrapper__2laOx[data-position="S"] {
  top: calc(100% - 8px);
  right: calc(50% - 10px);
  cursor: ns-resize;
  width: 20px;
  height: 16px;
  border-radius: 50px;
  box-shadow: 0 0 4px rgba(15,15,16,.08);
}

.styleSelectionBox_croppingDot__3abw3[data-is-crop="true"], .styleSelectionBox_dotWrapper__2laOx[data-is-crop="true"] {
  background-color: transparent;
  border: none;
  -webkit-transform: translate(2px, 2px);
          transform: translate(2px, 2px);
}

.styleSelectionBox_croppingDot__3abw3[data-is-crop="true"][data-position="NE"], .styleSelectionBox_dotWrapper__2laOx[data-is-crop="true"][data-position="NE"] {
  -webkit-transform: rotate(90deg) translate(2px, 2px);
          transform: rotate(90deg) translate(2px, 2px);;
}
.styleSelectionBox_croppingDot__3abw3[data-is-crop="true"][data-position="SE"], .styleSelectionBox_dotWrapper__2laOx[data-is-crop="true"][data-position="SE"] {
  -webkit-transform: rotate(180deg) translate(2px, 2px);
          transform: rotate(180deg) translate(2px, 2px);;
}
.styleSelectionBox_croppingDot__3abw3[data-is-crop="true"][data-position="SW"], .styleSelectionBox_dotWrapper__2laOx[data-is-crop="true"][data-position="SW"] {
  -webkit-transform: rotate(270deg) translate(2px, 2px);
          transform: rotate(270deg) translate(2px, 2px);;
}

.styleSelectionBox_lowerHandles__3y7tD, .styleSelectionBox_upperHandles__3nw0u {
  position: absolute;
  top: 5px;
  left: 5px;
}

.styleSelectionBox_lowerHandles__3y7tD > .styleSelectionBox_verticalHandle__3wWo_ {
  width: 6px;
  height: 16px;
  background-color: #3184FC;;
  position: absolute;
  z-index: 2;
  border-radius: 4px;
}

.styleSelectionBox_lowerHandles__3y7tD > .styleSelectionBox_horizontalHandle__3KxOd {
  width: 16px;
  height: 6px;
  background-color: #3184FC;;
  position: absolute;
  z-index: 1;
  border-radius: 4px;
}

.styleSelectionBox_upperHandles__3nw0u > .styleSelectionBox_verticalHandle__3wWo_ {
  width: 4px;
  height: 14px;
  top: 1px;
  left: 1px;
  background-color: #fff;
  position: absolute;
  z-index: 4;
  border-radius: 4px;
}

.styleSelectionBox_upperHandles__3nw0u > .styleSelectionBox_horizontalHandle__3KxOd {
  width: 14px;
  height: 4px;
  top: 1px;
  left: 1px;
  background-color: #fff;
  position: absolute;
  z-index: 3;
  border-radius: 4px;
}

.styleSelectionBox_dotWrapper__2laOx {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 16px;
  width: 16px;
  pointer-events: auto;
  z-index: 4;
}
:root {
  --grey-white: #ffffff;
}

.style_canvas__2oYW9 {
  display:block;
  position: relative;
}

.style_canvas__2oYW9[data-has-transparent-bg="true"] {
  background-position: 0px 0px, 0px 10px, 10px -10px, -10px 0px !important;
  background-size: 20px 20px !important;
  background-image: linear-gradient(45deg, rgb(223, 223, 223) 25%, transparent 25%), linear-gradient(-45deg, rgb(223, 223, 223) 25%, transparent 25%), linear-gradient(45deg, transparent 75%, rgb(223, 223, 223) 75%), linear-gradient(-45deg, transparent 75%, rgb(223, 223, 223) 75%) !important
}

.style_canvas__2oYW9 > div[id^="PAGE_CANVAS"] {
  margin-top: 36px !important;
  margin-top: var(--calculated-canvas-margin-top, 36px) !important;
}

.style_canvas__2oYW9 ~ .style_canvas__2oYW9 > div[id^="PAGE_CANVAS"] {
  margin-top: 0px !important;
}

#style_sideOptionsTip__1uCBQ::after {
  border-right-color: transparent !important;
  border-radius: 4px;
  font-size: 12px;
  color: white;
  height: 20px;
  width: 20px;
}

.style_canvasOptions__1_9as {
  height: 187px;
  width: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 500;
  color: #8f9297;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(21, 21, 22, 0.12);
}

.style_animationControlContainer__2YeC5 {
  position: absolute;
  height: 100%;
  width: 77px;
  left: calc(-20% - 10px);
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 3;
}

.style_canvasOptionsContainer__2HMzO {
  position: absolute;
  height: 100%;
  width: 77px;
  left: calc(100% + 10px);
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 3;
}

.style_canvasPage__J-TAI {
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.style_canvasOptions__1_9as > div:not(.style_canvasOptionsSelectedDot__1FRBQ):not(.style_pageNumbersSmall__i8lqS):not(.style_lineBreak__3-Pno):not(.style_pageNumbersBig__3Pcvh)  {
  cursor: pointer;
  width: 20px;
  height: 20px;
}

.style_canvasOptionsSelectedDot__1FRBQ {
  width: 6px;
  height: 6px;
  background-color: #3184fc;
  border-radius: 100%;
  margin: 10px !important;
  margin-bottom: 21px !important;
}

.style_canvasOptionsDot__1ds6q {
  background-size: cover;
  height: 48px;
  margin: 0 !important;
  width: 48px;
}

.style_pageNumbersBig__3Pcvh {
  display: flex;
  font-size: 18px;
  font-weight: 500;
  max-height: 21px;
  margin-bottom: 4px;
}

.style_pageNumbersSmall__i8lqS {
  display: flex;
  font-size: 14px;
  font-weight: 500;
  max-height: 21px;
  margin-bottom: 4px;
}

.style_animationControl__3zJ1b {
  width: 32px;
  height: 77px;
  margin: 0 12px 12px 0;
  padding: 10px 6px 8px;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(21, 21, 22, 0.12);
  background-color: #ffffff;
  background-color: var(--grey-white);
}

.style_animationPlayBox__1SSTe {
  width: 20px;
  height: 20px;
  padding: 4px 4px 3px 5px;
  border-radius: 4px;
  background-color: greenyellow;
}


.style_animationPlayButton__2qo3O {
  width: 20px;
  height: 20px;
  border: none;
  border-radius: 4px;
}

.style_animationPlayButton__2qo3O:hover {
  background: #f5f5f8;
}

.style_animationOptionsButton__v4c6B {
  width: 20px;
  height: 20px;
  border-radius: 4px;
}

.style_animationOptionsButton__v4c6B:hover {
  background: #f5f5f8;
}

.style_animationControlDivide__122Xs {
  width: 20px;
  height: 1px;
  margin: 10px 0 8px;
  background-color: #e5e8eb;
}

.style_animationDurationBox__1K4a0 {
  width: 20px;
  height: 20px;
  margin: 8px 0 0;
  padding: 9px 3px 8px 4px;
  border-radius: 4px;
}

.style_popoutDuration__2jB80 {
  position: absolute;
  width: 253px;
  height: 88px;
  margin: 15px 0 0 4px;
  padding: 12px 16px;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(21, 21, 22, 0.12);
  border: solid 1px #d1d4d6;
  background-color: #fff;
  top: -65px;
  left: 25px;
  overflow: hidden;
}

.style_durationRangeInput__3KVOO > label {
  display: none;
}

.style_durationRangeInput__3KVOO > div:first-of-type {
  padding-left: 0px !important;
  width: 100% !important;
}

.style_durationRangeInput__3KVOO > div:last-of-type {
  width: 60px !important;
}

.style_durationLabel__3ZYqe {
  text-align: left;
}

.style_numberInput__1Qias {
  margin: 3px 0px 0px -7px;
}

.style_lineBreak__3-Pno {
  width: 20px;
  height: 1px;
  margin-top: 4px;
  margin-bottom: 8px;
  background-color: #e5e8eb;
  pointer-events: none;
}

.style_settingsPopoutWrapper__2lBxm {
  width: 197px;
  min-height: 92px;
  height: auto;
  padding: 12px 16px;
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(21, 21, 22, 0.12);
  border: solid 1px #d1d4d6;
  background-color: #ffffff;
  left: 36px;
  top: -46px;
  position: absolute;
  display: flex;
  flex-direction: column;
}

.style_measurementUnitChanger__3pjVY {
  display: flex;
  flex-direction: row;
  margin-bottom: 4px;
}

.style_unitChangerTitle__1i-QG {
  margin-right: 51px;
  font-family: Roboto;
  font-size: 14px;
  color: #8f9297;
}

.style_unitNameLabel__ojp0s {
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  color: #afb3b6;
  text-transform: uppercase;
}

.style_unitNameLabel__ojp0s[data-is-selected="true"] {
  color: #2c2e2f;
}

.style_unitNameLabel__ojp0s[data-is-selected="false"] {
  cursor: pointer;
}

.style_unitNameLabel__ojp0s:not(:last-of-type) {
  margin-right: 15px;
}

.style_snappingToggle__3byll {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.style_snappingToggle__3byll > label > svg {
  left: 0;
  -webkit-transform: scale(1.25);
          transform: scale(1.25);
}

.style_snappingToggle__3byll > label > input:checked + span {
  background-color: #3184fc;
}

.style_gridSnap__18ei6 {
  margin-top: 8px;
}

.style_arrowOption__1QAoV {
  display: flex;
  align-items: center;
  justify-content: center;
}

.style_guideHash__3yZSK {
  height: 20px;
  width: 20px;
  margin-top: 8px;
  margin-bottom: 4px;
}

.style_guideHash__3yZSK:hover {
  background: #f5f5f8;
  border-radius: 4px;
}

.style_duplicatePage__q94NR:hover {
  background: #f5f5f8;
  border-radius: 4px;
}

.style_deletePage__jfbT-:hover {
  background: #f5f5f8;
  border-radius: 4px;
}

.style_arrowOption__1QAoV:hover {
  background: #f5f5f8;
  border-radius: 4px;
}

.style_arrowDownOption__B2Z8X {
  margin-bottom: 4px;
  margin-top: 4px;
}

.style_duplicatePage__q94NR {
  margin-bottom: 8px;
}

.style_bleedInner__1V4-h{
  /* pointer-events: all; */
  /* background-color: red;; */
}

.style_bleedOutline__39h2t {
  display: flex;
  position: absolute;
  background-color: #3184fc;
  pointer-events: all;
}

.style_bleedInnerTop__3n4mr {
  width: 100%;
  height: 1px;
  top: 0px;
}

.style_bleedInnerBottom__3CJ_- {
  width: 100%;
  height: 1px;
  bottom: 0px;
  left: 0px;
}

.style_bleedInnerLeft__2hfSt {
  width: 1px;
  height: 100%;
  top: 0px;
  left: 0px;
}

.style_bleedInnerRight__3mnDk {
  width: 1px;
  height: 100%;
  top: 0px;
  right: 0px;
}

.style_areaLabel__2vUW5 {
  position: absolute;
  top: calc(0px + 4px);
  top: calc(var(--data-offset-top, 0px) + 4px);
  left: calc(0px + 4px);
  left: calc(var(--data-offset-left, 0px) + 4px);
  padding: 2px 4px;
  font-family: Roboto;
  font-size: 10px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: white;
  border-radius: 2px 4px 4px 4px;
}

.style_safeAreaLabel__3rleF {
  background-color: #3184fc;
  width: 52px;
  height: 15px;
}

.style_safeAreaBorder__dEnfr {
  background-color: #3184fc;
  opacity: 0.3;
}

.style_safeAreaBorder__dEnfr[data-is-hovered="true"] {
  opacity: 1;
}

.style_designAreaLabel__38SjZ {
  width: 63px;
  height: 15px;
  background-color: #2c2e2f;
}

.style_designAreaBorder__3RWPp {
  background-color: #2c2e2f;
  opacity: 0.3;
}

.style_designAreaBorder__3RWPp[data-is-hovered="true"] {
  background-color: #2c2e2f;
  opacity: 1;
}

.style_bleedAreaLabel__TKkp5 {
  width: 63px;
  height: 15px;
  background-color: #c01818;
  position: absolute;
  z-index: 5;
}

.style_bleedAreaBorder__3Apk2 {
  background-color: #c01818;
  opacity: 0.3;
  z-index: 3;
}

.style_bleedAreaBorder__3Apk2[data-is-hovered="true"] {
  background-color: #c01818;
  opacity: 1;
}
.style_actionbar__2ZeB5 {
  height: 48px;
  position: relative;
  width: 100%;
  z-index: 6; /* This needs to sit above selection boxes and canvas pages */
  background-color: white;
  box-shadow: 0px 1px 2px rgba(15,15,16,.04);
  padding: 0px 20px 0px 24px;
  display: flex;
  align-items: center;
}

.style_actionButton__3nCVl > div::after {
  border-bottom-color: transparent !important;
  border-radius: 4px;
  font-size: 12px;
  color: white;
}

.style_actionButton__3nCVl[data-not-allowed="true"] {
  opacity: 0.2;
}

.style_actionbarColumns__1yjAt {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.style_topLeftIcons__2Ujgz {
  display: flex;
  align-items: center;
}

.style_topRightIcons__2o30v {
  display: flex;
  margin-left: auto;
}

.style_topRightIcons__2o30v > div {
  margin: 0px 4px;
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.style_topRightIcons__2o30v > div:hover {
  border-radius: 4px;
  border: solid 1px #8F9295 !important;
  cursor: pointer;
}

.style_canvasBackground__2e7QX {
  text-align: center;
  position: absolute;
  top: 48px;
  width: 100%;
  height: calc(100% - 48px);
  overflow: auto;
  /*   padding: 25px; */
  -webkit-animation: style_fadeIn__2xRXu 1000ms ease-out;
          animation: style_fadeIn__2xRXu 1000ms ease-out;
}

@-webkit-keyframes style_fadeIn__2xRXu {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes style_fadeIn__2xRXu {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.style_canvasBackground__2e7QX[data-is-locked="true"] * {
  cursor: not-allowed;
}

.style_canvasBackgroundButton__3gVYT {
  margin: 24px auto 24px auto;
  display: flex;
  justify-content: center;
  height: 48px;
  margin-bottom: 24px;
  margin-bottom: var(--calculated-canvas-button-margin-bottom, 24px)
}

.style_hover__mSfWC {
  pointer-events: none;
  opacity: 1;
  position: absolute;
  border: 1px solid #3184FC;
  top: 0;
  left: 0;
}

.style_pasteFailToastContent__314k4 {
  font-size: 16px;
  line-height: 1.38;
  color: rgba(255, 255, 255, 0.8);
}

.style_pasteFailToast__2az4x > div {
  height: 106px;
}
.style_pasteFailToast__2az4x > div > div{
  height: 106px;
}
.style_pasteFailToast__2az4x > div > div > div > div > div:last-of-type {
  width: 295px;
}
.style_table__1eJIc {
  opacity: 1
}

:root {
  --marker-width: 45px;
  --font-size: 10;
  --paragraph-spacing: 100;
  --line-height: 1.4;
}

.style_contentEditable__2nCNt {
  -webkit-user-select: text;
      -ms-user-select: text;
          user-select: text;
}

.style_textCursorAnchor__25N8m a {
  cursor: text;
}

.style_moveCursorAnchor__3JUOa a {
  cursor: move;
}

.style_EditorTextbox__1Rjnr ul {
  margin-left: calc(1px * (10));
  margin-left: calc(1px * (var(--font-size)));
}

.style_EditorTextbox__1Rjnr ul[class*="numeric"] {
  counter-reset: tab;
}

.style_EditorTextbox__1Rjnr ul[class*="numeric"] > li::before {
  counter-increment: tab;
}

.style_EditorTextbox__1Rjnr > div > ul[class*="numeric"] > li::before {
  content: counter(tab) ".";
}

.style_EditorTextbox__1Rjnr > div > ul > li > ul[class*="numeric"] > li::before {
  content: counter(tab, lower-alpha) ".";
}

.style_EditorTextbox__1Rjnr > div > ul > li > ul ul[class*="numeric"] > li::before {
  content: counter(tab, lower-roman) ".";
}

.style_EditorTextbox__1Rjnr ul[class*="numeric"] li::before {
  position: absolute;
  right: calc(100% - (45px - (10 * 1px)));
  right: calc(100% - (45px - (var(--font-size) * 1px)));
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-width: 45px;
  min-width: var(--marker-width);
}

.style_EditorTextbox__1Rjnr li {
  position: relative;
}

.style_EditorTextbox__1Rjnr ul[class*="bullet-bullet"] > li::before {
  content: '•';
  position: absolute;
  right: calc(100% - (45px - (10 * 1px)));
  right: calc(100% - (45px - (var(--font-size) * 1px)));
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-width: 45px;
  min-width: var(--marker-width);
}

.style_EditorTextbox__1Rjnr ul[class*="bullet-square"] > li::before {
  content: '■';
  position: absolute;
  right: calc(100% - (45px - (10 * 1px)));
  right: calc(100% - (45px - (var(--font-size) * 1px)));
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-width: 45px;
  min-width: var(--marker-width);
}

.style_EditorTextbox__1Rjnr > div > ul[class*="disc"] {
  list-style-type: disc;
}

.style_EditorTextbox__1Rjnr > div > ul[class*="disc"] ul {
  list-style-type: revert;
}

.style_EditorTextbox__1Rjnr li:empty:before {
  content: ".";
  color: transparent;
  cursor: text;
}

.style_EditorTextbox__1Rjnr li:empty:focus:before {
  content: "";
}

/* styling <mark/> tags for rich text
when selection is changed */
mark {
  background-color: #ACCEF7;
  color: inherit;
}

.style_textBoundaryLock__14O3K {
  overflow-wrap: normal;
  overflow: hidden;
}

.style_editorInputAutoComplete__3_q0r {
  width: 225px;
  /* height: 204px; */
}

sup {
  font-size: 56%;
}
.style_tableTextField__3kKdu {
  width: 100%;
  box-sizing: border-box;
  overflow-wrap: inherit;
  white-space: normal;
}

.style_tableTextField__3kKdu::selection {
  background: rgba(156,196,254, 0.48);
}

.style_tableCell__1E32i {
  z-index: 2;
  white-space: pre-wrap;
}

.style_tableRow__1Yhf6 {
  width: 100%;
  display: flex;
  position: relative;
}

.style_tableRow__1Yhf6:hover {
  outline: 1px rgb(49,132,252) solid;
}

.style_tableRowDropZone__Zaz-A {
  z-index: 3;
  left: 0;
  width: 100%;
  height: 50%;
  position: absolute;
}

.style_tableRowDropZone__Zaz-A[data-position="top"] {
  top: 0%;
}

.style_tableRowDropZone__Zaz-A[data-position="bottom"] {
  top: 50%;
}

.style_tableWrapper__1Z9ha {
  /* margin-bottom: 20px;
  width: 1000px;
  height: 500px; */
}

table {
  border-collapse: collapse;
  border-spacing: 0px;
  width: 100%;
  height: 100%;
  cursor: text;
  position: relative;
  z-index: 9999;
}


.style_tableCellWrapper__2IUCY {
  padding: 0px;
  box-sizing: border-box;
  width: auto;
}

.style_selectedCellStyle__FIMJd {
  border: 1px solid rgb(49,132,252);
  position: absolute;
  width: 0px;
  height: 0px;
  top: inherit;
  left: inherit;
  z-index: 5;
  pointer-events: none;
}

.style_tableCell__2Ej_C {
  width: 100%;
  height: 100%;
}

.style_tableCell__2Ej_C[data-is-selected="true"] {
  outline: 2px solid rgb(49,132,252);
}

.style_lockingIndicator__2DMc4 {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  background-color: white;
  right: 0;
  box-shadow: 0 1px 2px 0 rgba(21, 21, 22, 0.12);
  -webkit-animation: style_bounce-in__3CRJD 0.3s;
          animation: style_bounce-in__3CRJD 0.3s;
}

@-webkit-keyframes style_bounce-in__3CRJD {
  0% {
    opacity: 0;
    -webkit-transform: scale(.7);
            transform: scale(.7);
  }
  50% { -webkit-transform: scale(1.02); transform: scale(1.02); }
  100% { -webkit-transform: scale(1); transform: scale(1); }
}

@keyframes style_bounce-in__3CRJD {
  0% {
    opacity: 0;
    -webkit-transform: scale(.7);
            transform: scale(.7);
  }
  50% { -webkit-transform: scale(1.02); transform: scale(1.02); }
  100% { -webkit-transform: scale(1); transform: scale(1); }
}

html {
  box-sizing: border-box;
  height: 100%;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

strong {
  font-weight: 500;
}

body {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  line-height: 1.5;
  height: 100%;
  margin: 0;
  color: #7A7D81;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;

  -webkit-font-feature-settings: 'kern', 'liga', 'pnum';

          font-feature-settings: 'kern', 'liga', 'pnum';
  -webkit-font-smoothing: antialiased;
  /* IE hack to hide scrollbars */
}

button {
  font-family: 'Roboto', sans-serif;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

[data-no-pointer="true"] {
  cursor: default;
}

[data-is-pointer="true"] {
  cursor: pointer;
}

[data-not-allowed="true"] {
  cursor: not-allowed;
  pointer-events: none;
}

[data-disabled="true"] {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
}

[data-not-allowed="true"] * {
  cursor: not-allowed;
  pointer-events: none;
}

[data-nodisplay="true"] {
  display: none;
}

[data-hidden="true"] {
  visibility: hidden;
}

[data-zero-opacity="true"] {
  opacity: 0;
}

[data-half-opacity="true"] {
  opacity: 0.5;
}

svg[data-disabled="true"] {
  cursor: default;
  fill: #8f9295;
  stroke: #8f9295;
}

[contenteditable=true]:focus {
  outline: none
}

/* to support Safari */
[contenteditable] {
  -ms-user-select: text;
      user-select: text;
  -moz-user-select: text;
  -webkit-user-select: text;
}

@-webkit-keyframes style_Gradient__2Gr3g {
	0% {
		background-position: 0% 50%
	}
	50% {
		background-position: 100% 50%
	}
	100% {
		background-position: 0% 50%
	}
}

@keyframes style_Gradient__2Gr3g {
	0% {
		background-position: 0% 50%
	}
	50% {
		background-position: 100% 50%
	}
	100% {
		background-position: 0% 50%
	}
}

@-webkit-keyframes style_fadein__3Y9zQ {
    from { opacity: 0; }
    to   { opacity: 1; }
}

@keyframes style_fadein__3Y9zQ {
    from { opacity: 0; }
    to   { opacity: 1; }
}

#style_simulations__1iosi {
    opacity: 0;
    position: 'absolute';
}

/* export css globalVars into module so they can be imported in javascript */

/* below style disables the viewing of scroll bars without removing the scroll functionality from an element */
.style_no-scroll-bars__21TDw {
  -ms-overflow-style: none;  /* IE and Edge no scroll bar */
  scrollbar-width: none;  /* Firefox no scroll bar */
}

.style_no-scroll-bars__21TDw::-webkit-scrollbar {
  display: none; /* Chrome Safari and Opera no scroll bar */
}/*"views/style.module.css"*/


.style_avatar__1V8YE {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  font-size: 32px;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  line-height: 24px;
  font-weight: bold;
  background-color: #2c2e2f;
  border: solid 2px white;
  color: white;
  background-position: center;
  background-size: cover;
}

.style_avatar__1V8YE[data-has-double-border="true"] {
  border: 2px solid;
}

.style_light__FAopt {
  background-color: white;
  color: #2c2e2f;
  border: solid 2px #2c2e2f;
}

.style_dark__YskYA {
  background-color: #2c2e2f;
  color: white;
  border: none;
}

.style_gray__13eGN {
  background-color: #D1D4D6;
  color: white;
  border: solid 2px white;
}

.style_chars__1drD9 {
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.style_avatar__IlVMg {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  font-size: 32px;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  line-height: 24px;
  font-weight: bold;
  background-color: #2c2e2f;
  border: solid 2px white;
  color: white;
  background-position: center;
  background-size: cover;
}

.style_light__I_KNN {
  background-color: white;
  color: #2c2e2f;
  border: solid 2px #2c2e2f;
}

.style_gray__26bMm {
  background-color: #D1D4D6;
  color: white;
  border: solid 2px white;
}

.style_chars__1a9Ia {
  font-family: Roboto;
  font-size: 40%;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}

.style_label__16Dac {
  color: #2c2e2f;
  font-size: 13px;
  line-height: 1.85;
  text-align: left;
  padding-bottom: 9px;
}

.style_shareToolTip__prmmF {
  width: 208px;
  opacity: 1 !important;
}

.style_dropdown__2f9z0 {
  position: relative;
  display: inline-block;
  width: 100%;
}

.style_dropdown__2f9z0 * {
  cursor: pointer;
}

.style_icon__3Rfou {
  padding-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.style_inputDiv__1L6Gx {
  display: flex;
  align-items: center;
  border: 1px solid #D1D4D6;
  border-radius: 4px;
  line-height: 20px;
  height: 40px;
  padding: 10px 12px 10px 12px;
  cursor: pointer;
}

.style_inputDiv__1L6Gx[data-is-active="true"] {
  border-color: #3184FC;
}

.style_inputDiv__1L6Gx input {
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 500;
  color: #2c2e2f;
  outline: none;
  border: none;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  pointer-events: none;
}

.style_inputDiv__1L6Gx input[disabled] {
  background-color: transparent;
  color: #757575;
}

.style_inputDiv__1L6Gx[data-disabled="true"] input[disabled]{
  cursor: not-allowed;
}
.style_inputDiv__1L6Gx[data-error="true"]{
  border: 1px solid #E64826;
}

.style_inputDiv__1L6Gx[data-disabled="true"] {
  background-color: #FBFBFC;
  color: #AAAAAA;
  cursor: not-allowed;
}

.style_option__3KqNe:hover, .style_prependOption__3AqSS:hover {
  background-color: #F9F9FA;
}
.style_content__2bV_R {
  margin-top: 5px;
  width: 100%;
  max-height: 250px;
  border: 1px solid #D1D4D6;
  border-radius: 4px;
  display: block;
  position: absolute;
  top: 39px;
  background-color: white;
  min-width: 160px;
  padding: 8px;
  z-index: 1;
  overflow: auto;
}

.style_doneButton__wF0NB {
  width: 100%;
}

.style_error__1nyle {
  position: absolute;
  color: #E64826;
  font-size: 12px;
  left: 5px;
  top: 100%;
}

.style_optionList__25AVU {
  padding-bottom: 5px;
  padding-top: 5px;
  margin-top: -5px;
  margin-bottom: 5px;
  border-bottom: 1px solid #e2e5e8;
  cursor: default;
}

.style_searchWrapper__2lmuN {
  display: flex;
  flex-direction: row;
  margin: 0 0px 12px;
  border: 1px solid #d1d4d6;
  border-radius: 4px;
  padding: 6px 10px;
  cursor: default;
  align-items: center;
}

.style_searchWrapper__2lmuN > input {
  cursor: text;
  margin-left: 3px;
}

.style_option__3KqNe, .style_prependOption__3AqSS {
  height: 36px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 8px;
  color: #57595d;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.style_option__3KqNe[disabled] {
 opacity: 0.64;
}

.style_prependDivider__3ebKA {
  width: 100%;
  height: 1px;
  background-color: #d1d4d6;
  margin: 4px 0;
}

.style_optionWrapper__2eJ10 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.style_infoIcon__367-Y {
  margin-right: 8px;
}
.style_inputTransparent__3ScaD {
  padding: 0px;
  color: inherit;
  background-color: transparent;
  width: 100%;
  border: none;
  outline: none;
  height: 100%;
  width: 100%;
}

.style_inputTransparent__3ScaD:focus {
  outline: none;
}

.style_inputTransparent__3ScaD:disabled {
  outline: none;
  background-color: transparent;
  width: 100%;
  border: none;
  color: inherit;
}

.style_inputTransparent__3ScaD::-webkit-input-placeholder {
  color: #BDC0C2;
}

.style_inputTransparent__3ScaD:-ms-input-placeholder {
  color: #BDC0C2;
}

.style_inputTransparent__3ScaD::placeholder {
  color: #BDC0C2;
}

.style_label__3f-S8 {
  color: #2c2e2f;
  font-size: 13px;
  line-height: 1.85;
  text-align: left;
  padding-bottom: 9px;
}

.style_dropdown__3TSjt {
  position: relative;
  display: inline-block;
  width: auto;
  border-radius: 8px;
  margin-top: 24px;
}

.style_dropdown__3TSjt * {
  cursor: pointer;
}

.style_inputDiv__WHR-k {
  display: flex;
  align-items: center;
  font-size: 14px;
  border: 1px solid #D1D4D6;
  height: 48px;
  padding: 12px 16px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  color: #2c2e2f;
  line-height: 1.5;
  width: auto;
  border-radius: 8px;
}

.style_selected__31ZFs {
  margin-right: 4px
}

.style_inputDiv__WHR-k[data-error="true"]{
  border: 1px solid #E64826;
}

.style_inputDiv__WHR-k[data-disabled="true"] {
  background-color: #FBFBFC;
  color: #AAAAAA;
  cursor: not-allowed;
}

.style_option__167NX {
  height: 36px;
  border-radius: 8px;
  padding-left: 12px;
}

.style_option__167NX:hover {
  color: #2c2e2f;
  background-color: #F9F9FA;
}
.style_content__3A0Gm {
  border: 1px solid #D1D4D6;
  display: block;
  position: absolute;
  top: 39px;
  background-color: white;
  min-width: 160px;
  z-index: 1;
  overflow: auto;
  border-radius: 8px;
  max-height: 288px;
  margin-top: 16px;
  width: 250px;
  padding: 16px 12px;
  line-height: 36px;
  font-size: 16px;
  color: #57595d;
  box-shadow: 0 2px 4px 0 rgba(15,15,15, 0.08);
}

.style_doneButton__h8FVl {
  width: 100%;
}

.style_error__1Fp6- {
  position: absolute;
  color: #E64826;
  font-size: 12px;
  left: 5px;
  top: 100%;
}

.style_label__3vnaz {
  color: #2c2e2f;
  font-size: 13px;
  line-height: 1.85;
  text-align: left;
  padding-bottom: 9px;
}


.style_dropdown__qx4wS {
  position: relative;
  display: inline-block;
  width: 100%;
}

.style_dropdown__qx4wS * {
  cursor: pointer;
}

.style_inputDiv__2JFR9 {
  display: flex;
  align-items: center;
  font-size: 14px;
  border: 1px solid #D1D4D6;
  border-radius: 4px;
  line-height: 20px;
  height: 40px;
  padding: 10px 12px 10px 12px;
  cursor: pointer;
}

.style_inputDiv__2JFR9 input {
  color: #2c2e2f;
  outline: none;
  border: none;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  pointer-events: none;
}

.style_inputDiv__2JFR9 input[disabled] {
  background-color: transparent;
  color:   #57595D;
}

.style_inputDiv__2JFR9[data-error="true"]{
  border: 1px solid #E64826;
}

.style_inputDiv__2JFR9[data-disabled="true"] {
  background-color: #D1D4D6;
  cursor: not-allowed;
}

.style_content__3_HKR {
  margin-top: 4px;
  width: 100%;
  max-height: 200px;
  overflow: auto;
  border: 1px solid #D1D4D6;
  border-radius: 4px;
  display: block;
  position: absolute;
  top: 39px;
  background-color: white;
  min-width: 160px;
  padding: 16px 16px 16px 16px;
  z-index: 1;
}

.style_error__ZF36P {
  position: absolute;
  color: #E64826;
  font-size: 12px;
  left: 5px;
  top: 100%;
}

.style_label__TNzNy {
  color: #2c2e2f;
  font-size: 13px;
  line-height: 1.85;
  text-align: left;
  padding-bottom: 9px;
}

.style_scrollbar__1JrOe {
  width: 5px !important;
  border-radius: 2.5px;
  background-color: #7d7d7d;
  overflow-x: hidden;
  margin-right: -5px;
}

.style_scrollContentClassName__1B1i2 {
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
}

.style_dropdownScrollable__1noQJ { 
  max-height: 182px;
  width: calc(100% + 20px) !important;
  padding-right: 20px;
  position: relative;
    overflow: hidden;
    height: 100%;
  
}

.style_dropdownScrollable__1noQJ > div:first-of-type {
  max-height: inherit;
}

.style_dropdown__2znW8 {
  position: relative;
  display: inline-block;
  width: 100%;
}

.style_dropdown__2znW8 * {
  cursor: pointer;
}

.style_inputDiv__3oWWQ {
  display: flex;
  align-items: center;
  font-size: 14px;
  border-radius: 4px;
  line-height: 20px;
  height: 40px;
  padding: 10px 12px 10px 12px;
  cursor: pointer;
}

.style_inputDiv__3oWWQ input {
  color: #2c2e2f;
  outline: none;
  border: none;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  pointer-events: none;
}

.style_inputDiv__3oWWQ input[disabled] {
  background-color: transparent;
  color: #57595D;
}

.style_inputDiv__3oWWQ[data-error="true"] {
  border: 1px solid #E64826;
}

.style_inputDiv__3oWWQ[data-disabled="true"] {
  background-color: #D1D4D6;
  cursor: not-allowed;
}

.style_contentWrapper__2DP6H {
  display: flex;
  flex-direction: column;
  margin-top: 4px;
  width: 100%;
  overflow: hidden;
  border: 1px solid #d1d4d6;
  border-radius: 4px;
  position: absolute;
  top: 39px;
  min-width: 160px;
  padding: 4px 24px 2px 24px;
  z-index: 1;
  background-color: white;
}

.style_content__1R2tD {
  margin-top: 4px;
  overflow: auto;
  top: 39px;
  min-width: 160px;
  z-index: 1;
  width: calc(100% + 30px);
  margin-left: -15px;
  padding-left: 15px;
  padding-right: 15px;
  overflow-y: hidden;
}

.style_doneButton__2pnNk {
  margin-top: 10px;
}

.style_doneButtonContainer__3c859 {
  margin-top: 10px;
}

.style_error__2LLt5 {
  position: absolute;
  color: #E64826;
  font-size: 12px;
  left: 5px;
  top: 100%;
}

.style_inputTitle__FCCb- {
  opacity: 0.64;
  font-family: Roboto;
  font-size: 14px;
  line-height: 1.71;
  color: #ffffff;
  margin-right: 6px;
}
.style_wrapper__2V6IP {
  display: flex;
  flex-wrap: nowrap;
}

.style_button__19o2f {
  align-items: center;
  background-color: transparent;
  border-radius: 0px;
  border: 1px solid #8f9295;
  color: #bdc0c2;
  display: flex;
  flex: 0 1 100%;
  height: 40px;
  justify-content: center;
  padding: 0px 16px 0px 16px;
  position: relative;
  cursor: pointer;
  z-index: 1;
}

.style_button__19o2f:disabled {
  cursor: not-allowed;
}

.style_button__19o2f:focus {
  outline: none;
}

.style_button__19o2f:not(:first-child) {
  margin-left: -1px;
}

.style_button__19o2f:first-of-type {
  border-radius: 4px 0px 0px 4px;
}

.style_button__19o2f:last-of-type {
  border-radius: 0px 4px 4px 0px;
}

.style_selected__3_KJZ {
  border-color: #fff;
  color: #fff;
  z-index: 2;
}

.style_alert__2P0La {
  display: flex;
  align-items: center;
  border-radius: 4px;
  border: solid 1px #D1D4D6;
  padding: 16px 20px 16px 20px;
  color: #57595D;
}

.style_icon__3chcm {
  display: inline-flex;
  margin: 0px 20px 0px 0px;
  cursor: default;
}

.style_message__3MTLO {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  margin-right: auto
}

.style_button__2tGEu {
  margin-left: 20px;
}

.style_content__-DwUZ {
  background: #fff;
  overflow: visible;
  webkit-overflow-scrolling: touch;
  border-radius: 4px;
  outline: none;
  padding: 24px;
  width: 750px;
}

.style_overlay__3Y7pz {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(28, 29, 30, 0.88);
  z-index: 99;
}

.style_bodyOpen__sUQeo {
  overflow: hidden;
}

.style_closeIcon__2nlie {
  margin-left: 10px;
  cursor: pointer;
}

.style_header__jNLj- {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px
}

.style_subHeader__2Bg20 {
  margin-top: 16px;
  height: 24px;
  font-size: 14px;
  line-height: 1.71;
  letter-spacing: normal;
  color: #73767a;
  width: 100%;
  color: #73767a;
}

.style_headerTitle__2jh8a {
  color: #2c2e2f;
  font-size: 20px;
  line-height: 28px;
}

.style_buttons__3PX6h {
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
}

.style_buttons__3PX6h button {
  margin-right: 12px
}

.style_buttons__3PX6h button:last-child {
  margin-right: 0px
}

/* easilGrayDark is used on the JSX */
.style_cell__1APIG {
  flex: 1 0 auto;
  width: 25%;
  overflow: hidden;
}

.style_cell__1APIG:nth-child(4n+2) {
  width: 40%;
}

.style_cell__1APIG:nth-child(4n) {
  width: 10%;
  display: flex;
  justify-content: flex-end;
}

.style_header__Oa3rL {
  color: #2c2e2f;
  display: flex;
  flex: 1 0 100%;
  flex-wrap: wrap;
  padding-bottom: 25px;
}

.style_row__2aQV1 {
  font-size: 14px;
  display: flex;
  flex: 1 0 100%;
  flex-wrap: wrap;
  padding-top: 28px;
}

.style_table__3cSof {
  display: flex;
  flex: 1 0 100%;
  flex-wrap: wrap;
  color: #57595D;
  position: relative;
}

.style_dropzone__11ysq {
  width: 128px;
  height: 128px;
  border-radius: 50%;
  background-color: #F9F9FA;
  display: flex;
  align-items: center;
  justify-content: center;
}

.style_dropzone__11ysq img {
  width: 128px;
  height: 128px;
  border-radius: 50%;
}

.style_dropzone__11ysq[data-is-dragover="true"] {
  border: 2px dashed black;
}

.style_component__W-CP6 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.style_icon__3rBcF {
  pointer-events: none;
}

.style_description__2DSTl {
  margin-top: 32px;
  font-weight: 300;
}

.style_inputfile__15SBq {
  display: none
}

.style_inputfile__15SBq  ~ label {
  text-decoration: underline;
  cursor: pointer;
}

.style_error__CP7vx {
  margin-top: 8px;
  text-align: center;
  color: #E64826;
  font-size: 12px;
  -webkit-animation: style_fade__1Tf_4 0s ease-in 5s forwards;
          animation: style_fade__1Tf_4 0s ease-in 5s forwards;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

@keyframes style_fade__1Tf_4 {
  to {
    width:0;
    height:0;
    overflow:hidden;
  }
}
@-webkit-keyframes style_fade__1Tf_4 {
  to {
    width:0;
    height:0;
    visibility:hidden;
  }
}

.style_h1__2zzhs {
  display: inline-block;
  font-size: 32px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #2c2e2f;
}

.style_h2__2r1Y- {
  display: inline-block;
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #2c2e2f;
}

.style_h3__1izxj {
  display: inline-block;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.78;
  letter-spacing: normal;
  text-align: left;
  color: #2c2e2f;
}

.style_h5__S58EI {
  display: inline-block;
  font-size: 14px;
  line-height: 1.85;
  color: #2c2e2f;
}

.style_empty__3QkAD {
  /* background: linear-gradient(-45deg, #ECECEC, #ECECEC,#ECECEC, #ECECEC, #FFF, #ECECEC, #ECECEC, #ECECEC, #ECECEC); */
    /* background-size: 400% 400%; */
  background-color: #ECECEC;
  height: 31px;
  width: 90px;
  border-radius: 4px;
  /* animation: fadein 2s, Gradient 3s ease infinite; */
}

@-webkit-keyframes style_Gradient__84T0A {
	0% {
		background-position: 0% 50%
	}
	50% {
		background-position: 100% 50%
	}
	100% {
		background-position: 0% 50%
	}
}

@keyframes style_Gradient__84T0A {
	0% {
		background-position: 0% 50%
	}
	50% {
		background-position: 100% 50%
	}
	100% {
		background-position: 0% 50%
	}
}

@-webkit-keyframes style_fadein__2kHog {
    from { opacity: 0; }
    to   { opacity: 1; }
}

@keyframes style_fadein__2kHog {
    from { opacity: 0; }
    to   { opacity: 1; }
}

.style_wrap__3JZh- {
  display: inline-block;
}

/* The container */
.style_container__15fwE {
  display: block;
  position: relative;
  padding-left: 28px;
  margin-bottom: 3px;
  cursor: pointer;
  font-size: 14px;
  line-height: 20px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.style_container__15fwE input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.style_checkmark__3FvSr {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  border: 2px solid #D1D4D6;
  border-radius: 4px;
  background-color: white;
}

.style_checkmark__3FvSr:hover, .style_checkmark__3FvSr[data-is-hovered="true"] {
  border-color: #8f9295;
}

/* When the radio button is checked, add a blue background */
.style_container__15fwE input:checked ~ .style_checkmark__3FvSr {
  border: none;
  background-color: #3184fc;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.style_checkmark__3FvSr:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.style_container__15fwE input:checked ~ .style_checkmark__3FvSr:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.style_container__15fwE .style_checkmark__3FvSr:after {
  background-image: url(/static/media/check.a67179e2.svg);
  background-repeat: no-repeat;
  color: white;
  top: 5px;
  left: 4px;
  width: 8px;
  height: 8px;
}

.style_container__15fwE .style_checkmark__3FvSr[data-is-minus="true"]:after {
  background-image: url(/static/media/minus.73dbb2c9.svg);
}

.style_container__15fwE .style_checkmark__3FvSr[data-double-scale="true"]:after {
  background-image: url(/static/media/thickCheck.dcc0c47e.svg);
  -webkit-transform: scale(1);
          transform: scale(1);
}

.style_radioboxRoundedLabel__3cNHV {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #D1D4D6;
  font-size: 14px;
  line-height: 1.43;
}

.style_radioboxRoundedLabel__3cNHV:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border: 2px solid #D1D4D6;
  border-radius: 100%;
  background: transparent;
}

.style_radioboxRoundedLabel__3cNHV[data-checked="true"]:after {
  border-radius: 100%;
  content: '';
  width: 8px;
  height: 8px;
  background: white;
  position: absolute;
  top: 6px;
  left: 6px;
  transition: all 0.2s ease;
  color: white;
}

radioboxRoundedLabel[data-checked="true"] {
  border: 2px solid white;
}

.style_radioboxRoundedLabel__3cNHV > input {
  display: none;
}

.style_div__j_B1I {
  position: relative;
  border: 1px solid #D1D4D6;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  margin-right: 10px;
}

.style_div__j_B1I[data-disabled="true"] {
  background-color: #FBFBFC;
  cursor: not-allowed;
}

.style_div__j_B1I input[disabled] {
  background-color: #FBFBFC;
  color: #AAAAAA;
  cursor: not-allowed;
}

.style_div__j_B1I input {
  background-color: inherit;
  padding: 0px 12px 0px 12px;
  font-size: 15px;
  line-height: 24px;
  font-family: Roboto;
  color: #2c2e2f;
  width: 100%;
  border: none;
  border-radius: inherit;
  height: 100%;
  vertical-align: top;
}

.style_div__j_B1I input:focus {
  outline: none;
}

.style_div__j_B1I[data-error="true"] {
  border: 1px solid #E64826;
}

.style_label__1wTQ8 {
  color: #2c2e2f;
  font-size: 13px;
  line-height: 1.85;
  text-align: left;
  padding-bottom: 9px;
}

.style_error__1h2ZF {
  position: absolute;
  color: #E64826;
  font-size: 12px;
  top: 100%;
  left: 5px;
}

.style_clearCross__1kBFN {
  cursor: pointer;
  background-color: #3184fc;
  height: 28px;
  width: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}

.style2_wrapper__eDfeA {
  position: relative;
  align-items: center;
  border-radius: 8px;
  display: flex;
  border: 1px solid #f5f5f8;
  background-color: #f5f5f8;
}

.style2_wrapper__eDfeA[data-is-focused="true"] {
  background-color: #fcfcfd;
  border: 1px solid #D1D4D6;
}
.style2_icon__1rSfT {
  margin-left: 12px;
}

.style2_input__2C8is {
  border: none;
  height: 40px;
  line-height: 40px;
  padding-top: 0;
  padding-bottom: 0;
  color: #73767a;
}

.style2_searchSuggestions__1HV-M {
  position: absolute;
  width: 100%;
  max-height: 350px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(15, 15, 16, 0.1);
  border: solid 1px undefined;
  background-color: white;
  top: 54px;
  padding: 10px;
}

.style2_searchSuggestions__1HV-M > :last-child {
  margin-bottom: 0;
}

.style2_searchSuggestionsTitle__1tSec {
  display: flex;
  color: undefined;
  margin-bottom: 8px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: undefined;
  padding: 6px;
}

.style2_suggestion__2SYOR {
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #57595D;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 6px;
  padding: 6px;
}

.style2_suggestion__2SYOR:hover {
  cursor: pointer;
}

.style2_suggestion__2SYOR[data-is-highlighted="true"] {
  border-radius: 4px;
  background-color: #f4f4f6;
  cursor: pointer;
}

.style2_suggestion__2SYOR[data-is-highlighted="true"] > svg {
  visibility: visible;
}

.style2_suggestion__2SYOR[data-is-highlighted="false"] > svg {
  visibility: hidden;
}

.style2_suggestionIcon__1DNIj {
  margin-left: 12px;
}

.style_div__1DX2_ {
  position: relative;
  font-size: 14px;
  border: 1px solid #D1D4D6;
  border-radius: 4px;
  line-height: 20px;
  color: #2c2e2f;
  min-height: 80px;
  width: 100%;
  padding: 10px 12px 10px 12px;
}

.style_textAreaDiv___6xpZ {
  width: 100%;
  max-width: 256px;
  max-height: 46px;
  background-color: #f5f5f8;
  border-radius: 4px;
  margin-right: 0;
  border: none;
  outline:0px !important;
  -webkit-appearance:none;
  box-shadow: none !important;
  box-sizing: border-box;
  resize: none;
  transition: max-height 0.2s ease-in-out;
}

.style_textAreaDiv___6xpZ[data-isfocussed="true"] {
  max-height: 45vh !important;
}

.style_textAreaDiv___6xpZ[data-hasinputerror="true"] {
  display: flex;
  justify-content: center;
  align-items: center;
}

.style_errorWrapper__15FmH {
  display: flex;
  justify-content: center;
  align-items: center;
}

.style_div__1DX2_[data-disabled="true"] {
  background-color: #FBFBFC;
  cursor: not-allowed;
}

.style_div__1DX2_ textarea[disabled] {
  background-color: #FBFBFC;
  color: #AAAAAA;
  cursor: not-allowed;
}

.style_div__1DX2_ textarea {
  padding: 0px;
  color: #2c2e2f;
  width: 100%;
  border: none;
  vertical-align: top;
  resize: none;
}

.style_div__1DX2_ textarea:focus {
  outline: none;
}

.style_div__1DX2_[data-error="true"]{
  border: 1px solid #E64826;
}

.style_label__NWcDZ {
  color: #2c2e2f;
  font-size: 13px;
  line-height: 1.85;
  text-align: left;
  padding-bottom: 9px;
}

.style_error__uOtLt {
  position: absolute;
  color: #E64826;
  font-size: 12px;
  top: 100%;
  left: 5px;
}

.style_alertToolTip__3sW1n {
  font-size: 12px;
  width: 226px;
}

.style_messageContainer__2M2ky {
  background-color: #ecf5ff;
  border: 1px solid #a1c6ff;
  border-radius: 4px;
  color: #2c2e2f;
  display: flex;
  line-height: 1.71;
  margin-bottom: 40px;
  padding: 16px;
  color: #2c2e2f;
}

.style_messageIcon__20cRD {
  margin-top: 2px;
}

.style_messageContent__32VXh {
  flex: 1 1;
  margin-left: 12px;
}

.style_messageContent__32VXh a {
  color: #3184fc;
  font-weight: bold;
}

.style_rolesDropdownWrapper__2bOKY {
  position: absolute;
  margin-top: 8px;
  padding: 32px;
  left: -510px;
  width: 840px;
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid #d1d4d6;
  /* padding: 32px 0 24px; */
  z-index: 999;
}

.style_container__28Ogm {
  width: 100%;
  height: 100%;  
}

.style_teamRolesWrapper__3HqPw {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 96px;
}

.style_roleWrapper__1TXKC {
  height: 100%;
  cursor: pointer;
}

.style_roleWrapper__1TXKC > label {
  display: unset;
}

.style_roleWrapper__1TXKC:not(:first-of-type) {
  padding-left: 24px;
  border-left: 1px solid #d1d4d6;
}

.style_roleWrapper__1TXKC:not(:last-of-type) {
  margin-right: 16px;
}

.style_roleLabel__10SWG {
  margin-top: 12px;
  color: #2c2e2f;
  font-weight: 500;
}

.style_roleContent__8rKSr {
  margin-top: 8px;
  font-size: 13px;
  max-width: 150px;
}

.style_roleLink__2u5yQ {
  margin-top: 28px;
}

.style_roleLink__2u5yQ > a {
  color: #2c2e2f;
  font-weight: 500;
  cursor: pointer;
}

.style_teamRoleContentWrapper__-jcAn {
  padding-bottom: 32px;
  margin-bottom: 32px;
  border-bottom: 1px solid #d1d4d6;
}

.style_inviteRoleMessage__1QENo {
  margin-top: 24px;
  display: flex;
}

.style_inviteRoleMessage__1QENo > div {
  margin-right: 4px;
}

.style_teamRoleContent__258Yy {
  font-size: 15px;
  line-height: 24px;
}

.style_teamRoleContent__258Yy > a {
  color: #2c2e2f;
  font-weight: 500;
}

.style_memberAvatar__1aZXn {
  display: flex;
}

.style_userDetails__2G571 {
  margin: 0 0 32px 16px;
}

.style_memberName__39rTr {
  color: #2c2e2f;
  font-weight: 500;
}

.style_rolePickerTitle__13OnQ {
  margin-bottom: 12px;
  color: #57595d;
}

.style_memberModal__3gc8o {
  width: 792px;
  padding: 32px;
  border-radius: 8px;
}

.style_memberModal__3gc8o > div:first-of-type {
  margin-bottom: 12px;
}

.style_editButtons__23EV5 {
  margin-top: 32px;
}

.style_rolesDropdown__YUptX {
  width: inherit;
}
.style_label__1an9f {
  color: #2c2e2f;
  font-size: 13px;
  line-height: 1.85;
  text-align: left;
  padding-bottom: 9px;
}

.style_dropdownCustom__1H1zq {
  position: relative;
  display: inline-block;
}

.style_inputDiv__1Reqh {
  display: flex;
  align-items: center;
  font-size: 14px;
  border: 1px solid #D1D4D6;
  border-radius: 4px;
  line-height: 20px;
  height: 40px;
  padding: 10px 12px 10px 12px;
  cursor: pointer;
}

.style_inputDiv__1Reqh input {
  color: #2c2e2f;
  outline: none;
  border: none;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  pointer-events: none;
}

.style_inputDiv__1Reqh input[disabled] {
  background-color: transparent;
  color:   #57595D;
}

.style_inputDiv__1Reqh[data-error="true"]{
  border: 1px solid #E64826;
}

.style_inputDiv__1Reqh[data-disabled="true"] {
  background-color: #D1D4D6;
  cursor: not-allowed;
}

.style_error__3EDuQ {
  position: absolute;
  color: #E64826;
  font-size: 12px;
  left: 5px;
  top: 100%;
}
/* easilGrayDark is used on the JSX */

/* input widths */

/* scollable invites widths */

/* input headings */
.style_tableWrapper__2OV16 > :first-child {
  padding-bottom: 8px;
}

.style_cell__2ur49 {
  width: 180px;
  overflow: visible;
  margin-right: 12px;
}

.style_cell__2ur49:nth-child(4n+2) {
  width: 234px;
}

.style_cell__2ur49:nth-child(4n+3) {
  width: 210px;
}

.style_cell__2ur49:nth-child(4n) {
  justify-content: center;
  margin-right: 0;
}

.style_cellError__2oOkb {
  border: 1px solid #E64826;
  border-radius: 4px;
}

.style_emailError__2Rcof {
  position: relative;
  color: #E64826;
  max-height: 0px;
  overflow: hidden;
  transition: max-height 0.2s ease;
}

.style_emailError__2Rcof[data-has-error="true"] {
  max-height: 20px;
}

.style_list__2wyzZ {
  border-radius: 4px;
  box-shadow: 0 0 0 1px #E2E5E8;
  margin: 8px 0 32px 0;
  height: 30vh; /* allow this to dynamically grow at least a little */
  overflow: auto;
  width: 745px;
  display: flex;
  flex-direction: column;
}
.style_listrow__17SOA {
  align-items: center;
  display: flex;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.style_listrow__17SOA:first-child {
  padding: 26px 24px 0 24px;
}

.style_listrow__17SOA:not(:first-child) {
  padding: 16px 24px 0 24px;
}

.style_listrow__17SOA > div {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #2c2e2f;
  font-size: 15px;
}

.style_listrow__17SOA > div:not(:last-of-type) {
  margin-right: 26px;
}

.style_listrow__17SOA > div:nth-child(4n+3) {
  margin-right: 18px;
}

.style_row__3ZM7y {
  font-size: 14px;
  display: flex;
  flex: 1 0 100%;
  padding: 0px 0px 8px 0px;
}

/* select all but the first */
.style_row__3ZM7y ~ .style_row__3ZM7y{
  padding: 16px 0px 0px 0px;;
}

.style_plusIcon__3c0b5 {
  margin-right: 10px
}

.style_clickableIcon__2odVW {
  cursor: pointer;
}

.style_trashIcon__35Aya {
}

.style_addToTeamIcon__CksxS {
  width: 40px;
  height:40px;
  padding: 0;
  border: none;
}

.style_addToTeamIcon__CksxS[data-disabled="true"] {
  border: none;
}

.style_clickablePlusIcon__GCXKa {
}

.style_scrollable__LSbCL {
  width: calc(100% + 15px);
  margin-right: -15px;
}


.style_scrollContentClassName__LVnNf {
  height: 100%;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1 1;
  justify-content: space-between;
  align-content: flex-start;
  position: absolute;
}

.style_scrollContentClassName__LVnNf > div:nth-child(1) > div:nth-child(4) {
  height: 26px;
}

.style_dropdownContent__1znYM {
  z-index: 3;
}

.style_teamInput__vzCam {
  border-radius: 4px;
}

.style_scrollbar__2ma2I {
  right: 20px;
}
.style_stack__11WOX {
  display: inline-flex;
  flex-direction: row-reverse;
}

.style_wrapper__3QFSA {
  align-items: center;
  align-self: flex-start;
  background-color: #fff;
  border: 1px solid #D1D4D6;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  height: 48px;
  justify-content: center;
  position: relative;
  text-align: center;
  transition: all 300ms ease-in-out;
  width: 48px;
}

.style_wrapper__3QFSA[disabled],
.style_wrapper__3QFSA[disabled] * {
  opacity: 0.6;
  cursor: not-allowed;
}

.style_wrapper__3QFSA:hover {
  border-color: #57595D;
}

.style_wrapper__3QFSA[disabled]:hover {
  border-color: #D1D4D6;
}

.style_cancelIcon__2eG63 {
  background-color: #fff;
  border: 1px solid #D1D4D6;
  border-radius: 50%;
  display: none !important;
  height: 24px;
  position: absolute;
  right: -8px;
  top: -8px;
  width: 24px;
  z-index: 2;
}

.style_wrapper__3QFSA:hover .style_cancelIcon__2eG63 {
  display: flex !important;
}
.style_wrapper__229wG {
  align-items: center;
  background-color: #fff;
  border: 1px solid #D1D4D6;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  height: 24px;
  width: 24px;
}

.style_wrapper__229wG[disabled],
.style_wrapper__229wG[disabled] * {
  opacity: 0.6;
  cursor: not-allowed;
}
.style_wrapper__1YKGK {
  align-items: center;
  display: flex;
  justify-content: space-between;
  font-size: 15px;
}

.style_label__183R0 {
  color: #616569;
  margin-right: 8px;
}

.style_actions__48Cmu {
  align-items: center;
  display: flex;
}

.style_actions__48Cmu * {
  cursor: pointer;
  margin-left: 8px;
}

.style_actions__48Cmu svg:not(:first-child) {
  margin-left: 16px;
}

.style_input__1n_p1 {
  height: 42px;
  padding-top: 0;
  padding-bottom: 0;
}

.style_input__1n_p1 > input {
  height: 40px;
  line-height: 40px;
}

.style_wrapper__2mCs7 {
  align-items: center;
  border: 1px solid transparent;
  border-radius: 4px;
  display: flex;
  height: 138px;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 138px;
}

.style_wrapper__2mCs7:hover {
  cursor: pointer;
}

.style_image__3nWnj {
  max-height: 100%;
  max-width: 100%;
  background-image: none;
  background-color: transparent !important;
  background-size: 10px 10px;
  background-position: 0 0, 15px 15px;
  position: relative;
  z-index: 1;
}

.style_loader__38zi_ {
  background-color: #F9F9FA;
  bottom: 0;
  display: flex;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}

.style_wrapper__3dtgS {
  border: 1px solid transparent;
  border-radius: 4px;
  display: flex;
  height: 138px;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 138px;
}

.style_wrapper__3dtgS:hover {
  cursor: pointer;
}

.style_video__3O501 {
  max-height: 100%;
  max-width: 100%;
  background-image: none;
  background-color: transparent !important;
  background-size: 10px 10px;
  background-position: 0 0, 15px 15px;
  position: relative;
  z-index: 1;
}

.style_loader__2l9vA {
  background-color: #F9F9FA;
  bottom: 0;
  display: flex;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}

.style_inputfile__8qc7E {
  display: none
}

.style_inputfile__8qc7E ~ label {
  cursor: pointer;
}

.style_error__3scLw {
  margin-top: 8px;
  text-align: center;
  color: #E64826;
  font-size: 12px;
  -webkit-animation: style_fade__18VHn 0s ease-in 5s forwards;
          animation: style_fade__18VHn 0s ease-in 5s forwards;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

.style_profilePictureHover__2YXHy {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(255,255,255, 0.35) !important;
  border-radius: 50%; 
  z-index: 1;
  pointer-events: none;
}

.style_imageWrapper__3apXI {
  position:relative;
  display: flex;
  width: 100%;
  height: 100%;
}
.style_updateProfileButton__14KxC {
  border: none;
  border-radius: 4px;
}

@keyframes style_fade__18VHn {
  to {
    width:0;
    height:0;
    overflow:hidden;
  }
}
@-webkit-keyframes style_fade__18VHn {
  to {
    width:0;
    height:0;
    visibility:hidden;
  }
}

.style_wrapper__3yxgW {
  background-color: #fff;
  border: solid 1px #D1D4D6;
  border-radius: 4px;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.08);
  padding: 16px;
  position: relative;
  width: 300px;
}

.style_colorsWrapper__BN4zZ {
  display: flex;
  flex-direction: row;
  height: 204px;
  justify-content: space-between;
  margin: 16px 0;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.style_swatchesWrapper__3XbRJ {
  width: 20px;
}

.style_swatches__12dV2 {
  display: flex;
  flex-direction: column;
}

.style_swatch__RHISc {
  cursor: pointer;
  flex: 0 1 100%;
}

.style_gradientWrapper__3dwjS {
  border-radius: 4px;
  flex: 0 1 196px;
  position: relative;
  width: 100%;
  cursor: pointer;
  overflow: hidden;
}

.style_hue__2IJXT {
  border-radius: 4px;
  height: 204px;
  overflow: hidden;
  position: relative;
  width: 20px;
  cursor: pointer;
}

.style_hue__2IJXT > div > div > div {
  cursor: grabbing;
}

.style_saturationPointer__2RFQj {
  box-shadow: 0 1px 2px 0 rgba(0,0,0,.64);
  border: 2px solid #ffffff;
  border-radius: 50%;
  height: 12px;
  width: 12px;
  -webkit-transform: translate(-6px, -6px);
          transform: translate(-6px, -6px);
  cursor: grabbing;
}

.style_pointer__AL5o- {
  background-color: transparent;
  border: 2px solid #fff;
  border-radius: 2px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.12);
  height: 6px;
  -webkit-transform: translateX(2px);
          transform: translateX(2px);
  width: 16px;
}

.style_fieldsWrapper__34dQJ {
  display: flex;
  justify-content: space-between;
}

.style_fieldsWrapper__34dQJ[data-is-disabled="true"] {
  pointer-events: none;
  opacity: 0.5;
}

/* targeting the color eye-dropper div when rendered */
.style_colorEyeDropperField__2uUYu {
  flex: 0.5 1;
  margin-left: 8px;
  width: 100%;
  height: 32px;
}

.style_fieldSingle__2D32A {
  flex: 1 1;
  margin-left: 8px;
}

.style_fieldDouble__1XL3r {
  flex: 2 1;
}

.style_fieldDouble__1XL3r input,
.style_fieldSingle__2D32A input {
  width: 100%;
  height: 32px;
  box-shadow: 0 2px 3px 0 rgba(15, 15, 16, 0.08);
  border: solid 1px #D1D4D6;
  border-radius: 4px;
  text-align: center;
}

.style_fieldDouble__1XL3r label,
.style_fieldSingle__2D32A label {
  display: block;
  text-align: center;
  color: #8f9295;
  padding-top: 4px;
  text-transform: capitalize;
}

.style_pickerHeader__M9Yuf {
  display: flex;
  padding-bottom: 10px;
  justify-content: space-between;
  align-items: center;
}

.style_pickerHeader__M9Yuf > div {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: #2c2e2f;
}

.style_eyeDropperButtonHex__2NgEy button {
  width: 32px;
  border-radius: 4px;
  box-shadow: 0 2px 3px 0 rgba(15, 15, 16, 0.08);
  border: solid 1px #D1D4D6;
  padding: 0;
}

.style_fontName__2pQ9C {
  margin-right: -4px;
  max-width: 160px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.style_fontDropZone__1PPOw {
  align-items: center;
  background-color: #F9F9FA;
  border: 1px dashed #D1D4D6;
  border-radius: 4px;
  display: flex;
  min-height: 88px;
  padding: 24px;
  cursor: pointer;
  flex-direction: column;

  font-family: Roboto;
  font-size: 14px;
  text-align: center;
  color: #57595d;

  max-height: 60vh;
  overflow-y: scroll;
}

.style_fontDropZone__1PPOw .style_remove__2Ufjh {
  cursor: pointer;
  margin-left: 12px;
}

.style_uploadedFontMeta__3yW-C {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  text-align: center;
  line-height: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 14px;
  width: 100%;
  min-height: 20px;
}

.style_uploadedFontMeta__3yW-C:first-of-type {
  margin-top:0;
}

.style_uploadedFontMeta__3yW-C > span {
  min-height: 15px;
  max-width: calc(100% - 32px);
}

.style_uploadedFontMeta__3yW-C > div {
  margin: 0;
}

.style_dropzone__1Waxu {
  width: 128px;
  height: 128px;
  border-radius: 50%;
  background-color: #F9F9FA;
  display: flex;
  align-items: center;
  justify-content: center;
}

.style_dropzone__1Waxu img {
  width: 128px;
  height: 128px;
  border-radius: 50%;
}

.style_dropzone__1Waxu[data-is-dragover="true"] {
  border: 2px dashed black;
}

.style_component__SCHnt {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.style_icon__22H8a {
  pointer-events: none;
}

.style_inputfile__2p6_f {
  display: none
}

.style_fontUploadContent__3YB2c > label {
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
}

.style_fontUploadSubText__2VTVG {
  font-family: Roboto;
  font-size: 11px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: 0.08px;
  text-align: center;
  color: #d1d4d6;
}

.style_error__2zpj9 {
  margin-top: 8px;
  text-align: center;
  color: #E64826;
  font-size: 12px;
  -webkit-animation: style_fade__32bej 0s ease-in 5s forwards;
          animation: style_fade__32bej 0s ease-in 5s forwards;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

@keyframes style_fade__32bej {
  to {
    width:0;
    height:0;
    overflow:hidden;
  }
}
@-webkit-keyframes style_fade__32bej {
  to {
    width:0;
    height:0;
    visibility:hidden;
  }
}

.style_option__LqiNB {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 4px 16px;
}
.style_option__LqiNB:hover {
  background-color: #F9F9FA;
}

.style_selected__30wp3 {
  line-height: 1;
  margin-left: 16px;
}

.style_name__2T_RL {
  color: #2c2e2f;
}
.style_search__32eov {
  margin: 0 16px 20px;
}

.style_search__32eov > div > input {
  padding-left: 8px;
}
.style_wrapper__3cnnY {
  align-items: center;
  cursor: pointer;
  display: flex;
}

.style_icon__IDPMn {
  margin-left: 8px;
  stroke: #fff;
  -webkit-transform: rotate(90deg) !important;
          transform: rotate(90deg) !important;
  width: 6px;
}
.style_label__2j_o2 {
  color: #2c2e2f;
  font-size: 13px;
  line-height: 1.85;
  text-align: left;
  padding-bottom: 9px;
}


.style_dropdown__2Bd7O {
  position: relative;
  display: inline-block;
  width: 100%;
}

.style_dropdown__2Bd7O * {
  cursor: pointer;
}

.style_inputDiv__1e51r {
  align-items: center;
  border: 1px solid #D1D4D6;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  min-height: 40px;
  justify-content: space-between;
  line-height: 20px;
  padding: 10px 12px 10px 12px;
}

.style_inputDiv__1e51r > svg {
  min-width: 8px;
  min-height: 8px;
}

.style_tagList__1652B {
  display: flex;
  flex-wrap: wrap;
}

.style_tagList__1652B > * {
  margin: 0 8px 8px 0;
}

.style_option__1tYZj:hover {
  background-color: #F9F9FA;
}
.style_content__2JMS7 {
  width: 100%;
  max-height: 200px;
  overflow: auto;
  border: 1px solid #D1D4D6;
  border-radius: 4px;
  display: block;
  position: absolute;
  top: 39px;
  background-color: white;
  min-width: 160px;
  padding: 16px 16px 16px 16px;
  z-index: 1;
}

.style_doneButton__3DiLQ {
  width: 100%;
}

.style_error__xMo4Z {
  position: absolute;
  color: #E64826;
  font-size: 12px;
  left: 5px;
  top: 100%;
}

.style_options__3CoL1 {
  background-color: #fff;
  border: 1px solid #D1D4D6;
  border-radius: 8px;
  display: none;
  max-height: 380px;
  left: 0;
  padding: 16px 0;
  position: absolute;
  top: 116%;
  width: 100%;
  z-index: 2;
  overflow: hidden;
}

.style_optionList__36rsq {
  overflow-y: auto;
  max-height: 235px;
  margin-right: 24px;
  margin-left: 16px;
}

.style_optionsOpened__1IQDw {
  display: flex;
  flex-direction: column;
  z-index: 4;
}

.style_placeholder__14G87 {
  width: 100%;
}

.style_footer__NL5Wj {
  height: 48px;
  display: flex;
  justify-content: flex-end;
  padding: 12px 23px;
  border-top: 1px solid #d1d4d6;
}

.style_tagRemoveIcon__1__xd {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.style_wrapper__3sV5_ {
  align-items: center;
  background-color: #F9F9FA;
  border-radius: 16px;
  display: flex;
  line-height: 28px;
  justify-content: space-between;
  padding: 0 10px;
  max-width: calc(100% - 24px);
}

.style_wrapper__3sV5_ > * + * {
  margin-left: 8px;
}

.style_withAvatar__16Orl {
  padding-left: 6px;
}

.style_avatarLabel__h0wpk {
  cursor: auto;
  font-size: 10px;
}

.style_label__1VwaM {
  color: #2c2e2f;
  font-size: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.style_remove__1uAYU {
  cursor: pointer;
}

.style_tagInput__3jOzl {
  border: 1px solid #D1D4D6;
  border-radius: 4px;
  display: flex;
  flex-wrap: wrap;
  padding: 12px 8px 4px 12px;
}

.style_tagInputTag__3_S9X {
  background: #eee;
  margin: 0 4px 8px 0;
  max-width: 100%;
}

.style_tagInputTextfield__1c0YM {
  border: none;
  border-radius: 4px;
  flex: 1 1;
  height: 28px;
  line-height: 28px;
  margin: 0 4px 8px 0;
  min-width: 50px;
  outline: none;
  padding: 0;
}

.style_tagInputHelper__2bEWF {
  display: block;
  font-size: 11px;
  margin-top: 8px;
  text-align: right;
}

.style_option__3D4hc {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 4px 16px;
}
.style_option__3D4hc:hover {
  background-color: #F9F9FA;
}

.style_selected__FUGBq {
  line-height: 1;
  margin-left: 16px;
}

.style_avatarOption__2-vxL {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  height: 56px;
  margin: 0 0px 8px 0;
  padding: 8px;
  border-radius: 8px;
  margin-right: 34px;
  overflow-y: hidden !important;
}

.style_avatarOption__2-vxL[data-is-selected="true"] {
  border: solid 1px #abd0e8;
  background-color: #ecf5fa;
}

.style_avatarOption__2-vxL[data-is-in-scrollable="false"] {
  width: 100%;
  margin-right: 12px;
}

.style_avatarOption__2-vxL:hover {
  background-color: #F9F9FA;
}

.style_avatarName__lPqx3 {
  margin-left: 12px;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.33;
  color: #2c2e2f;
  width: 100%;
  margin-right: 24px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.style_cross__2fXPe {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.style_cross__2fXPe > svg {
  cursor: pointer;
}
.style_colorSample__3M-Mh {
  border-radius: 100%;
  width: 24px;
  height: 24px;
  border: solid 1px rgba(0, 0, 0, 0.12);
  margin: 4px;
  cursor: pointer;
  position: relative
}

.style_colorSampleTransparent__3unus {
  background: linear-gradient(135deg, #ffffff 47%,#ffffff 47%,#ff0000 47%,#000000 50%,#ffffff 50%) !important;
}

.style_colorSampleSquare__19oHg {
  border-radius: 20%;
}

.style_imageSample__2JF9Q {
  border-radius: 100%;
  width: 24px;
  height: 24px;
  margin: 4px;
  position: relative;
  background-size: cover;
}

.style_switch__og_Ks {
  position: relative;
  display: block;
}

.style_switch__og_Ks input {display:none;}

.style_slider___zRaA {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
}

.style_slider___zRaA:before {
  position: absolute;
  content: "";
  height: 76%;
  width: 43%;
  left: 8%;
  bottom: 12.5%;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .style_slider___zRaA {
  background-color: #2d70cf;
  /*background-color: easilBlue;*/
}

input:checked + .style_slider___zRaA:before {
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
}

.style_switch__og_Ks > svg {
  fill: #fff;
  pointer-events: none;
  position: absolute;
}

.style_wrapper__3tQNB {
  margin: 16px 16px 8px;
  padding-right: 16px;
  position: relative;
}

.style_label__15lXa {
  background-color: #fff;
  display: inline-block;
  padding-right: 16px;
  position: relative;
  z-index: 2;
}

.style_divider__1nTiY {
  background-color: #e1e5e8;
  display: block;
  height: 1px;
  left: 0;
  position: absolute;
  top: 50%;
  width: 100%;
  z-index: 1;
}
.style_label__1F8ax {
  margin-bottom: 8px;
}

.style_form__1gB4- {
  display: flex;
}

.style_form__1gB4- + .style_form__1gB4- {
  margin-top: 16px;
}

.style_form__1gB4- > * + * {
  margin-left: 16px;
}

.style_group__2E1po {
  width: 100%;
}

.style_error__GU2Tq {
  color: #fc3131;
  font-size: 11px;
  margin-top: 2px;
}
.style_wrapper__2XomA {
  background-color: #fff;
  border: 1px solid #D1D4D6;
  border-radius: 4px;
  box-shadow: 0 2px 3px 0 rgba(28, 29, 30, 0.08);
  position: absolute;
  width: 300px;
  z-index: 30;
}
.style_wrapper__2oDjy {
  display: flex;
}

.style_tab__1Y7aH,
.style_active__2NL0K {
  border-bottom: 1px solid #D1D4D6;
  color: #7A7D81;
  cursor: pointer;
  font-size: 16px;
  padding: 8px 16px;
  transition: 300ms all ease-in-out;
}

.style_active__2NL0K,
.style_tab__1Y7aH:hover {
  border-bottom-color: #2c2e2f;
  color: #2c2e2f;
}

.style_wrapper__3jwmG {
  padding: 12px 0;
}

.style_wrapper__3jwmG + .style_wrapper__3jwmG {
  border-top: 1px solid #e4e4e4;
}

.style_labelWrapper__33cGF {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}

.style_label__2uc1F {
  font-size: 14px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.style_icon__3WDEf {
  margin-left: 12px;
}

.style_content__3XOrK {
  color: #2c2e2f;
}
.style_padLock__3W-u8 {
  margin-right: 4px;
  height: 16px;
  width: 16px;
}

.style_contents__1pnnK {
  display: flex;
  align-items: center;
}

.style_linkDisabled__241wF {
  padding-bottom: 2px;
  color: #7A7D81;
  cursor: default !important;
}

#style_lockedTabTip__2r9R- {
  width: 247px;
  height: 72px;
  border-radius: 4px;
  background-color: #1c1d1e;
  display: flex;
  align-items: center;
  padding: 16px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #ffffff;
}

#style_lockedTabTip__2r9R-::after {
  border-bottom-color: transparent !important;
  border-radius: 4px;
  font-size: 12px;
  color: white;
}

.style_wrapper__1jd7w {
  border: 1px solid #d1d4d6;
  border-radius: 4px;
  padding: 20px;
}

.style_header__1KsmI {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.style_inactive__3mqch {
  cursor: default;
}

.style_headerContent__3cqwS {
  margin-right: 20px;
  width: 100%;
}

.style_toggle__2UPBE {
  align-items: center;
  display: flex;
}

.style_content__uL9nK {
  border-top: 1px solid #e2e5e8;
  margin-top: 20px;
  padding-top: 20px;
}
.style_inputContainer__y-DnC {
  display: flex;
  justify-content: space-between;
  height: 40px;
  align-items: center;
}

.style_inputContainer__y-DnC > div:nth-child(1) {
  height: 24px;
  line-height: 1.71;
  color:  #8f9295;
}

.style_inputContainer__y-DnC > div:nth-child(2) {
  width: 80px;
  height: 32px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding: 0px 13px;
  border-radius: 4px;
  border: solid 1px #D1D4D6;
}

.style_inputContainer__y-DnC::after {
  content: "s";
  position: absolute;
  right: 8%;
  top: 50%;
}

@-moz-document url-prefix() {
  .style_inputContainer__y-DnC::after {
    content: "s";
    position: absolute;
    right: 8%;
    top: 59%;
  }
}

.style_inputField__J4tce {
  color: #2c2e2f;
  text-align: center;
}

.style_toolTip__1fXBU {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  font-size: 12px;
  line-height: 20px;
  padding: 5px 12px !important;
  color: white;
  background-color: #2c2e2f !important;
  text-align: center;
  opacity: 1 !important;
  white-space: pre-line;
}

/* our design decided align left multiline tooltips */
.style_toolTip__1fXBU > span {
  text-align: left !important;
}
.style_toolTip__1fXBU::after {
  border-left-color: transparent !important;
  border-bottom-color: transparent !important;
}

.style_scrollable__2FYda {
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;
  scrollbar-width: none;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.style_scrollable__2FYda::-webkit-scrollbar {
    width: 0;
    height: 0;
}
.style_scrollable__2FYda >
*,
*:before,
*:after {
  scrollbar-width: none;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}

.style_scrollable__2FYda::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.style_scrollable__2FYda > *:after {
  scrollbar-width: none;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.style_scrollable__2FYda > *::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.style_scrollable__2FYda .style_contentWrapper__3GbXf {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: scroll;
  position: relative;
}

.style_scrollerY__3SRX0 {
  width: 8px;
  top: 0px;
  right: 8px;
  z-index: 3;
  cursor: pointer;
  position: absolute;
  border-radius: 5px;
  background: #C5C8CA;
  transition: top .08s;
  min-Height: 24px;
}

.style_scrollerX__2szTH {
  height: 8px;
  bottom: 8px;
  left: 0px;
  z-index: 3;
  cursor: pointer;
  position: absolute;
  border-radius: 5px;
  background: #C5C8CA;
  transition: top .08s;
  min-Width: 24px;
}

/* empty for now, here for target reasons */
.style_content__2vs05 {

}

.style_scrollFold__3_xEb > div {
  z-index: 2;
}
.style_dropDownWrapper__21vmM {
  width: 200px;
  border-radius: 4px;
  box-shadow: 0 2px 3px 0 rgba(15, 15, 16, 0.08);
  border: solid 1px var(--silver);
  display: flex;
  flex-direction: column;
  padding: 24px 16px;
  overflow: visible;
}

.style_dropDownWrapper__21vmM > div {
  height: 24px;
  font-family: Roboto;
  font-size: 14px;
  line-height: 1.71;
  color: #2c2e2f;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.style_dropDownWrapper__21vmM > div > div {
  margin-left: 11px;
  height: 16px;
}

.style_dropDownWrapper__21vmM > div > span {
  cursor: pointer;
}

.style_dropDownWrapper__21vmM > :nth-child(2) {
  margin-top: 12px;
}

.style_buttonDropDownWrapper__38-KP {
  position: relative;
}
.style_dropDownPopout__6S_Od {
  overflow: visible !important;
}
.style_buttonLabel__36Ib4 {
  margin: 0px 8px 0px 8px;
}

.style_designSizeCardWrapper__gpPq7:last-of-type {
  margin-right: 0px;
}

.style_designSizeCardWrapper__gpPq7 {
  display: flex;
  justify-content: center;
  width: 150px;
  min-width: 150px;
  height: 270px;
  position: relative;
  margin-right: 25px;
  margin-top: 24px;
}

.style_thumbCard__274D7 {
  position: absolute;
  bottom: 70px;
  display: inline-block;
  cursor: pointer;
  padding: 4px;
}

.style_thumbCard__274D7[data-is-selected="true"] {
  border: 2px solid #2c2e2f;
  border-radius: 6px;
  padding: 2px;
}

.style_thumbnail__3ZHQl {
  max-width: 144px;
  max-height: 175px;
  display: block;
  border-radius: 4px;
}

.style_cardInfo__3X6a4 {
  position: absolute;
  top: 200px;
  margin-top: 12px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

.style_cardInfoLabel__1WIcW {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.33;
  color: #2c2e2f;
}

.style_cardInfoText__2iSkv {
  height: 20px;
  font-size: 14px;
  line-height: 1.43;
  color: #afb3b6;
}

.style_row__16gm6 {
  display: flex;
  /* this is to fix chrome redraw issues, forces chrome to use gpu hardware acceleration*/
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  justify-content: flex-start;
  overflow: hidden;
}

.style_viewBox__1uZ_K {
  position: relative;
}

.style_buttons__38SDy {
  position: absolute;
  background-color: white;
  cursor: pointer;
  top: calc(50% - 24px);
  height: 48px;
  width: 48px;
  border-radius: 100%;
  border: 1px solid #D1D4D6;
  display: flex;
  justify-content: center;
  align-items: center;
}

.style_buttons__38SDy[data-direction="left"] {
  right: calc(100% + 24px);
}

.style_buttons__38SDy[data-direction="right"] {
  left: calc(100% + 24px);
}

.style_designSizeCard__2ZbMp {
  margin: 0px 25px 0px 0px;
}

.style_designSizeCard__2ZbMp:last-of-type {
  margin-right: 0px;
}

.style_toggleWrapper__WBGTz {
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  width: 226px;
  height: 60px;
  border-radius: 8px;
  border: solid 1px #58595a;
  background-color: #323334;
  margin-bottom: 32px;
  align-items: center;
  position: relative;
}

.style_toggleSwitch__1nWKJ {
  width: 105px;
  height: 44px;
  border-radius: 8px;
  background-color: #ffffff;
  margin-left: 8px;
  margin-right: 8px;
  position: absolute;
  transition: -webkit-transform 0.25s;
  transition: transform 0.25s;
  transition: transform 0.25s, -webkit-transform 0.25s;
}

.style_toggle__1ZBS4 {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1;
  z-index: 2;
  cursor: pointer;
  color: #ffffff;
  height: 100%;
}

.style_toggle__1ZBS4[data-toggle-left="true"] {
  margin-left: 8px;
}

.style_toggle__1ZBS4[data-toggle-right="true"] {
  margin-right: 8px;
}

.style_toggle__1ZBS4[data-is-active="true"] {
  color: #2c2e2f;
}

.style_toggle__1ZBS4[data-is-disabled="true"] {
  cursor: auto !important;
}

.style_toggle__1ZBS4[data-is-disabled="true"] > div:last-of-type {
  opacity: 0.72;
}

.style_toggleSwitchLeft__1B6sJ {
  -webkit-transform: translate(0px, 0px);
          transform: translate(0px, 0px);
}

.style_toggleSwitchRight__2T39X {
  -webkit-transform: translate(105px, 0px);
          transform: translate(105px, 0px);
}

.style_defaultSubHeader__3U5mh {
  height: 48px;
}

.style_lockIcon__293Jl {
  margin-right: 8px;
  width: 20px;
  height: 30px;
}

#style_lockedTabTip__3Tlen {
  width: 200px;
  height: 72px;
  border-radius: 8px;
  background-color: #111212 !important;
  display: flex;
  align-items: center;
  padding: 16px;
  font-family: Roboto;
  font-size: 14px;
  line-height: 1.43;
  color: #ffffff;
  opacity: 1 !important;
}

#style_lockedTabTip__3Tlen::after {
  border-bottom-color: #111212 !important;
}

.style_foldersTabTitle__2yqOd {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 130px;
  font-size: 16px;
  line-height: 1.5;
  color: #ffffff;
  cursor: pointer;
}

.style_foldersTabTitle__2yqOd[data-short-title="true"] {
  width: 100px;
}

.style_headerPadding__ECBLd {
  height: 0;
  margin-top: 48px;
}

.style_subHeaderWrapper__2GYgt {
  position: relative;
}

.style_error__2B8Ib {
  color: red;
  padding-bottom: 10px;
}

.style_modalFormLabel__laZ_B {
  margin-bottom: 9px;
}
/* subHeaderTabs */
.style_subHeaderTabsWrapper__1H_-L {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
}

/* subHeaderTab */
.style_subHeaderTabWrapper__1-Z5b {
  position: relative;
  flex: 0 0 auto;
  z-index: 0;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: #2c2e2f;
  color: white;
  height: 44px;
  font-family: Roboto;
  font-weight: 400;
}

.style_subHeaderTabWrapper__1-Z5b > span {
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  left: 0;
  bottom: 12px;
  position: absolute;
  width: 1px;
  height: 20px;
}

.style_subHeaderTabWrapper__1-Z5b:first-of-type > span {
  border-left: none;
}

.style_subHeaderTabWrapper__1-Z5b[id="non-nav-tab"] {
  bottom: -3px;
}

.style_tabText__OATj2 {
  padding: 10px 14px;
  text-align: center;
  font-size: 16px;
  line-height: 1.5;
  opacity: 0.72;
}

.style_selectedTab__2qS3H {
  background: white;
  color: black;
  z-index: 1;
  opacity: 1;
  font-family: Roboto !important;
  font-weight: 500;
}

.style_selectedTab__2qS3H::after {
  position: absolute;
  content:" ";
  background: white;
  width:10px;
  height: 10px;
  display: block;
  bottom: 0;
  right: -10px;
  z-index: 1;
}

.style_selectedTab__2qS3H::before {
  position: absolute;
  content:" ";
  background: white;
  width:10px;
  height: 10px;
  display: block;
  bottom: 0;
  left: -10px;
  z-index: 1;
}

.style_leftTabBumper__1JcQj {
  position: absolute;
  height: 0px;
  width: 0px;
  left: -20px;
  bottom: 0;
  z-index:2;
  border-left: 10px solid transparent;
  border-top: 10px solid transparent;
  border-right: 10px solid #2c2e2f;
  border-bottom: 10px solid #2c2e2f;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.style_rightTabBumper__kApUt {
  position: absolute;
  height: 0px;
  width: 0px;
  right: -20px;
  bottom: 0;
  z-index:2;
  border-right: 10px solid transparent;
  border-top: 10px solid transparent;
  border-left: 10px solid #2c2e2f;
  border-bottom: 10px solid #2c2e2f;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.tabsStyle_brandManagerApprovedDesignsTab__S7BPY {
  margin-left: auto;
  justify-self: flex-end;
}

.style_subHeaderWrapper__r67sH {
  background-color: #2c2e2f;
  color: white;
  display: flex;
  flex-direction: column;
}

.style_subHeaderTitle__2PFCL {
  font-family: Roboto;
  font-size: 32px;
  font-weight: 300;
  line-height: 1.19;
  color: #ffffff;
  display: flex;
  flex-direction: row;
}

.style_subHeaderTitlePrefix__2AZN- {
  margin-right: 10px;
  max-width: 863px;
  max-height: 38px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.style_headerWrapper__1nmX3 {
  background-color: #FCFCFD;
  border-bottom: solid 1px #E2E5E8;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 23px;
  padding-right: 8px;
  height: 55px;
}

.style_headerTitle__t_Hvr {
  color: #2c2e2f;
  font-family: Roboto;
  font-size: 17px;
  font-weight: 500;
  line-height: 1.41;
}

.style_headerButton__3YMxd {
  color: #2C6FCF;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
}

.style_iconBoundingBox__3wOnm {
  align-items: center;
  display: flex;
  height: 24px;
  justify-content: center;
  width: 24px;
  margin-right: 3px;
}

.style_selectionItem__29fCj {
  padding: 6px 12px;
  font-family: Roboto;
  font-size: 16px;
  line-height: 1.5;
  color: #57595d;
}

.style_selectionItem__29fCj[data-no-click="true"] {
  pointer-events: none;
}

.style_selectionItem__29fCj:first-of-type {
  margin-top: 6px;
}

.style_selectionItem__29fCj:last-of-type {
  margin-bottom: 6px;
}

.style_selectionItem__29fCj:hover {
  border-radius: 4px;
  background-color: #f9f9fa;
  cursor: pointer;
}

.style_selectionItemWrapper__DoziL {
  padding: 12px 12px;
  max-height: 394px;
  overflow-y: scroll;
}

.style_popout__1WYql {
  position: fixed;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px 0 rgba(21, 21, 22, 0.08);
  border: solid 1px #FFFFFF;
  overflow-y: hidden !important;
}

.style_wrapper__3ttUy {
  position: relative;
  display: inline-block
}

.style_button__1UHPe {
  padding: 12px 16px;
  height: 48px;
  color: #2c2e2f;
  background-color: white;
  box-shadow: 0px 2px 2px rgba(21, 21, 22, 0.12);
  border-radius: 8px;
}

.style_icon__2rGXd {
  display: flex;
  justify-content: center;
  align-items: center;
}

.style_title__3NHDR {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.6;
  color: #2c2e2f;
  margin: 0px 22px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.style_menu__27tg8 {
  display: flex;
  margin-left: auto;
  border-radius: 4px
}

.style_menu__27tg8:hover svg rect {
  fill: #e2e5e8 !important;
}

.style_menu__27tg8:hover svg circle {
  fill: #57595d !important;
}

.style_menu__27tg8[data-is-active="true"] svg rect {
  fill: #e2e5e8 !important;
}

.style_menu__27tg8[data-is-active="true"] svg circle {
  fill: #57595d !important;
}

.style_dropDownPopout__8KiOE {
  top: 40px;
  right: 16px;
  width: auto;
  border-radius: 8px;
  border: 1px solid #E2E5E8;
}

.style_scrollFold__3AnUR {
  position: absolute;
  height: 100%;
}

.style_scrollFoldContent__fctGb {
  position: relative;
  height: 100%;
}

.style_fold__1Z89q {
  position: absolute;
  pointer-events: none;
}
.style_buttonWrapper__3bCK0{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.style_toggleVariables__2z9-c {
  --switch-background-color: #323334;
  --switch-button-color: #ffffff;
  --switch-button-width: 105px;
  --switch-button-height: 44px;
  --switch-height: 60px;
  --switch-width: 226px;
  --switch-button-border-radius: 8px;
  --switch-border-radius: 8px;
  --switch-border-color: #58595a;
  --switch-translation: 105px;
  --switch-box-shadow: none;
  --switch-left-margin-offset: 1.5px;
}

.style_toggleWrapper__TJNG- {
  display: flex;
  flex-direction: row;
  width: var(--switch-width);
  height: var(--switch-height);
  border-radius: var(--switch-border-radius);
  border: solid 1px var(--switch-border-color);
  background-color: var(--switch-background-color);
  align-items: center;
  position: relative;
}

.style_toggleSwitch__3brvU {
  width: var(--switch-button-width);
  height: var(--switch-button-height);
  border-radius: var(--switch-button-border-radius);
  background-color: var(--switch-button-color);
  margin-left: calc(((var(--switch-height) - var(--switch-button-height)) / 2) - var(--switch-left-margin-offset));
  margin-right: calc((var(--switch-height) - var(--switch-button-height)) / 2);
  box-shadow: var(--switch-box-shadow);
  position: absolute;
  transition: -webkit-transform 0.25s;
  transition: transform 0.25s;
  transition: transform 0.25s, -webkit-transform 0.25s;
}

.style_toggle__2yCud {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1;
  z-index: 2;
  cursor: pointer;
  color: var(--switch-button-color);
  height: 100%;
}

.style_toggle__2yCud[data-toggle-left="true"] {
  margin-left: calc((var(--switch-height) - var(--switch-button-height)) / 2);
}

.style_toggle__2yCud[data-toggle-right="true"] {
  margin-right: calc((var(--switch-height) - var(--switch-button-height)) / 2);
}

.style_toggle__2yCud[data-is-active="true"] {
  color: var(--switch-background-color);
  font-weight: 500;
}

.style_toggle__2yCud[data-is-active="false"] {
  font-weight: 400;
  opacity: 0.72;
}

.style_toggle__2yCud[data-is-disabled="true"] {
  cursor: auto !important;
}

.style_toggle__2yCud[data-is-disabled="true"] > div:last-of-type {
  opacity: 0.72;
}

.style_toggleSwitchLeft__1878l {
  -webkit-transform: translate(0px, 0px);
          transform: translate(0px, 0px);
}

.style_toggleSwitchRight__2xxyS {
  -webkit-transform: translate(var(--switch-translation), 0px);
          transform: translate(var(--switch-translation), 0px);
  margin-left: calc(((var(--switch-height) - var(--switch-button-height)) / 2) +  (4 * var(--switch-left-margin-offset)));
}

.style_removeBGModalWrapper__17gzV {
  width: 650px;
  border-radius: 8px;
  background-color: white;
  padding: 32px;
  max-height: 95vh;
  display: flex;
  flex-direction: column;
}

.style_removeBGModalWrapper__17gzV > div:first-of-type {
  font-size: 20px;
  line-height: 1.2;
  color: #2c2e2f;
  margin-bottom: 24px;
}

.style_removeBGModalWrapper__17gzV > div:last-of-type {
  overflow: hidden;
  overflow-y: auto;
  flex-direction: column;
  display: flex;
  height: 100%;
}

.style_gifAnimationWrapper__2_H_R {
  width: 586px;
  height: 225px;
  border-radius: 4px;
  background-color: #eeeeee;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.style_featureDescription__18mjI {
  font-size: 15px;
  line-height: 1.6;
  color: #57595D;
  margin-top: 24px;
}

.style_upgradeBoxWrapper__17NPC {
  width: 586px;
  border-radius: 8px;
  border: solid 1px #abd0e8;
  background-color: #ecf5fa;
  margin-top: 32px;
  padding: 16px;
}

.style_upgradeBoxTitle__11Npt {
  font-size: 18px;
  line-height: 1.56;
  color: #2c2e2f;
}

.style_upgradeBoxDescription__2A2ux {
  font-size: 15px;
  line-height: 1.6;
  color: #57595D;
  margin: 16px 0px 16px 0px;
}

.style_creditBreakdownBoxWrapper__1sy7J {
  width: 586px;
  height: 84px;
  border-radius: 8px;
  border: solid 1px #E2E5E8;
  background-color: #FCFCFD;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 24px;
  padding: 0px 20.5px 0px 4px;
  justify-content: space-around;
}

.style_creditBreakdownDetail__1H1LU {
  height: 52px;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.style_detailSection__3yg-p {
  display: flex;
  width: 100%;
}

.style_verticalDivider__3UX4k {
  width:0px;
  height: 52px;
  border-right: 1px solid #dddddd;
}

.style_creditBreakdownDetailTitle__3nRBF {
  font-size: 14px;
  line-height: 1.43;
  display: flex;
  align-items: center;
  color: #57595D;
}

.style_creditBreakdownDetailTitle__3nRBF > div > svg {
  margin-left: 6px;
}

.style_creditBreakdownDetailValue__ESEiG {
  font-size: 20px;
  line-height: 1.4;
  color: #2c2e2f;
}

.style_info__1j2_Q {
  align-items: flex-start !important;
  margin-top: 24px;
}

.style_info__1j2_Q > div {
  font-size: 14px;
  line-height: 1.57 !important;
  color: #2c2e2f;
}

strong[data-is-link="true"] {
  color: #2c6fcf;
  cursor: pointer;
}

.style_buttons__1tk_A {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 40px;
}

.style_applyButton__VmRUo {
  margin-left: 16px;
  min-width: 197px;
}

.style_loadingData__PWEzk {
  margin-top: 40px;
}

.style_alert__1lqD7 {
  color: #2c2e2f;
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: solid 1px #abd0e8;
  background-color: #ecf5fa;
  font-size: 15px;
  line-height: 24px;
  padding: 16px 18px;
}

.style_green__KP--V {
  background-color: #e7f8f3;
  border: solid 1px rgba(59, 179, 141, 0.32);
}

.style_red__14p8o {
  background-color: #fcefee;
  border: solid 1px #f2bfb4;
  color: #992a3b;
}

.style_red__14p8o > * {
  color: #992a3b !important;
}

.style_yellow__3q0RS {
  background-color: #fff5db;
  border: solid 1px #ffd34a;
}

.style_blue__3NxBv {
  background-color: #ecf5ff;
  border: solid 1px #a1c6ff;
}

.style_icon__2LKdd {
  display: inline-flex;
  margin: 0px 10px 0px 0px;
  cursor: default;
}


ul.style_contextMenuContainer__x6tLa {
  background: white;
  border: solid 1px #D1D4D6;
  box-shadow: 0 1px 2px 0 rgba(21, 21, 22, 0.12);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  position: absolute;
  z-index: 10;
}

ul.style_contextMenuContainer__x6tLa > li {
  align-items: center;
  color: #8f9295;
  display: flex;
  padding: 4px 16px;
  pointer-events: none;
  font-weight: 500;
}

ul.style_contextMenuContainer__x6tLa > li:not([disabled]) {
  color: #2c2e2f;
  cursor: pointer;
  pointer-events: unset;
}

ul.style_contextMenuContainer__x6tLa > li:not([disabled]):hover {
  background: whitesmoke;
}

ul.style_contextMenuContainer__x6tLa > li > svg {
  margin-right: 16px;
}

ul.style_contextMenuContainer__x6tLa > hr {
  border-color: #eee;
  border-style: solid;
  border-width: 1px 0 0 0;
  margin: 8px 0;
}

.style_beta__3VdHx {
  opacity: 0.4;
}

.style_icon__29xL6 {
  height: 16px;
  width: 16px;
  margin-right: 18px;
  margin-left: -2px;
  margin-bottom: 5px;
}

.styleResizeBox_resizeBox__zpwVz {
  pointer-events: none;
  z-index: 5;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
}

.styleResizeBox_resizeBox__zpwVz:after {
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  clear: both;
  content: ' ';
  pointer-events: none;
  border: 2px solid #3184FC;
  background-color: transparent;
  border-radius: 4px;
}
:root {
  --replace-button-width: 48px;
  --replace-offset: 0px;
}

.styleElementContextMenu_menuWrapper__36Qei {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  top: calc(-35px + 0px);
  top: calc(-35px + var(--replace-offset));
  width: 100%;
}

.styleElementContextMenu_menuWrapperLeft__2jnSY {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  left: calc(-35px + 0px);
  left: calc(-35px + var(--replace-offset));
  width: 24px;
  height: 100%;
  flex-direction: column;
}

.styleElementContextMenu_menuWrapperRight__1DMA8 {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  width: 24px;
  left: calc(100% + 10px - 0px);
  left: calc(100% + 10px - var(--replace-offset));
  height: 100%;
  flex-direction: column;
}

.styleElementContextMenu_menuWrapperBottom__Xy_5k {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  top: calc(100% + 10px - 0px);
  top: calc(100% + 10px - var(--replace-offset));
  width: 100%;
}

.styleElementContextMenu_tableMenuWrapper__iJtE4 {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  top: -35px;
  left: 20px;
  width: 100%;
}

.styleElementContextMenu_tableMenuWrapperLeft__36elZ {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  left: -35px;
  width: 24px;
  height: 100%;
  flex-direction: column;
}

.styleElementContextMenu_tableMenuWrapperRight__OdrfY {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  width: 24px;
  left: calc(100% + 27px);
  height: 100%;
  flex-direction: column;
}

.styleElementContextMenu_tableMenuWrapperBottom__2a_I2 {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  top: calc(100% + 10px);
  left: -20px;
  width: 100%;
}

.styleElementContextMenu_table2MenuWrapper__3VnBi {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  top: calc(-58px + 0px);
  top: calc(-58px + var(--replace-offset));
  width: 100%;
}

.styleElementContextMenu_table2MenuWrapperLeft__1STWk {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  left: calc(-61px + 0px);
  left: calc(-61px + var(--replace-offset));
  width: 24px;
  height: 100%;
  flex-direction: column;
}

.styleElementContextMenu_table2MenuWrapperRight__-5DRu {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  width: 24px;
  left: calc(100% + 36px - 0px);
  left: calc(100% + 36px - var(--replace-offset));
  height: 100%;
  flex-direction: column;
}

.styleElementContextMenu_table2MenuWrapperBottom__NTOe3 {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  top: calc(100% + 36px - 0px);
  top: calc(100% + 36px - var(--replace-offset));
  width: 100%;
}

.styleElementContextMenu_ellipsisBox__3prlC {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 24px;
  height: 24px;
  padding: 2px;
  border-radius: 6px;
  box-shadow: 0 1px 2px 0 rgba(21, 21, 22, 0.12);
  background-color: var(--grey-white);
}

.styleElementContextMenu_ellipsisBox__3prlC[data-image-replace="true"] {
  width: calc(24px + 48px);
  width: calc(24px + var(--replace-button-width));
}

.styleElementContextMenu_ellipsisLockBox__3mXOv {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 44px;
  height: 24px;
  border-radius: 6px;
  box-shadow: 0 1px 2px 0 rgba(21, 21, 22, 0.12);
  background-color: var(--grey-white);
}

.styleElementContextMenu_ellipsisLockBox__3mXOv[data-image-replace="true"] {
  width: calc(44px + 48px);
  width: calc(44px + var(--replace-button-width));
}

.styleElementContextMenu_ellipsis__3FBPI {
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 4px;
  pointer-events: all !important;
}

.styleElementContextMenu_ellipsislockEnabled__3l2ny {
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 2px 4px 4px 2px;
  pointer-events: all !important;
  height: 20px;
  width: 20px;
}

.styleElementContextMenu_padlockEnabled__s8K9f {
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 4px 2px 2px 4px;
  pointer-events: all !important;
  height: 20px;
  width: 20px;
}

.styleElementContextMenu_replaceButton__2xLks {
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 2px 2px 2px 2px;
  pointer-events: all !important;
  height: 20px;
  width: 48px;
  width: var(--replace-button-width);
  font-family: Roboto;
  font-size: 11px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #2c2e2f;
}

.styleElementContextMenu_replaceButton__2xLks:first-child {
  border-radius: 4px 2px 2px 4px;
}

.styleElementContextMenu_ellipsis__3FBPI:hover {
  background: #f5f5f8;
}
.styleElementContextMenu_padlockEnabled__s8K9f:hover {
  background: #f5f5f8;
}
.styleElementContextMenu_ellipsislockEnabled__3l2ny:hover {
  background: #f5f5f8;
}
.styleElementContextMenu_replaceButton__2xLks:hover {
  background: #f5f5f8;
}

.styleElementContextMenu_suggestionWrapper__27Nnq {
  display: flex;
  flex-direction: column;
  top: calc(-35px + 0px);
  top: calc(-35px + var(--replace-offset));
}

.styleElementContextMenu_suggestionWrapperLeft__3EqQS {
  display: flex;
  flex-direction: column;
  left: calc(-35px + 0px);
  left: calc(-35px + var(--replace-offset));
}

.styleElementContextMenu_suggestionWrapperRight__3BbcE {
  display: flex;
  flex-direction: column;
  left: calc(100% + 10px - 0px);
  left: calc(100% + 10px - var(--replace-offset));
  flex-direction: column;
}

.styleElementContextMenu_suggestionWrapperBottom__15a2D {
  display: flex;
  flex-direction: column;
  top: calc(100% + 10px - 0px);
  top: calc(100% + 10px - var(--replace-offset));
}
.textMaskingCroppingStyle_textMasking__OZvgL {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: black;
  z-index: 3;
  opacity: 0.5;
}

.style_gridMasking__rRLmV {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: black;
  z-index: 3;
  opacity: 0.5;
}

:root {
  --canvas-transform: "";
  --canvas-opacity: 1;
  --canvas-top: 0px;
  --canvas-left: 0px;
  --canvas-filter: none;
}

.style_originSvgWrapper__1qAgF {
  opacity: 1;
  height: 100%;
  position: relative;
}

.style_originSvgWrapper__1qAgF > svg {
  position: absolute;
  top: 0;
  left: 0;
}

.style_originSvgWrapper__1qAgF canvas {
  -webkit-transform: "";
          transform: "";
  -webkit-transform: var(--canvas-transform);
          transform: var(--canvas-transform);
  opacity: 1;
  opacity: var(--canvas-opacity);
  top: 0px !important;
  top: var(--canvas-top) !important;
  left: 0px !important;
  left: var(--canvas-left) !important;
  -webkit-filter: none;
          filter: none;
  -webkit-filter: var(--canvas-filter);
          filter: var(--canvas-filter);
}
.style_photoFrameMasking__WASk6 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: black;
  z-index: 3;
  opacity: 0.5;
}

.style_image__p9S-8 {
  overflow: visible;
}

.style_resizeBox__346QX {
  position: fixed;
  z-index: 12;
}

.style_resizeBoxAbsoluteWrapper__3Rg5N {
  position: absolute;
}
.style_imageCropping__2qOXk {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: black;
  z-index: 3;
  opacity: 0.5;
}

.style_image__k4k6Y {
  width: 100%;
  pointer-events: none;
  position: absolute;
  top: 0px;
  left: 0px;
}

.style_videoCropping__1oOJw {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: black;
  z-index: 3;
  opacity: 0.5;
}

.style_video__3s0Ua {
  width: 100%;
  pointer-events: none;
  position: absolute;
  top: 0px;
  left: 0px;
}

.style_vectorCropping__2RTy_ {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: black;
  z-index: 3;
  opacity: 0.5;
}

.style_linkButton__1GfZl {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: none;
  display: inline;
  margin: 0;
  padding: 0;
  color: white;
  font-weight: 500;
  pointer-events: auto;
}

.style_linkButton__1GfZl:hover,
.style_linkButton__1GfZl:focus {
  text-decoration: underline;
}

.style_confirmationModal__3NvgH {
  width: 550px;
  border-radius: 8px;
  background-color: #fff;
  padding: 32px;
}

.style_confirmationModalContent__1GJmy {
  white-space: pre-line;
  font-family: Roboto;
  font-size: 15px;
  line-height: 24px;
  color: #57595d;
}

.style_hideButtons__3vhQE {
  display: none
}
.style_center__1L-5t {
  display: flex;
  text-align: center;
  vertical-align: middle;
  background-color: white;
  height: 100vh;
  width:100vw;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 10;
}

.style_windowedCenter__1yWxG {
  height: 100%;
  width: 100%;
  display: flex;
  text-align: center;
  vertical-align: middle;
  background-color: white;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.style_center__18Vpn {
  background-color: white;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  text-align: center;
  align-items: center;
  vertical-align: middle;
}

.style_center__18Vpn > :nth-child(1) {
  top: 32px;
  min-height: 30.5px;
  position: absolute;
  cursor: pointer;
}

.style_center__18Vpn > :nth-child(2) {
  margin-bottom: 40px;
  min-height: 85px;
}

.style_center__18Vpn > :nth-child(4) {
  margin-bottom: 32px;
}

.style_heading__1ZuDa {
  margin-bottom: 0;
  font-family: Roboto;
  font-size: 24px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #1c1d1e;
}
.style_wrap__4lVTE {
  padding: 13px 24px 13px 24px;
}

.style_header__7YDTF {
  height: 120px;
}

.style_errorMessage__1MaPN {
  width: 420px;
}

.style_upgradeButton__3H0Kv {
  margin-right: 32px;
}

.style_upgradeLink__2NFK2 {
  display: inline-block;
}

.style_logo__kZncK {
  height: 40px;
  padding: 4px;
  width: 40px;
  display: flex;
  opacity: 1;
  transition: opacity 100ms ease;
}

.style_logo__kZncK:hover {
  opacity: 0.88;
}

.style_logo__kZncK a {
  display: block;
  height: 32px;
}

.style_logo__kZncK svg {
  height: 32px;
  width: 32px;
}

.style_navlink__1avS6 {
  color: white;
  opacity: 0.72;
  padding: 6px 10px 6px 10px;
  transition: opacity 100ms ease;
}

.style_navlink__1avS6:not(:last-child) {
  margin-right: 4px;
}

.style_navRowRight__2PZO_ {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.style_navlinkActive__1dgpP {
  opacity: 1 !important;
  font-weight: 500 !important;
}

.style_navlink__1avS6:hover,
.style_navlink__1avS6:active,
.style_navlink__1avS6:focus {
  opacity: 1;
}

.style_navRow__1rJg7 {
  align-items: center;
  display: flex;
  padding-top: 24px;
}

.style_title__2QiG0 {
  color: white;
  font-size: 32px;
  line-height: 32px;
  margin-top: 63px;
  font-weight: 300;
}

.style_icon__189gs {
  position: relative;
}

.style_icon__189gs {
  height: 24px;
  margin-right: 24px;
  padding: 2px;
  position: relative;
  width: 24px;
}

.style_navIcon__1IrDV {
  height: 24px;
  width: 24px;
  align-items: center;
  cursor: pointer;
  display: flex;
  opacity: 0.72;
  transition: opacity 100ms ease;
}

.style_navIcon__1IrDV:hover {
  opacity: 1;
}

.style_navIcon__1IrDV:not(:last-child) {
  margin-right: 24px;
}

.style_navIcon__1IrDV[data-disabled="true"] {
  opacity: 0.2;
  pointer-events: none;
}

.style_iconWithDropDown__1zVjh {
  width: auto;
}

.style_teamMenuDropdown__3KXmD {
  height: 24px;
  position: relative;
}

.style_avatar__3leTN {
  opacity: 1;
  transition: opacity 100ms ease;
}

.style_avatar__3leTN:hover {
  opacity: 0.88;
}

.style_avatarActive__fCTQZ {
  border-color: #3184fc !important;
}

/* Team Dropdown Styles */

.style_teamDropdownTitle__3bh6- {
  margin-right: 16px;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: #ffffff;
}

.style_teamDropDownButton__2zxoh {
  align-items: center;
  color: #fff;
  display: flex;
}

.style_avatarWithImage__FUIuD {
  box-shadow: 0 0 0 2px #fff inset;
}

.style_bottomPanel__2MvHC {
  display: flex;
  justify-content: space-between;
  margin-top: 34px;
  min-height: 40px;
}

.style_bottomPanel__2MvHC > div {
  display: flex;
  align-items: center;
}

.style_bottomPanel__2MvHC > div > a {
  font-family: Roboto;
  font-size: 16px;
  line-height: 1.5;
  color: #ffffff;
  position: relative;
}

.style_bottomPanelIcon__32ThW {
  position: relative;
  width: 100%;
  height: 100%;
}

.style_createDesignButton__24mkF {
  margin-right: 14px;
}

.style_marginRight__x7-Uz {
  margin-right: 14px !important;
}

.style_notification__1ITeb {
  margin-right: 33px;
}

.style_dropdownLinkWrapper__34E45{
  position: relative;
}

.style_dropdownLink__1kIrk {
  font-family: Roboto;
  font-size: 16px;
  line-height: 1.5;
  color: #ffffff;
  opacity: 0.72;
  padding: 6px 10px 6px 10px;
  transition: opacity 100ms ease;
  cursor: pointer;
  margin-right: 4px;
}

.style_dropdownLink__1kIrk:hover {
  opacity: 1;
}

.style_dropdownLink__1kIrk > svg {
  margin-left: 6px;
}

.style_dropdownLink__1kIrk[data-is-active="true"] {
  opacity: 1 !important;
  font-weight: 500 !important;
}

.style_dropdown__1fqZw {
  position: absolute;
  width: 168px;
  padding: 12px;
  border-radius: 8px;
  border: solid 1px #d1d4d6;
  background-color: #ffffff;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.style_dropdownOption__pAIfu {
  width: 144px;
  height: 40px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 2.25;
  text-align: center;
  color: #57595d;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.style_dropdownOption__pAIfu:hover {
  background-color: #f5f5f6;
}

.style_linkEllipsisWrapper__2MfwN {
  height: 24px;
  margin-right: 24px;
  margin-left: 6px;
  cursor: pointer;
  position: relative;
}

.style_linkEllipsisWrapper__2MfwN > svg {
  opacity: 0.72;
}

.style_linkEllipsisWrapper__2MfwN:hover > svg {
  opacity: 1;
}

.style_linkPopout__36BLp {
  width: 171px;
  position: absolute;
  background-color: white;
  padding: 12px 0px;
  border-radius: 8px;
  -webkit-transform: translate(-75.5px);
          transform: translate(-75.5px);
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #d1d4d6;
  cursor: default;
  z-index: 99;
}

.style_popoutNavlinkWrapper__3IX5- {
  display: flex;
  height: 40px;
  width: 147px;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
}

.style_popoutNavlinkWrapper__3IX5-:hover {
  background-color: #f5f5f8;
}

.style_popoutNavlink__30uJk {
  padding: 6px 10px 6px 10px;
  transition: opacity 100ms ease;
  font-size: 16px;
  font-weight: 500;
  color: #57595d;
}

.style_popoutNavlinkActive__35-EB {
  font-weight: 500 !important;
}

.style_popoutLinksSeperator__2nqS5 {
  height: 1px;
  width: 100%;
  border-top: 1px solid #d1d4d6;
  margin: 6px 0;
}

.style_cartContentIndicator__1c3UC {
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #67dae3;
  border-radius: 100%;
  border: 2px solid #1c1d1e;
  left: 13px;
  top: -8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Roboto;
  font-size: 12px;
  color: #1c1d1e;
  font-weight: bold;
}
.style_count__34Cuk {
  position: absolute;
  top: -11px;
  left: 7px;
  width: auto;
  padding: 0px 8px;
  height: 20px;
  background-color: #fa4d50;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.67;
  letter-spacing: normal;
  color: #ffffff;
}

.style_iconBox__1RhtV {
  position: relative;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.style_popout__3Yv7R {
  z-index: 10;
  cursor: auto;
  left: calc(-1 * ((350px/2) - (24px/2)));
  position: absolute;
  top: calc(100% + 12px);
  width: 350px;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(21, 21, 22, 0.08);
  background-color: #ffffff;
}

.style_popout__3Yv7R:after {
  content: "";
  width: 100%;
  height: 20px;
  position: absolute;
  bottom: 0;
  background: linear-gradient(to top, rgb(255, 255, 255) 50%, rgba(255, 255, 255, 0) 100%);
  border-radius: inherit;
}
.style_popout__3Yv7R:before {
  content: ' ';
  width: 100%;
  height: 20px;
  position: absolute;
  top: 61px;
  left: 0px;
  background: linear-gradient(to bottom, rgb(255, 255, 255) 50%, rgba(255, 255, 255, 0) 100%);
  z-index: 10;
  border-radius: inherit;
}

.style_popoutHeader__31xXD {
  width: 92px;
  height: 20px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.25;
  color: #2c2e2f;
  margin: 20px auto 20px 26px;
}

.style_notificationList__1LCom {
  padding: 20px 20px 0px;
  overflow: auto;
  max-height: 350px;
  margin-bottom: 8px;
}

.style_wrapper__2amNf {
  height: 64px;
  border-radius: 8px;
  border: solid 1px #abd0e8;
  background-color: #ecf5fa;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  padding: 12px 16px;
  cursor: pointer;
}

.style_text__HF_uP {
  width: 220px;
  height: 40px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #57595d;
  flex: 1 0 auto;
}

.style_count__3yoge {
  font-weight: 500;
  color: #2c2e2f;
}

.style_teamName__ITMcI {
  font-weight: 500;
  color: #2d70cf;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
  display: inline-block;
}

.style_wrapper__3sPFn {
  height: 123px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.style_text__3lXAT {
  margin-top: 10px;
  width: 337px;
  height: 24px;
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  color: #57595d;
}

.style_wrapper__3-HLy {
  height: 123px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.style_wrapper__3m8Yn {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.style_header__1oVbf {
  background-color: #2c2e2f;
  min-width: 768px;
}

.style_subHeader__1aLNz {
  background-color: #2c2e2f;
  max-height: headerHeight;
  min-width: 768px;
  padding-bottom: 48px;
}

.style_subHeaderNoPadding__3r3iO {
  background-color: #2c2e2f;
  max-height: headerHeight;
  min-width: 768px;
  padding-bottom: 0px;
}

.style_gutters__1tH5g {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  padding-bottom: 48px;
  min-width: 768px;
}

.style_subHeaderGutters__dVM_E {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  min-width: 768px;
}

.style_content__2zlrK {
  max-width: 75%;
  width: 100%;
}

@media screen and (max-width: 75%) {
  .style_content__2zlrK {
    padding: 0 24px 0 24px;
  }
}

.style_wrapper__1obrA {
  width: 100%;
  height: 100%;
}

.style_selectionOptions__EKuej {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 24px;
}

.style_selectionOption__zgt6L {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 48%;
  padding: 29px 32px;
  border-radius: 8px;
  border: solid 2px #d1d4d6;
  cursor: pointer;
}

.style_selectionOption__zgt6L:first-of-type {
  margin-right: 24px;
}

.style_selectionOption__zgt6L[data-is-selected="true"] {
  border: solid 2px #1c1d1e;
}

.style_selectionOption__zgt6L[data-is-selected="true"] > .style_selectionOptionTitle__3XNvC {
  color: #2c2e2f;
}

.style_selectionOption__zgt6L[data-is-selected="true"] > .style_selectionOptionText__QVWIJ {
  color: #57595d;
}

.style_selectionOptionTitle__3XNvC {
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #7a7d81;
  margin-bottom: 12px;
}

.style_selectionOptionText__QVWIJ {
  font-family: Roboto;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-align: center;
  color: #8f9297;
  white-space: pre-line;
}
.style_wrapper__2mGkU {
  width: 700px;
  padding: 32px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 8px 16px 0 rgba(15, 15, 16, 0.16);
}

.style_cross__ra0WH {
  display: none;
}

.style_sectionTitle__COGeP {
  font-family: Roboto;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #2c2e2f;
}

.style_title__2apQv {
  font-family: Roboto;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #2c2e2f;
  margin-bottom: 12px;
}

.style_title__2apQv {
  font-family: Roboto;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #2c2e2f;
  margin-bottom: 12px;
}

.style_textContent__2NToq {
  display: flex;
  flex-direction: row;
  font-family: Roboto;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: #57595d;
}

.style_textContent__2NToq > a {
  font-weight: 500;
  color: #1c1d1e;
  margin-left: 4px;
}

.style_buttons__rlB51 {
  margin-top: 32px;
}
.style_breadCrumbWrapper__1w9wm {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
}

.style_sharedFolderIcon__1M11u {
  padding-right: 8px;
}

.style_crumb__vtOsI {
  display: flex;
  align-items: center;
}

.style_crumb__vtOsI[data-has-action="true"] {
  cursor: pointer;
}

.style_crumbText__Tk37P {
  margin-right: 8px;
  font-family: Roboto;
  font-size: 16px;
  line-height: 1.5;
  color: undefined;
}

.style_crumbText__Tk37P[data-is-last="true"] {
  color: #2c2e2f;
}

.style_crumbText__Tk37P[data-is-first="true"] {
  text-decoration: underline;
}

.style_crumbCaret__1hZdR {
  padding-right: 8px;
  height: 24px;
  display: flex;
  width: 24px;
  align-items: center;
}

.style_crumbDropdown__2PY2U {
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(21, 21, 22, 0.08);
  border: solid 1px undefined;
  background-color: white;
}

.style_crumbAndDropdownWrapper__2DBu_ {
  position: relative;
}
.style_defaultTeamToggle__21Iq0 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 24px auto 24px;
  border: 1px solid #e2e5e8;
  border-radius: 4px;
  padding: 16px;
}

.style_title__1B9rR {
  height: 24px;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.6;
  color: #2c2e2f;
}

.style_description__OJslv {
  font-size: 14px;
  line-height: 1.71;
  color: #7A7D81;
}

.teamSectionStyles_text__1q7eY {
  font-family: Roboto;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

.teamSectionStyles_title__1prq8 {
  font-size: 24px;
  line-height: 1.33;
  color: #2c2e2f;
}

.teamSectionStyles_breadCrumbList__2ojtv {
  margin-bottom: 32px;
}

.teamSectionStyles_wrapper__BqUI7 {
  width: 100%;
  height: 100%;
}

.teamSectionStyles_nameLabel__33ePT {
  font-size: 14px;
  line-height: 1.43;
  color: #8f9297;
  margin-top: 20px;
}

.teamSectionStyles_inputWrapper__UqEkL {
  width: 100%;
  height: 40px;
  padding: 10px 12px;
  border-radius: 4px;
  border: solid 1px #d1d4d6;
  margin-top: 8px;
}

.teamSectionStyles_input__1Vm98 {
  width: 100%;
  height: 20px;
  font-size: 15px;
  line-height: 1.33;
  color: #2c2e2f;
}

.teamSectionStyles_inputPlaceholder__3GYK9 {
  color: #8f9297;
}

.teamSectionStyles_noticeText__1qdKG {
  font-size: 15px;
  line-height: 1.33;
  color: #8f9297;
  margin-top: 12px;
}

.teamSectionStyles_noticeText__1qdKG > span {
  font-weight: 500;
  color: #2c2e2f;
}

.teamSectionStyles_divider__275Lw {
  width: 100%;
  height: 0px;
  border-bottom: 1px solid #d1d4d6;
  margin-top: 32px;
  margin-bottom: 32px;
}

.teamSectionStyles_teamToggle__1YtHq {
  border: none;
  flex-direction: row-reverse;
  justify-content: flex-end;
  padding: 0;
}

.teamSectionStyles_teamToggle__1YtHq > div:last-of-type {
  margin-right: 24px;
}

.teamSectionStyles_teamToggle__1YtHq div[class*="title"] {
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #57595d;
}

.teamSectionStyles_teamToggle__1YtHq div[class*="description"] {
  font-family: Roboto;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #7a7d81;
}

.teamSectionStyles_info__3c-AW {
  align-items: flex-start;
}

.teamSectionStyles_info__3c-AW > span > a {
  font-weight: 500;
  color: #2c2e2f;
}
.style_teamCreateButtonWrapper__3g157 {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.style_teamCreateTitle__13pUb {
  color: #3184fc;
  font-weight: 500;
  margin-left: 8px;
}

.style_teamCreateButtonWrapper__3g157[data-is-divider="true"] {
  border-right: 1px solid #d1d4d6;
  padding-right: 16px;
}
.style_addTeamMembersButtonWrapper__1W7Am {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.style_addTeamMembersButtonTitle__3UqnY {
  color: #3184fc;
  font-weight: 500;
  margin-left: 8px;
}
.style_teamSettingsModal__1ozG4 {
  width: 650px;
  padding: 32px;
}

/* heading styling */
.style_teamSettingsModal__1ozG4 > div:first-of-type {
  margin-bottom: 32px;
}

.style_teamSettingsModalWrapper__3WaQP {
  display: flex;
  height: 160px;
  width: 100%;
}

.style_teamLogoWrapper__136R- {
  height: 100%;
  /*  below could be updated to flex*/
  width: 224px;
  margin-right: 60px;
}

.style_teamDropdownsWrapper__Jfc3u {
  height: 100%;
  flex: 1 1;
}

.style_logoWrapper__1oKnB {
  display: flex;
  justify-content: space-between;
  height: 92px;
}

.style_sectionTitle__1Mglz {
  margin-bottom: 8px;
  color: #8f9297
}

.style_teamLogo__l6Nt3 {
  width: 92px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.style_noAvatarChars__3RZkI {
  font-size: 40px;
}

.style_logoButtons__3rijJ {
  width: 108px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.style_teamCountry__2KlZU {
  margin-top: 24px;
}
.style_countryFlagsDropdown__1wO2l {
  justify-content: space-between;
}

.style_countryOptions__2aREk {
  /* set height range to allow for dropdown to shrink with minimum search results */
  height: auto !important;
  min-height: 200px;
  max-height: 300px;
}

.style_countryNameFlagWrapper__1_qt0 {
  display: flex;
  align-items: center;
}

.style_name__2_93i {
  margin-left: 12px;
  color: #2c2e2f;
}
.style_label__CPcqO {
  color: #2c2e2f;
  font-size: 13px;
  line-height: 1.85;
  text-align: left;
  padding-bottom: 9px;
}


.style_dropdown__2TI9p {
  position: relative;
  display: inline-block;
  width: 100%;
}

.style_dropdown__2TI9p * {
  cursor: pointer;
}

.style_inputDiv__2YmsW {
  display: flex;
  align-items: center;
  font-size: 14px;
  border: 1px solid #D1D4D6;
  border-radius: 4px;
  line-height: 20px;
  height: 40px;
  padding: 10px 12px 10px 12px;
  cursor: pointer;
}

.style_inputDiv__2YmsW input {
  color: #2c2e2f;
  outline: none;
  border: none;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  pointer-events: none;
}

.style_inputDiv__2YmsW input[disabled] {
  background-color: transparent;
  color: #757575;
}

.style_inputDiv__2YmsW[data-disabled="true"] input[disabled]{
  cursor: not-allowed;
}
.style_inputDiv__2YmsW[data-error="true"]{
  border: 1px solid #E64826;
}

.style_inputDiv__2YmsW[data-disabled="true"] {
  background-color: #FBFBFC;
  color: #AAAAAA;
  cursor: not-allowed;
}

.style_option__mBrC5:hover {
  background-color: #F9F9FA;
}
.style_content__2XQUB {
  width: 100%;
  max-height: 200px;
  overflow: scroll;
  border: 1px solid #D1D4D6;
  border-radius: 4px;
  display: block;
  position: absolute;
  top: 39px;
  background-color: white;
  min-width: 160px;
  padding: 16px 16px 16px 16px;
  z-index: 1;
}

.style_doneButton__2BJzt {
  width: 100%;
}

.style_error__2Tb40 {
  position: absolute;
  color: #E64826;
  font-size: 12px;
  left: 5px;
  top: 100%;
}

.style_options__2Nb9u {
  background-color: #fff;
  border: 1px solid #D1D4D6;
  border-radius: 4px;
  display: none;
  height: 200px;
  left: 0;
  overflow-y: scroll;
  padding: 16px 0;
  position: absolute;
  top: 116%;
  width: 100%;
  z-index: 6;
}

.style_optionsOpened__1_8Dj {
  display: block;
}
.style_search__wpULd {
  margin: 0 24px;
}

.style_search__wpULd > div > input {
  padding-left: 8px;
}

.style_countryDivider__3BSPZ {
  margin: 8px 24px;
}
.style_option__omay2 {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 6px 24px;
}
.style_option__omay2:hover {
  background-color: #F9F9FA;
}

.style_selected__2pHIS {
  line-height: 1;
  margin-left: 16px;
}

.style_countryNameFlagWrapper__2PJyv {
  display: flex;
  align-items: center;
}

.style_name__3fqfZ {
  color: #2c2e2f;
  margin-left: 12px;
}
.style_teamSettingsButtonWrapper__3GlIE {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.style_teamSettingsTitle__1sJkF {
  color: #57595d;
  font-weight: 500;
  margin-left: 4px;
}

.style_teamSettingsButtonWrapper__3GlIE[data-is-divider="true"] {
  border-right: 1px solid #d1d4d6;
  padding-right: 16px;
}
.style_menu__2T5Yq {
  width: 300px;
}

.style_icon__2uNX1 {
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.style_actionsSection__3xZTi {
  padding: 24px 12px;

}
.style_actionName__28dof {
  margin-left: 12px;
  height: 24px;
  font-size: 16px;
  line-height: 1.5;
  color: #57595d;
}

.style_action__3wdY7 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  width: 100%;
  padding: 6px 8px;
  height: 36px;
  border-radius: 4px;
}

.style_action__3wdY7:hover {
  background-color: #f9f9fa;
}

.style_footerOpen__3zYnA {
  height: 70px;
  transition: height 0.3s ease, padding 0s ease-out 0.1s, opacity 0.3s ease-out 0.1s;
  border-top: 1px solid lightgray;
  overflow: visible;
  padding: 16px;
  opacity: 1;
}

.style_footerClosed__3M7s4 {
  height: 0px;
  transition: height 0.3s ease, border-top 0s ease-out 0.2s, padding 0s ease-in 0.1s, opacity 0.1s ease-in;
  border-top: 0px solid lightgray;
  overflow: hidden;
  padding: 0px;
  opacity: 0;
}

.style_menuFooterPopoutDropdown__4VdI6 {
  flex: 1 1 195px;
  width: 268px;
}

.style_menuFooterPopoutButton__3Wn6p {
  flex: 1 1 65px;
  height: 40px;
  margin-left: 12px;
  width: 100% !important;
}

.style_menuFooterWrapper__2ZEsf {
  display: flex;
}

.style_noBottomBorder__3coBI {
  border-bottom: none !important;
}
.style_nameSection__3PU5l {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  height: 64px;
  border-bottom: 1px solid #E2E5E8;
}

.style_nameSectionEditing__2hmzT {
  justify-content: flex-start;
}

.style_nameBox__1FLDX {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 36px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: #2c2e2f;
}

.style_nameBox__1FLDX:hover {
  background-color: #f9f9fa;
}

.style_nameBox__1FLDX[data-is-name-editable="false"]:hover {
  background-color: inherit;
}

.style_nameBox__1FLDX > div {
  overflow: hidden;
  text-overflow: ellipsis;
}

.style_nameInput__1zv6s {
  width: 230px;
  margin-left: 12px;
}

.style_icon__203sQ {
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.style_cancelIcon__oc9xJ {
  margin-left: 16px
}

.style_saveIcon__3k7pq {
  margin-left: 8px
}

.style_popoutItemMenu__1S0-a {
  width: 300px;
  border-radius: 8px;
}

.style_guardWrapper__UKUJD {
  cursor: default;
}

.style_loadingContent__Kfun3 {
  padding: 20px 0px;
}
.style_teamLogoButtonWrapper__1lzMK {
  border-radius: 50%;
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d1d4d6;
  border: 2px solid white;
  cursor: pointer;
}

.style_teamLogoButtonWrapper__1lzMK[data-has-logo="false"] {
  background-color: #3184fc;
}

.style_popout__2fzYX {
  left: 0px;
  top: 30px;
  width: auto !important;
}

.style_popoutMenu__3d2rb {
  width: auto !important;
  color: #2c2e2f;
}

.style_popoutMenu__3d2rb > div:first-of-type {
  width: -webkit-max-content;
  width: max-content;
  padding: 12px;
}

.style_popoutActions__12SEA > div > * {
  font-size: 14px !important;
  font-weight: 500;
  height: auto;
}
.style_topRowLoading__1t50t {
  flex: 1 0 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.style_topRowLoading__1t50t {
  flex: 1 0 100%;
  margin-top: 16px;
}

/* .topRowSubTitle {
  color: easilGrayDark;
} */

.style_item__1baT2 {
  color: #2c2e2f;
  display: inline-block;
  cursor: pointer;
}

.style_title__2BJiu {
  margin-right: auto;
  display: flex;
  align-items: center;
  width: calc(100% - 482px);
}

.style_topRowPrimary__1L7Sm {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.style_topRowPrimary__1L7Sm > *:first-child {
  margin-left: 0;
}

.style_topRowPrimary__1L7Sm > a {
  display: flex;
  margin-top: 4px; /* Adjustment for icon not filling bounding box height */
}

.style_noAvatarChars__38kuw {
  font-size: 40px;
}

.style_teamLogo__1a8mw {
  display: flex;
  align-items: flex-end;
}

.style_teamLogoButton__1CVKB {
  position: relative;
  left: -25px;
}

.style_teamName__2Si_e {
  font-size: 24px;
  color: #2c2e2f;
  margin-right: 24px;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.style_teamButtonsWrapper__1vOjg {
  display: flex;
  flex: 0 0 auto;
}

.style_teamButtonsWrapper__1vOjg > div {
  margin-left: 16px;
}
.style_col1Wrapper__3vTsk {
  width: 100%
}

.style_col1__2-eFQ {
  display: flex;
  flex: 1 0 100%;
  flex-wrap: wrap;
}

.style_row__3CwdC {
  flex: 1 0 100%;
}

.style_teamTitles__wi7pe {
  display: flex;
}

.style_tableTitle__2GwVL {
  margin-right: 24px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 4px;
}

.style_tableTitle__2GwVL:nth-of-type(1) {
  margin-left: 0px;
}

.style_tableSection__AgYhd {
  width: 100%;
  margin-bottom: 32px
}

.style_pendingEmpty__1A5Ph {
  width: 100%;
  text-align: center;
  margin-top: 48px;
}

.style_menuWrapper__3haKK {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.style_menu__3EmTI {
  display: flex;
  margin-left: auto;
  border-radius: 4px;
  padding-bottom: 8px;
  position: relative;
}

.style_menu__3EmTI > svg {
  cursor: pointer;
}

.style_menu__3EmTI:hover > svg rect {
  fill: #e2e5e8 !important;
}

.style_menu__3EmTI:hover > svg circle {
  fill: #57595d !important;
}

.style_teamTitleSearchRow__CYA5m {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 24px 0 32px;
}

.style_teamSearchWrapper__F2-Qj {
  width: 337px;
}

.style_searchTitleResults__1IxAS {
  font-size: 18px;
  line-height: 32px;
  font-family: Roboto;
  color: #1c1d1e;
  margin-bottom: 24px;
}

.style_searchTitleResults__1IxAS > span {
  font-weight: 500;
}

.style_teamMembersTitle__2LX9x {
  font-size: 20px;
  font-weight: 500;
  color: #2c2e2f;
}

.style_memberTable__3wEm7 > div:first-child {
  border-bottom: 2px solid #e5e8eb;
  padding-bottom: 32px;
}

.style_searchFilter__1r8F1 {
  display: flex;
}

.style_searchFilter__1r8F1:first-child {
  margin-right: 8px;
}
.style_pagination__ysCtf {
  margin-top: 48px;
  color: #7A7D81;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.style_pagination__ysCtf[data-detailed] {
  justify-content: space-between;
}

.style_pageSize__1jNN_ {
  display: flex;
  width: 32px;
  height: 32px;
  justify-content: center;
  padding: 4px;
  cursor: pointer;
  font-family: Roboto;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-align: center;
  color: #2c2e2f;
  margin: 4px 1px;
}

.style_pageSize__1jNN_[data-active="true"]{
  border: 1px solid #D1D4D6;
  border-radius: 6px;
  font-weight: 500;
  /* margin: 0px; */
  margin-top: 1px;
  color: #2c2e2f;
}

.style_views__1mjCP {
  flex: auto;
  align-items: center;
  display: flex;
}

.style_info__3YWbI {
  flex: auto;
}

.style_pages__iWPy- {
  align-items: center;
  display: flex
}

.style_navigation__3tbM1 {
  cursor: pointer;
  margin: 0 24px;
  font-family: Roboto;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #57595d;
}

.style_navigation__3tbM1[data-not-allowed="true"] {
  pointer-events:none;
}

/* easilGrayDark is used on the JSX */

.style_cell__3ODyj {
  width: 50%;
  display: flex;
  align-items: center;
}

.style_cell__3ODyj:nth-child(4n+2) {
  justify-content: flex-end;
}

@media screen and (max-width: 1030px) {
  .style_cell__3ODyj:nth-child(4n+2) {
    width: 60%;
    max-width: 60%;
  }
  .style_cell__3ODyj:nth-child(1) {
    width: 40%;
    max-width: 40%;
  }
}

@media screen and (max-width: 875px) {
  .style_cell__3ODyj:nth-child(4n+2) {
    width: 70%;
    max-width: 70%;
  }
  .style_cell__3ODyj:nth-child(1) {
    width: 30%;
  }
  .style_cell__3ODyj > div[aria-label="member email"] {
    display: none;
  }
}

.style_headerCell__2KEdb {
  display: flex;
  justify-content: flex-start;
  color: #8f9297;
  font-size: 14px;
  font-weight: 500;
  width: 64px;
}

.style_headerCell__2KEdb:nth-child(4n+2) {
  justify-content: flex-start;
}

.style_cellEdit__11-F5 {
  overflow: visible;
}
.style_row__1KvWZ {
  font-size: 14px;
  display: flex;
  align-items: center;
  flex: 1 0 100%;
  max-width: 100%;
}

.style_rowEditting__1O-0w {
  align-items: center;
  padding: 10px 0px 11px 0px;
}
.style_input__3JTIB {
  width: 95%;
}

.style_actionIcon__3kSd_ {
  cursor: pointer;
}

.style_leftIcon__67LBg {
  margin-right: 9px;
  display: flex;
  align-items: center;
}

.style_buttons__3J3CS {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
}

.style_cancelButton__1wIkN {
  margin-right: 12px
}

.style_roleIcon__GZQb_ {
  padding: 0 8px;
  font-size: 12px;
  font-weight: 500;
  color: #2c2e2f;
  background-color: #e5e8eb;
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  border-radius: 4px;
  width: -webkit-max-content;
  width: max-content;
}

.style_roleIcon__GZQb_:last-of-type {
  margin-right: 0px;
}

.style_roleIcon__GZQb_[data-is-active="false"] {
  opacity: 0.3;
}

.style_popout__2DPkC {
  right: 0px;
  top: 30px;
  width: auto !important;
}

.style_popoutMenu__1SeZF {
  width: auto !important;
}

.style_popoutMenu__1SeZF > div:nth-child(2) {
  padding: 12px;
  width: -webkit-max-content;
  width: max-content;
}

.style_popoutActions__3grqL > div > * {
  font-size: 14px !important;
  font-weight: 500;
  height: auto;
  color: #2c2e2f;
}

.style_popoutActions__3grqL > div {
  padding: 6px 10px;
}

.style_menu__3or2W {
  display: flex;
  margin-left: auto;
  border-radius: 4px;
  position: relative;
}

.style_menu__3or2W > svg {
  cursor: pointer;
}

.style_menu__3or2W:hover > svg rect {
  fill: #e2e5e8 !important;
}

.style_menu__3or2W:hover > svg circle {
  fill: #57595d !important;
}

.style_popoutNameSection__u6FoR {
  display: none;
}

.style_memberModal__OpYLL {
  padding: 32px;
  border-radius: 8px;
}

.style_menu__3or2W[data-is-ellipsis="true"] {
  padding-right: 4px;
}

.style_avatarCell__2kyYj {
  padding-right: 24px;
}

.style_memberDetails__1PloL {
  width: 100%;
  max-width: calc(100% - 64px);
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  justify-content: space-between;
  align-items: center; 
  padding: 20px 0;
  border-bottom: 1px solid #e5e8eb;
}

.style_nameCell__xrxbD {
  width: 50%;
  padding-right: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 15px;
  font-weight: 500;
  color: #2c2e2f;
}

@media screen and (max-width: 875px) {
  .style_nameCell__xrxbD {
    width: 100%;
  }
}

.style_emailCell__26TcW {
  width: 50%;
  color: #57595d;
  font-weight: normal;
  font-size: 14px;
}

.style_roleCell__1Raly {
  width: 330px;
  display: flex;
  margin-right: 32px;
  justify-content: flex-end;
}

.style_parentIndicator__3ZWFC {
  font-size: 13px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: normal;
  color: #57595d;
}
.roleFilterStyle_roleFilterWrapper__38qaW {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 307px;
  border-radius: 8px;
  border: 1px solid #d1d4d6;
  padding: 24px;
  background-color: white;
  z-index: 999;
  margin-top: 8px;
  left: -101px;
}

.roleFilterStyle_rolePicker__3qv3v {
  display: flex;
  flex-direction: column;
}

.roleFilterStyle_memberFilterRoles__6sft0 {
  width: 105px;
  margin-left: 8px;
}

.roleFilterStyle_roleFilterInput__205v3 {
  padding: 8px 16px 8px 24px;
  border-radius: 8px;
}

.roleFilterStyle_roleFilterInput__205v3 > input {
  font-family: 'Roboto';
  color: #2c2e2f !important;
  font-weight: 500;
  font-size: 16px;
}

.roleFilterStyle_titles__3pOdY {
  font-size: 12px;
  margin-bottom: 8px;
}

.roleFilterStyle_teamRole__30F7e {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 40px;
  cursor: pointer;
}

.roleFilterStyle_teamRole__30F7e > label {
  left: 12px;
}

.roleFilterStyle_roleIcon__2mbCT {
  padding: 0 8px;
  font-size: 12px;
  font-weight: 500;
  color: #2c2e2f;
  background-color: #e5e8eb;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  border-radius: 4px;
}

.roleFilterStyle_parentTeamToggleWrapper__281n7 {
  margin-top: 16px;
}

.roleFilterStyle_parentTeamToggle__2M3ze {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.roleFilterStyle_parentTeamToggle__2M3ze > div:first-child {
  font-size: 16px;
  font-weight: 500;
  color: #57595d;
}

.roleFilterStyle_buttons__1Cxl2 {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
}

.roleFilterStyle_buttons__1Cxl2 > button:first-of-type {
  margin-right: 8px;
}

.roleFilterStyle_buttons__1Cxl2 > button {
  width: 100%;
}

.roleFilterStyle_roleCheckbox__1bS28 {
  width: 20px;
  height: 20px;
  top: -9px;
  left: -5px;
}

.roleFilterStyle_roleCheckbox__1bS28:after {
  top: 8px !important;
  left: 6px !important;
  -webkit-transform: scale(1.3);
          transform: scale(1.3);
}

.roleFilterStyle_parentTeamToggle__2M3ze > svg {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}
.style_content__3vssT {
  margin: auto;
  min-width: 870px;
  max-width: 958px;
  display: flex;
}

.style_col1__2TZkr {
  width: 79%
}

.style_col2__3BTmH {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 21%
}

.style_content__3vssT .style_title__3E2KB {
  margin-bottom: 48px;
}

.style_teamName__3YUH_ {
  width: 309px;
  display: inline-block;
  margin-right: 12px;
}

.style_tableName__2JjJv {
  font-size: 22px;
  font-weight: 300;
  margin-bottom: 32px;
}

.style_tableSection__2dSU7 {
  margin: 64px 0px 48px 0px;
}

.style_header__3cf-4 {
  background: #f0eeff url(/static/media/background.633e43fd.png) center center no-repeat;
  background-size: cover;
  height: 350px;
  left: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  position: relative;
  right: 50%;
  width: 100vw;
  z-index: 1;
}

.style_testimonialsWrapper__2Buyb {
  color: #2c2e2f;
  margin-bottom: 64px;
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.style_testimonialsHeader__2ZStU {
  width: 100%;
  height: 109px;
  box-shadow: inset 0 1px 0 0 rgba(28, 29, 30, 0.04);
  background-color: #f5f5f8;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.style_testimonialsWrapper__2Buyb header {
  font-family: Roboto;
  font-size: 22px;
  font-weight: bold;
  text-align: center;
}

.style_testimonials__3-Qs5 {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  margin-top: 39px;
  width: 1024px;
}

.style_testimonial__2Fda0 {
  position: relative;
  width: 288px;
}

.style_quotationMark__3ujRQ {
  color: #D1D4D6;
  font-size: 64px;
  left: 0;
  line-height: 1;
  position: absolute;
  top: 0;
}

.style_testimonialContent__lFFbh {
  color: #2c2e2f;
  font-family: Roboto-Italic;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 1.6;
  letter-spacing: normal;
  white-space: break-spaces;
  height: 194px;
}

.style_testimonialCitation__3cSCD {
  margin-top: 32px;
  font-family: Roboto;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: #2c2e2f;
}

.style_testimonialCompanyName__1CokI {
  font-size: 11px;
  font-weight: 500;
  margin-top: 4px;
  font-family: Roboto;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: #2c2e2f;
}

.style_testimonialWebsite__4_pa9 {
  font-size: 11px;
  font-weight: 500;
  margin-top: 4px;
  font-family: Roboto;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: #2c2e2f;
}

.style_upgradeModalContainer__kAFi4 {
  width: 650px;
  border-radius: 8px;
  background-color: #ffffff;
  padding: 32px;
}

.style_upgradeModalWrapper__2HleF {
  color: #2c2e2f;
  margin-top: -16px;
  display: flex;
  flex-direction: column;
}

.style_cardLabelStyle__24-p5 {
  font-size: 14px;
  line-height: 1.43;
  color: #7a7d81;
}

.style_upgradeModalFormLabel__3z7vu {
  margin-bottom: 8px;
}



.style_upgradeModalPromoCode__15jt7 {
  padding-bottom: 16px;
}

.style_upgradeModalPromoCodeAlert__10s56 {
  font-size: 14px;
  margin: 24px 0 8px;
}

.style_upgradeModalPaymentDetails__3SvER h2 {
  font-size: 20px;
  font-weight: normal;
  margin-top: 0px;
}

.style_upgradeModalFormInline__2afEh {
  display: flex;
}

.style_upgradeModalFormInline__2afEh + .style_upgradeModalFormInline__2afEh {
  margin-top: 16px;
}

.style_upgradeModalFormInline__2afEh > * + * {
  margin-left: 16px;
}

.style_upgradeModalFormInlineGroup__RIOvr {
  width: 100%;
}

.style_upgradeModalFooter__3E0PG {
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
}

.style_upgradeModalCancelButton__3vTX7 {
  margin-right: 16px;
}

.style_infoAlert__RNbiC {
  align-items: flex-start !important;
}

.style_upgradeModalShowPaymentFormLink__1EgLv {
  cursor: pointer;
  height: 24px;
  font-family: Roboto;
  font-weight: 500;
  line-height: 1.71;
  color: #2c6fcf;
}

.style_errors__-rMNA {
  margin-top: 16px;
}

.style_error__3KaZa {
  color: #E64826;
}

.style_error__3KaZa + .style_error__3KaZa {
  margin-top: 4px;
}


.style_subscriptionDescriptionSection__36jvt {
  width: 75%;
  margin: auto
}

.style_upgradeDetails__3fdPK {
  width: 586px;
  height: 84px;
  border-radius: 8px;
  border: solid 1px #e2e5e8;
  background-color: #fcfcfd;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 24px;
}

.style_upgradeDetail__1usaZ {
  height: 52px;
  padding-right: 20px;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  border-right: 1px solid #dddddd;
}

.style_upgradeDetail__1usaZ:last-of-type {
  border-right: none;
}

.style_upgradeDetailTitle__1XAc8 {
  font-size: 14px;
  line-height: 1.43;
  display: flex;
  align-items: center;
  color: #57595d;
}

.style_upgradeDetailTitle__1XAc8 > div > svg {
  margin-left: 6px;
}

.style_upgradeDetailValue__FZ4as {
  font-size: 20px;
  line-height: 1.4;
  color: #2c2e2f;
}

.style_planTile__wFqce {
  min-width: 341px;
  display: inline-block;
  border-right: 1px solid #e6e9eb;
  flex: 0.5 1;
}

.style_trialEndDateTile__2MWYX {
  padding-left: 20px;
  width: 341px;
  display: inline-block;
  border-right: 1px solid #e6e9eb;
}

.style_remainingDaysTile__2WJpZ {
  padding-left: 20px;
  width: 255px;
  display: inline-block;
}

.style_remainingDaysTile__2WJpZ > div:last-child{
  color: #eb6063 !important;
}

.style_wrapper__avMRQ {
  width: 100%;
  background-color: white;
  padding: 40px 0px;
}

.style_header__2-81B {
  height: 28px;
  font-size: 20px;
  line-height: 1.4;
  color: #2c2e2f;
  margin-bottom: 12px;
}

.style_tilesSection__1VPRT {
  height: 52px;
  width: 100%;
  display: flex
}

.style_tileTitle__1BqmW {
  height: 20px;
  font-size: 14px;
  line-height: 1.43;
  color: #57595d;
  display: inline-block;
}

.style_tileTooltip__3An5h {
  display: inline-block;
  margin-left: 6px;
}

/* second last because the last div is the tooltip */
.style_tilesSection__1VPRT > div:nth-last-child(2) {
  border-right: none !important;
}

.style_tileBody__3uLHE {
  margin-top: 4px;
  height: 28px;
  font-size: 22px;
  line-height: 1.27;
  color: #2c2e2f;
}

.style_updateCard__2DgGe {
  display: inline-block;
  margin-top: 24px;
  width: 146px;
  height: 24px;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.6;
  color: #2c6fcf;
  cursor: pointer;
}

.style_cancelPlan__XPLy5 {
  display: inline-block;
  cursor: pointer;
  padding-left: 16px;
  height: 24px;
  font-size: 15px;
  line-height: 1.6;
  color: #8f9294;
  border-left: 1px solid #e6e9eb;
}

.style_info__qN5wd {
  margin-top: 24px;
}

.style_button__3610C {
  height: 40px;
}

.style_cancelModalWrapper__1fz69 {
    width: 550px;
    min-height: 329px;
    max-height: 433px;
    padding-top: 33px;
    border-radius: 8px;
}

.style_cancelModalWrapper__1fz69 > div:first-of-type {
    margin-bottom: 24px;
}
.style_textArea__1qkzk {
    margin-top: 16px;
}
.style_questionFeedback__tLguI {
    font-size: 15px;
    color: #2c2e2f;
    font-family: Roboto;
    margin-top: 24px;
    margin-bottom: 8px;
}
.style_dropdownSubscriptionCancellation__1hOpj {
    margin-top: 8px;
    color: #2c2e2f;
    font-family: Roboto;
    border-radius: 8px;
}
.style_dropdownSubscriptionCancellation__1hOpj > div {
    padding: 12px;
    border-radius: 4px;
}
.style_dropdownSubscriptionCancellation__1hOpj > div > div  {
    height: 36px;
    padding: 6px 12px;
    border-radius: 4px;
}
.style_dropdownSubscriptionCancellation__1hOpj > div > input[disabled] {
    color: #2c2e2f;
}
.style_dropdownSubscriptionCancellation__1hOpj > div > input::-webkit-input-placeholder {
    color: #2c2e2f;
}
.style_dropdownSubscriptionCancellation__1hOpj > div > input:-ms-input-placeholder {
    color: #2c2e2f;
}
.style_dropdownSubscriptionCancellation__1hOpj > div > input::placeholder {
    color: #2c2e2f;
}
.style_planTile__2pXEz {
  min-width: 341px;
  display: inline-block;
  flex: 0.5 1;
}

.style_planTile__1yNHv {
  min-width: 167px;
  display: inline-block;
  border-right: 1px solid #e6e9eb;
  flex: 0.5 1;
}

.style_membersCountTile__ayRW4 {
  padding-left: 20px;
  min-width: 183px;
  display: inline-block;
  border-right: 1px solid #e6e9eb;
  flex: 0.5 1;
}

.style_pricePerMemberTile__2vleS {
  padding-left: 20px;
  min-width: 255px;
  display: inline-block;
  border-right: 1px solid #e6e9eb;
  flex: 1 1;
}

.style_totalChargeTile__34EBh {
  padding-left: 20px;
  min-width: 204px;
  display: inline-block;
  border-right: 1px solid #e6e9eb;
  flex: 1 1;
}

.style_renewDateTile__1Agfi {
  padding-left: 20px;
  min-width: 204px;
  display: inline-block;
  flex: 1 1;
}

.style_wrapper__3Njfi {
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.style_wrapperTop__VAATs {
  height: 362px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #f5f5f8;
  box-shadow: inset 0 1px 0 0 rgba(28, 29, 30, 0.04);
  padding-top: 40px;
  margin-bottom: -230px;
}

.style_wrapperBottom__IzxZS {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.style_wrapperBottomCards__9KWwz {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.style_planDescriptionGrid__NUTwQ {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  margin-top: 60px;
}

.style_planGridHeaders__1Kvyy {
  width: 1024px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #ffffff;
  padding-bottom: 13px;
  border-bottom: 1px solid #d1d4d6;
}

.style_planGridHeader__3-PUI {
  font-family: Roboto;
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2c2e2f;
}

.style_planGridHeader__3-PUI:first-of-type {
  width: 405px;
  font-size: 24px;
}

.style_planGridHeader__3-PUI:not(:first-of-type) {
  width: 100%;
  text-align: center;
  flex: 1 1;
}

.style_planSectionHeader__1_nOz {
  font-size: 22px;
  text-align: left !important;
  width: 1024px !important;
  margin-top: 32px;
  padding-bottom: 13px;
  border-bottom: 1px solid #d1d4d6;
}

.style_planSectionEmptyHeader__1LIC3 {
  height: 32px;
  border-bottom: 1px solid #d1d4d6;
  width: 1024px
}

.style_planFeatureRow__e_Hau {
  width: 1024px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #ffffff;
  padding: 12px 0px;
  border-bottom: 1px solid #d1d4d6;
}

.style_planFeatureRowLeft__1vbn2 {
  font-family: Roboto;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  color: #2c2e2f;
  min-width: 405px;
  display: flex;
  align-items: flex-end;
}

.style_planFeatureRowRight__IpxvA {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.style_planFeatureDetailsLabel__B7_-s {
  width: 100%;
  flex: 1 1;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  color: #2c2e2f
}

.style_comparisonTopText__33VQW {
  white-space: pre;
  font-size: 24px;
  line-height: 1.33;
  text-align: center;
  color: #2c2e2f;
  margin-bottom: 24px;
}

.style_planCardWrapper__2-Zed {
  display: flex;
  width: 320px;
  border: solid 1px #e2e5e8;
  background-color: #ffffff;
  margin: 0px 16px 4.35px;
  border-radius: 5.5px;
  flex-direction: column;
  align-items: center;
}

.style_planCardHeader__365Pn {
  width: 322px;
  border-radius: 8px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 32px 24px;
  flex-direction: column;
  color: #2c2e2f;
  height: 100%;
}

.style_planTitleBox__ggOhP {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  min-height: 55px;
}

.style_planTitleBox__ggOhP > svg + svg {
  margin-top: -9px;
}

.style_planTitle__DYrXZ {
  font-size: 24px;
  line-height: 1.25;
  margin-bottom: 19px;
}

.style_largePricePoint__jowXK {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  margin-top: 24px;
}

.style_largePricePointTop__2L8AK {
  display: flex;
  flex-direction: row;
  min-height: 44px;
  max-height: 44px;
  width: 100%;
  justify-content: flex-start;
}

.style_largePricePointCurrencySymbol__1L3r5 {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  font-size: 40px;
  margin-left: 4px;
  font-family: Roboto;
  font-weight: bold;
}

.style_largePricePointValue__Zmt-c {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  font-family: Roboto;
  font-size: 40px;
  font-weight: bold;
}

.style_largePricePointCurrency__1ekYO {
  font-size: 24px;
  line-height: 3;
  display: flex;
  flex-direction: column;
  height: 100%;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

.style_billingInfo__2LnvX {
  display: flex;
  flex-direction: column;
  white-space: break-spaces;
  margin-top: 7px;
  color: #2c2e2f;
  font-family: Roboto;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2c2e2f;
  line-height: 1.54;
}

.style_savingText__1pLs7 {
  font-family: Roboto;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  font-weight: 500;
  color: #1dcda3;
  display: contents;
}

.style_planDescription___TpMb {
  height: 72px;
  min-height: 72px;
  width: 100%;
  margin-top: 9px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #484a4c;
  white-space: break-spaces;
}

.style_planCardFeature__1iOA6 {
  height: 36px;
  display: flex;
  align-items: center;
  font-size: 15px;
  line-height: 1.6;
  color: #57595d;
  opacity: 1;
}

.style_planCardFeature__1iOA6[data-is-available="false"] {
  opacity: 0.48;
}

.style_planCardFeature__1iOA6[data-is-available="false"] > svg {
  visibility: hidden;
}

.style_planCardFeature__1iOA6 > svg {
  margin-right: 12px;
}

.style_testimonials__1b1Uj {
  width: 100%;
}

.style_upgradeButtonWrapper__1qyao {
  width: 100%;
  margin-top: 21px;
  min-height: 44px;
  height: 100%;
  display: flex;
  align-items: flex-end;
}

.style_upgradeButton__3M2rY {
  display: flex;
  justify-content: center !important;
  align-items: center;
  margin-bottom: 0px !important;
}

.style_planCardFeatureList__dIroX {
  margin: 24px 0px;
}

.style_infoToolTip__1IjrP {
  flex-direction: column;
}
.style_wrapper__96ova {
  margin-top: 40px;
}

.style_pageHeader__2_yq6 {
  color: #2c2e2f;
  font-size: 24px;
  font-weight: normal;
}

.style_sectionHeading__2-dzE {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
  min-height: 36px;
}

.style_sectionHeading__2-dzE h2 {
  color: #2c2e2f;
  font-size: 18px;
  font-weight: normal;
  margin: 0;
}

.style_tablist__D_CQZ {
  margin: 32px 0 64px;
}

.style_editableInput__2S5QD {
  justify-content: space-between;
  width: 100%;
}

.style_formGroup__1y-sl + .style_formGroup__1y-sl {
  margin-top: 24px;
}

.style_formGroupInline__1WZmO + .style_formGroup__1y-sl {
  margin-top: 24px;
}

.style_formGroupInline__1WZmO {
  display: flex;
  margin-top: 24px;
}

.style_formGroupInline__1WZmO .style_formGroup__1y-sl {
  flex: 1 1 auto;
}

.style_formGroupInline__1WZmO .style_formGroup__1y-sl + .style_formGroup__1y-sl {
  margin-top: 0;
  margin-left: 16px;
}

.style_formGroupLabel__1Boyt {
  color: #2c2e2f;
  margin-bottom: 23px;
}

.style_fieldError__3ebq7 {
  color: #E64826;
  font-size: 12px;
  margin-top: 2px;
}

.style_addImageButton__3tbMq {
  margin-right: 20px;
}

.style_addImageButton__3tbMq > label:last-of-type > div > img {
  height: 100% !important;
}

.style_teamLogoUpload__3qHtR {
  display: flex;
  align-items: center;
}

.style_removeLogoLink__1up5W {
  color: #2c2e2f;
  cursor: pointer;
}

.style_teamTab__12eLx {
  display: flex;
  margin-bottom: 32px;
}

.style_teamTabCol__RA55a {
  flex: 1 1 50%;
}

.style_teamTabCol__RA55a:first-child {
  margin-right: 120px;
}

.style_billingEditButtonIcon__1QDa6 {
  margin-right: 8px;
}

.style_billingInvoiceEmailCallout__2yQpW {
  background-color: #F9F9FA;
  border-radius: 4px;
  color: #7A7D81;
  display: flex;
  padding: 24px;
}

.style_billingInvoiceEmailCalloutContent__4baZb {
  line-height: 1.71;
  margin-left: 16px;
}

.style_billingInvoiceEmailCalloutContent__4baZb span {
  color: #57595D;
}

.style_billingInvoiceEmailInfo__-2uPg {
  color: #57595D;
  margin-top: 31px;
}

.style_billingInvoiceEmailInfo__-2uPg span {
  color: #2c2e2f;
  cursor: pointer;
}

.style_wrapper__3YLCv {
  margin-top: 40px;
}

.style_pageHeader__3jft7 {
  color: #2c2e2f;
  font-weight: normal;
  font-family: Roboto;
  font-size: 24px;
  line-height: 1.17;
}

.style_sectionHeading__18Ffv {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  min-height: 36px;
}

.style_sectionHeading__18Ffv h2 {
  color: #2c2e2f;
  margin: 0;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.44;
}

.style_tablist__27AhR {
  margin: 32px 0 64px;
}

.style_editableInput__3SQb1 {
  justify-content: space-between;
  width: 100%;
}

.style_formGroup__5s6TM {
  padding-bottom: 24px;
}

.style_formGroup__5s6TM + .style_formGroup__5s6TM {
  padding-top: 24px;
  border-top: 1px solid #e2e5e8;
}

.style_formGroupInline__ZBVrI + .style_formGroup__5s6TM {
  margin-top: 24px;
}

.style_formGroupInline__ZBVrI {
  display: flex;
  margin-top: 24px;
}

.style_formGroupInline__ZBVrI .style_formGroup__5s6TM {
  flex: 1 1 auto;
}

.style_formGroupInline__ZBVrI .style_formGroup__5s6TM + .style_formGroup__5s6TM {
  margin-top: 0;
  margin-left: 16px;
}

.style_formGroupLabel__btdur {
  margin-bottom: 8px;
  font-family: Roboto;
  font-size: 15px;
  line-height: 1.6;
  color: #7a7d81;
}

.style_formGroupDefaultTeamSubText__1pbAC {
  margin-top: 12px;
}

.style_formGroupDefaultTeamHeader__3hrAE {
  margin-bottom: 24px;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  color: #2c2e2f;
}

.style_fieldError__2Zw-i {
  color: #E64826;
  font-size: 12px;
  margin-top: 2px;
}

.style_addImageButton__1fmnM {
  margin-right: 20px;
}

.style_addImageButton__1fmnM > label:last-of-type > div > div > img {
  height: 100% !important;
  width: 100% !important;
}

.style_LogoUpload__vdsaN {
  display: flex;
  align-items: center;
}

.style_removeLogoLink__1eFT4 {
  color: #2c2e2f;
  cursor: pointer;
}

.style_Tab__2MIN4 {
  display: flex;
}

.style_TabCol___Bgz7 {
  flex: 1 1 50%;
}

.style_TabCol___Bgz7:first-child {
  margin-right: 120px;
}

.style_billingEditButton__Shm0L {
  background-color: white;
  border: 1px solid #D1D4D6;
  color: #57595D;
}

.style_billingEditButtonIcon__2wqxJ {
  margin-right: 8px;
}

.style_billingInvoiceEmailCallout__3TdtJ {
  background-color: #F9F9FA;
  border-radius: 4px;
  color: #7A7D81;
  display: flex;
  padding: 24px;
}

.style_billingInvoiceEmailCalloutContent__12Qgj {
  line-height: 1.71;
  margin-left: 16px;
}

.style_billingInvoiceEmailCalloutContent__12Qgj span {
  color: #57595D;
}

.style_billingInvoiceEmailInfo__2ERFT {
  color: #57595D;
  margin-top: 31px;
}

.style_billingInvoiceEmailInfo__2ERFT span {
  color: #2c2e2f;
  cursor: pointer;
}

.style_changeLogoText__2xRMD {
  cursor: pointer;
}

.style_teamChooser__3Rt-s {
  width: 100% !important;
}

.style_teamChooser__3Rt-s > div {
  width: 100% !important;
}

.style_changePasswordButton__rOPEC {
  margin-top: 16px;
}

.style_removePictureButton__2oKUi {
  width: 160px;
  height: 24px;
  font-family: Roboto;
  font-size: 16px;
  line-height: 1.5;
  color: #2c2e2f;
}

.style_removePictureButton__2oKUi > span {
  font-weight: 500;
  border-bottom: 1px solid rgba(44,46,47, 0.24);
}

.style_defaultTeamContentWrapper__lPhsR {
  display: flex;
  align-items: center;
}

.style_teamUpdateStatus__3WSd4 {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ;
  margin-left: 20px;
}
.style_modal__3CZr1 {
  width: 500px;
  height: 100vh;
  max-height: 452px;
  border-radius: 8px;
  background-color: #ffffff;
  padding: 32px;
}

.style_inputTitle__2pXl3 {
  font-family: Roboto;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: #7a7d81;
  margin-bottom: 8px;
}

.style_input__46w7L {
  width: 436px;
  height: 40px;
  border-radius: 4px;
  border: solid 1px #d1d4d6;
  background-color: #ffffff;
}

.style_inputContainer__2ACbY {
  margin-bottom: 16px;
}

.style_inputContainer__2ACbY:last-of-type {
  margin-bottom: 32px;
}

.style_buttons__31Gh3 {
  margin-top: 0px;
}

.style_buttons__31Gh3 > button {
  height: 40px;
}

.style_error__2QswI {
  color: #E64826;
  margin-bottom: 5px;
  margin-top: -5px;
}

.style_modal2Field__3saa9 {
  width: 500px;
  height: 100vh;
  max-height: 352px;
  border-radius: 8px;
  background-color: #ffffff;
  padding: 32px;
  transition: max-height 1s ease
}

.style_modal3Field__3muu1 {
  max-height: 452px;
}

.style_inputTitle__2ylVL {
  font-family: Roboto;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: #7a7d81;
  margin-bottom: 8px;
}

.style_input__114nq {
  width: 436px;
  height: 40px;
  border-radius: 4px;
  border: solid 1px #d1d4d6;
  background-color: #ffffff;
}

.style_inputContainer__3bVUp {
  overflow: hidden;
  padding-bottom: 16px;
  max-height: 100px;
  transition: max-height 1s ease;
}

.style_inputContainer__3bVUp:last-of-type {
  height: 120px;
  padding-bottom: 0px;
  margin-bottom: 16px;
}

.style_inputContainer__3bVUp[data-is-password-required="false"] {
  max-height: 0px;
}

.style_buttons__3WlZq {
  margin-top: 0px;
}

.style_buttons__3WlZq > button {
  height: 40px;
}

.style_error__1bhOu {
  color: #E64826;
  margin-top: -2px;
  position: absolute;
}

.style_wrapper__15NcT {
  position: relative;
  marginTop: 10px;
  width: 300px;
}

.style_dropDownTop__1-Kmu {
  height: 40px;
  width: 300px;
  border: 1px solid silver;
  padding: 12px ;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.style_currentDefaultTeam__2FHdM {
  color: #2c2e2f;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.style_dropdownPopout__2tifl {
  background-color: #FFFFFF;
  position: absolute;
  margin: 8px auto 16px;
  width: 300px;
  border-radius: 4px;
  border: 1px solid #D1D4D6;
  box-shadow: 0px 2px 3px 0px rgba(28, 29, 30, 0.08);
  padding: 16px 0px;
  max-height: 370px;
  overflow: scroll;
  /* make the popout come through the top */
  bottom: 30px;
}

.style_dropdownPopoutTitle__mEJVL {
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0;
  color: #2c2e2f;
  margin-bottom: 16px;
  padding: 0px 16px;
}

.style_currentDefaultTeam__1QmkY {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #57595D;
  padding: 0px 16px;
  cursor: pointer;
  height: 48px;  color: #2c2e2f;
  background-color: #fafafb;
  cursor: unset;
}

.style_currentDefaultTeam__1QmkY > span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.style_searchWrapper__3n3vL {
  padding: 0px 16px;
  margin-bottom: 16px;
}

.style_returnLink__dETlt {
  align-items: center;
  display: flex;
  font-weight: 500;
  margin-bottom: 8px;
  padding: 0 16px;
}

.style_returnLinkIcon__3Ikq7 {
  margin-right: 20px;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.style_returnLink__dETlt {
  cursor: pointer;
}
.style_dropdownItem__2h8m4 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #57595D;
  padding: 0px 16px;
  cursor: pointer;
}

/* previous element was a dropdownItem */
.style_dropdownItem__2h8m4 + .style_dropdownItem__2h8m4 > .style_optionContents__3WUOS {
  border-top: 1px solid #e6e8ea;
}

.style_dropdownItem__2h8m4 > span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.style_dropdownItem__2h8m4[data-is-current-default="true"] {
  height: 48px;  color: #2c2e2f;
  background-color: #fafafb;
  cursor: unset;
}


.style_dropdownItem__2h8m4:hover {
  background-color: #fafafb
}

.style_optionContents__3WUOS {
  padding: 16px 0px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.style_optionLeft__2_svk {
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.style_optionLeft__2_svk > p {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.71;
  color: #1c1d1e;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 300px;
  overflow: hidden;
  margin: 0;
}

.style_optionRight__ZnOTo {
  height: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
}

.style_optionRight__ZnOTo > p {
  width: 125px;
  height: 24px;
  font-family: Roboto;
  font-size: 14px;
  line-height: 1.71;
  text-align: right;
  color: #73767a;
  margin: 0px 10px;
}

.style_meta__3DE-A {
  align-items: center;
  display: flex;
  font-weight: normal;
}

.style_meta__3DE-A small {
  color: easilGrayDark;
  font-size: 13px;
  margin-right: 8px;
}

.style_meta__3DE-A strong {
  margin-right: 4px;
}

.style_meta__3DE-A > * {
  cursor: pointer;
}
.style_wrapper__21Y0L {
  margin: 40px 0;
}

.style_ordersList__CLuo8 {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
}

.style_ordersListRow__2T6Ad {
  display: flex;
  flex-direction: row;
  border-top: 1px solid #e2e5e8;
  padding-top: 16px;
  padding-bottom: 16px;
}

.style_ordersListRow__2T6Ad:first-of-type {
  border-top: none;
}

.style_ordersListRow__2T6Ad:first-of-type > div {
  font-size: 14px;
  color: #8e9294;
}

.style_ordersListRow__2T6Ad:nth-of-type(2) {
  padding-top: 24px;
}

.style_ordersListRow__2T6Ad > div {
  font-family: Roboto-Regular;
  font-size: 15px;
  color: #56595e;
}

.style_ordersListRow__2T6Ad > div[data-column-key="id"] {
  color: #2c2e2f;
  font-family: Roboto-Medium;
}

.style_ordersListRow__2T6Ad > div[data-column-key="viewDetails"] {
  color: #2c6fcf;
  font-family: Roboto-Medium;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.style_ordersListRow__2T6Ad > div[data-column-key="viewDetails"] > div {
  cursor: pointer;
}

.style_ordersListRow__2T6Ad > div[data-column-key="viewDetails"] > div > svg {
  margin-left: 8px;
}


.style_circlecheck__1tWls {
  width: 38px;
  height: 38px;
  border: 2px solid red;
}

.style_heading__15Cma {
  margin: 19px 0 16px;
}

.style_successMessage__2oZ2F {
  color: #54595e;
  font-size: 15px;
  line-height: 1.6;
  margin-bottom: 24px;
}

.style_supportEmail__3O1fX {
  text-decoration: underline;
  color: #2c2e2f;
}

.style_orderStatusBox__15ne9 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #FCFCFD;
  border: 1px solid #E2E5E8;
  border-radius: 8px;
  width: 300px;
  height: 74px;
}

.style_orderStatusBox__15ne9 > div {
  color: #2c2e2f;
  font-size: 15px;
  line-height: 1.33;
  margin-left: 16px;
}

.style_orderStatusBox__15ne9 > div > strong {
  font-weight: 500;
}

.style_orderHeading__2ByEg {
  width: 100%;
  margin: 40px 0px 0px;
  border-bottom: 1px solid #D1D4D6;
  padding-bottom: 12px;
}

.style_priceSummarySection__1Tvf2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.style_priceSummarySection__1Tvf2 > div {
  border-top: none;
  width: 512px;
}

.style_workspaceButton__1OsVL {
  height: 48px;
  width: 213px;
  padding: 14px 24px;
  font-size: 15px;
  float: right;
}

.style_breadCrumb__3C2m3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 24px;
}

.style_breadCrumb__3C2m3 > div {
  font-size: 16px;
  color: #2a2e2f;
}

.style_breadCrumb__3C2m3 > div:first-of-type {
  margin-right: 15px;
  color: #797d81;
  border-bottom: 1px solid rgba(122, 125, 129, 0.16);
  cursor: pointer;
}

.style_breadCrumb__3C2m3 > svg {
  margin-right: 15px;
}

.style_wrapper__3UXuR {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}

.style_title__3pQm3 {
  margin-bottom: 32px;
}

.style_billingAndPaymentSection__j25EQ {
  display: flex;
  flex-direction: row;
}

.style_billingAndPaymentSection__j25EQ > div {
  width: 100%;
}

.style_billingRow__1dNdS {
  display: flex;
  flex-direction: row;
}

.style_billingRow__1dNdS:first-of-type {
  margin-top: 24px;
}

.style_billingRow__1dNdS:not(:last-of-type) {
  margin-bottom: 4px;
}

.style_billingRowLeft__eXkzR {
  width: 131px;
  font-size: 14px;
  font-weight: 500;
  color: #57595d;
}

.style_billingRowRight__1wSc2 {
  font-size: 14px;
  color: #57595d;
}

.style_orderDesigns__2xBfI:first-of-type {
  border-top: 1px solid #D1D4D6;
}

.style_orderDesigns__2xBfI > div:last-of-type {
  margin-top: 12px !important;
}

.style_orderDesigns__2xBfI:last-of-type > div:last-of-type {
  border-bottom: 2px solid #D1D4D6;
}
.style_orderDesign__3YUdr {
  display: flex;
  flex-direction: column;
  padding-top: 24px;
}

.style_divider__1wsnQ {
  background-color: #D1D4D6;
  border-radius: 1px;
  height: 1px;
  margin-top: 24px;
}

.style_orderDesignItem__1Rtp- {
  display: flex;
}

.style_productDescription__GNPQO {
  flex: 2.5 1;
  flex-wrap: wrap;
}

.style_designDescription__320xt {
  margin-top: 4px;
  line-height: 20px;
}

.style_productPrice__1RFDP {
  color: #2c2e2f;
  flex: 1 1;
  text-align: end;
}

.style_designName__3S5Y2, .style_productPrice__1RFDP {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.33;
}
.style_printItem__2nmHJ {
  display: flex;
  padding: 16px 0 20px;
}

.style_printItem__2nmHJ ~ .style_printItem__2nmHJ {
  border-top: 1px solid #e2e5e8;
}

.style_col1__3N823 {
  flex: 1 1;
  margin-right: 16px;
}

.style_col2__1XFJU {
  flex: 3 1;
  flex-wrap: wrap;
}

.style_col1__3N823, .style_col2__1XFJU {
  font-size: 13px;
  display: flex;
  flex-direction: column;
}

.style_subtotal__20i3y {
  color: #2c2e2f;
  font-weight: 500;
}
.style_priceSummary__1_CnB {
  border-top: 1px solid #d1d4d6;
  padding-top: 12px;
}

.style_subtotal__3W6lx, .style_grandTotal__6zzu1 {
  display: flex;
  align-items: center;
}

.style_subtotal__3W6lx {
  padding-bottom: 12px;
}

.style_grandTotal__6zzu1 {
  border-top: 1px solid #d1d4d6;
  padding-top: 16px;
}

.style_col1__1k6tp {
  flex: 2 1;
}

.style_col1__1k6tp div:not(:first-child) {
  padding-top: 8px;
}

.style_col2__1KsAm {
  flex: 3 1;
  text-align: end;
}

.style_col2__1KsAm div:not(:first-child) {
  padding-top: 8px;
}

.style_col1__1k6tp, .style_col2__1KsAm {
  display: flex;
  flex-direction: column;
  color: #2c2e2f;
}

.style_shippingCost__JBfL_ {
  color: #7a7d81;
}

.style_total__2pLJz {
  font-size: 20px;
  font-weight: 500;
}

.style_couponCode__lKotG {
  color: #11b992;
  font-size: 15px;
  font-weight: 500;
}

.style_billingSectionWrapper__1Nbe5 {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 8px 0px;
}

.style_billingSubSection__1i3hw {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.style_billingSubSection__1i3hw:last-of-type {
  margin-bottom: 40px;
}

.style_billingTitle__1FJzo {
  margin-top: 48px;
}

.style_shippingTitle__bpshQ {
  margin-top: 40px;
}

.style_checkbox__1yn0V {
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
}

.style_checkbox__1yn0V > span {
  height: 20px;
  width: 20px;
  border-radius: 4px;
  border: solid 1px #d1d4d6;
}

.style_checkbox__1yn0V > span::after {
  background-position-x: center;
  background-position-y: center;
  background-size: contain;
  height: 9px !important;
  width: 11px !important;
}

.style_nextButtonWrapper__-VApm {
  display: flex;
  justify-content: flex-end;
  width: 680px;
  margin-top: 24px;
}

.style_loading__Km0id, .style_empty__xElz- {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
  width: 680px;
}

.style_info__3LWj2 {
  align-items: flex-start !important;
  margin-top: 24px;
}

.style_info__3LWj2 > div {
  font-size: 14px;
  line-height: 1.57 !important;
  color: #2c2e2f;
}
:root {
  --row-spacing-x: 16px;
  --row-spacing-y: 16px;
}

.style_title__6Jheh {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2;
  color: #2c2e2f;
  margin-bottom: 24px;
  margin-top: 48px;
}

.style_inputRow__3cL3s {
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow: visible;
  transition: height 0.5s ease, margin 0.3s ease, opacity 0.3s ease 0.5s;
}

.style_inputRow__3cL3s:not(:last-of-type) {
  margin-bottom: 16px;
  margin-bottom: var(--row-spacing-y, 16px);
}

.style_input__1KIg5 {
  height: 44px;
  background-color: #ffffff;
}

/* had an input before it */
.style_input__1KIg5 + .style_input__1KIg5 {
  margin-left: 16px;
  margin-left: var(--row-spacing-x, 16px);
}

.style_input__1KIg5 > div[aria-label="dropdown"] > input {
  padding-top: 12px;
  color: #2c2e2f;
  margin-right: 10px;
  border-right: 1px solid #e2e5e8;
}

.style_input__1KIg5 > div[aria-label="dropdown"] > div {
  position: absolute;
  margin-top: -16px;
  font-size: 10px;
  padding-left: 3px;
}

.style_input__1KIg5 > div[aria-label="dropdown"][data-disabled="true"] > input {
  color: #2c2e2f;
}

.style_input__1KIg5 > div[aria-label="dropdown"][data-disabled="true"] > div {
  color: #767e83;
}

.style_dropdownWrapper__3BUgw {
  height: 100%;
}

.style_dropdownPopout__12dwB {
  height: auto;
}

.style_warn__2XIgo > div:first-of-type {
  border: solid 1px #FFD34A !important;
}

.style_error__39LIC > div:first-of-type {
  border: solid 1px #c01818 !important;
}
:root {
  --label-float-height: 44px;
  --label-float-margin-x: 12px;
  --label-float-margin-y: 12px;
}

.style_floatLabel__3lDsP > div {
  position: absolute;
  /* V Test styling, will be overwritten */
  border-radius: 4px;
  border: solid 1px #d1d4d6;
  width: 100%;
  height: 44px;
  height: var(--label-float-height, 44px);
}

.style_floatLabel__3lDsP > div[id^="number"] {
  border-radius: none;
  border: none;
}

.style_floatLabel__3lDsP > div[id^="number"] > div {
  position: absolute;
  /* V Test styling, will be overwritten */
  border-radius: 4px;
  border: solid 1px #d1d4d6;
  width: 100%;
  height: 44px;
  height: var(--label-float-height, 44px);
}

.style_floatLabel__3lDsP > div[id^="number"] > div:focus-within {
  border: solid 1px #3184FC;
}

.style_floatLabel__3lDsP > div > input {
  position: absolute;
  margin: 15px 12px 15px 12px;
  
  color: inherit;
  background-color: transparent;
  outline: none;
  width: calc(100% - (12px * 2));
  width: calc(100% - (var(--label-float-margin-x, 12px) * 2));

  font-size: 14px;

  height: auto;
}

.style_floatLabel__3lDsP > div > textarea {
  position: absolute;
  margin: 15px 12px 15px 12px;
  
  color: inherit;
  background-color: transparent;
  outline: none;
  width: calc(100% - (12px * 2));
  width: calc(100% - (var(--label-float-margin-x, 12px) * 2));

  font-size: 14px;

  border: none;
  padding: 0;
  height: calc(100% - (12px + 4px));
  height: calc(100% - (var(--label-float-margin-y, 15px) + 4px));
  resize: none;
}

.style_floatLabel__3lDsP > div[id^="number"] > div > input {
  position: absolute;
  margin: 15px 12px 15px 12px;
  
  color: inherit;
  background-color: transparent;
  outline: none;
  width: calc(100% - (12px * 2));
  width: calc(100% - (var(--label-float-margin-x, 12px) * 2));

  font-size: 14px;
  text-align: left;

  height: auto;
}

.style_floatLabel__3lDsP {
  position: relative;
  height: 44px;
  height: var(--label-float-height, 44px);
  width: 100%;
}

.style_floatLabel__3lDsP > div > label {
  position: absolute;
  -webkit-transform: translate(12px, calc(12px * 0.75)) scale(1);
          transform: translate(12px, calc(12px * 0.75)) scale(1);
  -webkit-transform: translate(var(--label-float-margin-x), calc(var(--label-float-margin-y) * 0.75)) scale(1);
          transform: translate(var(--label-float-margin-x), calc(var(--label-float-margin-y) * 0.75)) scale(1);
  transition: all 0.2s ease-out;
  pointer-events: none;
  -webkit-transform-origin: 0% 0%;
          transform-origin: 0% 0%;
}

.style_floatLabel__3lDsP > div[id^="number"] > div > label {
  position: absolute;
  -webkit-transform: translate(12px, calc(12px * 0.75)) scale(1);
          transform: translate(12px, calc(12px * 0.75)) scale(1);
  -webkit-transform: translate(var(--label-float-margin-x), calc(var(--label-float-margin-y) * 0.75)) scale(1);
          transform: translate(var(--label-float-margin-x), calc(var(--label-float-margin-y) * 0.75)) scale(1);
  transition: all 0.2s ease-out;
  pointer-events: none;
  -webkit-transform-origin: 0% 0%;
          transform-origin: 0% 0%;
}

.style_floatLabel__3lDsP > div > input {
  transition: all 0.2s ease-out;
  color: #2c2e2f;
}

.style_floatLabel__3lDsP > div[id^="number"] > div > input {
  transition: all 0.2s ease-out;
  color: #2c2e2f;
}

.style_floatLabel__3lDsP > div > textarea {
  transition: all 0.2s ease-out;
  color: #2c2e2f;
}

.style_floatLabel__3lDsP:focus-within > div > label {
  -webkit-transform: translate(12px, calc(12px / 3)) scale(0.67) !important;
          transform: translate(12px, calc(12px / 3)) scale(0.67) !important;
  -webkit-transform: translate(var(--label-float-margin-x, 12px), calc(var(--label-float-margin-y, 15px) / 3)) scale(0.67) !important;
          transform: translate(var(--label-float-margin-x, 12px), calc(var(--label-float-margin-y, 15px) / 3)) scale(0.67) !important;
}

.style_floatLabel__3lDsP:focus-within > div[id^="number"] > div > label {
  -webkit-transform: translate(12px, calc(12px / 3)) scale(0.67) !important;
          transform: translate(12px, calc(12px / 3)) scale(0.67) !important;
  -webkit-transform: translate(var(--label-float-margin-x, 12px), calc(var(--label-float-margin-y, 15px) / 3)) scale(0.67) !important;
          transform: translate(var(--label-float-margin-x, 12px), calc(var(--label-float-margin-y, 15px) / 3)) scale(0.67) !important;
}

.style_floatLabel__3lDsP:focus-within > div > input {
  margin-top: calc(12px + 4px);
  margin-top: calc(var(--label-float-margin-y, 15px) + 4px);
  margin-bottom: 0;
}

.style_floatLabel__3lDsP:focus-within > div[id^="number"] > div > input {
  margin-top: calc(12px + 4px);
  margin-top: calc(var(--label-float-margin-y, 15px) + 4px);
  margin-bottom: 0;
}

.style_floatLabel__3lDsP:focus-within > div > textarea {
  margin-top: calc(12px + 4px);
  margin-top: calc(var(--label-float-margin-y, 15px) + 4px);
  margin-bottom: 0;
}

.style_activeText__1r1NQ > div > label {
  -webkit-transform: translate(12px, calc(12px / 3)) scale(0.67) !important;
          transform: translate(12px, calc(12px / 3)) scale(0.67) !important;
  -webkit-transform: translate(var(--label-float-margin-x, 12px), calc(var(--label-float-margin-y, 15px) / 3)) scale(0.67) !important;
          transform: translate(var(--label-float-margin-x, 12px), calc(var(--label-float-margin-y, 15px) / 3)) scale(0.67) !important;
}

.style_activeText__1r1NQ > div > input {
  margin-top: calc(12px + 4px);
  margin-top: calc(var(--label-float-margin-y, 15px) + 4px);
  margin-bottom: 0;
}

.style_activeText__1r1NQ > div[id^="number"] > div > label {
  -webkit-transform: translate(12px, calc(12px / 3)) scale(0.67) !important;
          transform: translate(12px, calc(12px / 3)) scale(0.67) !important;
  -webkit-transform: translate(var(--label-float-margin-x, 12px), calc(var(--label-float-margin-y, 15px) / 3)) scale(0.67) !important;
          transform: translate(var(--label-float-margin-x, 12px), calc(var(--label-float-margin-y, 15px) / 3)) scale(0.67) !important;
}

.style_activeText__1r1NQ > div[id^="number"] > div > input {
  margin-top: calc(12px + 4px);
  margin-top: calc(var(--label-float-margin-y, 15px) + 4px);
  margin-bottom: 0;
}

.style_activeText__1r1NQ > div > textarea {
  margin-top: calc(12px + 4px);
  margin-top: calc(var(--label-float-margin-y, 15px) + 4px);
  margin-bottom: 0;
}

.style_error__1FAsO {
  margin-top: calc(44px + 4px);
  margin-top: calc(var(--label-float-height, 44px) + 4px);
  border: none !important;
  color: #c01818;
  margin-left: 12px;
  margin-left: var(--label-float-margin-x);
}
.style_wrapper__2AaPc {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  min-height: 1074px;
}

.style_cartFlowWrapper__2-5nu {
  width: calc(100% - 370px);
  height: 100%;
}

.style_cartFlowCrumbs__10DKS {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 95%;
  padding: 40px 0 24px 0;
}

.style_cartFlowCrumbs__10DKS > p {
  padding-right: 10px;
  font-size: 16px;
  color: #7a7d81;
  transition: all 0.2s ease;
  font-family: 'Roboto-Regular';
}

.style_cartFlowCrumbs__10DKS > p[data-is-past-section="true"] {
  color: #2c2e2f;
  cursor: pointer;
}

.style_cartFlowCrumbs__10DKS > p[data-is-selected="true"] {
  font-family: Roboto-Medium;
}

.style_cartFlowCrumbs__10DKS > p:not(:first-of-type) {
  padding-left: 10px;
}

.style_cartFlowSectionWrapper__1-lsI {
  display: flex;
  align-items: center;
  width: 95%;
  /* TODO: replace this height since it is just placeholder */
  min-height: 180px;
  border-bottom: 1px solid #e2e5e8;
  border-top: 1px solid #e2e5e8;
}

.style_nextButtonWrapper__1MddB {
  display: flex;
  justify-content: flex-end;
  width: 95%;
  margin-top: 24px;
  position: relative;
}

.style_buttonHoverWrapper__3LYfA {
  position: relative;
  display: flex;
  justify-content: flex-end;
}

.style_buttonHoverWrapper__3LYfA[data-is-paypal="true"]:hover > button {
  background-color: #11b992e1;
}

.style_buttonBacking__fFVv1 {
  background-color: #ffffff;
  pointer-events: none;
  z-index: 2;
  width: 100%;
  height: 100%;
  position: absolute;
}

.style_orderSummaryWrapper__27Vb2 {
  width: 50%;
  /* offset the box so that its background can cover full right space */
  margin-right: -50%;
  height: 100%;
  border-left: 1px solid #e2e5e8;
  background-color: #fbfcfc;
}

.style_orderSummaryRightGuttering__1UK0i {
  width: 301px;
  height: 100%;
}

.style_loadingFullPage__3Z61G {
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.style_loadingSection__3bow8 {
  height: 100%;
}

.style_continueButton__TLOx5 {
  z-index: 3;
}

.style_backButton__hINI3 {
  margin-right: 14px;
}

.style_backButton__hINI3 > svg {
  margin-left: 0px !important;
  margin-right: 13px;
}

.style_continueButton__TLOx5 > *:first-child {
  margin-left: 13px;
}

.style_buttonLoading__2dgwD {
  margin-left: 0 !important;
  width: 138px;
}

.style_paypalButton__2C2qV {
  overflow: hidden;
  border-radius: 8px;
  position: absolute;
  height: 40px;
  width: 100%;
  z-index: 1;
}

.style_paypalButton__2C2qV > div {
  -webkit-transform: scale(3);
          transform: scale(3);
}
.style_wrapper__1fYj4 {
  width: 100%;
}

.style_container__k3PQ1 {
  width: 100%;
  height: 100%;
  position: relative;
}

.style_designUpdateNotice__1sGe5 {
  border-bottom: 1px solid #abd0e8 !important;
  margin-top: 48px;
  width: 100%;
  padding: 16px !important;
  border-radius: 8px !important;
  align-items: center !important;
}

.style_designUpdateNotice__1sGe5 > div:nth-of-type(2) {
  line-height: 20px;
}

.style_CartOptionSection__3Q8-w, .style_deleteOverlay__QyAwK {
  width: 100%;
  top: 0;
  left: 0;
  position: relative;
}

.style_CartOptionSection__3Q8-w {
  display: flex;
  padding: 48px 0 32px;
}

.style_thumbPreviewSection__3WNgu {
  width: 132px;
}

.style_thumbnail__3WNFJ {
  border-radius: 4px;
  width: 100%;
  min-height: 62px;
  cursor: default;
}

.style_thumbnail__3WNFJ > div {
  pointer-events: none;
}

.style_previewRemoveButtons__Zoc23 {
  padding-top: 16px;
}

.style_previewButton__3QWOg {
  margin-bottom: 8px;
}

.style_removeButton__DNQ74 {
  font-weight: 500;
  border: 1px solid #e2e5e8;
}

.style_printOptionSelections__3_pCQ {
  /* flex: 1; */
  padding-left: 40px;
  width: 85%;
}

.style_designSubtitle__3vOLb {
  font-size: 15px;
}

.style_printItems__3QRAb {
  border: 2px dotted pink;
  margin-top: 24px;
  min-height: 116px;
}

.style_cartDesignWrapper__1zTR7 {
  position: relative;
}

.style_deleteOverlay__QyAwK {
  position: absolute;
  height: 100%;
  z-index: 5000;
  background: linear-gradient(-45deg, #ECECEC, #ECECEC, #FFF, #ECECEC, #ECECEC);
  background-size: 200% 200%;
  -webkit-animation: style_Gradient__2rRhx 2s ease infinite;
          animation: style_Gradient__2rRhx 2s ease infinite;
  opacity: 0.8;
}

@-webkit-keyframes style_Gradient__2rRhx {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes style_Gradient__2rRhx {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.style_printItemsWrapper__2H8by {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 12px;
}

.style_printItemsWrapper__2H8by > div:not(:last-of-type) {
  border-bottom: 1px solid #e2e5e8;
}

.style_printItemWrapper__jcEh8 {
  display: flex;
  width: 100%;
  height: 124px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.style_printItemWrapper__jcEh8 > div:first-of-type {
  width: 100%
}

.style_trashCan__6WbFm {
  width: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.style_trashCan__6WbFm > svg {
  cursor: pointer;
}

.style_addButton__3VLMo {
  display: flex;
  justify-content: center !important;
  align-items: center;
  margin-top: 12px;
}

.style_addButton__3VLMo > svg {
  margin-right: 13px;
}

.style_noPrintWarning__S-PeU {
  margin-top: 12px;
}

.style_noPrintWarning__S-PeU > span > span > strong {
  text-decoration: underline;
  cursor: pointer;
}

.style_loading__K9Vwf {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.style_infoAlertBox__12CLb {
  width: 100%;
  margin-top: 48px;
  border-radius: 8px;
  border: solid 1px rgba(248, 161, 127, 0.4);
  background-color: #fffbfa;
}

.style_infoAlertBox__12CLb > span {
  width: 100%;
}

.style_alertAnimationMessage__1LsVK {
  width: 559px;
  width: 100%;
  display: flex;
  margin-right: 18px;
  margin-left: 10px;
  font-family: Roboto;
  font-size: 14px;
  line-height: 1.43;
  position: relative;
  padding-right: 30px;
  align-items: center;
}

.style_alertAnimationMessage__1LsVK > svg > g {
  fill: #57595D;
}

.style_alertAnimationMessage__1LsVK > div {
  position: absolute;
  right: 5px;
}

.style_plusIconDisplay__1xnta {
  margin-left: 28px;
  cursor: pointer;
}

.style_warnings__KCiXS > div:not(:first-child){
  margin-top: 15px;
}

.style_previewVideo__2VRIl {
  width: 100%;
  height: auto;
}
.designUpdateNotice_wrapper__BQjSU {
  align-items: flex-start;
  background-color: rgba(49, 133, 252, 0.03);
  border: 1px solid #abd0e8;
  border-radius: 4px;
  display: flex;
  padding: 24px;
}

.designUpdateNotice_label__bkXWh {
  color: #2c2e2f;
  flex: 1 1 auto;
  font-size: 14px;
  line-height: 1.71;
  margin: 0 16px;
}

.designUpdateNotice_updateButton__3Len8 {
  display: flex;
  flex: 1 1 136px;
  height: 40px;
  justify-content: flex-end;
}

:root {
  --payment-option-height-offset-difference: 0px;
}

.style_wrapper__3fw00 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  margin: 48px 0px;
}

.style_title__3DlFd {
  font-size: 20px;
  color: #2c2e2f;
  line-height: 24px;
  margin-bottom: 16px;
}

.style_subTitle__16a4g {
  font-size: 15px;
  line-height: 24px;
  color: #57595D;
  margin-bottom: 24px;
}

.style_paymentOptions__1t5ML {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.style_paymentOptions__1t5ML > *:not(:first-child) {
  margin-top: 16px;
}

.style_paymentOption__33xoY {
  border: 1px solid #D1D4D6;
  width: 100%;
  max-height: 52px;
  overflow: hidden;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  transition: max-height 0.3s ease;
}

.style_paymentOption__33xoY[data-is-active="true"] {
  max-height: calc(190px + 0px);
  max-height: calc(190px + var(--payment-option-height-offset-difference));
}

.style_paymentOption__33xoY[data-is-active="false"] > .style_paymentOptionHeader__Db9AS {
  cursor: pointer;
}

.style_paymentOptionHeader__Db9AS {
  display: flex;
  flex-direction: row;
  height: 52px;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
}

.style_paymentOptionHeaderLeft__3h4KL {
  display: flex;
  flex-direction: row;
  color: #2c2e2f;
}

.style_selectionCircle__1vXSc {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #D1D4D6;
  margin-right: 12px;
  transition: border-width 0.1s ease-in-out;
}

.style_selectionCircle__1vXSc[data-is-active="true"] {
  border: 7px solid #2C6FCF;
}

.style_paymentOptionHeaderRight__Oe3KL {
  display: flex;
  flex-direction: row;
}

.style_paymentOptionHeaderRight__Oe3KL > img:not(:last-of-type) {
  margin-right: 12px;
}

.style_logo__2Srdx {
  width: 36px;
  height: 22px;
  object-fit: contain;
}

.style_paymentOptionContent__BfF_g {
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-top: 1px solid #dddddd;
  background-color: #fcfcfc;
  border-radius: 0px 0px 8px 8px;
}

.style_paypalContent__KLWbx {
  height: 104px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #57595D;
}

.style_paypalButton__OaKcd > div > div:not(:first-of-type) {
  display: none;
}
.style_orderSummary__1dZu3 {
  max-width: 512px;
  padding: 40px 0 40px 32px;
  background-color: #fbfcfc;
}

.style_orderHeading__39uaI {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  color: #2c2e2f;
  font-size: 20px;
}

.style_couponInput__3fPtU {
  display: flex;
  align-items: center;
  padding-right: 5px;
}

.style_couponInput__3fPtU > div {
  background-color: white;
}

.style_couponPromoCodeAlert__35oYy {
  color: #c01818;
  position: relative;
  overflow: hidden;
  font-size: 14px;
  margin: -15px 0 16px;
  max-height: 0px;
  transition: max-height 0.2s ease;
}

.style_couponPromoCodeAlert__35oYy[data-has-error="true"] {
  max-height: 20px;
  margin: -7px 0 10px;
}

.style_loading__3lyz2 {
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.style_coupon__2NpaX {
  height: 72px;
  display: flex;
  align-items: center;
}

.style_promoCodeButton__1P9P9 {
  margin-left: 12px;
}
.orderFailedLoading_wrapper__2EB2v {
  justify-content: center;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.orderFailedLoading_heading__1c0g- {
  color: #2c2e2f;
  font-size: 24px;
  line-height: 1.33;
  margin-bottom: 8px;
}

.orderFailedLoading_content__2sqBq {
  color: #7A7D81;
  font-size: 16px;
  line-height: 1.63;
}

.orderFailedLoading_button__2wRG_ {
  display: inline-block !important;
}

.style_circlecheck__28_Gd {
  position: relative;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.style_circlecheck__28_Gd > svg {
  z-index: 10;
}

.style_circlecheck__28_Gd > div:first-of-type {
  position: absolute;
  width: 38px;
  height: 38px;
  border: 2px solid #2c2e2f;
  border-radius: 50%;
  z-index: 10;
} 

.style_circlecheck__28_Gd > div:last-of-type {
  position: absolute;
  background-color: #fce068;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  -webkit-transform: translate(4px, 4px);
          transform: translate(4px, 4px);
  z-index: 1;
}

.style_heading__3L2io {
  margin: 19px 0 16px;
}

.style_successMessage__1osJ6 {
  color: #54595e;
  font-size: 15px;
  line-height: 1.6;
  margin-bottom: 24px;
}

.style_supportEmail__esFtP {
  text-decoration: underline;
  color: #2c2e2f;
}

.style_orderStatusBox__30OM3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #FCFCFD;
  border: 1px solid #E2E5E8;
  border-radius: 8px;
  width: 300px;
  height: 74px;
}

.style_orderStatusBox__30OM3 > div {
  color: #2c2e2f;
  font-size: 15px;
  line-height: 1.33;
  margin-left: 16px;
}

.style_orderStatusBox__30OM3 > div > strong {
  font-weight: 500;
}

.style_orderHeading__2ArSb {
  width: 100%;
  margin: 40px 0px 0px;
  border-bottom: 1px solid #D1D4D6;
  padding-bottom: 12px;
}

.style_buttonSection__1C3nn {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 40px;
}

.style_priceSummarySection__1i5GM {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.style_priceSummarySection__1i5GM > div {
  border-top: none;
  width: 512px;
}

.style_workspaceButton__2hZLe {
  float: right;
}

.style_breadCrumb__l7pDD {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 24px;
}

.style_breadCrumb__l7pDD > div {
  font-size: 16px;
  color: #2a2e2f;
}

.style_breadCrumb__l7pDD > div:first-of-type {
  margin-right: 15px;
  color: #797d81;
  border-bottom: 1px solid rgba(122, 125, 129, 0.16);
  cursor: pointer;
}

.style_breadCrumb__l7pDD > svg {
  margin-right: 15px;
}

.style_wrapper__7VYYV {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}

.style_title__xUO3A {
  margin-bottom: 32px;
}

.style_billingAndPaymentSection__29YnN {
  display: flex;
  flex-direction: row;
}

.style_billingAndPaymentSection__29YnN > div {
  width: 100%;
}

.style_billingRow__2zrOr {
  display: flex;
  flex-direction: row;
}

.style_billingRow__2zrOr:first-of-type {
  margin-top: 24px;
}

.style_billingRow__2zrOr:not(:last-of-type) {
  margin-bottom: 4px;
}

.style_billingRowLeft__28AJF {
  width: 131px;
  font-size: 14px;
  font-weight: 500;
  color: #57595d;
}

.style_billingRowRight__2w9MT {
  font-size: 14px;
  color: #57595d;
}

.style_orderDesigns__3MBG2 {
  border-top: 1px solid #D1D4D6;
}

.style_orderDesigns__3MBG2 > div:last-of-type {
  margin-top: 12px !important;
  border-bottom: 2px solid #D1D4D6;
}
.style_container__2zPiA {
  position: relative;
}

.style_icon__gyzXy {
  position: relative;
}

.style_icon__gyzXy {
  height: 24px;
  margin-right: 24px;
  padding: 2px;
  position: relative;
  width: 24px;
}

.style_iconWithDropDown__XcAHx {
  width: auto
}

.style_wrapper__1bXXI {
  align-items: center;
  border-bottom: 1px solid #E2E5E8;
  display: flex;
  justify-content: space-between;
  padding: 12px 12px;
  width: 337px;
  height: 80px;
}

.style_wrapper__1bXXI > * + * {
  margin-left: 12px;
}

.style_wrapper__1bXXI > div:hover {
  background-color: inherit;
}

.style_details__2ceMP {
  display: flex;
}

.style_meta__2dDmP {
  margin-left: 12px;
}

.style_name__28ISz {
  color: #57595D;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.33;
  color: #2c2e2f;
  width: 235px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.style_email__1Dg3q {
  font-family: Roboto;
  font-size: 13px;
  line-height: 1.54;
  color: #57595d;
  width: 235px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.style_nav__2k_cw {
  color: #57595D;
}

.style_nav__2k_cw > * {
  align-items: center;
  display: flex;
  padding: 8px 16px;
}

.style_icon__gyzXy {
  margin-right: 12px;
}

.style_modal__2XVX_ {
  width: 100%;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.style_modalInput__1we90 {
  display: inline-block;
  width: 50%;
  margin-right: 24px;
}

.style_avatarWithImage__1ptVy {
  box-shadow: 0 0 0 2px #fff inset;
}

.style_link__2Xuvw {
  cursor: pointer;
}

.style_popout__1Awzi {
  border-radius: 8px;
  overflow: hidden;
  border: none;
}

.style_linkSectionWrapper__2sJE6 {
  align-items: center;
  border-bottom: 1px solid #E2E5E8;
  display: flex;
  justify-content: space-between;
  padding: 12px 12px;
  height: 64px;
}

.style_linkSectionWrapper__2sJE6:last-of-type {
  border-bottom: none;
  background-color: #fcfcfd;
  border-radius: 8px;
}

.style_linkSectionWrapper__2sJE6:last-of-type > a:hover {
  background-color: #f3f3f7;
}

.style_linkContainer__2KZCL {
  width: 313px;
  height: 36px;
  display: flex;
  align-items: center;
  padding: 6px 12px;
  border-radius: 4px;
  font-family: Roboto;
  font-size: 16px;
  line-height: 1.5;
  color: #57595d;
}

.style_linkContainer__2KZCL:hover {
  cursor: pointer;
}

.style_linkContainer__2KZCL > a {
  display: flex;
  align-items: center;
}

.style_linkContainer__2KZCL:hover {
  background-color: #f9f9fa;;
}



.style_card__24Ki6 {
  min-height: 360px;
  max-width: 464px;
  width: 100%;
}

.style_logo__2vMCO {
  display: flex;
  align-items: center;
  margin-bottom: 35px;
  justify-content: center;
}

.style_title__3C5FR {
  color: #2c2e2f;
  font-size: 28px;
}

.style_columns__1287_ {
  display: flex;
}

.style_col__1udtd {
  padding-right: 32px;
  width: 50%;
  flex: auto;
  border-right: 1px solid #D1D4D6;
}

.style_form__2keWt {
  width: 100%;
}

.style_form__2keWt > div ~ div {
  margin-top: 16px;
}

.style_links__19LoD {
  display: flex;
  align-items: center;
}

.style_links__19LoD a {
  color: #2c2e2f;
}

.style_footer__Tmgg3 {
  display: flex;
  flex-wrap: wrap;
  font-size: 13px;
  line-height: 21px;
}

.style_footer__Tmgg3 a {
  margin:0px 3px 0px 3px;
  color: easilGrayDark;
}

.style_login__7wbXJ {
  margin-top: 62px;
}

.style_login__7wbXJ a {
  margin:0px 3px 0px 3px;
  color: #2c2e2f;
}

.style_actions__2D5wM {
  align-content: center;
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
}
.style_wrapper__1EAyu {
  width: 100%;
  height: 100%;
}

.style_title__3oyxJ > h1 {
  width: 170px;
  height: 38px;
  font-family: Roboto;
  font-size: 32px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  color: #1c1d1e;
  margin: 0;
}

.style_description__30iTQ > p {
  width: 486px;
  height: 48px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #57595d;
  margin: 16px 0px 0px;
}

.style_description__30iTQ > p > a {
  color: #1c1d1e;
}

.style_teamListTitle__3bSZR > p {
  width: 43px;
  height: 24px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #1c1d1e;
  margin: 24px 0px 15px 0px;
}

.style_card__15Maq {
  padding: 32px !important;
  width: 550px !important;
  height: auto !important;
  max-height: 558px !important;
  border-radius: 4px !important;
  background-color: #ffffff !important;
}

.style_returnLink__1Wrf2 {
  align-items: center;
  display: flex;
  font-weight: 500;
  margin-bottom: 12px;
  margin-top: -8px;
}

.style_returnLinkIcon__3dBsY {
  margin-right: 20px;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.style_returnLink__1Wrf2,
.style_listItemInner__1Efoj {
  cursor: pointer;
}

.style_searchInput__26ja9 {
  width: 100%;  
  margin-bottom: 16px;
}
.style_wrapper__lKUTO > :nth-last-child(1) {
  margin-bottom: 0;
}

.style_wrapper__lKUTO {
  max-height: 270px;
  overflow-y: scroll;
}

.style_teamOption__12BY- {
  width: 486px;
  height: 56px;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 var(--dark-grey-8);
  border: solid 1px #d6d6d6;
  background-color: rgba(255, 255, 255, 0.4);
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  padding: 16px 16px 16px 12px;
}

.style_teamName__16Yv7 {
  width: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.style_teamName__16Yv7 > p {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #1c1d1e;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 300px;
  overflow: hidden;
  margin: 0;
}

.style_meta__VQt-x {
  align-items: center;
  display: flex;
  font-weight: normal;
}

.style_meta__VQt-x small {
  color: easilGrayDark;
  font-size: 13px;
  margin-right: 8px;
}

.style_meta__VQt-x strong {
  margin-right: 4px;
}

.style_meta__VQt-x > * {
  cursor: pointer;
}

.style_teamAvatar__1wgJI {
  margin-right: 16px;
}

.style_teamOptionAction__1S89r {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
}

.style_teamOptionAction__1S89r > p {
  width: 77px;
  height: 24px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: right;
  color: #73767a;
  margin: 0px 10px;
}

.style_background__3jZBS {
  height:100%;
  display: flex;
  overflow-y: scroll;
  justify-content: center;
  align-items: center;
  padding: 137px 0px 137px 0px;
  background-size: cover
}

.style_card__K2u5S {
  margin: auto;
  width: 600px;
  padding: 48px 100px 48px 100px;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.12);
  background-color: white;
}

.style_outer__1uBnj {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.style_topRow__2kiVN {
  flex: 1 0 100%;
  padding: 32px 0px 40px;
  width: 100%;
}
.style_col1__3kUCN {
  margin-right: auto;
  width: 100%;
}

.style_col2__g11Rx > div {
  width: 196px
}

@media screen and (max-width: 950px) {
  .style_col1__3kUCN {
    max-width: none;
  }
}

.style_outer__24Hh- {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 64px;
}

.style_topRow__2ykf6 {
  flex: 1 0 100%;
  padding: 64px 0px 64px 0px;
}
.style_col1__ZoDub {
  padding-right: 16px;
  flex: 1 0 50%;
}

.style_col2__2NO9_ {
  padding-left: 16px;
  flex: 1 0 50%;
}

@media screen and (max-width: 950px) {
  .style_col1__ZoDub,
  .style_col2__2NO9_ {
    flex: 1 0 100%;
    padding: 0;
  }

  .style_col1__ZoDub {
    margin-bottom: 32px;
  }
}

.style_card__2soB5 {
  width: 100%;
}

.style_logo__26et9 {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  justify-content: center;
}

.style_title__3MPDk {
  color: #2c2e2f;
  font-size: 24px;
  margin-bottom: 16px;
}

.style_columns__PTT12 {
  display: flex;
  justify-content: center;
}

.style_col__3fdzy {
  width: 50%;
  flex: auto;
  border-right: 1px solid #D1D4D6;
}

.style_form__2lTfl {
  width: 100%;
}

.style_form__2lTfl > div ~ div {
  margin-top: 16px;
}

.style_links__QknXG {
  align-items: center;
  display: flex;
}

.style_links__QknXG a {
  color: #2c2e2f;
}

.style_footer__rY4-C {
  display: flex;
  flex-wrap: wrap;
  font-size: 13px;
  line-height: 21px;
}

.style_footer__rY4-C a {
  margin:0px 3px 0px 3px;
  color: easilGrayDark;
}

.style_login__1MUcC {
  margin-top: 62px;
}

.style_login__1MUcC a {
  margin:0px 3px 0px 3px;
  color: #2c2e2f;
}

.style_errors__2LUYs {
  margin-top: 16px;
}

.style_error__2oQyb {
  color: #E64826;
}

.style_error__2oQyb + .style_error__2oQyb {
  margin-top: 4px;
}

.style_actions__77-Yy {
  align-content: center;
  display: flex;
  justify-content: space-between;
}

.style_brandKitMenuOptions__3Jd63 {
  display: flex;
  flex-direction: column;
}

.style_brandKitMenuOption__28I_3 {
  color: #57595D;
  display: flex;
  line-height: 24px;
  padding: 12px 0;
}
.style_brandKitMenuOption__28I_3:hover,
.style_brandKitMenuOption__28I_3[data-active="true"] {
  color: #2c2e2f;
  font-weight: 500;
}

.style_brandKitMenuOptionIcon__2QMnU {
  height: 24px;
  margin-right: 16px;
  width: 24px;
}

.style_brandKitMenuOption__28I_3:first-child {
  margin-top: -12px;
}

:root {
  --gif-canvas-width: 64px;
  --gif-canvas-height: 64px;
  --gif-canvas-left: 0px;
  --gif-canvas-top: 0px;
  --gif-div-height: auto;
  --gif-div-width: auto;
}

/* TITLES */

.common_contentTitle__2uWRb {
  font-size: 20px;
  margin-bottom: 32px;
}
.common_contentTitleForSearch__SwJut {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  color: #262f30;
  height: 24px;
  font-family: Roboto;
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  color: #57595d;
}

/* BREADCRUMB */

.common_breadcrumb__3MjBR {
  display: flex;
  font-size: 16px;
  margin: -16px 0 40px 0;
  margin-top: 40px;
}

.common_breadcrumb__3MjBR > a {
  border-bottom: 1px solid rgba(122, 125, 129, 0.16);
  color: #777d82;
}

.common_breadcrumbSpacer__p-SYw {
  align-items: center;
  display: flex;
  height: 24px;
  justify-content: center;
  margin: 0 8px;
  width: 24px;
}

.common_breadcrumbMenu__3TqLH {
  position: relative;
}

.common_breadcrumbMenuDropDown__2ffu3 {
  cursor: pointer;
  display: flex;
}
.common_breadcrumbMenuDropDown__2ffu3:hover {
  border-bottom: 1px solid #afb3b6;
}
.common_breadcrumbMenuDropDown__2ffu3 > svg {
  margin: 6px 0 6px 10px;
}

/* WHITE ROW */

.common_whiteRow__3WwGz {
  background: white;
  border: 1px solid #E2E5E8;
  border-radius: 8px;
  margin-bottom: 12px;
  padding: 20px;
}

.common_whiteRowTop__3Gksc {
  display: flex;
  justify-content: space-between;
}

.common_whiteRowTop__3Gksc > div:first-of-type {
  max-width: calc(100% - 80px);
}

/* WHITE ROW - BUTTONS */

.common_whiteRowButtons__caeoy {
  display: flex;
  position: relative;
}

.common_whiteRowButton__1Wv89 {
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  height: 24px;
  justify-content: center;
  margin-left: 16px;
  width: 24px;
}
.common_whiteRowButton__1Wv89:hover {
  background: whitesmoke;
}

/* WHITE ROW, UPLOAD BUTTON (ERROR MSG.) */

.common_uploadButtonPrependError__2OAcE {
  display: flex;
  flex-direction: row-reverse;
}
.common_uploadButtonPrependError__2OAcE > div:nth-child(3) {
  font-weight: 500;
  line-height: 16px;
  margin: 0 8px 0 0;
  max-width: 87px;
}

/* IMAGE LIST */

.common_imageListTitles__1qYNx {
  border-bottom: 1px solid rgba(226, 229, 232, 0.88);
  color: #8f9295;
  display: flex;
  font-size: 14px;
  margin-bottom: 16px;
  padding-bottom: 12px;
}

.common_imageList__2kI6F {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}

.common_imageListFolderRow__16a1H,
.common_imageListRow__1Ks4w {
  display: flex;
  
}

.common_imageListFolderRow__16a1H:hover {
  cursor: pointer;
}

.common_imageListRowRight__oAkwM {
  align-items: center;
  border-top: 1px solid rgba(226, 229, 232, 0.88);
  display: flex;
  flex: 1 1 auto;
  justify-content: space-between;
}
.common_imageListFolderRow__16a1H:first-child .common_imageListRowRight__oAkwM,
.common_imageListRow__1Ks4w:first-child .common_imageListRowRight__oAkwM {
  border-top: none;
}

.common_imageListRowTitle__3Gm3I {
  width: calc((0.6 * (75vw - 226px)) - 112px );
  font-weight: 500;
  padding-right: 10px;
  max-width: calc((0.6 * (75vw - 226px)) - 112px );
}
.common_imageListFolderRow__16a1H:hover .common_imageListRowTitle__3Gm3I {
  text-decoration: underline;
}

.common_imageListTile__1rhna {
  width: 64px;
  height: 64px;
  background-color: #FEFEFE;
  background-image: linear-gradient(45deg, #DFDFDFBF 25%, #DFDFDF00 25%, #DFDFDF00 75%, #DFDFDFBF 75%, #DFDFDFBF), linear-gradient(45deg, #DFDFDFBF 25%, #DFDFDF00 25%, #DFDFDF00 75%, #DFDFDFBF 75%, #DFDFDFBF);
  background-size: 10px 10px;
  background-position: 0 0, 15px 15px;
  border-radius: 4px;
  margin-right: 24px;
  margin-bottom: 8px;
  margin-top: 8px;
}

.common_logoListRowImage__3IE6X {
  background-color: #FEFEFE;
  background-position: center center;
  background-size: cover;
  border-radius: 4px;
  height: 64px;
  width: 64px;
  min-height: 64px;
  min-width: 64px;
  margin: 8px 24px 8px 0;
}

.common_logoListRowFolder__2g2aq {
  width: 64px;
  height: 64px;
  align-items: center;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.common_imageListWrapper__2J98i {
  width: 64px;
  height: 64px;
  align-items: center;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.common_folderTile__1dfao {
  width: 64px;
  height: 64px;
  background-color: white;
  margin-right: 24px;
  margin-bottom: 8px;
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(21, 21, 22, 0.12);
}

.common_imageListWrapper__2J98i img {
  display: block;
  object-fit: cover;
  border-radius: 2px;
  position: relative;
  z-index: 1;
}

.common_imageListWrapper__2J98i > div {
  height: auto;
  height: var(--gif-div-height);
  width: auto;
  width: var(--gif-div-width);
}


.common_imageListWrapper__2J98i > div > canvas {
  width: 64px;
  width: var(--gif-canvas-width);
  height: 64px;
  height: var(--gif-canvas-height);
  left: 0px !important;
  left: var(--gif-canvas-left) !important;
  border-radius: 4px;
}

.common_imageListFolderRow__16a1H .common_imageListRowImage__2UgFB {
  align-items: center;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
}

.common_folderTile__1dfao {
  width: 64px;
  height: 64px;
  background-color: white;
  margin-right: 24px;
  margin-bottom: 8px;
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(21, 21, 22, 0.12);
}

.common_imageListRowColumn__3e-xu {
  width: calc(0.2 * (75vw - 226px - 88px));
  min-width: calc(0.2 * (75vw - 226px - 88px));
}
.common_imageListRow__1Ks4w .common_imageListRowColumn__3e-xu {
  color: #616569;
}

.common_imageListRowMenu__2Y03G {
  position: relative;
  width: 24px;
  min-width: 24px;
}

.common_imageListRowEllipse__1kp9j {
  border-radius: 4px;
  cursor: pointer;
  display: flex;
}

.common_imageListRowEllipse__1kp9j:hover {
  background: rgba(0, 0, 0, 0.1);
}

.common_imageListRowMenuPopout__1Dd61 {
  right: 0;
  top: 100%;
}

/* POPOUT MENU */

.common_popoutItemMenu__2QnUc {
  right: 0;
  top: 24px;
}

.common_sortDropdownWrapper__3Q9dy {
  width: auto !important;
  padding-right: 8px;
}

.common_sortDropdown__3IYZw {
  width: 168px;
  height: 48px !important;
  border-radius: 8px !important;
}

.common_sortDropdownPopout__o8FX6 {
  width: 211px;
  height: auto;
  padding: 6px 0 !important;
}

.common_sortDropdownOptions__2gTuF {
  display: flex;
  flex-direction: column;
  margin: 0px 6px;
}

.common_sortDropdownOption__2zsGj {
  padding: 8px 12px;
  border-radius: 4px;
  display: flex;
  height: 32px;
  align-items: center;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #2c2e2f;
}

.common_sortDropdownOption__2zsGj > *:first-child {
  margin-right: 12px;
  min-width: 20px;
}

.common_sortDropdownOption__2zsGj:hover {
  background-color:#f9f9fa;
}
.common_sortDropdownOption__2zsGj[data-disabled="true"] {
  border: solid 1px easilGrayXLight;
  background-color: easilGrayXLight;
  color: easilGrayXLight;
  cursor: not-allowed;
}

.common_sortDropdownLabel__1nrTf {
  display: flex;
  align-items: center;
  width: 100%;
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #56595e;
}

.common_sortDropdownLabel__1nrTf > *:first-child {
  margin-right: 8px;
  min-width: 22px;
}

.common_sortFiltersWrapper__1KpfV {
  margin-top: 10px;
  padding: 0 14px 0 14px;
}

.common_filtersText__2PzHC {
  width: 33px;
  height: 20px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
}

.common_sortSmartImages__28lTP {
  width: 199px;
  height: 32px;
  display: flex;
  align-items: center;
  color: #2c2e2f;
}

.common_smartImageOnly__2O5pn { 
  margin-right: 20px;
}

.common_fileDropWrapper__ZKvXN {
  height: 100%;
  top: 0;
  left: 0;
  width: 100vw;
}

.common_imageListTitle__3mevr {
  width: calc(75vw - 262px - 88px - 24px);
}

.common_imageListTitle__3mevr > div:first-of-type {
  max-width: calc(75vw - 262px - 88px - 24px );
  width: calc(75vw - 262px - 88px - 24px );
}

.common_imageRowTitle__2Dr9t {
  width: calc(0.4 * (75vw - 226px - 88px));
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.common_imageRowRightWrapper__16UqU {
  display: flex;
  flex-direction: row;
}
.style_container__3TfF7 {
  align-items: center;
  color: #2c2e2f;
  display: flex;
  flex-direction: column;
  font-size: 15px;
  min-width: 725px;
  height: 100%;
}

.style_top__2F9Qd {
  align-items: center;
  background: white;
  box-shadow: 0 1px 0 0 rgba(28, 29, 30, 0.04);
  display: flex;
  height: 83px;
  min-height: 83px;
  margin-bottom: 40px;
  width: 100%;
}

.style_bottom__17VC6 {
  display: flex;
  width: 75%;
  height: 100%;
}

.style_bottom__17VC6 > .style_left__TM7vw {
  min-width: 262px;
}

.style_bottom__17VC6 > .style_right__3cSAd {
  flex: 1 1 auto;
  position: relative;
  width: 100vw;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  min-height: 100%;
  max-width: calc(75vw - 262px);
}

/* CONTAINER */

.style_searchContainer__1RmpJ {
  align-items: center;
  display: flex;
  margin: 0 auto;
  width: 75%;

}

.style_iconBoundingBox__6Vpqh {
  align-items: center;
  display: flex;
  height: 24px;
  justify-content: center;
  width: 24px;
  margin-right: 8px;
}

/* SPACER */

.style_searchSpacer__3SP_7 {
  border-left: 1px solid #E2E5E8;
  height: 24px;
  margin: 0px 4px 0px 4px;
}

/* TEXT BUTTON */

.style_searchTextButton__qWAPh {
  align-items: center;
  display: flex;
  height: 48px;
  padding: 12px 24px 12px 16px;
  width: -webkit-max-content !important;
  width: max-content !important;
}

.style_searchTextButton__qWAPh > svg {
  display: block;
  margin-right: 8px;
}

/* INPUT */

.style_searchInput__Ad8Lo {
  flex: 1 1 auto;
  margin-right: 8px;
  position: relative;
  height: 48px;
}

.style_searchInput__Ad8Lo > input {
  background: #eef0f1;
  border: none;
  border-radius: 8px;
  color: #2c2e2f;
  font-size: 16px;
  line-height: 24px;
  padding: 12px 12px 12px 48px;
  width: 100%;
}

.style_searchInput__Ad8Lo > input::-webkit-input-placeholder {
  color: #8f9295;
}

.style_searchInput__Ad8Lo > input:-ms-input-placeholder {
  color: #8f9295;
}

.style_searchInput__Ad8Lo > input::placeholder {
  color: #8f9295;
}

.style_searchInputIcon__1YmNo {
  left: 15px;
  outline: none;
  pointer-events: none;
  position: absolute;
  top: 15px;
}

.style_searchInputClear__2Ej6K {
  align-items: center;
  background: #2C6FCF;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  height: 28px;
  justify-content: center;
  position: absolute;
  right: 11px;
  top: 11px;
  width: 28px;
}

.style_searchInputClear__2Ej6K:hover {
  background: #3184fc;
}

.style_headerButton__2xRCZ {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: -webkit-max-content;
  width: max-content;
  height: 46px;
  padding: 12px 24px 12px 16px;
}

.style_headerButtonDisabled__2UMqE {
  color: #3184fc;
  cursor: default;
}

.style_iconBoundingBox__2S4XA {
  align-items: center;
  display: flex;
  height: 24px;
  justify-content: center;
  width: 24px;
  margin-right: 8px;
}

.style_uploadModalTo__1mVRR {
  width: 650px;
  height: 392px;
  border-radius: 8px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 32px;
}

.style_uploadModalDetails__35xJj {
  width: 650px;
  max-height: 526px;
  border-radius: 8px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 32px;
}

.style_transferModalDetails__3ELYm {
  width: 836px;
  height: 545px;
  max-height: none;
}

.style_transferDetailsModal__2Jra5 {
  width: 836px;
}

.style_transferUploadHeader__315TC {
  display: flex;
  align-items: center;
}
.style_transferUploadHeader__315TC > div:first-child {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.style_transferModalDetails__3ELYm > div:first-child {
  margin-bottom: 32px;
}

.style_uploadContent__2D1k5 {
  width: 594px;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  /* padding: 12px; */
}

.style_tansferContent__2d9kz {
  width: 100%;
}

.style_uploadContentSubText__sejaT {
  font-family: Roboto;
  font-size: 15px;
  line-height: 1.6;
  color: #57595d;
}

.style_uploadUploadingTo__6qtK1 {
  font-family: Roboto;
  font-size: 15px;
  line-height: 1.6;
  color: #7a7d81;
  margin-top: 24px;
}

.style_uploadLocation__16QHC {
  color: #57595d;
}

.style_uploadTable__RlxvL {
  width: 100%;
  max-height: 240px;
  overflow-y: auto;
  margin-top: 13px;
  margin-bottom: 32px;
}

.style_uploadError__2P6VV {
  margin: 0px 0px 10px 17px;
  color: #E64826
}

.style_uploadTableRow__Xasnm {
  height: 48px;
  width: 100%;
  border-top: 1px solid #e2e5e8;
  display: flex;
  align-items: center;
  padding-left: 16px;
}

.style_uploadImageIconWrapper__158Ws {
  width: 24px;
  height: 24px;
}

.style_uploadTableRow__Xasnm:last-of-type {
  border-bottom: 1px solid #e2e5e8;
}

.style_uploadRowLeft__1F-nS {
  display: flex;
  flex-direction: row;
  flex: 1 1;
}

.style_uploadRowRight__2U9x8 {
  display: flex;
  flex-direction: row;
  flex: 1 1;
  align-items: center;
  justify-content: flex-end;
  margin-right: 16px;
}

.style_uploadRowRight__2U9x8 > div {
  margin-right: 33px;
  display: flex;
}

.style_uploadRowRight__2U9x8:last-child {
  margin-right: 0px;
}

.style_selectButtons__IM29r > button {
  height: 40px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
}

.style_uploadButtons__kYIO3 {
  margin-top: 0px;
}

.style_uploadButtons__kYIO3 > button {
  height: 40px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
}

.style_uploadRowText__3Dfoa {
  font-family: Roboto;
  font-size: 15px;
  line-height: 1.6;
  color: #57595d;
  margin-left: 12px;
  width: 340px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* .folderSelectionContent {
  width: 586px;
  height: 200px;
  border-radius: 4px;
  border: solid 1px easilGrayXLight;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 12px;
  overflow-y: auto;
} */

/* .folderSelectionContentItem {
  width: 562px;
  min-height: 36px;
  height: 36px;
  border-radius: 4px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 6px;
  font-family: Roboto;
  font-size: 15px;
  line-height: 1.6;
  color: easilGrayXDark;
  cursor: pointer;
} */

/* .folderSelectionContentItem[data-is-selected="true"] {
  background-color: #e3f1ff;
}

.folderSelectionContentItem[data-is-sub="true"] {
  margin-left : 16px;
  width: 544px;
} */

/* .uploadFolderIconWrapper {
  height: 24px;
  width: 24px;
  margin-right: 12px;
} */

/* .folderSelectionContentTickWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding-top: 5px;
  margin-right: 12px;
  margin-left: auto;
} */

/* .folderSelectionFolderTitle {
  width: 100%;
  font-family: Roboto;
  font-size: 15px;
  line-height: 1.6;
  color: easilGrayXDark;
} */

.style_inputfile__2cA1U {
  display: none;
}

/* .uploadFolderName {
  width: 100%;
  max-width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
} */

.style_smartImageTransferContent__1KoIL {
  width: 100% !important;
  height: 361px !important;
}

.style_smartImageTransferContent__1KoIL > * {
  width: 100% !important;
}

.style_transferButtons__2xIFN {
  margin-top: 24px;
}
.style_folderSelectionContent__Ji2N3 {
  width: 586px;
  height: 200px;
  border-radius: 4px;
  border: solid 1px #D1D4D6;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 12px;
  overflow-y: auto;
}

.style_folderSelectionContentItem__3ZqYb {
  width: 562px;
  min-height: 36px;
  height: 36px;
  border-radius: 4px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 6px;
  font-family: Roboto;
  font-size: 15px;
  line-height: 1.6;
  color: #57595D;
  cursor: pointer;
}

.style_folderSelectionContentItem__3ZqYb[data-is-selected="true"] {
  background-color: #e3f1ff;
}

.style_folderSelectionContentItem__3ZqYb[data-is-sub="true"] {
  margin-left : 16px;
  width: 544px;
}

.style_uploadFolderIconWrapper__DEqos {
  height: 24px;
  width: 24px;
  margin-right: 12px;
}

.style_folderSelectionFolderTitle__1szE- {
  width: 100%;
  font-family: Roboto;
  font-size: 15px;
  line-height: 1.6;
  color: #57595D;
}

.style_folderSelectionContentTickWrapper__1S1Py {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding-top: 5px;
  margin-right: 12px;
  margin-left: auto;
}

.style_uploadFolderName__3OLGL {
  width: 100%;
  max-width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}


.style_headerButton__3LYkf {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: -webkit-max-content;
  width: max-content;
  height: 46px;
  padding: 12px 24px 12px 16px;
}

.style_headerButtonDisabled__k8ixe {
  color: #3184fc;
  cursor: default;
}

.style_iconBoundingBox__3UFWh {
  align-items: center;
  display: flex;
  height: 24px;
  justify-content: center;
  width: 24px;
  margin-right: 8px;
}

.style_uploadModalTo__M29xP {
  width: 650px;
  height: 392px;
  border-radius: 8px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 32px;
}

.style_uploadModalDetails__3d3rZ {
  width: 650px;
  max-height: 526px;
  border-radius: 8px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 32px;
}

.style_transferModalDetails__VfVke {
  width: 836px;
  height: 545px;
  max-height: none;
}

.style_transferDetailsModal__2Zy8j {
  width: 836px;
}

.style_transferUploadHeader__1MjPZ {
  display: flex;
  align-items: center;
}
.style_transferUploadHeader__1MjPZ > div:first-child {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.style_transferModalDetails__VfVke > div:first-child {
  margin-bottom: 32px;
}

.style_uploadContent__2tB9U {
  width: 594px;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  /* padding: 12px; */
}

.style_tansferContent__ZDbiL {
  width: 100%;
}

.style_uploadContentSubText__1ze1E {
  font-family: Roboto;
  font-size: 15px;
  line-height: 1.6;
  color: #57595d;
}

.style_uploadUploadingTo__2EVx- {
  font-family: Roboto;
  font-size: 15px;
  line-height: 1.6;
  color: #7a7d81;
  margin-top: 24px;
}

.style_uploadLocation__6G0yH {
  color: #57595d;
}

.style_uploadTable__Qe71m {
  width: 100%;
  max-height: 240px;
  overflow-y: auto;
  margin-top: 13px;
  margin-bottom: 32px;
}

.style_uploadError__2hHvL {
  margin: 0px 0px 10px 17px;
  color: #E64826
}

.style_uploadTableRow__90Rfk {
  height: 48px;
  width: 100%;
  border-top: 1px solid #e2e5e8;
  display: flex;
  align-items: center;
  padding-left: 16px;
}

.style_uploadImageIconWrapper__1ZOai {
  width: 24px;
  height: 24px;
}

.style_uploadTableRow__90Rfk:last-of-type {
  border-bottom: 1px solid #e2e5e8;
}

.style_uploadRowLeft__2dEql {
  display: flex;
  flex-direction: row;
  flex: 1 1;
}

.style_uploadRowRight__2--C2 {
  display: flex;
  flex-direction: row;
  flex: 1 1;
  align-items: center;
  justify-content: flex-end;
  margin-right: 16px;
}

.style_uploadRowRight__2--C2 > div {
  margin-right: 33px;
  display: flex;
}

.style_uploadRowRight__2--C2:last-child {
  margin-right: 0px;
}

.style_selectButtons__1WcPr > button {
  height: 40px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
}

.style_uploadButtons__IjlRO {
  margin-top: 0px;
}

.style_uploadButtons__IjlRO > button {
  height: 40px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
}

.style_uploadRowText__1IThU {
  font-family: Roboto;
  font-size: 15px;
  line-height: 1.6;
  color: #57595d;
  margin-left: 12px;
  width: 340px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.style_inputfile__1HE2J {
  display: none;
}

.style_smartImageTransferContent__rAIz5 {
  width: 100% !important;
  height: 361px !important;
}

.style_smartImageTransferContent__rAIz5 > * {
  width: 100% !important;
}

.style_transferButtons__26PZ1 {
  margin-top: 24px;
}
.style_transferSmartImageModal__1P9Ly {
  width: 836px;
  height: 545px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 8px;
}

/* hide render of transfer modal when upload modal is selected */
.style_uploadingTransferSmartAssetModal__2oQL3 {
  display: none;
}

.style_transferSmartImageModal__1P9Ly > div:first-of-type {
  margin-bottom: 24px;
}

.style_transferModalWrapper__20hOe {
}

.style_tansferScrollSection__zC0Xf {
  position: relative;
  bottom: 0;
  height: 100%;
}

.style_scrollbar__3mUf3 {
  background: #c5c8ca5d !important;
  right: 0;
}

.style_foldersSection__1AGao {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  bottom: 0;
  grid-gap: 0 8px;
  gap: 0 8px;
}

.style_folderButton__2CSMg {
  width: 252px;
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(21, 21, 22, 0.12);
  cursor: pointer;
  height: 64px;
  justify-content: flex-start !important;
  padding: 20px 22px;
  margin: 0 0 8px;
}

.style_folderButton__2CSMg:nth-child(n + 4) {
  margin-right: 0;
}

.style_folderButtonTitle__3E_M8 {
  margin-left: 22px;
  color: #2c2e2f;
}

.style_modalButtons__341Tj {
  margin-top: 24px;
}

.style_tranferRootPathClass__2B2QO {
  font-family: Roboto;
  font-size: 16px;
  line-height: 1.5;
  color: #8f9297;
}

.style_folderPathClass__2KAP0 {
  font-family: Roboto;
  font-size: 16px;
  line-height: 1.5;
  color: #2c2e2f;
}

.style_emptyGrid__3mY3z {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.style_emptyGrid__3mY3z > div:first-of-type {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.style_emptyGrid__3mY3z button {
  display: none;
}

.style_assetCards__2VHGr {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 0 8px;
  gap: 0 8px;
  align-items: center;
}

.style_topSection__1hHA- {
  display: flex;
}

.style_search__3vB8H {
  height: 48px;
  border-radius: 8px;
  width: 100%;
  z-index: 9;
}

.style_searchInput__1hi1K {
  height: 34px;
  line-height: 34px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.style_searchInput__1hi1K > input {
  font-family: Roboto;
  background-color: inherit;
  font-size: 16px;
  padding: 0px 12px 0px 12px;
}

.style_smartImageSuccessToast__1S6Ov {
  font-family: Roboto;
  font-size: 14px;
  line-height: 1.43;
  height: 92px
}

.style_smartImageSuccessToast__1S6Ov > div:first-child {
  margin: 0;
}

.style_smartImageSuccessToast__1S6Ov > div:first-child > div:first-child {
  height: 100%;
}
:root {
  --gif-canvas-width: 64px;
  --gif-canvas-height: 64px;
  --gif-canvas-left: 0px;
  --gif-canvas-top: 0px;
  --gif-div-height: auto;
  --gif-div-width: auto;
}

/* TITLES */

.style_contentTitle__LCngO,
.style_contentTitleForSearch__2MuW2 {
  font-size: 20px;
  margin-bottom: 40px;
}
.style_contentTitleForSearch__2MuW2 {
  color: #262f30;
}

/* BREADCRUMB */

.style_breadcrumb__THNsV {
  display: flex;
  font-size: 16px;
  margin: -16px 0 40px 0;
}

.style_breadcrumb__THNsV > a {
  border-bottom: 1px solid rgba(122, 125, 129, 0.16);
  color: #777d82;
}

.style_breadcrumbSpacer__3G_5b {
  align-items: center;
  display: flex;
  height: 24px;
  justify-content: center;
  margin: 0 8px;
  width: 24px;
}

.style_breadcrumbMenu__16GgS {
  position: relative;
}

.style_breadcrumbMenuDropDown__1T9V9 {
  cursor: pointer;
  display: flex;
}
.style_breadcrumbMenuDropDown__1T9V9:hover {
  border-bottom: 1px solid #afb3b6;
}
.style_breadcrumbMenuDropDown__1T9V9 > svg {
  margin: 6px 0 6px 10px;
}

/* WHITE ROW */

.style_whiteRow__wva_8 {
  background: white;
  border: 1px solid #E2E5E8;
  border-radius: 8px;
  margin-bottom: 12px;
  padding: 20px;
}

.style_whiteRowTop__3T8jG {
  display: flex;
  justify-content: space-between;
}

/* WHITE ROW - BUTTONS */

.style_whiteRowButtons__1u6tS {
  display: flex;
  position: relative;
}

.style_whiteRowButton__dJd5M {
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  height: 24px;
  justify-content: center;
  margin-left: 16px;
  width: 24px;
}
.style_whiteRowButton__dJd5M:hover {
  background: whitesmoke;
}

/* WHITE ROW, UPLOAD BUTTON (ERROR MSG.) */

.style_uploadButtonPrependError__1ghSL {
  display: flex;
  flex-direction: row-reverse;
}
.style_uploadButtonPrependError__1ghSL > div:nth-child(3) {
  font-weight: 500;
  line-height: 16px;
  margin: 0 8px 0 0;
  max-width: 87px;
}

/* IMAGE LIST */

.style_imageListTitles__lixXk {
  border-bottom: 1px solid rgba(226, 229, 232, 0.88);
  color: #8f9295;
  display: flex;
  font-size: 14px;
  margin-bottom: 16px;
  padding-bottom: 12px;
}

.style_imageList__2Hh9r {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}

.style_imageListFolderRow__wJB42,
.style_imageListRow__3CKfH {
  display: flex;
  
}

.style_imageListFolderRow__wJB42:hover {
  cursor: pointer;
}

.style_imageListRowRight__2nm4c {
  align-items: center;
  border-top: 1px solid rgba(226, 229, 232, 0.88);
  display: flex;
  flex: 1 1 auto;
  justify-content: space-between;
}
.style_imageListFolderRow__wJB42:first-child .style_imageListRowRight__2nm4c,
.style_imageListRow__3CKfH:first-child .style_imageListRowRight__2nm4c {
  border-top: none;
}

.style_imageListRowTitle__1WBDZ {
  flex: 1 1;
  font-weight: 500;
  max-width: 340px;
  margin-right: 10px
}
.style_imageListFolderRow__wJB42:hover .style_imageListRowTitle__1WBDZ {
  text-decoration: underline;
}

.style_imageListTile__XiAOS {
  width: 64px;
  height: 64px;
  background-color: #FEFEFE;
  background-image: linear-gradient(45deg, #DFDFDFBF 25%, #DFDFDF00 25%, #DFDFDF00 75%, #DFDFDFBF 75%, #DFDFDFBF), linear-gradient(45deg, #DFDFDFBF 25%, #DFDFDF00 25%, #DFDFDF00 75%, #DFDFDFBF 75%, #DFDFDFBF);
  background-size: 10px 10px;
  background-position: 0 0, 15px 15px;
  border-radius: 4px;
  margin-right: 24px;
  margin-bottom: 8px;
  margin-top: 8px;
}

.style_logoListRowImage__R85gi {
  background-color: #FEFEFE;
  background-position: center center;
  background-size: cover;
  border-radius: 4px;
  height: 64px;
  width: 64px;
  margin: 8px 24px 8px 0;
}

.style_logoListRowFolder__cPiyR {
  width: 64px;
  height: 64px;
  align-items: center;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.style_imageListWrapper__NXLTn {
  width: 64px;
  height: 64px;
  align-items: center;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.style_folderTile__HULB9 {
  width: 64px;
  height: 64px;
  background-color: white;
  margin-right: 24px;
  margin-bottom: 8px;
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(21, 21, 22, 0.12);
}

.style_imageListWrapper__NXLTn img {
  display: block;
  object-fit: cover;
  border-radius: 2px;
  position: relative;
  z-index: 1;
}

.style_imageListWrapper__NXLTn > div {
  height: auto;
  height: var(--gif-div-height);
  width: auto;
  width: var(--gif-div-width);
}


.style_imageListWrapper__NXLTn > div > canvas {
  width: 64px;
  width: var(--gif-canvas-width);
  height: 64px;
  height: var(--gif-canvas-height);
  left: 0px !important;
  left: var(--gif-canvas-left) !important;
  border-radius: 4px;
}

.style_imageListFolderRow__wJB42 .style_imageListRowImage__1NIe3 {
  align-items: center;
  box-shadow: 0 1px 2px 0 rgba(21, 21, 22, 0.12);
  background-color: #ffffff;
  display: flex;
  justify-content: center;
}

.style_folderTile__HULB9 {
  width: 64px;
  height: 64px;
  background-color: white;
  margin-right: 24px;
  margin-bottom: 8px;
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(21, 21, 22, 0.12);
}

.style_imageListRowColumn__l-ap3 {
  width: 150px;
}
.style_imageListRow__3CKfH .style_imageListRowColumn__l-ap3 {
  color: #616569;
}

.style_imageListRowMenu__1bgVQ {
  position: relative;
  width: 24px;
}

.style_imageListRowEllipse__3gahs {
  border-radius: 4px;
  cursor: pointer;
  display: flex;
}

.style_imageListRowEllipse__3gahs:hover {
  background: rgba(0, 0, 0, 0.1);
}

.style_imageListRowMenuPopout__38rBo {
  right: 0;
  top: 100%;
}

/* POPOUT MENU */

.style_popoutItemMenu__2aQSH {
  right: 0;
  top: 24px;
}

.style_brandKitAnimationsWrapper__2ETDZ {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  min-height: 100%;
  position: relative;
}

.style_brandAnimationsList__3f2k5 {
  width: 100%;
  margin-top: 40px !important;
  padding-top: 0px !important;
}

.style_brandAnimationsList__3f2k5 > div:first-of-type {
  padding-top: 0px;
}

.style_brandAnimationRow__kBwCv {
  width: 100%;
}

.style_brandAnimationRow__kBwCv > div:last-child {
  padding-right: 0px;
}

.style_brandAnimationRow__kBwCv > div:last-child > a {
  width: 340px;
}

.style_animationsUploadButtonWrapper__EU6Op {
  display: flex;
  align-items: center;
}

.style_brandAnimationsUploadButton__2G84I {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 46px;
  padding: 12px 24px 12px 16px;
}

.style_brandAnimationBreadCrumb__MlcKf {
  width: 762px;
  padding-top: 0px !important;
  margin-bottom: 40px !important;
  z-index: 2;
}

.style_brandAnimationFolderRow__11-G7 > div:last-child {
  padding-right: 0px;
}

.style_searchResultsTitle__3p6oQ {
  display: block;
  width: auto;
  margin-bottom: 20px;
}

.style_searchResultsTitle__3p6oQ > div:first-of-type {
  margin-top: 5px;
}

.style_addSmartTextModal__2RDKe {
  width: 450px;
  height: 352px;
}

.style_updateSmartTextModal__32qll {
  width: 450px;
  height: 440px;
}
.style_modalFields__G5FHE {
  margin-bottom: 32px;
}
.style_modalInputRow__2F5Iz {
  margin-bottom: 16px;
}
.style_modalInputWrapper__3d_N5 {
  border-radius: 4px;
}
.style_modalInput__23-li {
  font-weight: 500;
}
.style_modalInput__23-li::-webkit-input-placeholder {
  font-weight: normal;
}
.style_modalInput__23-li:-ms-input-placeholder {
  font-weight: normal;
}
.style_modalInput__23-li::placeholder {
  font-weight: normal;
}
.style_modalButtons__3YFHc {
  margin-top: 32px;
}
.style_info__AuFcP {
  margin-bottom: 24px;
  font-size: 14px;
}
.style_infoIcon__25f3i {
  margin-right: 12px;
}

.style_smartTextIdLabel__1gJ-l {
  display: flex;
  justify-content: space-between;
}

.style_tooltip__37wrh {
  margin-top: 0px;
}
.style_div__1rRmS {
  position: relative;
  border: 1px solid #D1D4D6;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  border-radius: 4px;
}

.style_div__1rRmS[data-disabled="true"] {
  background-color: #FBFBFC;
  cursor: not-allowed;
}

.style_div__1rRmS input[disabled] {
  background-color: #FBFBFC;
  color: #AAAAAA;
  cursor: not-allowed;
}

.style_div__1rRmS input {
  background-color: inherit;
  padding: 0px 12px 0px 12px;
  font-size: 15px;
  line-height: 24px;
  color: #2c2e2f;
  width: 100%;
  border: none;
  border-radius: inherit;
  height: 100%;
  vertical-align: top;
}

.style_div__1rRmS input:focus {
  outline: none;
}

.style_div__1rRmS[data-error="true"] {
  border: 1px solid #E64826;
}

.style_label__3dR-G {
  color: #2c2e2f;
  font-size: 13px;
  line-height: 1.85;
  text-align: left;
  padding-bottom: 9px;
}

.style_error__1T5Nk {
  position: absolute;
  color: #E64826;
  font-size: 12px;
  top: 100%;
  left: 5px;
}

.style_clearCross__SEHWs {
  cursor: pointer;
  background-color: #3184fc;
  height: 28px;
  width: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}

.style_alertToolTip__2sgOF {
  font-size: 12px;
  width: 226px;
}
/* ADD BUTTON */

.style_addButtonContainer__3UP0h {
  cursor: pointer;
  display: inline-block;
  margin-bottom: 24px;
}

.style_addButton__1a7_T {
  align-items: center;
  display: flex;
}

.style_addButtonPlus__3PDC8 {
  align-items: center;
  background: white;
  border: 1px dashed #D1D4D6;
  border-radius: 32px;
  display: flex;
  height: 64px;
  justify-content: center;
  transition: -webkit-text-decoration 0.2s;
  transition: text-decoration 0.2s;
  transition: text-decoration 0.2s, -webkit-text-decoration 0.2s;
  width: 64px;
}

.style_addButton__1a7_T:hover .style_addButtonText__1WfJj {
  text-decoration: underline;
}

.style_addButtonText__1WfJj {
  font-weight: 500;
  margin: 0 12px;
}

/* COLOR GROUP */

.style_colorsGroupName__3PiSm {
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  height: 32px;
  margin: -6px 0 6px -6px;
  padding: 0 6px;
}
.style_colorsGroupName__3PiSm > span {
  max-width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.style_colorsGroupName__3PiSm > svg {
  margin-left: 16px;
}
.style_colorsGroupName__3PiSm:hover {
  background: whitesmoke;
}

.style_colorsGroupRename__lBuMS {
  align-items: center;
  display: flex;
  margin: -6px 0 6px -6px;
}
.style_colorsGroupRename__lBuMS > input {
  border: 1px solid #E2E5E8;
  border-radius: 4px;
  line-height: 24px;
  margin-right: 6px;
  padding: 3px 6px;
}
.style_colorsGroupRename__lBuMS > input:focus {
  outline: none;
}
.style_colorsGroupRenameButton__C2-UG {
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  height: 32px;
  justify-content: center;
  width: 32px;
}
.style_colorsGroupRenameButton__C2-UG:hover {
  background: whitesmoke;
}

.style_colorsGroupColors__2DasG {
  display: flex;
  flex-wrap: wrap;
  margin: -6px;
}
.style_colorsGroupColors__2DasG > div {
  margin: 6px;
}

/* COLOR (IN GROUP) */

.style_colorsGroupColor__27oU6 {
  position: relative;
}

.style_colorsGroupColorCircle__1dcrA {
  border-radius: 32px;
  cursor: pointer;
  height: 64px;
  width: 64px;
}
.style_colorsGroupColorCircle__1dcrA[data-is-white="true"] {
  border: 1px solid #E2E5E8;
}

.style_colorsGroupColorPicker__3BIzq {
  margin-left: -118px;
  position: absolute;
  top: 100%;
  z-index: 1;
}

.style_colorsGroupColorRemove__2Xe6R {
  align-items: center;
  background: white;
  border: 1px solid #E2E5E8;
  border-radius: 12px;
  box-shadow: 0 2px rgba(0, 0, 0, 0.1);
  display: none;
  height: 24px;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  width: 24px;
}
.style_colorsGroupColorRemove__2Xe6R:hover {
  border: 1px solid #ccc;
  box-shadow: 0 2px rgba(0, 0, 0, 0.2);
}
.style_colorsGroupColor__27oU6:hover .style_colorsGroupColorRemove__2Xe6R {
  display: flex;
}

.style_popoutMenu__Sbg7x {
  width: 150px;
}

.style_popoutMenu__Sbg7x > div {
  width: 100%;
}


.style_empty__OW84t {
  margin: 50px 0;
  text-align: center;
}

.style_emptyIcon__JV5fF {
  display: inline-block;
  margin-bottom: 23px;
}

.style_emptyTitle__O67QA {
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 16px;
}

.style_emptySubtitle__30YLz {
  color: #57595D;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 24px;
  white-space:pre;
}

.style_emptyButton__19t7w {
  display: inline-block;
}

.style_preview__2Wo0q {
  border-bottom: 1px solid #e6e8ea;
  color: #2c2e2f;
  font-size: 36px;
  padding-bottom: 24px;
  display: flex;
  flex-direction: column;
}

.style_headerSubtitle__3KAO3 {
  height: 20px;
  font-family: Roboto;
  font-size: 14px;
  line-height: 1.43;
  color: #888888;
}

.style_title__1KQj- {
  max-width: 505px;
  overflow: hidden;
  text-overflow: ellipsis;
}
:root {
  --delta-scroll-left: 0px;
  --animation-duration: 7s;
}

.style_scrollingTextWrapper__1_mnX {
  width: 100%;
  display: inline-block; /* important */
  white-space: nowrap;
  overflow: hidden;
}

/* when not hovering show ellipsis */
.style_scrollingTextWrapper__1_mnX:not(:hover) {
  text-overflow: ellipsis;
}

/* animate on either hover or focus */
.style_scrollingTextWrapper__1_mnX:hover span {
  display: inline-block;
  -webkit-animation: style_scroll-text__1L3YE 7s;
          animation: style_scroll-text__1L3YE 7s;
  -webkit-animation: style_scroll-text__1L3YE var(--animation-duration);
          animation: style_scroll-text__1L3YE var(--animation-duration);
}

/* define the animation */
@-webkit-keyframes style_scroll-text__1L3YE {
  0% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
  75% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
    -webkit-transform: translateX(var(--delta-scroll-left, 0px));
            transform: translateX(var(--delta-scroll-left, 0px));
  }
  95% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
    -webkit-transform: translateX(var(--delta-scroll-left, 0px));
            transform: translateX(var(--delta-scroll-left, 0px));
  }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
}
@keyframes style_scroll-text__1L3YE {
  0% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
  75% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
    -webkit-transform: translateX(var(--delta-scroll-left, 0px));
            transform: translateX(var(--delta-scroll-left, 0px));
  }
  95% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
    -webkit-transform: translateX(var(--delta-scroll-left, 0px));
            transform: translateX(var(--delta-scroll-left, 0px));
  }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
}
.style_fontDescritpionSection__M6znk {
  margin: 24px 0px 32px 0px;
  padding-bottom: 32px;
  border-bottom: 1px solid #e6e8ea;
}

.style_inputAndToggle__2OWmQ {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.style_heading__1pHcN {
  color: #57595D;
  display: block;
  margin-bottom: 8px;
}

.style_toggleSection__1q89v {
  display: flex;
  justify-content: space-between;
  width: 32%;
  align-items: center;
}

.style_inputField__2CpQy {
  width: 63%
}

.style_toggleCheckBox__1UhCj span{
  background-color: #DDE1E3;
}

.style_settings__33Mz0 {
  display: flex;
  margin: 24px 0;
}

.style_settingsFontFamily__mcWZz {
  flex: 0 1 100%;
  z-index: 4;
}

.style_heading__3Wj12 {
  color: easilGrayXDark;
  display: block;
  margin-bottom: 8px;
}

.style_dropdown__2wEVR input {
  color: #2c2e2f !important;
}

.style_upload__3yHjb {
  margin-top: 32px;
}

.style_fontDropZone__3gd8w {
  color: #2c2e2f;
  margin-bottom: 16px;
}

.style_checkbox__3X5Pg {
  top: 2px;
}
.style_fontModalDivider__21G4Y {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 13px;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 1px;
  color: #1c1d1e;
}

.style_fontModalDividerLine__n_vRK:before {
  opacity: 1;
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  border-top: 1px solid #e6e8ea;
  width: 100%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  z-index: 2;
}

.style_fontModalDividerText__24Pps {
  background-color: white;
  z-index: 3;
  padding: 16px;
}

.style_fontModal__ihXLw {
  width: 552px;
  max-height: 100vh;
  overflow-y: auto;
}

.style_userTeamFontModal__1Y-Gl {
  width: 552px;
  max-height: 100vh;
  overflow-y: auto;
  border-radius: 8px;
}

.style_userTeamFontModal__1Y-Gl > div[class*="header"] {
  margin-bottom: 20px;
}

.style_fontModalHeader__1qDJ8 {
  color: #57595d;
  font-family: Roboto;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.style_heading__riIPP {
  color: easilGrayXDark;
  display: block;
  margin-bottom: 8px;
}

.style_headingUppercase__2j_6x {
  color: #57595d;
  font-family: Roboto;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.style_brandFont__JOEsl {
  display: flex;
  flex-wrap: wrap;
  margin-top: 32px;
}

.style_brandFont__JOEsl .style_heading__riIPP {
  flex: 1 1 100%;
  margin-bottom: 24px;
}

.style_brandFont__JOEsl span {
  margin-left: 8px;
}


.style_brandFontButton__3ZXkn {
  align-content: center;
  cursor: pointer;
  display: flex;
}

.style_brandFontButton__3ZXkn span {
  line-height: 24px;
}


.style_settings__3Axb2 {
  display: flex;
  margin: 24px 0;
}


.style_settingsFontFamily__t09RA {
  flex: 0 1 100%;
}

.style_settingsFontStyles__2yrsl {
  display: flex;
  flex: 0 1 136px;
  flex-wrap: wrap;
}

.style_settingsFontStyles__2yrsl label {
  flex: 1 1 100%;
}

.style_overflowText__33_ab {
  max-width: 640px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.style_teamFontName__2F2Ww {
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 8px;

}

.style_fontFamily__22xaB {
  color: #8f9295;
}

.style_smartImageLabel__29ddj {
  padding-right: 4px !important;
  width: auto;
  height: 24px;
  margin: 0 0 2px 0;
  padding: 3px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  background-color: #e5e8eb;
}

.style_smartImageModal__3KAUy {
  width: 450px;
  height: auto;
  padding: 24px;
  border-radius: 8px;
  background-color: #fff;
}

.style_smartImageModalHeader__tJGG- {
  font-family: Roboto;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2c2e2f;
}

.style_contentWrapper__2cYiE {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.style_modalImageWrapper__24Hu4 {
  margin-bottom: 26px;
}

.style_modalImage__90DCA {
  width: 100%;
  height: 100%;
  max-width: 386px;
  max-height: 300px;
  border-radius: 10px;
  background-color: #eeeeee;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.style_modalButtons__1feHg {
  margin-top: 24px;
}

.style_modalForm__Nanlv {
  width: 100%;
}

.style_modalFormLabel__2I8iw {
  margin-bottom: 8px;
}

.style_modalFormInput__FWAdT {
  border-radius: 4px;
  margin-right: 0;
  line-height: 40px;
}

.style_modalFormField__3gVTq {
  display: flex;
  width: 100%;
}

.style_modalFormFieldCreateFolderNameInput__1ycT4 {
  max-width: 300px;
}

.style_deleteSmartImageModal__1KxX- {
  width: 550px;
}

.style_deleteSmartImageModal__1KxX- > div:first-of-type {
  margin-bottom: 24px;
}

.style_transferIdButton__Z_MYX {
  display: flex;
  justify-content: center;
  align-items: center;
}

.style_transferIcon__1xvtf {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
}

.style_userPrepend__SAZPl {
  border-radius: 4px 0px 0px 4px;
  border: 1px solid #d1d4d6;
  border-right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 8px 8px 12px;
  color: #2c2e2f;
  font-size: 14px;
  font-weight: 500;
}

.style_modal__1dZdi {
  width: 550px !important;
  height: 256px !important;
}

/* modal cross */
.style_modal__1dZdi > :nth-child(1) > :nth-child(2) {
  margin-top: -9px;
  margin-right: -9px;
}

/* modal text */
.style_modal__1dZdi > :nth-child(2) > div > div {
  font-family: Roboto;
  font-size: 16px;
  line-height: 1.5;
  color: #57595d;
}

.style_popout__-rEgE {
  right: 0;
  margin-right: 16px;
  top: 48px;
}

.style_folderPopoutActions__1ge91 {
  padding: 12px 8px;
}

.style_sharedFolderTitle__394hX {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.style_sharedFolderTitle__394hX > svg {
  margin-right: 8px;
}

.style_sharedTitle__zX5FD {
  margin-left: 16px;
}
.style_masonryWrapper__3v0ff {
  position: relative;
  width: 75%;
  margin: 40px auto auto auto;
}

.style_grid__2FHGF {
  width: 100%;
}

.style_gridSizer__2fz5W {
  width: 184.5px;
  width: var(--masonry-design-card-width, 184.5px);
}

.style_gutterSizer__1efUQ {
  width: 20.5px;
}

.style_item__143Ql {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
  width: 184.5px;
  width: var(--masonry-design-card-width, 184.5px);
  margin-bottom: 20.5px;
  overflow: visible;
}

.style_item__143Ql:hover {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
}

.style_landscapeGridSizer__2IQ6j {
  width: 328px;
  width: var(--masonry-design-card-width, 328px);
}

.style_landscapeGutterSizer__1PAij {
  width: 10.25px;
}

.style_itemLandscape__3JktX {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
  overflow: visible;
  width: 328px;
  width: var(--masonry-design-card-width, 328px);
  margin-bottom: 10.25px;
}

.style_item__143Ql > img {
  width: 100%;
}

.style_cardBottom__s4Syy {
  height: 72px;
  padding: 0px 12px 0px 12px;
}

.style_cardBottomRow__2WxuA {
  display: flex;
  align-items: center;
  position: relative;
}

.style_popout__1Wwt7 {
  position: absolute;
  width: 300px;
  bottom: 25px;
  z-index: 1;
  border: solid 1px #e4e4e4;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
}

.style_designTitle__IINaP {
  color: #2c2e2f;
  margin-bottom: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.style_caretWrapper__3Giuj {
  display: flex;
}

.style_image__2PjBc {
  border-radius: 2px 2px 0px 0px;
}

.style_popoutContentTop__mZa4E {
  border-bottom: 1px solid lightgray;
  padding: 16px;
}

.style_popoutContentTop1Row__3a5pe {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 11px;
}

.style_popoutContentTop1Row__3a5pe > div:first-child {
  padding-right: 10px;
  font-size: 15px;
  color: #2c2e2f;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.style_popoutContentTop1RowEditting__2E7Sx {
  align-items: center;
  display: flex;
  margin-bottom: 8px;
}

.style_popoutContentTop1RowEditting__2E7Sx > svg:last-child {
  margin-left: 12px;
}

.style_editDesignName__q8Lph {
  height: 36px;
  margin-right: 16px;
}

.style_popoutContentTop__mZa4E > div + div {
  font-size: 13px;
  color: #8f9295;
  margin-bottom: 0px;
}

.style_popoutContentMiddle__1gCvB {
  border-bottom: 1px solid lightgray;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 16px 0px 16px 0px;
}

.style_popoutContentMiddle__1gCvB > ul > li {
  display: flex;
  align-items: center;
  line-height: 1.71;
  color: #57595D;
  margin-bottom: 10px;
  padding: 0px 16px 0px 16px;
  cursor: pointer;
}

.style_popoutContentMiddle__1gCvB > ul > li:last-child {
  margin-bottom: 0px;
}

.style_popoutContentMiddle__1gCvB > ul > li:hover {
  background-color: #f5f5f6;
}

.style_popoutContentMiddle__1gCvB > ul > li > div {
  padding-left: 12px;
}

.style_popoutContentBottom__2H95N {
  border-bottom: 1px solid lightgray;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 16px;
}

.style_topRowLeftItem__lvimG {
  margin-left: 32px;
}

.style_folderMenu__vqZPi {
  margin-top: 60px;
}

.style_folderMenuWrapper__aINcA {
  position: relative;
}

.style_folderMenuLabelWrapper__3i5n3 {
  align-items: center;
  cursor: pointer;
  display: flex;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.style_folderMenuLabel__U9O-w {
  color: #2c2e2f;
  font-size: 15px;
  margin: 0 12px 0 8px;
}

.style_folderMenuDropdown__1y9BU {
  background-color: #fff;
  border: 1px solid #D1D4D6;
  border-radius: 4px;
  left: 0;
  padding: 16px;
  position: absolute;
  top: 40px;
  z-index: 2;
}

.style_folderMenuDropdownOption__2Lclh {
  color: #2c2e2f;
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.style_emptyStateContainer__1gHcd {
  display: flex;
  align-items: center;
  min-height: 300px;
  padding-top: 156px;
  flex-direction: column;
  padding-bottom: 206px;
}

.style_emptyStateIcons__118zI {
  margin-bottom: 32px;
}

.style_emptyStateTitle__V8X2b {
  font-size: 24px;
  margin-bottom: 16px;
  color: #2c2e2f;
}

.style_emptyStateDescription__3Deks {
  white-space: pre-line;
  text-align: center;
  font-size: 15px;
  margin-bottom: 24px;
}

.style_imagesEmptyContainer__2qN60 {
  padding-top: 74px;
}

.style_smartImageEmptyContainer__3i1qV {
  padding: 0;
  min-height: 0;
}

.style_brandAnimationsEmptyContainer__28eiS {
  position: relative;
  padding-top: 20px;
}

.style_designsPlaceholder__om2b_ {
  min-height: 1000px;
}
.style_assetCard__3QuZj {
  display: flex;
  width: 252px;
  height: 64px;
  padding: 12px;
  cursor: pointer;
  margin-bottom: 8px;
  border-radius: 8px;
  background-color: white;
  transition: background-color 300ms ease-out;
  transition: border 300ms ease-out;
  border: solid 1px white;
}

.style_assetCard__3QuZj:hover[data-is-disabled="false"] {
  background-color: #f5f5f8;
}

.style_assetCard__3QuZj[data-is-selected="true"] {
  border: solid 1px #a1c6ff;
  background-color: #ecf5ff;
}

.style_assetCard__3QuZj[data-is-disabled="true"] {
  opacity: 0.3;
  cursor: pointer;
  cursor: unset;
}

.style_asset__3AedD {
  border-radius: 8px;
  margin-right: 12px;
}

.style_asset__3AedD > img {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  display: block;
  object-fit: cover;
  position: relative;
  z-index: 1;
}

.style_assetInfo__1LwX0 {
  display: flex;
  flex-direction: column;
  height: 40px;
}

.style_assetName__1O7xG {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  color: #57595d;
  max-width: 176px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.style_smartAssetName__V-lgT {
  margin-left: 4px;
}

.style_smartAssetLabel__JovyB {
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.5;
  color: #2c2e2f;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 3px 8px;
  border-radius: 4px;
  background-color: #e5e8eb;
  max-width: 176px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.style_info__2hEMC {}
.style_dropWrapper__3pNZh {
  display: inline-block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
  pointer-events: all;
}

.style_dropWrapper__3pNZh[data-is-passthrough="true"] {
  pointer-events: none;
}

.style_draggingOverlay__3qCpf {
  background-color: #0000001F;
  position: absolute;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.style_draggingOverlayEditor__zkrz1 {
  position: absolute;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.style_draggingOverlayText__31r9k {
  text-align: center;
  color: #303134;
  font-size: 11px;
  width: 138px;
  height: 66px;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(28, 29, 30, 0.04);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.style_optionWrapper__1WHrx {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 24px;
  margin: 0 8px 0 8px;
  padding: 3px 8px 3px 8px;
  border-radius: 4px;
  background-color: #e5e8eb;
}
.style_option__BzRmL {
  width: 100%;
  height: 18px;
  margin: 0 8px 0 0;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #2c2e2f;
}
.style_icon__3LmCf {
  display: flex;
  align-items: center;
  justify-content: center;
}
:root {
  --gif-canvas-width: 64px;
  --gif-canvas-height: 64px;
  --gif-canvas-left: 0px;
  --gif-canvas-top: 0px;
  --gif-div-height: auto;
  --gif-div-width: auto;
}

.style_imageListRowGutters__2ei1h {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  flex-direction: column;
  position: relative;
}

.style_imageList__183DW {
  width: 100%;
  min-height: 500px;
  height: 100%;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 20px 0px 20px 0px;
  margin-bottom: 40px;
  position: relative;
}

.style_titleRow__1iK3u {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 24px;
  border-bottom: 1px solid rgba(226, 229, 232, 0.88);
  padding-bottom: 12px;
  padding-top: 20px;
  font-family: Roboto;
  font-size: 14px;
  line-height: 1.43;
  color: #8f9294;
}

.style_titleRowRight__3Dpsf {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 22px;
}

.style_titleRowRightColumn__22Fkm {
  display: flex;
  align-items: center;
  width: 85px;
  font-size: 14px;
  line-height: 1.43;
  color: #8f9294;
  justify-content: flex-start;
  width: 150px;
}

.style_titleRowLeft__3SgrG {
  margin-right: 24px;
  display: flex;
  align-items: center;
}

.style_imageRow__324jE {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}

.style_folderRow__2D2i_ {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  cursor: pointer;
  position: relative;
  width: 100%;
}

.style_folderRow__2D2i_:hover {
  text-decoration: underline;
}

.style_imageRowRight__27eNV {
  min-width: calc(75vw - 262px - 412px);
  border-bottom: 1px solid rgba(226, 229, 232, 0.88);
  width: 100%;
  height: 72px; /* 64 height plus 8px offset for border bottom */
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  justify-content: space-between;
  padding-right: 10px;
  max-width: 100%;
}

.style_imageRowRight__27eNV[data-is-last="true"] {
  border-bottom: none;
}

.style_imageDetails__1bvld {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 1025px) {
  .style_imageDetailsColumn__1k9mt:nth-of-type(1) {
    width: 75px !important;
    min-width: 75px !important;
  }
  .style_imageDetailsColumn__1k9mt:nth-of-type(2) {
    width: 100px !important;
    min-width: 100px !important;
  }

  .style_titleRowRightColumn__22Fkm:nth-of-type(1) {
    width: 75px !important;
    min-width: 75px !important;
  }
  .style_titleRowRightColumn__22Fkm:nth-of-type(2) {
    width: 100px !important;
    min-width: 100px !important;
  }
}

@media screen and (max-width: 880px) {
  .style_imageDetailsColumn__1k9mt:nth-of-type(1) {
    display: none;
  }
  .style_titleRowRightColumn__22Fkm:nth-of-type(1) {
    display: none;
  }
}

@media screen and (max-width: 750px) {
  .style_imageDetailsColumn__1k9mt:nth-of-type(2) {
    display: none;
  }
  .style_titleRowRightColumn__22Fkm:nth-of-type(2) {
    display: none;
  }
}

.style_imageDetailsColumn__1k9mt {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 150px;
  min-width: 150px;
}

.style_imageDetailsColumn__1k9mt > p {
  margin-right: 80px;
  max-width: 100px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.style_folderTile__1pGK- {
  width: 64px;
  height: 64px;
  background-color: white;
  margin-right: 24px;
  margin-bottom: 8px;
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(21, 21, 22, 0.12);
}

.style_imageTile__1Ie6b {
  position: relative;
  width: 64px;
  height: 64px;
  background-color: #FEFEFE;
  background-image: linear-gradient(45deg, #DFDFDFBF 25%, #DFDFDF00 25%, #DFDFDF00 75%, #DFDFDFBF 75%, #DFDFDFBF), linear-gradient(45deg, #DFDFDFBF 25%, #DFDFDF00 25%, #DFDFDF00 75%, #DFDFDFBF 75%, #DFDFDFBF);
  background-size: 10px 10px;
  background-position: 0 0, 15px 15px;
  border-radius: 4px;
  margin-right: 24px;
  margin-bottom: 8px;
}

.style_imageWrapper__1TO-p {
  width: 64px;
  height: 64px;
  align-items: center;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.style_imageWrapper__1TO-p img, .style_imageWrapper__1TO-p video {
  display: block;
  object-fit: cover;
  border-radius: 2px;
  position: relative;
  z-index: 1;
}

.style_imageTitle__3am84 {
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.6;
  color: #2c2e2f;
  padding-right: 10px;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  display: flex;
  min-width: 50px;
}

.style_folderTitle__h0j8T {
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.6;
  color: #2c2e2f;
  padding-bottom: 8px;
}

.style_imageRowPopoutCaretWrapper__2JDGX {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.style_imageRowPopoutCaret__3w0eH {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex: 1 0 auto;
}

.style_imageRowPopoutCaret__3w0eH:hover {
  opacity: 0.8;
}

.style_menu__1C7fJ {
  display: flex;
  margin-left: auto;
  border-radius: 4px;
  padding-bottom: 8px;
}

.style_menu__1C7fJ:hover > div > svg rect {
  fill: #E2E5E8 !important;
}

.style_menu__1C7fJ:hover > div > svg circle {
  fill: #57595D !important;
}

.style_crumbWrapper__2LiGb {
  width: 1025px;
  padding-top: 40px;
  padding-bottom: 0px;
  margin-bottom: -20px;
  height: 100%;
  display: flex;
  z-index: 5;
}

.style_loader__1Pmly {
  margin-top: 80px;
}

.style_popout__2h6l0 {
  left: -330px;
  bottom: 24px;
}

.style_folderPopout__izg44 {
  left: -350px;
  bottom: 20px;
  position: absolute;
}

.style_nameSection__1NrK_ {
  min-height: 64px;
  height: auto;
}

.style_nameBox__2aE5X {
  overflow-wrap: break-word;
  min-height: 36px;
  height: auto;
}

.style_nameBox__2aE5X > div {
  text-overflow: initial;
}

.style_resultsTitleGutters__17E_0 {
  width: 100vw;
  display: flex;
  justify-items: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: -16px;
}

.style_resultsTitle__2QsEX {
  width: 1025px;
  margin-top: 40px;
  font-size: 20px;
  line-height: 1.2;
  color: #2c2e2f;
}

.style_resultsTitle__2QsEX > span {
  font-weight: 500;
}

.style_imageOverlay__QgLM1 {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--image-result-card-border-radius);
  z-index: 3;
  width: 100%;
  height: 100%;
}

.style_imageOverlay__QgLM1 > div:first-child {
  background-color: #0f0f10;
  opacity: 0.2;
  width: 100%;
  height: 100%;
  position: absolute;
  pointer-events: none;
  border-radius: var(--image-result-card-border-radius);
}

.style_imageListRowEllipse__1sOqV {
  border-radius: 4px;
  cursor: pointer;
  display: flex;
}

.style_imageListRowEllipse__1sOqV:hover {
  background: #E2E5E8;
  opacity: 1;
}

.style_multiSelectCheckbox__sU_XQ {
  width: 16px;
  height: 16px;
  position: absolute;
  top: calc(50% - 16px / 2);
  margin-left: calc(-22px + -16px);
}

.style_contentTitleForSearch__UZoNu {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  color: #262f30;
  height: 24px;
  font-family: Roboto;
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  color: #57595d;
}
.style_grid__3Ls2K {
  width: 100%;
}

.style_gridSizer__5ZEfg {
  width: 22%;
}

.style_gutterSizer__3r-lz {
  width: 4%;
}

.style_item__3znAa {
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
  width: 22%;
  margin-bottom: 4%;
  overflow: visible;
}

.style_item__3znAa > img {
  width: 100%;
}

.style_cardBottom__KCJgX {
  height: 72px;
  padding: 0px 12px 0px 12px;
}

.style_cardBottomRow__2ad-C {
  display: flex;
  align-items: center;
  position: relative;
}

.style_popout__jKz4R {
  position: absolute;
  width: 300px;
  /* we need to set bottom so all popouts are starting from the same position */
  bottom: 0px;
  z-index: 10;
  border: solid 1px #e4e4e4;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
}

.style_designTitle__c4Nf2 {
  color: #2c2e2f;
  margin-bottom: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.style_caretWrapper__3yr9g {
  display: flex;
}

.style_caretOuterWrapFullFill__25jGZ {
  flex: 0.25 0 auto;
}

.style_image__2oD34 {
  border-radius: 2px 2px 0px 0px;
}

.style_popoutContentTop__3207t {
  border-bottom: 1px solid lightgray;
  padding: 16px;
}

.style_popoutContentTop1Row__1Hb0A {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 11px;
}

.style_popoutContentTop1Row__1Hb0A > div:first-child {
  padding-right: 10px;
  font-size: 15px;
  color: #2c2e2f;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.style_popoutContentTop1RowEditting__v7nJV {
  align-items: center;
  display: flex;
  margin-bottom: 8px;
}

.style_popoutContentTop1RowEditting__v7nJV > svg:last-child {
  margin-left: 12px;
}

.style_editDesignName__3v9qp {
  height: 36px;
  margin-right: 16px;
}

.style_popoutContentTop__3207t > div + div {
  font-size: 13px;
  color: #8f9295;
  margin-bottom: 0px;
}

.style_popoutContentMiddle__CvKl1 {
  border-bottom: 1px solid lightgray;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 16px 0px 16px 0px;
}

.style_popoutContentMiddle__CvKl1 > ul > li {
  display: flex;
  align-items: center;
  line-height: 1.71;
  color: #57595D;
  margin-bottom: 10px;
  padding: 0px 16px 0px 16px;
  cursor: pointer;
}

.style_popoutContentMiddle__CvKl1 > ul > li:last-child {
  margin-bottom: 0px;
}

.style_popoutContentMiddle__CvKl1 > ul > li:hover {
  background-color: #f5f5f6;
}

.style_popoutContentMiddle__CvKl1 > ul > li > div {
  padding-left: 12px;
}

.style_popoutContentBottom__1YDqN {
  border-bottom: 1px solid lightgray;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 16px;
}

.style_more__1d4ql {
  text-align: center;
  cursor: pointer;
  border: 1px solid lightgray;
  padding: 9px;
  border-radius: 4px;
}

.style_topRowLeftItem__5FJE2 {
  margin-left: 32px;
}

@media screen and (min-width: 900px) {
  /* 5 columns for larger screens */
  .style_gridSizer__5ZEfg {
    width: 18%;
  }

  .style_gutterSizer__3r-lz {
    width: 2%;
  }

  .style_item__3znAa {
    width: 18%;
    margin-bottom: 2%;
  }
}

.style_fileDropWrapper__25qay > div {
  align-items: unset;
}

.style_fileDropWrapper__25qay {
  height: 100%;
  top: 0;
  left: 0;
  width: 100vw;
}
.style_brandKitSmartTextWrapper__29yFb {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  min-height: 100%;
  position: relative;
}

.style_contentTitle__3Ic8o,
.style_contentTitleForSearch__21KVF {
  font-size: 20px;
  margin-bottom: 40px;
}

.style_contentTitleForSearch__21KVF {
  color: #262f30;
}

.style_info__3ggC- {
  margin-top: 24px;
  margin-bottom: 24px;
}

.style_infoText__3MGwL {
  display: flex;
  justify-content: center;
  align-items: center;
}

.style_deleteConfirmationModal__ZKuxT {
  width: 550px;
  height: 216px;
  border-radius: 8px;
}

.style_deleteConfirmationModalButtons__1nYUE {
  margin-top: 32px;
}


.style_overflowText__HlhML {
  max-width: 640px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.style_overrideRow__7IV0N {
  max-height: 88px;
  border: 1px solid #d1d4d6;
  border-radius: 8px;
  margin-bottom: 12px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.style_overrideRowText__2nvbJ {
  display: flex;
  justify-content: space-between;
}

.style_smartTextRow__1fCM_ {
  height: 88px;
  background-color: #ffffff;
  border: 1px solid #d1d4d6;
  border-radius: 8px;
  margin-bottom: 12px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.style_smartTextRowText__vcDTf {
  display: flex;
  justify-content: space-between;
}

.style_whiteRowTop__1fues > div:first-of-type {
  max-width: calc(100% - 80px);
}

.style_smartTextValue__25F5C {
  color: #2c2e2f;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  margin-bottom: 4px;
}

.style_smartTextName__3v4GC {
  color: #7a7d81;
  font-size: 13px;
  line-height: 18px;
}

.style_smartTextRowTextTop__2bk7T {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.style_pencilIconWrapper__CmoZq {
  cursor: pointer;
  border-radius: 4px;
  margin-right: 12px;
  height: 24px;
  width: 24px;
}

.style_pencilIconWrapper__CmoZq:hover {
  background: whitesmoke
}

.style_trashCanIconWrapper__1Ulhp {
  cursor: pointer;
  border-radius: 4px;
  height: 24px;
  width: 24px;
}

.style_trashCanIconWrapper__1Ulhp:hover {
  background: whitesmoke;
}

.style_rowIcons__19cOA {
  display: flex;
  justify-content: space-between;
}
.style_section__1yub1 {
  width: 100%
}

.style_toggleLabel__-mQk8 {
  height: 24px;
  font-size: 14px;
  line-height: 1.71;
  color: #2c2e2f;
  display: flex;
  align-items: center;
  justify-content: center;
}

.style_padlock__-rLjJ {
  display: flex;
  align-items: center;
  margin-right: 8px;
}

.style_toggleSection__2b6J9 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.style_toggle__2IFR_ {
  opacity: 1;
}

.style_toggle__2IFR_[data-disabled="true"] {
  opacity: 0.36;
}

.style_description__23V2y {
  width: 100%;
  height: 48px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #7A7D81;
  cursor: pointer;
}

.style_learnMore__28hFr {
  text-decoration: underline;
  color: #2c2e2f;
}

:root {
  --invoice-month-list-selected-max-height: 50px
}

.style_column__37BD- {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.style_sectionTitle__R8AGs {
  font-size: 20px;
  line-height: 1.4;
  color: #2c2e2f;
  padding-bottom: 12px;
  border-bottom: 1px solid rgba(226, 229, 232, 0.64);
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
}

.style_sectionTitle__R8AGs:not(:first-of-type) {
  margin-top: 40px;
}

.style_columnWrapper__3jL8- {
  width: 100%;
  margin-right: 100px;
}

.style_downloadButton__1RsZZ {
  box-shadow: none;
  justify-content: flex-start !important;
  padding: 0px;
  height: auto;
}

.style_titleButton__3WXZu {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.6;
  color: #57595d;
  margin-left: 8px;
}

.style_invoicesListWrapper__3U53m {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.style_loadingWrapper__3IqjQ {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.style_emptySectionWrapper__2Zox7 {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: 80px;
}

.style_emptySectionText__3nps1 {
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  color: #57595d;
  margin-top: 24px;
}

.style_invoiceYears__1KOOt {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 24px;
  margin-bottom: 16px;
}

.style_invoiceYear__3qVpe {
  display: flex;
  font-size: 15px;
  line-height: 1.6;
  margin-right: 20px;
  color: #7a7d81;
  cursor: pointer;
}

.style_invoiceYear__3qVpe[data-is-selected="true"] {
  color: #2c2e2f;
  border-bottom: 2px solid #2c2e2f;
  font-weight: 500;
}

.style_invoiceMonths__19adK {
  display: flex;
  flex-direction: column;

}

.style_invoiceMonthInvoiceList__3SIFg {
  height: auto;
  max-height: 0px;
  transition: max-height 300ms ease, border-bottom-color 100ms ease 200ms;
  border-bottom: 1px solid rgba(226, 229, 232, 0);
  border-bottom-color: rgba(226, 229, 232, 0);
  overflow: hidden;
}

.style_invoiceMonthInvoiceList__3SIFg[data-is-selected="true"] {
  max-height: 50px;
  max-height: var(--invoice-month-list-selected-max-height);
  border-bottom-color: rgba(226, 229, 232, 0.48);
  transition: max-height 300ms ease, border-bottom-color 100ms ease 0s;
}

.style_invoiceMonthLabel__2eP-V {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.6;
  color: #2c2e2f;
  min-height: 48px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e2e5e8;
  cursor: pointer;
  padding-right: 6px;
}

.style_invoiceListHeaders__nrCHK {
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  margin-bottom: 16px;
}

.style_invoiceListValueRow__2BLkd {
  display: flex;
  flex-direction: row;
  margin-top: 12px;
  padding-bottom: 12px;
}

.style_invoiceListValueRow__2BLkd:first-of-type {
  margin-top: none;
}

.style_invoiceListValueRow__2BLkd:not(:last-of-type) {
  border-bottom: 1px solid rgba(226, 229, 232, 0.48);
}

.style_invoiceListHeader__2Yv7D {
  font-size: 14px;
  line-height: 1.43;
  color: #8f9294;
}

.style_invoiceListTotal__3MCtm {
  width: 25%;
}

.style_invoiceListTotalHeader__2-l-5 {
}

.style_invoiceListTotalValue__1dvLW {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.71;
  color: #57595d;
}

.style_invoiceListInvoiceId__1PJDG {
  width: 30%;
}

.style_invoiceListInvoiceIdHeader__2q1TG {
}

.style_invoiceListInvoiceIdValue__29F0y {
  font-size: 14px;
  line-height: 1.71;
  color: #57595d;
}

.style_invoiceListDate__2H3YW {
  width: 40%;
}

.style_invoiceListDateHeader__15pSL {
}

.style_invoiceListDateValue__2SypK {
  font-size: 14px;
  line-height: 1.71;
  color: #8f9295;
}

.style_invoiceListDownloadButton__1XW1e {
  cursor: pointer;
}

.style_downloadAllLoading__3dOsp {
  height: 26.48px;
  width: 142.77px;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* General/Shared */

.style_addButton__3PWZz,
.style_ellipsisButton__1oPBb {
  background: none;
  border: 0;
  cursor: pointer;
  outline: none;
  padding: 0;
}

.style_addButton__3PWZz {
  color: #2D70CF;
  font-size: 15px;
  font-weight: bold;
  font-weight: 500;
  height: 24px;
  line-height: 24px;
  margin: 0 0 40px;
}
.style_addButton__3PWZz:hover {
  color: #1e81df;
}
.style_addButton__3PWZz > svg {
  fill: #2D70CF;
  margin-right: 12px;
  vertical-align: text-top;
}

.style_ellipsisButton__1oPBb {
  border-radius: 4px;
  margin: 0;
  position: relative;
}
.style_ellipsisButton__1oPBb:hover {
  background: #E2E5E8;
}
.style_ellipsisButton__1oPBb > svg {
  display: block;
}

.style_ellipsisWrapper__2LOsv {
  margin: 0;
  position: relative;
}

.style_ellipsisMenu__1OvK5 {
  position: absolute;
  top: 34px;
  right: 0;
  width: 300px;
}
.style_ellipsisMenu__1OvK5 > div {
  width: auto;
}
.style_ellipsisMenu__1OvK5 > div > div {
  padding: 24px;
}
.style_ellipsisMenu__1OvK5 > div > div > div {
  padding-left: 12px;
  margin-left: -12px;
  margin-top: -6px;
  margin-bottom: 6px;
  width: calc(100% + 24px);
}
.style_ellipsisMenu__1OvK5 > div > div > div:last-of-type {
  margin-bottom: -6px;
}

.style_modalContainer__3yclF {
  width: 545px;
  border-radius: 8px;
  background-color: #fff;
  padding: 32px;
}

/* Billing */

.style_billing__3W2AU {
  width: 100%;
}

.style_billing__3W2AU > h2 {
  border-bottom: 1px solid rgba(226, 229, 232, 0.64);
  color: #2c2e2f;
  font-size: 20px;
  font-weight: normal;
  line-height: 1.4;
  padding-bottom: 12px;
  margin: 0 0 24px;
}

.style_billing__3W2AU > h3 {
  color: #2c2e2f;
  font-size: 18px;
  font-weight: normal;
  line-height: 1.56;
  margin: 0 0 16px;
}

.style_billing__3W2AU > p {
  color: #57595D;
  margin: 0 0 24px;
}

/* BillingCards */

.style_billingCard__3Ehvf {
  display: flex;
  margin-bottom: 40px;
  align-items: center;
}

.style_billingCard__3Ehvf > .logo {
  background-size: cover;
  border: 1px solid #ddd;
  border-radius: 4px;
  height: 24px;
  width: 36px;
}

.style_billingCard__3Ehvf > .number {
  flex: 1 1;
  margin: 0 12px;
  color: #2c2e2f;
}

/* BillingCardsModal */

.style_upgradeModalPaymentDetails__2GO8I h2 {
  font-size: 20px;
  font-weight: normal;
  margin-top: 0px;
}

.style_errors__2rfcu {
  margin-top: 16px;
}

.style_error__1p3nK {
  color: #E64826;
}

.style_error__1p3nK + .style_error__1p3nK {
  margin-top: 4px;
}

.style_upgradeModalFooter__3IDm0 {
  margin-top: 40px;
  display: flex;
  justify-content: flex-end;
}

.style_upgradeModalCancelButton__2nWmL {
  margin-right: 16px;
  width: 94px;
}

/* BillingAddress */

.style_billingAddress__2G_10 {
  background-color: #ecf5fa;
  border: 1px solid #abd0e8;
  border-radius: 8px;
  font-size: 15px;
  margin-bottom: 24px;
  padding: 16px;
  color: #57595D;
}
.style_billingAddress__2G_10 > b {
  font-size: 16px;
  color: #2c2e2f;
}

/* BillingAddressModal */

.style_formGroup__1oGLu + .style_formGroup__1oGLu,
.style_formGroupInline__2QPCz,
.style_formGroupInline__2QPCz + .style_formGroup__1oGLu {
  margin-top: 16px;
}

.style_formGroupInline__2QPCz {
  display: flex;
}

.style_formGroupInline__2QPCz .style_formGroup__1oGLu {
  flex: 1 1 auto;
}

.style_formGroupInline__2QPCz .style_formGroup__1oGLu + .style_formGroup__1oGLu {
  margin-top: 0;
  margin-left: 16px;
}

.style_formGroupLabel__2Ep4Z {
  color: easilGrayDark;
  margin-bottom: 8px;
}

.style_fieldError__1_UOQ {
  color: #E64826;
  font-size: 12px;
  margin-top: 2px;
}

.style_formGroupButtons__258RU {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 40px;
}

.style_formGroupButtons__258RU > button {
  margin-left: 12px;
}

/* BillingContacts */

.style_contacts__3gCrE {
  background: #FCFCFD;
  border: solid 1px #E2E5E8;
  border-radius: 8px;
  margin: 0 0 24px;
  padding: 0 16px;
}
.style_contacts__3gCrE > li {
  align-items: center;
  border-top: 1px solid #E2E5E8;
  color: #57595D;
  display: flex;
  padding: 16px 0;
}
.style_contacts__3gCrE > li:first-child {
  border-top: none;
}
.style_contacts__3gCrE > li > .email {
  margin: 0 15px;
  flex: 1 1 auto;
}

/* BillingNotification */

.style_notification__13f8V {
  cursor: pointer;
  display: flex;
}
.style_notification__13f8V > span {
  color: #57595D;
  font-size: 15px;
  font-weight: 500;
  line-height: 27px;
  margin-left: 12px;
}

.style_formGroup__1oGLu[data-is-country="true"] {
  width: 100%;
}

.style_headerPadding__2sTmp {
  height: 0;
  margin-top: 48px;
}

.style_subHeaderWrapper__2h5__ {
  position: relative;
}

.style_modal__1jEIS {
  background-position: center right;
  background-repeat: no-repeat;
  color: #2c2e2f;
  padding: 32px;
  width: 1000px
}

.style_header__2Ac_1 {
  font-size: 24px;
  font-weight: 500;
  letter-spacing: -0.24px;
  line-height: 32px;
}

.style_intro__1BtU5 {
  color: #57595D;
}

.style_container__3fU_R {
  font-size: 15px;
  margin-top: -20px;
  width: 50%;
}
.style_container__3fU_R > ul {
  margin: 26px 0;
}
.style_container__3fU_R > ul > li {
  align-items: center;
  display: flex;
  height: 32px;
}
.style_container__3fU_R > ul > li > svg {
  margin-right: 12px;
}

.style_buttons__13R4h {
  justify-content: flex-start;
}

.style_buttons__13R4h > button {
  background-color: #3184fc;
  width: 436px;
}

.style_buttons__13R4h > button:hover {
  background-color: #2e7def;
}

.style_collectionPreviewModal__3qy3j {
  width: 850px;
  height: 650px;
  border-radius: 8px;
  /* need to set a transparent background since this inherits white */
  background-color: rgb(0,0,0,0);
  padding: 0;
  overflow: hidden;
}

.style_collectionPreviewModalContent__3dHt1 {
  white-space: pre-line;
  font-family: Roboto;
  font-size: 15px;
  line-height: 24px;
  display: flex;
  color: #57595d;
  flex-direction: row;
  height: 650px;
}

.style_designPreviewWindow__2t562 {
  width: 575px;
  height: 650px;
  background-color: #57595d;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.style_designPreviewWindow__2t562 > img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}

.style_infoPanel__12bNv {
  height: 100%;
  width: 275px;
  display: flex;
  flex-direction: column;
}

.style_infoPanelTop__2zJ3P {
  width: 275px;
  height: auto;
  background-color: #ffffff;
  padding: 32px 32px 24px 32px;
}

.style_infoPanelBottom__Vc93U {
  width: 275px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  overflow: hidden;
  justify-content: flex-end;
}

.style_selectedDesignTitle__WFzXT {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #272829;
}

.style_selectedDesignSize__17k8P {
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #7a7d81;
  margin-top: 12px;
}

.style_infoPanelButtons__UfNuA {
  text-align: center;
  width: 275px;
  max-height: 218px;
  border-top: 1px solid #e2e5e8;
  background-color: #f9f9fa;
  padding: 24px 32px 32px 32px;
}

.style_infoPanelButtons__UfNuA > div {
  margin-top: 12px;
}

.style_infoPanelButtons__UfNuA > div:first-of-type {
  margin-top: 0;
}

.style_sizeOption__30jvR {
  margin: 4px 6px 4px 0;
  padding: 0 12px;
  border-radius: 8px;
  border: solid 1px #e2e5e8;
  background-color: #f9f9fa;
  cursor: pointer;
  font-size: 12px;
  color: #7a7d81;
}

.style_sizeOption__30jvR > span {
  color: #afb3b6;
}

.style_sizeOption__30jvR[data-is-highlighted="true"] {
  border: solid 1px #3184fc;
  background-color: rgba(45, 112, 207, 0.04);
  color: #3184fc !important;
}

.style_sizeOption__30jvR[data-is-highlighted="true"] > span {
  color: #a1c6ff;
}

.style_sizeCategories__2y1eT {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #ffffff;
  margin-right: -5px;
  overflow-y: scroll;
  padding: 0px 5px 0px 32px;
  margin-bottom: 12px;
  margin-right: 24.4px;
}

.style_sizeCategory__27ARt {
  display: flex;
  flex-direction: column;
}

.style_sizeCategoryTitle__3-s7N {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #272829;
}

.style_designSizeOptions__19cdC {
  display: flex;
  flex-wrap: wrap;
  margin-top: 4px;
  margin-bottom: 12px;
}

.style_editButton__2jgEW {
  height: 40px;
  width: 211px;
  background-color: #3184fc;
  border: 1px solid #3184fc;
  justify-content: center !important;
  box-shadow: none;
}

.style_editButton__2jgEW:hover {
  background-color: #2e7def;
  border: 1px solid #2e7def;
}

.style_editButtonTitle__EoFmm {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  margin: 0px 0px 0px 8px;
}

.style_closeModalButton__2Clhz {
  position: absolute;
  margin-left: 212px;
  margin-top: -20px;
  cursor: pointer;
  height: 18px;
  width: 18px;
}

.style_loadingWrapper__1NsQU {
  width: 850px;
  height: 650px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.style_previewOverlay__3NxGq {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex
}

.style_relativeWrapper__1ITzo {
  position: relative;
  width: 100%;
  height: 100%;
}

.style_shiftButton__3KjE_ {
  position: absolute;
  border-radius: 8px;
  top: calc(50% - 16px);
  width: 32px;
  height: 32px;
  background-color: #484a4c;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.style_shiftButton__3KjE_:hover {
  background-color: #2c2e2f;
}

.style_nextButton__1z9Rf {
  right: 18px;
}

.style_nextButton__1z9Rf > svg {
  margin-right: -2px;
}

.style_previousButton__2KS4l {
  left: 18px;
}

.style_previousButton__2KS4l > svg {
  margin-left: -2px;
}

.style_printingOptions__2018z {
  display: flex;
  margin-top: 8px;
  /* button styling overide as per design */
  height: 24px !important;
  padding: 0 4px 0 12px;
}

.style_printingOptions__2018z > span {
  font-size: 12px;
  font-weight: 500;
  line-height: 2;
}
.style_wrapper__2mHXF {
  width: 500px;
  padding: 32px;
}

.style_alert__rWyPO {
  margin: 24px auto;
}

.style_description__1LzoA {
  height: 48px;
  font-size: 15px;
  line-height: 1.6;
  color: #57595d;
}

.style_textareaLabel__jIekC {
  height: 24px;
  font-size: 15px;
  line-height: 1.6;
  color: #7a7d81;
  margin: 24px auto 8px;
}

.style_textarea__1bksz {
  resize: none;
  width: 100%;
  height: 100px;
  border-radius: 4px;
  border: solid 1px #d1d4d6;
  background-color: #ffffff;
  padding: 16px;
}

.style_textarea__1bksz:focus {
  outline: none;
}

.style_wrapper__28C6a {
  padding: 32px;
  width: 550px !important;
  border-radius: 8px;
}

.style_comment__1pgFK {
  width: 486px;
  max-height: 72px;
  min-height: 52px;
  border-radius: 8px;
  border: solid 1px #e2e5e8;
  background-color: #fcfcfd;
  padding: 16px;
  white-space: pre-line;
  overflow: auto;
}

.style_commentSection__2OjZI {
  margin: 24px auto;
}

.style_commentLabel__2GBI_ {
  height: 24px;
  font-size: 15px;
  line-height: 1.6;
  color: #57595d;
  margin: 24px auto 8px auto;
}

.style_notification__2RUh- {
  position: relative;
}

.style_notification__2RUh- > div {
  position: absolute;
  top:48px;
}

.style_notification__2RUh- > div > span {
  color: #2c2e2f;
}

.style_textarea__3gvSR {
  /* make the style disable scroll bar visibility */
  resize: none;
  width: 486px;
  height: 40px;
  padding: 8px 12px;
  white-space: pre-line;
  overflow: auto;
  font-size: 15px;
  color: #2c2e2f;
  line-height: 1.6;
  border-radius: 4px;
  border: solid 1px #d1d4d6;
  background-color: #ffffff;
}

.style_textarea__3gvSR:focus {
  outline: none;
}

.style_icon__1GQj1 {
  margin-right: 8px;
}

.style_label__2ROiz {
  color: black;
  display: flex;
  align-items: center;
}

.style_detailRow__3EAsx {
  display: flex;
  margin-bottom: 12px;
}

.style_iconBox__3mI0M {
  height: 24px;
  width: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
}

.style_detailText__XAtf4 {
  min-height: 24px;
  font-family: Roboto;
  font-size: 15px;
  line-height: 1.6;
  color: #57595d;
}

.style_pageWrapper__woA5N {
  background-color: rgb(244, 244, 245);
}

.style_divider__2a9Wq {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 8px 0 -4px !important;
}

/* Remove divider from all except first instance */
.style_divider__2a9Wq~.style_divider__2a9Wq {
  display: none;
}

.style_line__2ZSrz {
  width: 83px;
  height: 1px;
  background-color: #e2e5e8;
}

.style_text__3TMME {
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  color: #73767a;
}

.style_freeEdgeNotice__1c9mw {
  font-size: 15px;
  font-weight: 500;
  color: #272829;
}

.style_easilEdgeLink__10nvX {
  cursor: pointer;
  color: #2D70CF;
}

.style_disclaimer__wSMiY {
  text-align: center;
  font-size: 8px;
  color: #7A7D81;
  line-height: 12px;
}

.style_disclaimerLink__2hjf7 {
  color: #2C6FCF;
}

.style_buyCollectionButton__3UtBm > span {
  font-size: 11px;
}

.style_titleClassName__ukose {
  margin: 0px;
}
.BuyCollectionStyle_buyCollectionModalContainer__2T07P {
  width: 650px;
  height: auto;
  padding: 32px;
  border-radius: 8px;
  max-height: 90vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.BuyCollectionStyle_buyCollectionModalContainer__2T07P > div[aria-label="Modal Body"] {
  display: flex;
  flex-direction: column;
  /* max-height: 100%; */
  height: auto;
  max-height: 100%;
  overflow: hidden;
  padding-right: 15px;
  margin-right: -15px;
}

.BuyCollectionStyle_content__3fDYc {
  overflow: scroll;
  overflow-x: hidden;
  flex: 1 1 auto;
  padding-right: 15px;
  margin-right: -15px;
}

/* reduce header margin-bottom */
.BuyCollectionStyle_buyCollectionModalContainer__2T07P > div {
  margin-bottom: 24px;
}

.BuyCollectionStyle_collectionNotification__1x53H {
  width: 100%;
  margin-top: 24px;
}

.BuyCollectionStyle_paymentDetails__2hDU8{
  border: 2px solid blue;
  width: 100%;
  height: 106px;
  margin-top: 24px;
}

.BuyCollectionStyle_promoCode__XQdYy {
  border: 2px solid green;
  width: 100%;
  height: 56px;
  margin-top: 24px;
}

.BuyCollectionStyle_payButton__1JSvY {
  z-index: 3;
}

.BuyCollectionStyle_notificationAlert__15rjn {
  justify-content: flex-start;
  align-items: flex-start;
}

.BuyCollectionStyle_notificationAlertContent___OFjj {
  font-family: Roboto;
  font-size: 14px;
  line-height: 1.71;
  color: #2c2e2f;
}

.BuyCollectionStyle_pollingErrorAlert__1LIeM {
  text-align: center;
  position: relative;
  color: #E64826;
  max-height: 0px;
  margin-top: 12px;
  transition: max-height 0.2s ease;
}

.BuyCollectionStyle_pollingErrorAlert__1LIeM[data-has-error="true"] {
  max-height: 21px;
}

.BuyCollectionStyle_buttonHoverWrapper__2R3GH {
  position: relative;
  display: flex;
  justify-content: flex-end;
  min-height: 48px;
  margin-top: 24px;
  height: 48px;
}

.BuyCollectionStyle_buttonHoverWrapper__2R3GH[data-is-paypal="true"]:hover > button {
  background-color: easilGreenDarkAlphaHover;
}

.BuyCollectionStyle_buttonBacking__wt3LP {
  background-color: #ffffff;
  pointer-events: none;
  z-index: 2;
  width: 100%;
  height: 100%;
  position: absolute;
}

.BuyCollectionStyle_paypalButton__iGNgB {
  overflow: hidden;
  border-radius: 8px;
  position: absolute;
  height: 48px;
  width: 100%;
  z-index: 1;
}

.BuyCollectionStyle_paypalButton__iGNgB > div {
  -webkit-transform: scale(3);
          transform: scale(3);
}
.CollectionDetailsStyle_collectionDetails__3ows0 {
  width: 100%;
  height: 141px;
  display: flex;
}

.CollectionDetailsStyle_designPreviewWindow__2psck {
  width: 100px;
  height: 141px;
}

.CollectionDetailsStyle_designThumbnail__2H8C1 {
  border-radius: 8px;
  max-width: 100%;
  max-height:100%;
  display: flex;
  justify-content: center;
  margin: 0 auto;
}

.CollectionDetailsStyle_collectionInformation__1A8bE {
  width: 100%;
  max-height: 145px;
  margin-left: 24px;
  display: flex;
  flex-direction: column;
}

.CollectionDetailsStyle_collectionHeaderPrice__26cSV {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.CollectionDetailsStyle_designTitle__31syU {
  width: 374px;
  /* max height is set to 3 lines of text */
  max-height: 72px;
  font-size: 18px;
  font-weight: 500;
  display: inline-block;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #2c2e2f;
}

.CollectionDetailsStyle_collectionIncludes__5yhmR {
  line-height: 20px;
}

.CollectionDetailsStyle_collectionPrice__4ydvR {
  float: right;
  font-size: 18px;
  color: #272829;
  font-weight: 500;
}

.CollectionDetailsStyle_collectionPrice__4ydvR > span {
  font-size: 11px;
  color: #272829;
  font-weight: 500;
}

.CollectionDetailsStyle_designSizeOptions__3A17s {
  /* make the style disable scroll bar visibility */
  display: flex;
  flex-wrap: wrap;
  margin-top: 8px;
  max-height: 100%;
  overflow-y: scroll;
}

.CollectionDetailsStyle_scrollbar__1tfva {
  margin-left: 12px;
  width: 5px;
  height: 17px !important;
  border-radius: 2.5px;
  background-color: #7d7d7d;
}

.CollectionDetailsStyle_scrollContentClassName__1OamK {
  position: absolute;
}
:root {
  --payment-option-height-offset-difference: 0px;
}

.style_wrapper__EpXqH {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  /* height: 100%; */
  width: 100%;
  margin-top: 24px;
}

.style_title__1M-u7 {
  color: #2c2e2f;
  margin-bottom: 16px;
  font-family: Roboto;
  font-size: 18px;
  line-height: 1.44;
  color: #2c2e2f;
}

.style_paymentOptions__39Jaq {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.style_paymentOption__1PHCa {
  border: 1px solid #D1D4D6;
  width: 100%;
  max-height: 52px;
  overflow: hidden;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  transition: max-height 0.3s ease;
}

.style_paymentOption__1PHCa + .style_paymentOption__1PHCa {
  margin-left: 16px;
}

.style_paymentOption__1PHCa[data-is-active="true"] {
  max-height: calc(190px + 0px);
  max-height: calc(190px + var(--payment-option-height-offset-difference));
}

.style_paymentOption__1PHCa[data-is-active="false"] > .style_paymentOptionHeader__1Xq4q {
  cursor: pointer;
}

.style_paymentOptionHeader__1Xq4q {
  display: flex;
  flex-direction: row;
  height: 52px;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  cursor: pointer;
}

.style_paymentOptionHeaderLeft__e7PO7 {
  display: flex;
  flex-direction: row;
  color: #2c2e2f;
  align-items: center;
}

.style_selectionCircle__3J8Zo {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #D1D4D6;
  margin-right: 12px;
  transition: border-width 0.1s ease-in-out;
}

.style_selectionCircle__3J8Zo[data-is-active="true"] {
  border: 7px solid #2C6FCF;
}

.style_paymentOptionHeaderRight___eFrN {
  display: flex;
  flex-direction: row;
}

.style_paymentOptionHeaderRight___eFrN > img:not(:last-of-type) {
  margin-right: 12px;
}

.style_logo__1G5iN {
  width: 36px;
  height: 22px;
  object-fit: contain;
}

.style_paymentOptionContent__18kKN {
  display: flex;
  flex-direction: column;
  padding: 16px;
  background-color: #fcfcfc;
  min-height: 138px;

  border: 1px solid #D1D4D6;
  width: 100%;
  border-radius: 8px;
  margin-top: 8px;
}

.style_paypalContent__Tp5oM {
  height: 104px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #57595D;
}

.style_headerSection__2qGVa {
  display: flex;
  flex-direction: row;
}

.style_contentWrapper__1fPGV {
  max-height: calc(147px + 0px);
  max-height: calc(147px + var(--payment-option-height-offset-difference));;
  overflow: hidden;
  transition: max-height 0.3s ease 0s;
}

.style_contentWrapper__1fPGV[data-is-content-shown="false"] {
  max-height: 0px;
  transition: max-height 0.3s ease 0.2s;
}

.style_contentWrapper__1fPGV > .style_paymentOptionContent__18kKN {
  opacity: 1;
  transition: opacity 0.2s ease 0.3s
}

.style_contentWrapper__1fPGV[data-is-content-shown="false"] > .style_paymentOptionContent__18kKN {
  opacity: 0;
  transition: opacity 0.2s ease 0s
}

.style_paypalLogo__3FeD_ {
  width: 83px;
  height: 22px;
  object-fit: contain;
}
.PromoCode_promoCode__1yNiN {
  margin-top: 16px;
}

.PromoCode_promoClosed__Dzxnq {
  display: inline-block;
  font-size: 14px;
  line-height: 20px;
  color: #2c2e2f;
  border-bottom: 1px solid #2c2e2f;
  cursor: pointer;
}

.PromoCode_promoOpen__3NQbx {
  width: 100%;
  max-height: 0px;
  height: auto;
  overflow: hidden;
  transition: max-height 0.8s 0.2s ease;
}

.PromoCode_promoOpen__3NQbx[data-is-active="true"] {
  max-height: 65px;
}

.PromoCode_couponInputSection__GkLsU {
  display: flex;
  align-items: flex-start;
  transition: height 0.8s 0.2s ease;
}

.PromoCode_couponInputSection__GkLsU[data-has-error="true"] {
  height: 65px !important;
}

.PromoCode_couponInput__BzNR7 {
  width: 484px;
}

.PromoCode_couponApply__36qW6 {
  margin-left: 16px;
}

.PromoCode_couponApply__36qW6:disabled {
  color: #57595D;
}

