@value colors: "~views/style.css";
/* easilGrayDark is used on the JSX */
@value easilBlack, easilGrayXDark from colors;

@value cell1Width: 50%;
@value cell2Width: 40%;
@value cell3Width: 20%;
@value cell4Width: 10%;

@value headerCell1Left: calc((((100% - 64px) * 0.5) * 0.5) + 64px);
@value headerCell2Right: 354px;

.cell {
  width: cell1Width;
  display: flex;
  align-items: center;
}

.cell:nth-child(4n+2) {
  justify-content: flex-end;
}

@media screen and (max-width: 1030px) {
  .cell:nth-child(4n+2) {
    width: 60%;
    max-width: 60%;
  }
  .cell:nth-child(1) {
    width: 40%;
    max-width: 40%;
  }
}

@media screen and (max-width: 875px) {
  .cell:nth-child(4n+2) {
    width: 70%;
    max-width: 70%;
  }
  .cell:nth-child(1) {
    width: 30%;
  }
  .cell > div[aria-label="member email"] {
    display: none;
  }
}

.headerCell {
  display: flex;
  justify-content: flex-start;
  color: #8f9297;
  font-size: 14px;
  font-weight: 500;
  width: 64px;
}

.headerCell:nth-child(4n+2) {
  justify-content: flex-start;
}

.cellEdit {
  composes: cell;
  overflow: visible;
}
.row {
  font-size: 14px;
  display: flex;
  align-items: center;
  flex: 1 0  100%;
  max-width: 100%;
}

.rowEditting {
  composes: row;
  align-items: center;
  padding: 10px 0px 11px 0px;
}
.input {
  width: 95%;
}

.actionIcon {
  cursor: pointer;
}

.leftIcon {
  composes: actionIcon;
  margin-right: 9px;
  display: flex;
  align-items: center;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
}

.cancelButton {
  margin-right: 12px
}

.roleIcon {
  padding: 0 8px;
  font-size: 12px;
  font-weight: 500;
  color: easilBlack;
  background-color: #e5e8eb;
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  border-radius: 4px;
  width: max-content;
}

.roleIcon:last-of-type {
  margin-right: 0px;
}

.roleIcon[data-is-active="false"] {
  opacity: 0.3;
}

.popout {
  right: 0px;
  top: 30px;
  width: auto !important;
}

.popoutMenu {
  width: auto !important;
}

.popoutMenu > div:nth-child(2) {
  padding: 12px;
  width: max-content;
}

.popoutActions > div > * {
  font-size: 14px !important;
  font-weight: 500;
  height: auto;
  color: easilBlack;
}

.popoutActions > div {
  padding: 6px 10px;
}

.menu {
  display: flex;
  margin-left: auto;
  border-radius: 4px;
  position: relative;
}

.menu > svg {
  cursor: pointer;
}

.menu:hover > svg rect {
  fill: #e2e5e8 !important;
}

.menu:hover > svg circle {
  fill: #57595d !important;
}

.popoutNameSection {
  display: none;
}

.memberModal {
  padding: 32px;
  border-radius: 8px;
}

.menu[data-is-ellipsis="true"] {
  padding-right: 4px;
}

.avatarCell {
  padding-right: 24px;
}

.memberDetails {
  width: 100%;
  max-width: calc(100% - 64px);
  height: fit-content;
  display: flex;
  justify-content: space-between;
  align-items: center; 
  padding: 20px 0;
  border-bottom: 1px solid #e5e8eb;
}

.nameCell {
  width: 50%;
  padding-right: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 15px;
  font-weight: 500;
  color: easilBlack;
}

@media screen and (max-width: 875px) {
  .nameCell {
    width: 100%;
  }
}

.emailCell {
  composes: nameCell;
  width: 50%;
  color: #57595d;
  font-weight: normal;
  font-size: 14px;
}

.roleCell {
  width: 330px;
  display: flex;
  margin-right: 32px;
  justify-content: flex-end;
}

.parentIndicator {
  font-size: 13px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: normal;
  color: #57595d;
}