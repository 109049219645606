@value colors: "../../colors.css";
@value easilGrayXDark, easilGrayXLight from colors;

.wrapper {
  align-items: center;
  background-color: #fff;
  border: 1px solid easilGrayXLight;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  height: 24px;
  width: 24px;
}

.wrapper[disabled],
.wrapper[disabled] * {
  opacity: 0.6;
  cursor: not-allowed;
}