@value colors: "../style.css";
@value easilBlack, easilGrayXDark from colors;

.promoCode {
  margin-top: 16px;
}

.promoClosed {
  display: inline-block;
  font-size: 14px;
  line-height: 20px;
  color: easilBlack;
  border-bottom: 1px solid easilBlack;
  cursor: pointer;
}

.promoOpen {
  width: 100%;
  max-height: 0px;
  height: auto;
  overflow: hidden;
  transition: max-height 0.8s 0.2s ease;
}

.promoOpen[data-is-active="true"] {
  max-height: 65px;
}

.couponInputSection {
  display: flex;
  align-items: flex-start;
  transition: height 0.8s 0.2s ease;
}

.couponInputSection[data-has-error="true"] {
  height: 65px !important;
}

.couponInput {
  width: 484px;
}

.couponApply {
  margin-left: 16px;
}

.couponApply:disabled {
  color: easilGrayXDark;
}
