@value colors: "~views/style.css";
/* easilGrayDark is used on the JSX */
@value easilBlack, easilGrayXDark, easilGrayXXXLight, easilRed, easilGrayXLight from colors;

/* input widths */
@value cell1Width: 180px;
@value cell2Width: 234px;
@value cell3Width: 210px;

/* scollable invites widths */
@value scrollableCell1Width: 155px;
@value scrollableCell2Width: 220px;
@value scrollableCell3Width: 187px;

/* input headings */
.tableWrapper > :first-child {
  padding-bottom: 8px;
}

.cell {
  width: cell1Width;
  overflow: visible;
  margin-right: 12px;
}

.cell:nth-child(4n+2) {
  width: cell2Width;
}

.cell:nth-child(4n+3) {
  width: cell3Width;
}

.cell:nth-child(4n) {
  justify-content: center;
  margin-right: 0;
}

.cellError {
  border: 1px solid easilRed;
  border-radius: 4px;
}

.emailError {
  position: relative;
  color: easilRed;
  max-height: 0px;
  overflow: hidden;
  transition: max-height 0.2s ease;
}

.emailError[data-has-error="true"] {
  max-height: 20px;
}

.list {
  border-radius: 4px;
  box-shadow: 0 0 0 1px easilGrayXXXLight;
  margin: 8px 0 32px 0;
  height: 30vh; /* allow this to dynamically grow at least a little */
  overflow: auto;
  width: 745px;
  display: flex;
  flex-direction: column;
}
.listrow {
  align-items: center;
  display: flex;
  width: fit-content;
}
.listrow:first-child {
  padding: 26px 24px 0 24px;
}

.listrow:not(:first-child) {
  padding: 16px 24px 0 24px;
}

.listrow > div {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: easilBlack;
  font-size: 15px;
}

.listrow > div:not(:last-of-type) {
  margin-right: 26px;
}

.listrow > div:nth-child(4n+3) {
  margin-right: 18px;
}

.row {
  font-size: 14px;
  display: flex;
  flex: 1 0  100%;
  padding: 0px 0px 8px 0px;
}

/* select all but the first */
.row ~ .row{
  padding: 16px 0px 0px 0px;;
}

.plusIcon {
  margin-right: 10px
}

.clickableIcon {
  cursor: pointer;
}

.trashIcon {
  composes: clickableIcon;
}

.addToTeamIcon {
  width: 40px;
  height:40px;
  padding: 0;
  border: none;
}

.addToTeamIcon[data-disabled="true"] {
  border: none;
}

.clickablePlusIcon {
  composes: clickableIcon;
}

.scrollable {
  width: calc(100% + 15px);
  margin-right: -15px;
}


.scrollContentClassName {
  height: 100%;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1 1;
  justify-content: space-between;
  align-content: flex-start;
  position: absolute;
}

.scrollContentClassName > div:nth-child(1) > div:nth-child(4) {
  height: 26px;
}

.dropdownContent {
  z-index: 3;
}

.teamInput {
  border-radius: 4px;
}

.scrollbar {
  right: 20px;
}