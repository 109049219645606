@value colors: "../style.css";
@value  easilGrayXLight from colors;

.radioboxRoundedLabel {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: easilGrayXLight;
  font-size: 14px;
  line-height: 1.43;
}

.radioboxRoundedLabel:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border: 2px solid easilGrayXLight;
  border-radius: 100%;
  background: transparent;
}

.radioboxRoundedLabel[data-checked="true"]:after {
  border-radius: 100%;
  content: '';
  width: 8px;
  height: 8px;
  background: white;
  position: absolute;
  top: 6px;
  left: 6px;
  transition: all 0.2s ease;
  color: white;
}

radioboxRoundedLabel[data-checked="true"] {
  border: 2px solid white;
}

.radioboxRoundedLabel > input {
  display: none;
}
