@value colors: "../style.css";
@value easilBlack from colors;

.cancelModalWrapper {
    width: 550px;
    min-height: 329px;
    max-height: 433px;
    padding-top: 33px;
    border-radius: 8px;
}

.cancelModalWrapper > div:first-of-type {
    margin-bottom: 24px;
}
.textArea {
    margin-top: 16px;
}
.questionFeedback {
    font-size: 15px;
    color: easilBlack;
    font-family: Roboto;
    margin-top: 24px;
    margin-bottom: 8px;
}
.dropdownSubscriptionCancellation {
    margin-top: 8px;
    color: easilBlack;
    font-family: Roboto;
    border-radius: 8px;
}
.dropdownSubscriptionCancellation > div {
    padding: 12px;
    border-radius: 4px;
}
.dropdownSubscriptionCancellation > div > div  {
    height: 36px;
    padding: 6px 12px;
    border-radius: 4px;
}
.dropdownSubscriptionCancellation > div > input[disabled] {
    color: easilBlack;
}
.dropdownSubscriptionCancellation > div > input::placeholder {
    color: easilBlack;
}