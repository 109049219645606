@value colors: "~views/colors.css";
@value easilBlack from colors;

.designSizeCardWrapper:last-of-type {
  margin-right: 0px;
}

.designSizeCardWrapper {
  display: flex;
  justify-content: center;
  width: 150px;
  min-width: 150px;
  height: 270px;
  position: relative;
  margin-right: 25px;
  margin-top: 24px;
}

.thumbCard {
  position: absolute;
  bottom: 70px;
  display: inline-block;
  cursor: pointer;
  padding: 4px;
}

.thumbCard[data-is-selected="true"] {
  border: 2px solid easilBlack;
  border-radius: 6px;
  padding: 2px;
}

.thumbnail {
  max-width: 144px;
  max-height: 175px;
  display: block;
  border-radius: 4px;
}

.cardInfo {
  position: absolute;
  top: 200px;
  margin-top: 12px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

.cardInfoLabel {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.33;
  color: #2c2e2f;
}

.cardInfoText {
  height: 20px;
  font-size: 14px;
  line-height: 1.43;
  color: #afb3b6;
}
