.center {
  display: flex;
  text-align: center;
  vertical-align: middle;
  background-color: white;
  height: 100vh;
  width:100vw;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 10;
}

.windowedCenter {
  height: 100%;
  width: 100%;
  display: flex;
  text-align: center;
  vertical-align: middle;
  background-color: white;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
