@value colors: "../style.css";
@value easilBlack from colors;

.h1 {
  display: inline-block;
  font-size: 32px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: easilBlack;
}
