.dropWrapper {
  display: inline-block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
  pointer-events: all;
}

.dropWrapper[data-is-passthrough="true"] {
  pointer-events: none;
}

.draggingOverlay {
  background-color: #0000001F;
  position: absolute;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.draggingOverlayEditor {
  position: absolute;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.draggingOverlayText {
  text-align: center;
  color: #303134;
  font-size: 11px;
  width: 138px;
  height: 66px;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(28, 29, 30, 0.04);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}