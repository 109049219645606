@value colors: "~views/style.css";
@value easilBlack, easilGray, easilGrayXDark, hoverColor from colors;

@value smallScreenCardSize: 22%;
@value smallScreenGutterSize: 4%;
@value largeScreenCardSize: 18%;
@value largeScreenGutterSize: 2%;

.grid {
  width: 100%;
}

.gridSizer {
  width: smallScreenCardSize;
}

.gutterSizer {
  width: smallScreenGutterSize;
}

.item {
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
  width: smallScreenCardSize;
  margin-bottom: smallScreenGutterSize;
  overflow: visible;
}

.item > img {
  width: 100%;
}

.cardBottom {
  height: 72px;
  padding: 0px 12px 0px 12px;
}

.cardBottomRow {
  display: flex;
  align-items: center;
  position: relative;
}

.popout {
  position: absolute;
  width: 300px;
  /* we need to set bottom so all popouts are starting from the same position */
  bottom: 0px;
  z-index: 10;
  border: solid 1px #e4e4e4;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
}

.designTitle {
  color: easilBlack;
  margin-bottom: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.caretWrapper {
  display: flex;
}

.caretOuterWrapFullFill {
  flex: 0.25 0 auto;
}

.image {
  border-radius: 2px 2px 0px 0px;
}

.popoutContentTop {
  border-bottom: 1px solid lightgray;
  padding: 16px;
}

.popoutContentTop1Row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 11px;
}

.popoutContentTop1Row > div:first-child {
  padding-right: 10px;
  font-size: 15px;
  color: easilBlack;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.popoutContentTop1RowEditting {
  align-items: center;
  display: flex;
  margin-bottom: 8px;
}

.popoutContentTop1RowEditting > svg:last-child {
  margin-left: 12px;
}

.editDesignName {
  height: 36px;
  margin-right: 16px;
}

.popoutContentTop > div + div {
  font-size: 13px;
  color: easilGray;
  margin-bottom: 0px;
}

.popoutContentMiddle {
  border-bottom: 1px solid lightgray;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 16px 0px 16px 0px;
}

.popoutContentMiddle > ul > li {
  display: flex;
  align-items: center;
  line-height: 1.71;
  color: easilGrayXDark;
  margin-bottom: 10px;
  padding: 0px 16px 0px 16px;
  cursor: pointer;
}

.popoutContentMiddle > ul > li:last-child {
  margin-bottom: 0px;
}

.popoutContentMiddle > ul > li:hover {
  background-color: hoverColor;
}

.popoutContentMiddle > ul > li > div {
  padding-left: 12px;
}

.popoutContentBottom {
  border-bottom: 1px solid lightgray;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 16px;
}

.more {
  text-align: center;
  cursor: pointer;
  border: 1px solid lightgray;
  padding: 9px;
  border-radius: 4px;
}

.topRowLeftItem {
  margin-left: 32px;
}

@media screen and (min-width: 900px) {
  /* 5 columns for larger screens */
  .gridSizer {
    width: largeScreenCardSize;
  }

  .gutterSizer {
    width: largeScreenGutterSize;
  }

  .item {
    width: largeScreenCardSize;
    margin-bottom: largeScreenGutterSize;
  }
}
