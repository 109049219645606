@value colors: "./colors.css";
@value (
  easilGrayDark,
  easilBlack,
  easilBlackLight,
  easilRed,
  easilOrange,
  easilGreen,
  easilGreenDarkAlpha,
  easilGreenDarkAlphaHover,
  easilBlue,
  easilBlueDark,
  easilBlueLight,
  easilBlueDeep,
  easilRoyalBlue,
  easilRoyalBlueHover,
  easilBlueDeepWithAlpha,
  easilBlueDeepWithAlphaHover,
  easilGray,
  easilGrayDark,
  easilGrayXDark,
  easilGrayLight,
  easilGrayMidLight,
  easilGrayXLight,
  easilGrayXXLight,
  easilGrayXXXLight,
  easilGrayXXXXLight,
  backgroundDisabled,
  fontDisabled,
  hoverColor
) from colors;

@value globalVars: "./globalVars.css";
@value (
  cardSize,
  gutterSize,
  landscapeCardSize,
  landscapeGutterSize,
  layoutWidth
) from globalVars;

html {
  box-sizing: border-box;
  height: 100%;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

strong {
  font-weight: 500;
}

body {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  line-height: 1.5;
  height: 100%;
  margin: 0;
  color: easilGrayDark;
  user-select: none;

  font-feature-settings: 'kern', 'liga', 'pnum';
  -webkit-font-smoothing: antialiased;
  /* IE hack to hide scrollbars */
}

button {
  font-family: 'Roboto', sans-serif;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

[data-no-pointer="true"] {
  cursor: default;
}

[data-is-pointer="true"] {
  cursor: pointer;
}

[data-not-allowed="true"] {
  cursor: not-allowed;
  pointer-events: none;
}

[data-disabled="true"] {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
}

[data-not-allowed="true"] * {
  cursor: not-allowed;
  pointer-events: none;
}

[data-nodisplay="true"] {
  display: none;
}

[data-hidden="true"] {
  visibility: hidden;
}

[data-zero-opacity="true"] {
  opacity: 0;
}

[data-half-opacity="true"] {
  opacity: 0.5;
}

svg[data-disabled="true"] {
  cursor: default;
  fill: easilGray;
  stroke: easilGray;
}

[contenteditable=true]:focus {
  outline: none
}

/* to support Safari */
[contenteditable] {
  user-select: text;
  -moz-user-select: text;
  -webkit-user-select: text;
}

@keyframes Gradient {
	0% {
		background-position: 0% 50%
	}
	50% {
		background-position: 100% 50%
	}
	100% {
		background-position: 0% 50%
	}
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

#simulations {
    opacity: 0;
    position: 'absolute';
}
