@value colors: "~views/colors.css";
@value easilGrayXDark from colors;

.empty {
  margin: 50px 0;
  text-align: center;
}

.emptyIcon {
  display: inline-block;
  margin-bottom: 23px;
}

.emptyTitle {
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 16px;
}

.emptySubtitle {
  color: easilGrayXDark;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 24px;
  white-space:pre;
}

.emptyButton {
  display: inline-block;
}
