.center {
  background-color: white;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  text-align: center;
  align-items: center;
  vertical-align: middle;
}

.center > :nth-child(1) {
  top: 32px;
  min-height: 30.5px;
  position: absolute;
  cursor: pointer;
}

.center > :nth-child(2) {
  margin-bottom: 40px;
  min-height: 85px;
}

.center > :nth-child(4) {
  margin-bottom: 32px;
}

.heading {
  margin-bottom: 0;
  font-family: Roboto;
  font-size: 24px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #1c1d1e;
}