.tableWrapper {
  /* margin-bottom: 20px;
  width: 1000px;
  height: 500px; */
}

table {
  border-collapse: collapse;
  border-spacing: 0px;
  width: 100%;
  height: 100%;
  cursor: text;
  position: relative;
  z-index: 9999;
}

