@value colors: "../../colors.css";
@value easilBlack, easilGrayXDark, easilGrayDark, easilGrayXLight from colors;

.orderDesign {
  display: flex;
  flex-direction: column;
  padding-top: 24px;
}

.divider {
  background-color: easilGrayXLight;
  border-radius: 1px;
  height: 1px;
  margin-top: 24px;
}

.orderDesignItem {
  display: flex;
}

.productDescription {
  flex: 2.5;
  flex-wrap: wrap;
}

.designDescription {
  margin-top: 4px;
  line-height: 20px;
}

.productPrice {
  color: easilBlack;
  flex: 1;
  text-align: end;
}

.designName, .productPrice {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.33;
}