@value colors: "~views/colors.css";
@value easilBlack from colors;

.upload {
  margin-top: 32px;
}

.fontDropZone {
  color: easilBlack;
  margin-bottom: 16px;
}

.checkbox {
  top: 2px;
}