@value colors: "~views/colors.css";
@value easilGrayXDark from colors;

.wrapper {
  width: 100%;
}

.container {
  width: 100%;
  height: 100%;
  position: relative;
}

.designUpdateNotice {
  border-bottom: 1px solid #abd0e8 !important;
  margin-top: 48px;
  width: 100%;
  padding: 16px !important;
  border-radius: 8px !important;
  align-items: center !important;
}

.designUpdateNotice > div:nth-of-type(2) {
  line-height: 20px;
}

.CartOptionSection, .deleteOverlay {
  width: 100%;
  top: 0;
  left: 0;
  position: relative;
}

.CartOptionSection {
  display: flex;
  padding: 48px 0 32px;
}

.thumbPreviewSection {
  width: 132px;
}

.thumbnail {
  border-radius: 4px;
  width: 100%;
  min-height: 62px;
  cursor: default;
}

.thumbnail > div {
  pointer-events: none;
}

.previewRemoveButtons {
  padding-top: 16px;
}

.previewButton {
  margin-bottom: 8px;
}

.removeButton {
  font-weight: 500;
  border: 1px solid #e2e5e8;
}

.printOptionSelections {
  /* flex: 1; */
  padding-left: 40px;
  width: 85%;
}

.designSubtitle {
  font-size: 15px;
}

.printItems {
  border: 2px dotted pink;
  margin-top: 24px;
  min-height: 116px;
}

.cartDesignWrapper {
  position: relative;
}

.deleteOverlay {
  position: absolute;
  height: 100%;
  z-index: 5000;
  background: linear-gradient(-45deg, #ECECEC, #ECECEC, #FFF, #ECECEC, #ECECEC);
  background-size: 200% 200%;
  animation: Gradient 2s ease infinite;
  opacity: 0.8;
}

@keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.printItemsWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 12px;
}

.printItemsWrapper > div:not(:last-of-type) {
  border-bottom: 1px solid #e2e5e8;
}

.printItemWrapper {
  display: flex;
  width: 100%;
  height: 124px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.printItemWrapper > div:first-of-type {
  width: 100%
}

.trashCan {
  width: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.trashCan > svg {
  cursor: pointer;
}

.addButton {
  display: flex;
  justify-content: center !important;
  align-items: center;
  margin-top: 12px;
}

.addButton > svg {
  margin-right: 13px;
}

.noPrintWarning {
  margin-top: 12px;
}

.noPrintWarning > span > span > strong {
  text-decoration: underline;
  cursor: pointer;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.infoAlertBox {
  width: 100%;
  margin-top: 48px;
  border-radius: 8px;
  border: solid 1px rgba(248, 161, 127, 0.4);
  background-color: #fffbfa;
}

.infoAlertBox > span {
  width: 100%;
}

.alertAnimationMessage {
  width: 559px;
  width: 100%;
  display: flex;
  margin-right: 18px;
  margin-left: 10px;
  font-family: Roboto;
  font-size: 14px;
  line-height: 1.43;
  position: relative;
  padding-right: 30px;
  align-items: center;
}

.alertAnimationMessage > svg > g {
  fill: easilGrayXDark;
}

.alertAnimationMessage > div {
  position: absolute;
  right: 5px;
}

.plusIconDisplay {
  margin-left: 28px;
  cursor: pointer;
}

.warnings > div:not(:first-child){
  margin-top: 15px;
}

.previewVideo {
  width: 100%;
  height: auto;
}