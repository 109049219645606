.wrapper {
  margin: 16px 16px 8px;
  padding-right: 16px;
  position: relative;
}

.label {
  background-color: #fff;
  display: inline-block;
  padding-right: 16px;
  position: relative;
  z-index: 2;
}

.divider {
  background-color: #e1e5e8;
  display: block;
  height: 1px;
  left: 0;
  position: absolute;
  top: 50%;
  width: 100%;
  z-index: 1;
}