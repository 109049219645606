@value colors: "../style.css";
@value (
  easilBlack,
  easilGrayXLight,
  easilGrayXXLight,
  easilGrayXDark,
  easilBlueDark,
  easilRed,
  fontDisabled,
  backgroundDisabled
) from colors;

.label {
  color: easilBlack;
  font-size: 13px;
  line-height: 1.85;
  text-align: left;
  padding-bottom: 9px;
}

.shareToolTip {
  width: 208px;
  opacity: 1 !important;
}

.dropdown {
  position: relative;
  display: inline-block;
  width: 100%;
}

.dropdown * {
  cursor: pointer;
}

.icon {
  padding-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inputDiv {
  display: flex;
  align-items: center;
  border: 1px solid easilGrayXLight;
  border-radius: 4px;
  line-height: 20px;
  height: 40px;
  padding: 10px 12px 10px 12px;
  cursor: pointer;
}

.inputDiv[data-is-active="true"] {
  border-color: #3184FC;
}

.inputDiv input {
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 500;
  color: easilBlack;
  outline: none;
  border: none;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  pointer-events: none;
}

.inputDiv input[disabled] {
  background-color: transparent;
  color: #757575;
}

.inputDiv[data-disabled="true"] input[disabled]{
  cursor: not-allowed;
}
.inputDiv[data-error="true"]{
  border: 1px solid easilRed;
}

.inputDiv[data-disabled="true"] {
  background-color: backgroundDisabled;
  color: fontDisabled;
  cursor: not-allowed;
}

.option:hover, .prependOption:hover {
  background-color: easilGrayXXLight;
}
.content {
  margin-top: 5px;
  width: 100%;
  max-height: 250px;
  border: 1px solid easilGrayXLight;
  border-radius: 4px;
  display: block;
  position: absolute;
  top: 39px;
  background-color: white;
  min-width: 160px;
  padding: 8px;
  z-index: 1;
  overflow: auto;
}

.doneButton {
  width: 100%;
}

.error {
  position: absolute;
  color: easilRed;
  font-size: 12px;
  left: 5px;
  top: 100%;
}

.optionList {
  padding-bottom: 5px;
  padding-top: 5px;
  margin-top: -5px;
  margin-bottom: 5px;
  border-bottom: 1px solid #e2e5e8;
  cursor: default;
}

.searchWrapper {
  display: flex;
  flex-direction: row;
  margin: 0 0px 12px;
  border: 1px solid #d1d4d6;
  border-radius: 4px;
  padding: 6px 10px;
  cursor: default;
  align-items: center;
}

.searchWrapper > input {
  cursor: text;
  margin-left: 3px;
}

.option, .prependOption {
  height: 36px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 8px;
  color: #57595d;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.option[disabled] {
 opacity: 0.64;
}

.prependDivider {
  width: 100%;
  height: 1px;
  background-color: #d1d4d6;
  margin: 4px 0;
}

.optionWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.infoIcon {
  margin-right: 8px;
}