.wrapper {
  width: 500px;
  padding: 32px;
}

.alert {
  margin: 24px auto;
}

.description {
  height: 48px;
  font-size: 15px;
  line-height: 1.6;
  color: #57595d;
}

.textareaLabel {
  height: 24px;
  font-size: 15px;
  line-height: 1.6;
  color: #7a7d81;
  margin: 24px auto 8px;
}

.textarea {
  resize: none;
  width: 100%;
  height: 100px;
  border-radius: 4px;
  border: solid 1px #d1d4d6;
  background-color: #ffffff;
  padding: 16px;
}

.textarea:focus {
  outline: none;
}
