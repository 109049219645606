@value colors: "~views/style.css";
@value easilBlack, easilGreenDarkAlphaHover from colors;

.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  min-height: 1074px;
}

.cartFlowWrapper {
  width: calc(100% - 370px);
  height: 100%;
}

.cartFlowCrumbs {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 95%;
  padding: 40px 0 24px 0;
}

.cartFlowCrumbs > p {
  padding-right: 10px;
  font-size: 16px;
  color: #7a7d81;
  transition: all 0.2s ease;
  font-family: 'Roboto-Regular';
}

.cartFlowCrumbs > p[data-is-past-section="true"] {
  color: easilBlack;
  cursor: pointer;
}

.cartFlowCrumbs > p[data-is-selected="true"] {
  font-family: Roboto-Medium;
}

.cartFlowCrumbs > p:not(:first-of-type) {
  padding-left: 10px;
}

.cartFlowSectionWrapper {
  display: flex;
  align-items: center;
  width: 95%;
  /* TODO: replace this height since it is just placeholder */
  min-height: 180px;
  border-bottom: 1px solid #e2e5e8;
  border-top: 1px solid #e2e5e8;
}

.nextButtonWrapper {
  display: flex;
  justify-content: flex-end;
  width: 95%;
  margin-top: 24px;
  position: relative;
}

.buttonHoverWrapper {
  position: relative;
  display: flex;
  justify-content: flex-end;
}

.buttonHoverWrapper[data-is-paypal="true"]:hover > button {
  background-color: easilGreenDarkAlphaHover;
}

.buttonBacking {
  background-color: #ffffff;
  pointer-events: none;
  z-index: 2;
  width: 100%;
  height: 100%;
  position: absolute;
}

.orderSummaryWrapper {
  width: 50%;
  /* offset the box so that its background can cover full right space */
  margin-right: -50%;
  height: 100%;
  border-left: 1px solid #e2e5e8;
  background-color: #fbfcfc;
}

.orderSummaryRightGuttering {
  width: 301px;
  height: 100%;
}

.loadingFullPage {
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingSection {
  composes: loadingFullPage;
  height: 100%;
}

.continueButton {
  z-index: 3;
}

.backButton {
  composes: continueButton;
  margin-right: 14px;
}

.backButton > svg {
  margin-left: 0px !important;
  margin-right: 13px;
}

.continueButton > *:first-child {
  margin-left: 13px;
}

.buttonLoading {
  margin-left: 0 !important;
  width: 138px;
}

.paypalButton {
  overflow: hidden;
  border-radius: 8px;
  position: absolute;
  height: 40px;
  width: 100%;
  z-index: 1;
}

.paypalButton > div {
  transform: scale(3);
}