.toggleVariables {
  --switch-background-color: #323334;
  --switch-button-color: #ffffff;
  --switch-button-width: 105px;
  --switch-button-height: 44px;
  --switch-height: 60px;
  --switch-width: 226px;
  --switch-button-border-radius: 8px;
  --switch-border-radius: 8px;
  --switch-border-color: #58595a;
  --switch-translation: 105px;
  --switch-box-shadow: none;
  --switch-left-margin-offset: 1.5px;
}

.toggleWrapper {
  display: flex;
  flex-direction: row;
  width: var(--switch-width);
  height: var(--switch-height);
  border-radius: var(--switch-border-radius);
  border: solid 1px var(--switch-border-color);
  background-color: var(--switch-background-color);
  align-items: center;
  position: relative;
}

.toggleSwitch {
  width: var(--switch-button-width);
  height: var(--switch-button-height);
  border-radius: var(--switch-button-border-radius);
  background-color: var(--switch-button-color);
  margin-left: calc(((var(--switch-height) - var(--switch-button-height)) / 2) - var(--switch-left-margin-offset));
  margin-right: calc((var(--switch-height) - var(--switch-button-height)) / 2);
  box-shadow: var(--switch-box-shadow);
  position: absolute;
  transition: transform 0.25s;
}

.toggle {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  flex: 1;
  z-index: 2;
  cursor: pointer;
  color: var(--switch-button-color);
  height: 100%;
}

.toggle[data-toggle-left="true"] {
  margin-left: calc((var(--switch-height) - var(--switch-button-height)) / 2);
}

.toggle[data-toggle-right="true"] {
  margin-right: calc((var(--switch-height) - var(--switch-button-height)) / 2);
}

.toggle[data-is-active="true"] {
  color: var(--switch-background-color);
  font-weight: 500;
}

.toggle[data-is-active="false"] {
  font-weight: 400;
  opacity: 0.72;
}

.toggle[data-is-disabled="true"] {
  cursor: auto !important;
}

.toggle[data-is-disabled="true"] > div:last-of-type {
  opacity: 0.72;
}

.toggleSwitchLeft {
  transform: translate(0px, 0px);
}

.toggleSwitchRight {
  transform: translate(var(--switch-translation), 0px);
  margin-left: calc(((var(--switch-height) - var(--switch-button-height)) / 2) +  (4 * var(--switch-left-margin-offset)));
}
