.planTile {
  min-width: 167px;
  display: inline-block;
  border-right: 1px solid #e6e9eb;
  flex: 0.5;
}

.membersCountTile {
  padding-left: 20px;
  min-width: 183px;
  display: inline-block;
  border-right: 1px solid #e6e9eb;
  flex: 0.5;
}

.pricePerMemberTile {
  padding-left: 20px;
  min-width: 255px;
  display: inline-block;
  border-right: 1px solid #e6e9eb;
  flex: 1;
}

.totalChargeTile {
  padding-left: 20px;
  min-width: 204px;
  display: inline-block;
  border-right: 1px solid #e6e9eb;
  flex: 1;
}

.renewDateTile {
  padding-left: 20px;
  min-width: 204px;
  display: inline-block;
  flex: 1;
}
