.teamSettingsModal {
  width: 650px;
  padding: 32px;
}

/* heading styling */
.teamSettingsModal > div:first-of-type {
  margin-bottom: 32px;
}

.teamSettingsModalWrapper {
  display: flex;
  height: 160px;
  width: 100%;
}

.teamLogoWrapper {
  height: 100%;
  /*  below could be updated to flex*/
  width: 224px;
  margin-right: 60px;
}

.teamDropdownsWrapper {
  height: 100%;
  flex: 1;
}

.logoWrapper {
  display: flex;
  justify-content: space-between;
  height: 92px;
}

.sectionTitle {
  margin-bottom: 8px;
  color: #8f9297
}

.teamLogo {
  width: 92px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.noAvatarChars {
  font-size: 40px;
}

.logoButtons {
  width: 108px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.teamCountry {
  margin-top: 24px;
}