@value colors: "~views/colors.css";
@value easilGrayLight from colors;

.inputTransparent {
  padding: 0px;
  color: inherit;
  background-color: transparent;
  width: 100%;
  border: none;
  outline: none;
  height: 100%;
  width: 100%;
}

.inputTransparent:focus {
  outline: none;
}

.inputTransparent:disabled {
  outline: none;
  background-color: transparent;
  width: 100%;
  border: none;
  color: inherit;
}

.inputTransparent::placeholder {
  color: easilGrayLight;
}
