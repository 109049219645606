@value colors: "../../colors.css";
@value easilBlack, easilBlue, easilGrayXLight, easilGrayXXLight, easilGrayDark, easilGrayXDark from colors;
.upgradeButton {
  margin-right: 32px;
}

.upgradeLink {
  display: inline-block;
}

.logo {
  height: 40px;
  padding: 4px;
  width: 40px;
  display: flex;
  opacity: 1;
  transition: opacity 100ms ease;
}

.logo:hover {
  opacity: 0.88;
}

.logo a {
  display: block;
  height: 32px;
}

.logo svg {
  height: 32px;
  width: 32px;
}

.navlink {
  color: white;
  opacity: 0.72;
  padding: 6px 10px 6px 10px;
  transition: opacity 100ms ease;
}

.navlink:not(:last-child) {
  margin-right: 4px;
}

.navRowRight {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.navlinkActive {
  opacity: 1 !important;
  font-weight: 500 !important;
}

.navlink:hover,
.navlink:active,
.navlink:focus {
  opacity: 1;
}

.navRow {
  align-items: center;
  display: flex;
  padding-top: 24px;
}

.title {
  color: white;
  font-size: 32px;
  line-height: 32px;
  margin-top: 63px;
  font-weight: 300;
}

.icon {
  position: relative;
}

.icon {
  height: 24px;
  margin-right: 24px;
  padding: 2px;
  position: relative;
  width: 24px;
}

.navIcon {
  height: 24px;
  width: 24px;
  align-items: center;
  cursor: pointer;
  display: flex;
  opacity: 0.72;
  transition: opacity 100ms ease;
}

.navIcon:hover {
  opacity: 1;
}

.navIcon:not(:last-child) {
  margin-right: 24px;
}

.navIcon[data-disabled="true"] {
  opacity: 0.2;
  pointer-events: none;
}

.iconWithDropDown {
  width: auto;
}

.teamMenuDropdown {
  height: 24px;
  position: relative;
}

.avatar {
  opacity: 1;
  transition: opacity 100ms ease;
}

.avatar:hover {
  opacity: 0.88;
}

.avatarActive {
  border-color: easilBlue !important;
}

/* Team Dropdown Styles */

.teamDropdownTitle {
  margin-right: 16px;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: #ffffff;
}

.teamDropDownButton {
  align-items: center;
  color: #fff;
  display: flex;
}

.avatarWithImage {
  box-shadow: 0 0 0 2px #fff inset;
}

.bottomPanel {
  display: flex;
  justify-content: space-between;
  margin-top: 34px;
  min-height: 40px;
}

.bottomPanel > div {
  display: flex;
  align-items: center;
}

.bottomPanel > div > a {
  font-family: Roboto;
  font-size: 16px;
  line-height: 1.5;
  color: #ffffff;
  position: relative;
}

.bottomPanelIcon {
  position: relative;
  width: 100%;
  height: 100%;
}

.createDesignButton {
  margin-right: 14px;
}

.marginRight {
  margin-right: 14px !important;
}

.notification {
  margin-right: 33px;
}

.dropdownLinkWrapper{
  position: relative;
}

.dropdownLink {
  font-family: Roboto;
  font-size: 16px;
  line-height: 1.5;
  color: #ffffff;
  opacity: 0.72;
  padding: 6px 10px 6px 10px;
  transition: opacity 100ms ease;
  cursor: pointer;
  margin-right: 4px;
}

.dropdownLink:hover {
  opacity: 1;
}

.dropdownLink > svg {
  margin-left: 6px;
}

.dropdownLink[data-is-active="true"] {
  opacity: 1 !important;
  font-weight: 500 !important;
}

.dropdown {
  position: absolute;
  width: 168px;
  padding: 12px;
  border-radius: 8px;
  border: solid 1px #d1d4d6;
  background-color: #ffffff;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dropdownOption {
  width: 144px;
  height: 40px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 2.25;
  text-align: center;
  color: #57595d;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropdownOption:hover {
  background-color: #f5f5f6;
}

.linkEllipsisWrapper {
  height: 24px;
  margin-right: 24px;
  margin-left: 6px;
  cursor: pointer;
  position: relative;
}

.linkEllipsisWrapper > svg {
  opacity: 0.72;
}

.linkEllipsisWrapper:hover > svg {
  opacity: 1;
}

.linkPopout {
  width: 171px;
  position: absolute;
  background-color: white;
  padding: 12px 0px;
  border-radius: 8px;
  transform: translate(-75.5px);
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #d1d4d6;
  cursor: default;
  z-index: 99;
}

.popoutNavlinkWrapper {
  display: flex;
  height: 40px;
  width: 147px;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
}

.popoutNavlinkWrapper:hover {
  background-color: #f5f5f8;
}

.popoutNavlink {
  padding: 6px 10px 6px 10px;
  transition: opacity 100ms ease;
  font-size: 16px;
  font-weight: 500;
  color: #57595d;
}

.popoutNavlinkActive {
  font-weight: 500 !important;
}

.popoutLinksSeperator {
  height: 1px;
  width: 100%;
  border-top: 1px solid #d1d4d6;
  margin: 6px 0;
}

.cartContentIndicator {
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #67dae3;
  border-radius: 100%;
  border: 2px solid #1c1d1e;
  left: 13px;
  top: -8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Roboto;
  font-size: 12px;
  color: #1c1d1e;
  font-weight: bold;
}