:root {
  --delta-scroll-left: 0px;
  --animation-duration: 7s;
}

.scrollingTextWrapper {
  width: 100%;
  display: inline-block; /* important */
  white-space: nowrap;
  overflow: hidden;
}

/* when not hovering show ellipsis */
.scrollingTextWrapper:not(:hover) {
  text-overflow: ellipsis;
}

/* animate on either hover or focus */
.scrollingTextWrapper:hover span {
  display: inline-block;
  animation: scroll-text var(--animation-duration);
}

/* define the animation */
@keyframes scroll-text {
  0% {
    transform: translateX(0%);
  }
  75% {
    transform: translateX(var(--delta-scroll-left, 0px));
  }
  95% {
    transform: translateX(var(--delta-scroll-left, 0px));
  }
  100% {
    transform: translateX(0%);
  }
}