@value colors: "../../colors.css";
@value easilGrayXXLight from colors;

.wrapper {
  border: 1px solid transparent;
  border-radius: 4px;
  display: flex;
  height: 138px;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 138px;
}

.wrapper:hover {
  cursor: pointer;
}

.video {
  max-height: 100%;
  max-width: 100%;
  background-image: none;
  background-color: transparent !important;
  background-size: 10px 10px;
  background-position: 0 0, 15px 15px;
  position: relative;
  z-index: 1;
}

.loader {
  background-color: easilGrayXXLight;
  bottom: 0;
  display: flex;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}
