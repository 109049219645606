@value colors: "~views/style.css";
@value (
  easilBlack,
  easilGrayLight,
  easilGrayXLight,
  easilGrayXDark,
  easilGrayDark,
  easilBlue,
  easilBlueDark,
  easilRed
) from colors;

.pagination {
  margin-top: 48px;
  color: easilGrayDark;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.pagination[data-detailed] {
  justify-content: space-between;
}

.pageSize {
  display: flex;
  width: 32px;
  height: 32px;
  justify-content: center;
  padding: 4px;
  cursor: pointer;
  font-family: Roboto;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-align: center;
  color: #2c2e2f;
  margin: 4px 1px;
}

.pageSize[data-active="true"]{
  border: 1px solid easilGrayXLight;
  border-radius: 6px;
  font-weight: 500;
  /* margin: 0px; */
  margin-top: 1px;
  color: easilBlack;
}

.views {
  flex: auto;
  align-items: center;
  display: flex;
}

.info {
  flex: auto;
}

.pages {
  align-items: center;
  display: flex
}

.navigation {
  cursor: pointer;
  margin: 0 24px;
  font-family: Roboto;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #57595d;
}

.navigation[data-not-allowed="true"] {
  pointer-events:none;
}
