@value colors: "~views/style.css";
/* easilGrayDark is used on the JSX */
@value easilBlack, easilGrayXDark from colors;
.cell {
  flex: 1 0 auto;
  width: 25%;
  overflow: hidden;
}

.cell:nth-child(4n+2) {
  width: 40%;
}

.cell:nth-child(4n) {
  width: 10%;
  display: flex;
  justify-content: flex-end;
}

.header {
  color: easilBlack;
  display: flex;
  flex: 1 0  100%;
  flex-wrap: wrap;
  padding-bottom: 25px;
}

.row {
  font-size: 14px;
  display: flex;
  flex: 1 0  100%;
  flex-wrap: wrap;
  padding-top: 28px;
}

.table {
  display: flex;
  flex: 1 0  100%;
  flex-wrap: wrap;
  color: easilGrayXDark;
  position: relative;
}
