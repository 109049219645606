@value colors: "../../colors.css";
@value easilBlue from colors;

.addTeamMembersButtonWrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.addTeamMembersButtonTitle {
  color: easilBlue;
  font-weight: 500;
  margin-left: 8px;
}