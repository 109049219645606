@value colors: "~views/colors.css";
@value easilBlack, easilGrayXXXLight from colors;

.wrapper {
  position: relative;
  display: inline-block
}

.button {
  padding: 12px 16px;
  height: 48px;
  color: #2c2e2f;
  background-color: white;
  box-shadow: 0px 2px 2px rgba(21, 21, 22, 0.12);
  border-radius: 8px;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.6;
  color: easilBlack;
  margin: 0px 22px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.menu {
  display: flex;
  margin-left: auto;
  border-radius: 4px
}

.menu:hover svg rect {
  fill: #e2e5e8 !important;
}

.menu:hover svg circle {
  fill: #57595d !important;
}

.menu[data-is-active="true"] svg rect {
  fill: #e2e5e8 !important;
}

.menu[data-is-active="true"] svg circle {
  fill: #57595d !important;
}

.dropDownPopout {
  top: 40px;
  right: 16px;
  width: auto;
  border-radius: 8px;
  border: 1px solid easilGrayXXXLight;
}
