@value colors: "../../colors.css";
@value easilBlack, easilGrayXLight from colors;

.card {
  min-height: 360px;
  max-width: 464px;
  width: 100%;
}

.logo {
  display: flex;
  align-items: center;
  margin-bottom: 35px;
  justify-content: center;
}

.title {
  color: easilBlack;
  font-size: 28px;
}

.columns {
  display: flex;
}

.col {
  padding-right: 32px;
  width: 50%;
  flex: auto;
  border-right: 1px solid easilGrayXLight;
}

.form {
  width: 100%;
}

.form > div ~ div {
  margin-top: 16px;
}

.links {
  display: flex;
  align-items: center;
}

.links a {
  color: easilBlack;
}

.footer {
  display: flex;
  flex-wrap: wrap;
  font-size: 13px;
  line-height: 21px;
}

.footer a {
  margin:0px 3px 0px 3px;
  color: easilGrayDark;
}

.login {
  margin-top: 62px;
}

.login a {
  margin:0px 3px 0px 3px;
  color: easilBlack;
}

.actions {
  align-content: center;
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
}