@value colors: "../../colors.css";
@value easilBlack, easilBlue, easilGray, easilGrayXLight, easilGrayXDark, easilRed from colors;
@value globalVars: "~views/style.css";
@value layoutWidth from globalVars;

.header {
  background: #f0eeff url("./background.png") center center no-repeat;
  background-size: cover;
  height: 350px;
  left: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  position: relative;
  right: 50%;
  width: 100vw;
  z-index: 1;
}

.testimonialsWrapper {
  color: easilBlack;
  margin-bottom: 64px;
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.testimonialsHeader {
  width: 100%;
  height: 109px;
  box-shadow: inset 0 1px 0 0 rgba(28, 29, 30, 0.04);
  background-color: #f5f5f8;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.testimonialsWrapper header {
  font-family: Roboto;
  font-size: 22px;
  font-weight: bold;
  text-align: center;
}

.testimonials {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  margin-top: 39px;
  width: 1024px;
}

.testimonial {
  position: relative;
  width: 288px;
}

.quotationMark {
  color: easilGrayXLight;
  font-size: 64px;
  left: 0;
  line-height: 1;
  position: absolute;
  top: 0;
}

.testimonialContent {
  color: #2c2e2f;
  font-family: Roboto-Italic;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 1.6;
  letter-spacing: normal;
  white-space: break-spaces;
  height: 194px;
}

.testimonialCitation {
  margin-top: 32px;
  font-family: Roboto;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: #2c2e2f;
}

.testimonialCompanyName {
  font-size: 11px;
  font-weight: 500;
  margin-top: 4px;
  font-family: Roboto;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: #2c2e2f;
}

.testimonialWebsite {
  font-size: 11px;
  font-weight: 500;
  margin-top: 4px;
  font-family: Roboto;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: #2c2e2f;
}

.upgradeModalContainer {
  width: 650px;
  border-radius: 8px;
  background-color: #ffffff;
  padding: 32px;
}

.upgradeModalWrapper {
  color: easilBlack;
  margin-top: -16px;
  display: flex;
  flex-direction: column;
}

.cardLabelStyle {
  font-size: 14px;
  line-height: 1.43;
  color: #7a7d81;
}

.upgradeModalFormLabel {
  composes: cardLabelStyle;
  margin-bottom: 8px;
}



.upgradeModalPromoCode {
  padding-bottom: 16px;
}

.upgradeModalPromoCodeAlert {
  font-size: 14px;
  margin: 24px 0 8px;
}

.upgradeModalPaymentDetails h2 {
  font-size: 20px;
  font-weight: normal;
  margin-top: 0px;
}

.upgradeModalFormInline {
  display: flex;
}

.upgradeModalFormInline + .upgradeModalFormInline {
  margin-top: 16px;
}

.upgradeModalFormInline > * + * {
  margin-left: 16px;
}

.upgradeModalFormInlineGroup {
  width: 100%;
}

.upgradeModalFooter {
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
}

.upgradeModalCancelButton {
  margin-right: 16px;
}

.infoAlert {
  align-items: flex-start !important;
}

.upgradeModalShowPaymentFormLink {
  cursor: pointer;
  height: 24px;
  font-family: Roboto;
  font-weight: 500;
  line-height: 1.71;
  color: #2c6fcf;
}

.errors {
  margin-top: 16px;
}

.error {
  color: easilRed;
}

.error + .error {
  margin-top: 4px;
}


.subscriptionDescriptionSection {
  width: layoutWidth;
  margin: auto
}

.upgradeDetails {
  width: 586px;
  height: 84px;
  border-radius: 8px;
  border: solid 1px #e2e5e8;
  background-color: #fcfcfd;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 24px;
}

.upgradeDetail {
  height: 52px;
  padding-right: 20px;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  border-right: 1px solid #dddddd;
}

.upgradeDetail:last-of-type {
  border-right: none;
}

.upgradeDetailTitle {
  font-size: 14px;
  line-height: 1.43;
  display: flex;
  align-items: center;
  color: #57595d;
}

.upgradeDetailTitle > div > svg {
  margin-left: 6px;
}

.upgradeDetailValue {
  font-size: 20px;
  line-height: 1.4;
  color: #2c2e2f;
}
