@value colors: "../../colors.css";
@value easilBlue from colors;

.teamCreateButtonWrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.teamCreateTitle {
  color: easilBlue;
  font-weight: 500;
  margin-left: 8px;
}

.teamCreateButtonWrapper[data-is-divider="true"] {
  border-right: 1px solid #d1d4d6;
  padding-right: 16px;
}