.wrapper {
  width: 100%;
  background-color: white;
  padding: 40px 0px;
}

.header {
  height: 28px;
  font-size: 20px;
  line-height: 1.4;
  color: #2c2e2f;
  margin-bottom: 12px;
}

.tilesSection {
  height: 52px;
  width: 100%;
  display: flex
}

.tileTitle {
  height: 20px;
  font-size: 14px;
  line-height: 1.43;
  color: #57595d;
  display: inline-block;
}

.tileTooltip {
  display: inline-block;
  margin-left: 6px;
}

/* second last because the last div is the tooltip */
.tilesSection > div:nth-last-child(2) {
  border-right: none !important;
}

.tileBody {
  margin-top: 4px;
  height: 28px;
  font-size: 22px;
  line-height: 1.27;
  color: #2c2e2f;
}

.updateCard {
  display: inline-block;
  margin-top: 24px;
  width: 146px;
  height: 24px;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.6;
  color: #2c6fcf;
  cursor: pointer;
}

.cancelPlan {
  display: inline-block;
  cursor: pointer;
  padding-left: 16px;
  height: 24px;
  font-size: 15px;
  line-height: 1.6;
  color: #8f9294;
  border-left: 1px solid #e6e9eb;
}

.info {
  margin-top: 24px;
}

.button {
  height: 40px;
}
