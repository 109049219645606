.photoFrameMasking {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: black;
  z-index: 3;
  opacity: 0.5;
}

.image {
  overflow: visible;
}

.resizeBox {
  position: fixed;
  z-index: 12;
}

.resizeBoxAbsoluteWrapper {
  position: absolute;
}