@value colors: "../../colors.css";
@value easilGrayXDark, easilGrayXLight from colors;

.wrapper {
  align-items: center;
  align-self: flex-start;
  background-color: #fff;
  border: 1px solid easilGrayXLight;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  height: 48px;
  justify-content: center;
  position: relative;
  text-align: center;
  transition: all 300ms ease-in-out;
  width: 48px;
}

.wrapper[disabled],
.wrapper[disabled] * {
  opacity: 0.6;
  cursor: not-allowed;
}

.wrapper:hover {
  border-color: easilGrayXDark;
}

.wrapper[disabled]:hover {
  border-color: easilGrayXLight;
}

.cancelIcon {
  background-color: #fff;
  border: 1px solid easilGrayXLight;
  border-radius: 50%;
  display: none !important;
  height: 24px;
  position: absolute;
  right: -8px;
  top: -8px;
  width: 24px;
  z-index: 2;
}

.wrapper:hover .cancelIcon {
  display: flex !important;
}