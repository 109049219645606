@value colors: "../style.css";
@value (
  easilBlack,
  easilGrayXLight,
  easilBlueDark,
  easilRed,
  backgroundDisabled,
  fontDisabled
) from colors;

.div {
  position: relative;
  font-size: 14px;
  border: 1px solid easilGrayXLight;
  border-radius: 4px;
  line-height: 20px;
  color: easilBlack;
  min-height: 80px;
  width: 100%;
  padding: 10px 12px 10px 12px;
}

.textAreaDiv {
  width: 100%;
  max-width: 256px;
  max-height: 46px;
  background-color: #f5f5f8;
  border-radius: 4px;
  margin-right: 0;
  border: none;
  outline:0px !important;
  -webkit-appearance:none;
  box-shadow: none !important;
  box-sizing: border-box;
  resize: none;
  transition: max-height 0.2s ease-in-out;
}

.textAreaDiv[data-isfocussed="true"] {
  max-height: 45vh !important;
}

.textAreaDiv[data-hasinputerror="true"] {
  display: flex;
  justify-content: center;
  align-items: center;
}

.errorWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.div[data-disabled="true"] {
  background-color: backgroundDisabled;
  cursor: not-allowed;
}

.div textarea[disabled] {
  background-color: backgroundDisabled;
  color: fontDisabled;
  cursor: not-allowed;
}

.div textarea {
  padding: 0px;
  color: easilBlack;
  width: 100%;
  border: none;
  vertical-align: top;
  resize: none;
}

.div textarea:focus {
  outline: none;
}

.div[data-error="true"]{
  border: 1px solid easilRed;
}

.label {
  color: easilBlack;
  font-size: 13px;
  line-height: 1.85;
  text-align: left;
  padding-bottom: 9px;
}

.error {
  position: absolute;
  color: easilRed;
  font-size: 12px;
  top: 100%;
  left: 5px;
}

.alertToolTip {
  font-size: 12px;
  width: 226px;
}
