@value colors: "../style.css";
@value easilGrayXXLight, easilRed from colors;

.inputfile {
  display: none
}

.inputfile ~ label {
  cursor: pointer;
}

.error {
  margin-top: 8px;
  text-align: center;
  color: easilRed;
  font-size: 12px;
  animation: fade 0s ease-in 5s forwards;
  animation-fill-mode: forwards;
}

.profilePictureHover {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(255,255,255, 0.35) !important;
  border-radius: 50%; 
  z-index: 1;
  pointer-events: none;
}

.imageWrapper {
  position:relative;
  display: flex;
  width: 100%;
  height: 100%;
}
.updateProfileButton {
  border: none;
  border-radius: 4px;
}

@keyframes fade {
  to {
    width:0;
    height:0;
    overflow:hidden;
  }
}
@-webkit-keyframes fade {
  to {
    width:0;
    height:0;
    visibility:hidden;
  }
}
