.wrapper {
  height: 64px;
  border-radius: 8px;
  border: solid 1px #abd0e8;
  background-color: #ecf5fa;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  padding: 12px 16px;
  cursor: pointer;
}

.text {
  width: 220px;
  height: 40px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #57595d;
  flex: 1 0 auto;
}

.count {
  font-weight: 500;
  color: #2c2e2f;
}

.teamName {
  font-weight: 500;
  color: #2d70cf;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
  display: inline-block;
}
