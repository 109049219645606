.collectionPreviewModal {
  width: 850px;
  height: 650px;
  border-radius: 8px;
  /* need to set a transparent background since this inherits white */
  background-color: rgb(0,0,0,0);
  padding: 0;
  overflow: hidden;
}

.collectionPreviewModalContent {
  white-space: pre-line;
  font-family: Roboto;
  font-size: 15px;
  line-height: 24px;
  display: flex;
  color: #57595d;
  flex-direction: row;
  height: 650px;
}

.designPreviewWindow {
  width: 575px;
  height: 650px;
  background-color: #57595d;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.designPreviewWindow > img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}

.infoPanel {
  height: 100%;
  width: 275px;
  display: flex;
  flex-direction: column;
}

.infoPanelTop {
  width: 275px;
  height: auto;
  background-color: #ffffff;
  padding: 32px 32px 24px 32px;
}

.infoPanelBottom {
  width: 275px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  overflow: hidden;
  justify-content: flex-end;
}

.selectedDesignTitle {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #272829;
}

.selectedDesignSize {
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #7a7d81;
  margin-top: 12px;
}

.infoPanelButtons {
  text-align: center;
  width: 275px;
  max-height: 218px;
  border-top: 1px solid #e2e5e8;
  background-color: #f9f9fa;
  padding: 24px 32px 32px 32px;
}

.infoPanelButtons > div {
  margin-top: 12px;
}

.infoPanelButtons > div:first-of-type {
  margin-top: 0;
}

.sizeOption {
  margin: 4px 6px 4px 0;
  padding: 0 12px;
  border-radius: 8px;
  border: solid 1px #e2e5e8;
  background-color: #f9f9fa;
  cursor: pointer;
  font-size: 12px;
  color: #7a7d81;
}

.sizeOption > span {
  color: #afb3b6;
}

.sizeOption[data-is-highlighted="true"] {
  border: solid 1px #3184fc;
  background-color: rgba(45, 112, 207, 0.04);
  color: #3184fc !important;
}

.sizeOption[data-is-highlighted="true"] > span {
  color: #a1c6ff;
}

.sizeCategories {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #ffffff;
  margin-right: -5px;
  overflow-y: scroll;
  padding: 0px 5px 0px 32px;
  margin-bottom: 12px;
  margin-right: 24.4px;
}

.sizeCategory {
  display: flex;
  flex-direction: column;
}

.sizeCategoryTitle {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #272829;
}

.designSizeOptions {
  display: flex;
  flex-wrap: wrap;
  margin-top: 4px;
  margin-bottom: 12px;
}

.editButton {
  height: 40px;
  width: 211px;
  background-color: #3184fc;
  border: 1px solid #3184fc;
  justify-content: center !important;
  box-shadow: none;
}

.editButton:hover {
  background-color: #2e7def;
  border: 1px solid #2e7def;
}

.editButtonTitle {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  margin: 0px 0px 0px 8px;
}

.closeModalButton {
  position: absolute;
  margin-left: 212px;
  margin-top: -20px;
  cursor: pointer;
  height: 18px;
  width: 18px;
}

.loadingWrapper {
  width: 850px;
  height: 650px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.previewOverlay {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex
}

.relativeWrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.shiftButton {
  position: absolute;
  border-radius: 8px;
  top: calc(50% - 16px);
  width: 32px;
  height: 32px;
  background-color: #484a4c;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.shiftButton:hover {
  background-color: #2c2e2f;
}

.nextButton {
  composes: shiftButton;
  right: 18px;
}

.nextButton > svg {
  margin-right: -2px;
}

.previousButton {
  composes: shiftButton;
  left: 18px;
}

.previousButton > svg {
  margin-left: -2px;
}
