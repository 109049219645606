.planTile {
  min-width: 341px;
  display: inline-block;
  border-right: 1px solid #e6e9eb;
  flex: 0.5;
}

.trialEndDateTile {
  padding-left: 20px;
  width: 341px;
  display: inline-block;
  border-right: 1px solid #e6e9eb;
}

.remainingDaysTile {
  padding-left: 20px;
  width: 255px;
  display: inline-block;
}

.remainingDaysTile > div:last-child{
  color: #eb6063 !important;
}
