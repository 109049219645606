@value colors: "~views/colors.css";
@value easilBlue from colors;

.addSmartTextModal {
  width: 450px;
  height: 352px;
}

.updateSmartTextModal {
  width: 450px;
  height: 440px;
}
.modalFields {
  margin-bottom: 32px;
}
.modalInputRow {
  margin-bottom: 16px;
}
.modalInputWrapper {
  border-radius: 4px;
}
.modalInput {
  font-weight: 500;
}
.modalInput::placeholder {
  font-weight: normal;
}
.modalButtons {
  margin-top: 32px;
}
.info {
  margin-bottom: 24px;
  font-size: 14px;
}
.infoIcon {
  margin-right: 12px;
}

.smartTextIdLabel {
  display: flex;
  justify-content: space-between;
}

.tooltip {
  margin-top: 0px;
}