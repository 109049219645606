.popout {
  right: 0;
  margin-right: 16px;
  top: 48px;
}

.folderPopoutActions {
  padding: 12px 8px;
}

.sharedFolderTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.sharedFolderTitle > svg {
  margin-right: 8px;
}

.sharedTitle {
  margin-left: 16px;
}