@value colors: "../style.css";
@value easilBlack from colors;

.empty {
  /* background: linear-gradient(-45deg, #ECECEC, #ECECEC,#ECECEC, #ECECEC, #FFF, #ECECEC, #ECECEC, #ECECEC, #ECECEC); */
    /* background-size: 400% 400%; */
  background-color: #ECECEC;
  height: 31px;
  width: 90px;
  border-radius: 4px;
  /* animation: fadein 2s, Gradient 3s ease infinite; */
}

@keyframes Gradient {
	0% {
		background-position: 0% 50%
	}
	50% {
		background-position: 100% 50%
	}
	100% {
		background-position: 0% 50%
	}
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}
