@value colors: "~views/colors.css";
@value easilBlack, easilGrayXDark, easilGrayXLight, easilGrayXXXLight from colors;

/* ADD BUTTON */

.addButtonContainer {
  cursor: pointer;
  display: inline-block;
  margin-bottom: 24px;
}

.addButton {
  align-items: center;
  display: flex;
}

.addButtonPlus {
  align-items: center;
  background: white;
  border: 1px dashed easilGrayXLight;
  border-radius: 32px;
  display: flex;
  height: 64px;
  justify-content: center;
  transition: text-decoration 0.2s;
  width: 64px;
}

.addButton:hover .addButtonText {
  text-decoration: underline;
}

.addButtonText {
  font-weight: 500;
  margin: 0 12px;
}

/* COLOR GROUP */

.colorsGroupName {
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  height: 32px;
  margin: -6px 0 6px -6px;
  padding: 0 6px;
}
.colorsGroupName > span {
  max-width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.colorsGroupName > svg {
  margin-left: 16px;
}
.colorsGroupName:hover {
  background: whitesmoke;
}

.colorsGroupRename {
  align-items: center;
  display: flex;
  margin: -6px 0 6px -6px;
}
.colorsGroupRename > input {
  border: 1px solid easilGrayXXXLight;
  border-radius: 4px;
  line-height: 24px;
  margin-right: 6px;
  padding: 3px 6px;
}
.colorsGroupRename > input:focus {
  outline: none;
}
.colorsGroupRenameButton {
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  height: 32px;
  justify-content: center;
  width: 32px;
}
.colorsGroupRenameButton:hover {
  background: whitesmoke;
}

.colorsGroupColors {
  display: flex;
  flex-wrap: wrap;
  margin: -6px;
}
.colorsGroupColors > div {
  margin: 6px;
}

/* COLOR (IN GROUP) */

.colorsGroupColor {
  position: relative;
}

.colorsGroupColorCircle {
  border-radius: 32px;
  cursor: pointer;
  height: 64px;
  width: 64px;
}
.colorsGroupColorCircle[data-is-white="true"] {
  border: 1px solid easilGrayXXXLight;
}

.colorsGroupColorPicker {
  margin-left: -118px;
  position: absolute;
  top: 100%;
  z-index: 1;
}

.colorsGroupColorRemove {
  align-items: center;
  background: white;
  border: 1px solid easilGrayXXXLight;
  border-radius: 12px;
  box-shadow: 0 2px rgba(0, 0, 0, 0.1);
  display: none;
  height: 24px;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  width: 24px;
}
.colorsGroupColorRemove:hover {
  border: 1px solid #ccc;
  box-shadow: 0 2px rgba(0, 0, 0, 0.2);
}
.colorsGroupColor:hover .colorsGroupColorRemove {
  display: flex;
}

.popoutMenu {
  width: 150px;
}

.popoutMenu > div {
  width: 100%;
}

