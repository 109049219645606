@value colors: "~views/colors.css";
@value easilGrayDark from colors;

.scrollable {
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;
  scrollbar-width: none;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.scrollable::-webkit-scrollbar {
    width: 0;
    height: 0;
}
.scrollable >
*,
*:before,
*:after {
  scrollbar-width: none;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}

.scrollable::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.scrollable > *:after {
  scrollbar-width: none;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.scrollable > *::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.scrollable .contentWrapper {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: scroll;
  position: relative;
}

.scrollerY {
  width: 8px;
  top: 0px;
  right: 8px;
  z-index: 3;
  cursor: pointer;
  position: absolute;
  border-radius: 5px;
  background: #C5C8CA;
  transition: top .08s;
  min-Height: 24px;
}

.scrollerX {
  height: 8px;
  bottom: 8px;
  left: 0px;
  z-index: 3;
  cursor: pointer;
  position: absolute;
  border-radius: 5px;
  background: #C5C8CA;
  transition: top .08s;
  min-Width: 24px;
}

/* empty for now, here for target reasons */
.content {

}

.scrollFold > div {
  z-index: 2;
}