@value colors: "~views/colors.css";
@value easilBlack, easilGrayXLight from colors;

.dropDownWrapper {
  width: 200px;
  border-radius: 4px;
  box-shadow: 0 2px 3px 0 rgba(15, 15, 16, 0.08);
  border: solid 1px var(--silver);
  display: flex;
  flex-direction: column;
  padding: 24px 16px;
  overflow: visible;
}

.dropDownWrapper > div {
  height: 24px;
  font-family: Roboto;
  font-size: 14px;
  line-height: 1.71;
  color: easilBlack;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.dropDownWrapper > div > div {
  margin-left: 11px;
  height: 16px;
}

.dropDownWrapper > div > span {
  cursor: pointer;
}

.dropDownWrapper > :nth-child(2) {
  margin-top: 12px;
}
