@value colors: "~views/colors.css";
@value (
  easilBlack,
  easilBlueDeep,
  easilGrayXXXLight,
  easilGrayXXXXLight
) from colors;

.headerWrapper {
  background-color: easilGrayXXXXLight;
  border-bottom: solid 1px easilGrayXXXLight;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 23px;
  padding-right: 8px;
  height: 55px;
}

.headerTitle {
  color: easilBlack;
  font-family: Roboto;
  font-size: 17px;
  font-weight: 500;
  line-height: 1.41;
}

.headerButton {
  color: easilBlueDeep;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
}

.iconBoundingBox {
  align-items: center;
  display: flex;
  height: 24px;
  justify-content: center;
  width: 24px;
  margin-right: 3px;
}

.selectionItem {
  padding: 6px 12px;
  font-family: Roboto;
  font-size: 16px;
  line-height: 1.5;
  color: #57595d;
}

.selectionItem[data-no-click="true"] {
  pointer-events: none;
}

.selectionItem:first-of-type {
  margin-top: 6px;
}

.selectionItem:last-of-type {
  margin-bottom: 6px;
}

.selectionItem:hover {
  border-radius: 4px;
  background-color: #f9f9fa;
  cursor: pointer;
}

.selectionItemWrapper {
  padding: 12px 12px;
  max-height: 394px;
  overflow-y: scroll;
}

.popout {
  position: fixed;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px 0 rgba(21, 21, 22, 0.08);
  border: solid 1px #FFFFFF;
  overflow-y: hidden !important;
}
