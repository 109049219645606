.overflowText {
  max-width: 640px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.overrideRow {
  max-height: 88px;
  border: 1px solid #d1d4d6;
  border-radius: 8px;
  margin-bottom: 12px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.overrideRowText {
  display: flex;
  justify-content: space-between;
}

.smartTextRow {
  height: 88px;
  background-color: #ffffff;
  border: 1px solid #d1d4d6;
  border-radius: 8px;
  margin-bottom: 12px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.smartTextRowText {
  display: flex;
  justify-content: space-between;
}

.whiteRowTop > div:first-of-type {
  max-width: calc(100% - 80px);
}

.smartTextValue {
  composes: overflowText;
  color: #2c2e2f;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  margin-bottom: 4px;
}

.smartTextName {
  color: #7a7d81;
  font-size: 13px;
  line-height: 18px;
}

.smartTextRowTextTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pencilIconWrapper {
  cursor: pointer;
  border-radius: 4px;
  margin-right: 12px;
  height: 24px;
  width: 24px;
}

.pencilIconWrapper:hover {
  background: whitesmoke
}

.trashCanIconWrapper {
  cursor: pointer;
  border-radius: 4px;
  height: 24px;
  width: 24px;
}

.trashCanIconWrapper:hover {
  background: whitesmoke;
}

.rowIcons {
  display: flex;
  justify-content: space-between;
}