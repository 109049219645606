@value colors: "../../colors.css";
@value easilBlack, easilBlue, easilGrayXLight, easilGrayXXLight, easilGrayDark, easilGrayXDark from colors;

.subHeaderWrapper {
  background-color: easilBlack;
  color: white;
  display: flex;
  flex-direction: column;
}

.subHeaderTitle {
  font-family: Roboto;
  font-size: 32px;
  font-weight: 300;
  line-height: 1.19;
  color: #ffffff;
  display: flex;
  flex-direction: row;
}

.subHeaderTitlePrefix {
  margin-right: 10px;
  max-width: 863px;
  max-height: 38px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
