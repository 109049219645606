@value colors: "~views/colors.css";
@value easilBlack from colors;

.preview {
  border-bottom: 1px solid #e6e8ea;
  color: easilBlack;
  font-size: 36px;
  padding-bottom: 24px;
  display: flex;
  flex-direction: column;
}

.headerSubtitle {
  height: 20px;
  font-family: Roboto;
  font-size: 14px;
  line-height: 1.43;
  color: #888888;
}

.title {
  max-width: 505px;
  overflow: hidden;
  text-overflow: ellipsis;
}