@value colors: "../../colors.css";
@value easilBlue, easilBlack, easilGrayXDark, easilGrayDark from colors;

.topRowLoading {
  flex: 1 0 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.topRowLoading {
  flex: 1 0  100%;
  margin-top: 16px;
}

/* .topRowSubTitle {
  color: easilGrayDark;
} */

.item {
  color: easilBlack;
  display: inline-block;
  cursor: pointer;
}

.title {
  margin-right: auto;
  display: flex;
  align-items: center;
  width: calc(100% - 482px);
}

.topRowPrimary {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.topRowPrimary > *:first-child {
  margin-left: 0;
}

.topRowPrimary > a {
  display: flex;
  margin-top: 4px; /* Adjustment for icon not filling bounding box height */
}

.noAvatarChars {
  font-size: 40px;
}

.teamLogo {
  display: flex;
  align-items: flex-end;
}

.teamLogoButton {
  position: relative;
  left: -25px;
}

.teamName {
  font-size: 24px;
  color: easilBlack;
  margin-right: 24px;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.teamButtonsWrapper {
  display: flex;
  flex: 0 0 auto;
}

.teamButtonsWrapper > div {
  margin-left: 16px;
}