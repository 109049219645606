.brandKitSmartTextWrapper {
  width: 100%;
  height: fit-content;
  min-height: 100%;
  position: relative;
}

.contentTitle,
.contentTitleForSearch {
  font-size: 20px;
  margin-bottom: 40px;
}

.contentTitleForSearch {
  color: #262f30;
}

.info {
  margin-top: 24px;
  margin-bottom: 24px;
}

.infoText {
  display: flex;
  justify-content: center;
  align-items: center;
}

.deleteConfirmationModal {
  width: 550px;
  height: 216px;
  border-radius: 8px;
}

.deleteConfirmationModalButtons {
  margin-top: 32px;
}

