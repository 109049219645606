@value colors: "../../colors.css";
@value easilBlack, easilGrayXDark, easilGrayDark from colors;

.background {
  height:100%;
  display: flex;
  overflow-y: scroll;
  justify-content: center;
  align-items: center;
  padding: 137px 0px 137px 0px;
  background-size: cover
}

.card {
  margin: auto;
  width: 600px;
  padding: 48px 100px 48px 100px;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.12);
  background-color: white;
}
