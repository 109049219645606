.wrapper {
  width: 100%;
  height: 100%;
}

.selectionOptions {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 24px;
}

.selectionOption {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 48%;
  padding: 29px 32px;
  border-radius: 8px;
  border: solid 2px #d1d4d6;
  cursor: pointer;
}

.selectionOption:first-of-type {
  margin-right: 24px;
}

.selectionOption[data-is-selected="true"] {
  border: solid 2px #1c1d1e;
}

.selectionOption[data-is-selected="true"] > .selectionOptionTitle {
  color: #2c2e2f;
}

.selectionOption[data-is-selected="true"] > .selectionOptionText {
  color: #57595d;
}

.selectionOptionTitle {
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #7a7d81;
  margin-bottom: 12px;
}

.selectionOptionText {
  font-family: Roboto;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-align: center;
  color: #8f9297;
  white-space: pre-line;
}