.fontModal {
  width: 552px;
  max-height: 100vh;
  overflow-y: auto;
}

.userTeamFontModal {
  width: 552px;
  max-height: 100vh;
  overflow-y: auto;
  border-radius: 8px;
}

.userTeamFontModal > div[class*="header"] {
  margin-bottom: 20px;
}

.fontModalHeader {
  color: #57595d;
  font-family: Roboto;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.heading {
  color: easilGrayXDark;
  display: block;
  margin-bottom: 8px;
}

.headingUppercase {
  color: #57595d;
  font-family: Roboto;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.brandFont {
  display: flex;
  flex-wrap: wrap;
  margin-top: 32px;
}

.brandFont .heading {
  flex: 1 1 100%;
  margin-bottom: 24px;
}

.brandFont span {
  margin-left: 8px;
}


.brandFontButton {
  align-content: center;
  cursor: pointer;
  display: flex;
}

.brandFontButton span {
  line-height: 24px;
}


.settings {
  display: flex;
  margin: 24px 0;
}


.settingsFontFamily {
  flex: 0 1 100%;
}

.settingsFontStyles {
  display: flex;
  flex: 0 1 136px;
  flex-wrap: wrap;
}

.settingsFontStyles label {
  flex: 1 1 100%;
}
