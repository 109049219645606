.menu {
  width: 300px;
}

.icon {
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.actionsSection {
  padding: 24px 12px;

}
.actionName {
  margin-left: 12px;
  height: 24px;
  font-size: 16px;
  line-height: 1.5;
  color: #57595d;
}

.action {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  width: 100%;
  padding: 6px 8px;
  height: 36px;
  border-radius: 4px;
}

.action:hover {
  background-color: #f9f9fa;
}

.footerOpen {
  height: 70px;
  transition: height 0.3s ease, padding 0s ease-out 0.1s, opacity 0.3s ease-out 0.1s;
  border-top: 1px solid lightgray;
  overflow: visible;
  padding: 16px;
  opacity: 1;
}

.footerClosed {
  height: 0px;
  transition: height 0.3s ease, border-top 0s ease-out 0.2s, padding 0s ease-in 0.1s, opacity 0.1s ease-in;
  border-top: 0px solid lightgray;
  overflow: hidden;
  padding: 0px;
  opacity: 0;
}

.menuFooterPopoutDropdown {
  flex: 1 1 195px;
  width: 268px;
}

.menuFooterPopoutButton {
  flex: 1 1 65px;
  height: 40px;
  margin-left: 12px;
  width: 100% !important;
}

.menuFooterWrapper {
  display: flex;
}

.noBottomBorder {
  border-bottom: none !important;
}