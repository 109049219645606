@value colors: "../../colors.css";
@value easilBlack from colors;
@value lgBreakPoint: 75%;
@value globalVars: "~views/style.css";
@value layoutWidth from globalVars;
@value subHeaderHeight: 70px;

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.header {
  background-color: easilBlack;
  min-width: 768px;
}

.subHeader {
  background-color: easilBlack;
  max-height: headerHeight;
  min-width: 768px;
  padding-bottom: 48px;
}

.subHeaderNoPadding {
  background-color: easilBlack;
  max-height: headerHeight;
  min-width: 768px;
  padding-bottom: 0px;
}

.gutters {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  padding-bottom: 48px;
  min-width: 768px;
}

.subHeaderGutters {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  min-width: 768px;
}

.content {
  max-width: layoutWidth;
  width: 100%;
}

@media screen and (max-width: layoutWidth) {
  .content {
    padding: 0 24px 0 24px;
  }
}
