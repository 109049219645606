@value colors: "../../colors.css";
@value easilBlack, easilBlueDark, easilGrayXDark, easilGrayXLight, easilGrayXXXLight, easilGrayXXXXLight, easilRed from colors;

/* General/Shared */

.addButton,
.ellipsisButton {
  background: none;
  border: 0;
  cursor: pointer;
  outline: none;
  padding: 0;
}

.addButton {
  color: easilBlueDark;
  font-size: 15px;
  font-weight: bold;
  font-weight: 500;
  height: 24px;
  line-height: 24px;
  margin: 0 0 40px;
}
.addButton:hover {
  color: #1e81df;
}
.addButton > svg {
  fill: easilBlueDark;
  margin-right: 12px;
  vertical-align: text-top;
}

.ellipsisButton {
  border-radius: 4px;
  margin: 0;
  position: relative;
}
.ellipsisButton:hover {
  background: easilGrayXXXLight;
}
.ellipsisButton > svg {
  display: block;
}

.ellipsisWrapper {
  margin: 0;
  position: relative;
}

.ellipsisMenu {
  position: absolute;
  top: 34px;
  right: 0;
  width: 300px;
}
.ellipsisMenu > div {
  width: auto;
}
.ellipsisMenu > div > div {
  padding: 24px;
}
.ellipsisMenu > div > div > div {
  padding-left: 12px;
  margin-left: -12px;
  margin-top: -6px;
  margin-bottom: 6px;
  width: calc(100% + 24px);
}
.ellipsisMenu > div > div > div:last-of-type {
  margin-bottom: -6px;
}

.modalContainer {
  width: 545px;
  border-radius: 8px;
  background-color: #fff;
  padding: 32px;
}

/* Billing */

.billing {
  width: 100%;
}

.billing > h2 {
  border-bottom: 1px solid rgba(226, 229, 232, 0.64);
  color: easilBlack;
  font-size: 20px;
  font-weight: normal;
  line-height: 1.4;
  padding-bottom: 12px;
  margin: 0 0 24px;
}

.billing > h3 {
  color: easilBlack;
  font-size: 18px;
  font-weight: normal;
  line-height: 1.56;
  margin: 0 0 16px;
}

.billing > p {
  color: easilGrayXDark;
  margin: 0 0 24px;
}

/* BillingCards */

.billingCard {
  display: flex;
  margin-bottom: 40px;
  align-items: center;
}

.billingCard > :global(.logo) {
  background-size: cover;
  border: 1px solid #ddd;
  border-radius: 4px;
  height: 24px;
  width: 36px;
}

.billingCard > :global(.number) {
  flex: 1;
  margin: 0 12px;
  color: easilBlack;
}

/* BillingCardsModal */

.upgradeModalPaymentDetails h2 {
  font-size: 20px;
  font-weight: normal;
  margin-top: 0px;
}

.errors {
  margin-top: 16px;
}

.error {
  color: easilRed;
}

.error + .error {
  margin-top: 4px;
}

.upgradeModalFooter {
  margin-top: 40px;
  display: flex;
  justify-content: flex-end;
}

.upgradeModalCancelButton {
  margin-right: 16px;
  width: 94px;
}

/* BillingAddress */

.billingAddress {
  background-color: #ecf5fa;
  border: 1px solid #abd0e8;
  border-radius: 8px;
  font-size: 15px;
  margin-bottom: 24px;
  padding: 16px;
  color: easilGrayXDark;
}
.billingAddress > b {
  font-size: 16px;
  color: easilBlack;
}

/* BillingAddressModal */

.formGroup + .formGroup,
.formGroupInline,
.formGroupInline + .formGroup {
  margin-top: 16px;
}

.formGroupInline {
  display: flex;
}

.formGroupInline .formGroup {
  flex: 1 1 auto;
}

.formGroupInline .formGroup + .formGroup {
  margin-top: 0;
  margin-left: 16px;
}

.formGroupLabel {
  color: easilGrayDark;
  margin-bottom: 8px;
}

.fieldError {
  color: easilRed;
  font-size: 12px;
  margin-top: 2px;
}

.formGroupButtons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 40px;
}

.formGroupButtons > button {
  margin-left: 12px;
}

/* BillingContacts */

.contacts {
  background: easilGrayXXXXLight;
  border: solid 1px easilGrayXXXLight;
  border-radius: 8px;
  margin: 0 0 24px;
  padding: 0 16px;
}
.contacts > li {
  align-items: center;
  border-top: 1px solid easilGrayXXXLight;
  color: easilGrayXDark;
  display: flex;
  padding: 16px 0;
}
.contacts > li:first-child {
  border-top: none;
}
.contacts > li > :global(.email) {
  margin: 0 15px;
  flex: 1 auto;
}

/* BillingNotification */

.notification {
  cursor: pointer;
  display: flex;
}
.notification > span {
  color: easilGrayXDark;
  font-size: 15px;
  font-weight: 500;
  line-height: 27px;
  margin-left: 12px;
}

.formGroup[data-is-country="true"] {
  width: 100%;
}
