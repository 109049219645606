.pasteFailToastContent {
  font-size: 16px;
  line-height: 1.38;
  color: rgba(255, 255, 255, 0.8);
}

.pasteFailToast > div {
  height: 106px;
}
.pasteFailToast > div > div{
  height: 106px;
}
.pasteFailToast > div > div > div > div > div:last-of-type {
  width: 295px;
}