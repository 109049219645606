@value colors: "../../../colors.css";
@value easilBlack, easilGrayXDark, easilGrayDark from colors;

.printItem {
  display: flex;
  padding: 16px 0 20px;
}

.printItem ~ .printItem {
  border-top: 1px solid #e2e5e8;
}

.col1 {
  flex: 1;
  margin-right: 16px;
}

.col2 {
  flex: 3;
  flex-wrap: wrap;
}

.col1, .col2 {
  font-size: 13px;
  display: flex;
  flex-direction: column;
}

.subtotal {
  color: easilBlack;
  font-weight: 500;
}