@value colors: "../../colors.css";
@value easilGrayXLight, easilGrayXDark, easilGrayXXXLight from colors;

.container {
  position: relative;
}

.icon {
  position: relative;
}

.icon {
  height: 24px;
  margin-right: 24px;
  padding: 2px;
  position: relative;
  width: 24px;
}

.iconWithDropDown {
  width: auto
}

.wrapper {
  align-items: center;
  border-bottom: 1px solid easilGrayXXXLight;
  display: flex;
  justify-content: space-between;
  padding: 12px 12px;
  width: 337px;
  height: 80px;
}

.wrapper > * + * {
  margin-left: 12px;
}

.wrapper > div:hover {
  background-color: inherit;
}

.details {
  display: flex;
}

.meta {
  margin-left: 12px;
}

.name {
  color: easilGrayXDark;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.33;
  color: #2c2e2f;
  width: 235px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.email {
  font-family: Roboto;
  font-size: 13px;
  line-height: 1.54;
  color: #57595d;
  width: 235px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.nav {
  color: easilGrayXDark;
}

.nav > * {
  align-items: center;
  display: flex;
  padding: 8px 16px;
}

.icon {
  margin-right: 12px;
}

.modal {
  width: 100%;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.modalInput {
  display: inline-block;
  width: 50%;
  margin-right: 24px;
}

.avatarWithImage {
  box-shadow: 0 0 0 2px #fff inset;
}

.link {
  cursor: pointer;
}

.popout {
  border-radius: 8px;
  overflow: hidden;
  border: none;
}

.linkSectionWrapper {
  align-items: center;
  border-bottom: 1px solid easilGrayXXXLight;
  display: flex;
  justify-content: space-between;
  padding: 12px 12px;
  height: 64px;
}

.linkSectionWrapper:last-of-type {
  border-bottom: none;
  background-color: #fcfcfd;
  border-radius: 8px;
}

.linkSectionWrapper:last-of-type > a:hover {
  background-color: #f3f3f7;
}

.linkContainer {
  width: 313px;
  height: 36px;
  display: flex;
  align-items: center;
  padding: 6px 12px;
  border-radius: 4px;
  font-family: Roboto;
  font-size: 16px;
  line-height: 1.5;
  color: #57595d;
}

.linkContainer:hover {
  cursor: pointer;
}

.linkContainer > a {
  display: flex;
  align-items: center;
}

.linkContainer:hover {
  background-color: #f9f9fa;;
}


