@value colors: "~views/colors.css";
@value easilGrayXXDark, easilBlack from colors;
.settings {
  display: flex;
  margin: 24px 0;
}

.settingsFontFamily {
  flex: 0 1 100%;
  z-index: 4;
}

.heading {
  color: easilGrayXDark;
  display: block;
  margin-bottom: 8px;
}

.dropdown input {
  color: easilBlack !important;
}
