.wrapper {
  display: flex;
  flex-direction: row;
}

.leftPanel {
  flex: 1 0 80%;
}

.rightPanel {
  flex: 1 1 6px;
  min-height: 140px;
  border-left: 1px solid rgba(255, 255, 255, 0.16);
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: auto;
}

.rightPanel > svg {
  cursor: pointer;
  pointer-events: all;
}

.rightPanel > svg:hover {
  opacity: 0.64;
}

.toastContentWrapper {
  margin: 16px;
  display: flex;
  flex-direction: row;
  height: 95px;
}

.toastContentLeft {
  padding-right: 20px;
}

.toastContentRight {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: baseline;
}

.toastContentRight > :first-child {
  margin: 0px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: rgba(255, 255, 255, 0.8);
}

.toastContentRight > :first-child > a {
  font-weight: 500;
  cursor: pointer;
  pointer-events: auto;
  color: #fff;
}

.toastContentRight > :first-child > a:hover {
  text-decoration: underline;
}

.toastContentRight > button {
  pointer-events: auto;
}

.toastContentRight > button:hover {
  opacity: 0.90;
}

.withoutButtonWrapper {
  height: 84px;
}

.withoutButtonWrapper > div:last-of-type {
  min-height: 84px;
}

.iconCircle {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 50%;
}
