@value colors: "~views/colors.css";
@value (
  easilBlack,
  easilGreen,
  easilGreenMid,
  easilGreenDarkAlpha,
  easilGreenDarkAlphaHover,
  easilBlackLight,
  easilGrayXLight,
  easilBlue,
  easilBlueDeepWithAlpha,
  easilBlueDeepWithAlphaHover,
  easilBlueDark,
  easilRoyalBlue,
  easilRoyalBlueHover,
  easilRed,
  easilGrayXDark,
  easilBlueSolidHover,
  easilRedSolidHover,
  easilGreenSolidHover,
  easilGrayBackgroundXDarkGrayFontHover,
  easilGrayXXXXLight,
  hoverColor
) from colors;

.button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: easilBlue;
  height: 40px;
  border-radius: 8px;
  border: solid 1px easilBlue;
  padding: 8px 24px 8px 24px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  cursor: pointer;
}

.button[data-disabled="true"] {
  border: solid 1px easilGrayXLight;
  background-color: easilGrayXLight;
  color: white;
  cursor: not-allowed;
}

.button[data-is-full-width="true"], .button[data-is-extra-wide="true"] {
  width: 100%;
  padding: 8px 32px 8px 32px;
}

.button[data-is-hoverable="true"]:hover {
  opacity: 0.9;
}

.button:focus {
  outline: none;
}

.gray {
  border: solid 1px easilGrayXLight;
  background-color: white;
  color: easilGrayXDark;
}

.grayBackgroundWhiteFont {
  background-color: #a1a7ab;
  color: white;
  border: solid 1px #a1a7ab;
}

.grayBackgroundXDarkGrayFont {
  background-color: #e5e8eb;
  color: easilGrayXDark;
  border: 1px solid #e5e8eb;
}

.grayBackgroundXDarkGrayFont:hover {
  background-color: easilGrayBackgroundXDarkGrayFontHover;
  color: easilGrayXDark;
  border: 1px solid easilGrayBackgroundXDarkGrayFontHover;
}

.grayBackgroundXDarkGrayFontDisabled {
  color: easilGrayXDark !important;
  border: 1px solid #e5e8eb !important;
  background-color: #e5e8eb !important;
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
}

.grayBorderOnly {
  border: solid 1px easilGrayXLight;
  background-color: transparent;
  color: easilBlack;
}

.whiteBorderWhiteFont {
  border: solid 2px white;
  background-color: transparent;
  color: white;
}

.whiteBorderWhiteFontWithTransition {
  border: solid 2px white;
  background-color: rgba(255, 255, 255, 0);
  color: white;
  transition: color 100ms ease;
  transition: background-color 100ms ease;
}

.whiteBorderWhiteFontWithTransition:hover {
  border: solid 2px white;
  background-color: rgba(255, 255, 255, 1);
  color: easilBlack;
}

.xLightGrayBorderWhiteFont {
  border: solid 1px easilGrayXLight;
  background-color: transparent;
  color: white;
}

.xLightGrayBorderXDarkGrayFont {
  border: solid 1px easilGrayXLight;
  background-color: transparent;
  color: easilGrayXDark;
}

.grayBorderWhiteFont {
  border: solid 1px #56585c;
  background-color: transparent;
  color: white;
}

.grayBorderWhiteFontDisabled {
  border: solid 1px #56585c !important;
  background-color: transparent !important;
  color: white !important;
  opacity: 0.48;
}

.blue {
  border: solid 1px white;
  background-color: easilBlue;
  color: white;
}

.blueTall {
  height: 40px;
  border: solid 1px white;
  background-color: easilBlue;
  color: white;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.6;
}

.blueSolid {
  border: solid 1px easilBlue;
  background-color: easilBlue;
  color: white;
}

.blueSolid:hover {
  background-color: easilBlueSolidHover;
  border: solid 1px easilBlueSolidHover;
}

.blueSolidWithTransition {
  border: none;
  background-color: easilBlueDeepWithAlpha;
  color: white;
  transition: background-color 100ms ease;
}

.blueDarkSolid {
  border: solid 1px easilBlueDark;
  background-color: easilBlueDark;
  color: white;
}

.blueSolidWithTransition:hover {
  background-color: easilBlueDeepWithAlphaHover;
}

.greenDarkSolidWithTransition {
  border: none;
  background-color: easilGreenDarkAlpha;
  color: white;
  line-height: 1;
  transition: background-color 100ms ease;
}

.greenDarkSolidWithTransition:hover {
  background-color: easilGreenDarkAlphaHover;
}

.blueDeepNoBackgroundNoBorder {
  color: easilBlueDeep;
  border: none;
}

.blueSolidNoBackgroundNoBorder {
  background-color: rgba(0, 0, 0, 0);
  color: easilBlue;
  border: none;
}

.blueSolidNoBackgroundNoBorder:hover {
  background-color: #e5e8eb;
}

.blueOutline {
  border: solid 1px easilBlue;
  background-color: white;
  color: easilBlue;
}

.whiteSolidBlueFontNoBorder {
  border: none;
  background-color: white;
  color: easilBlue;
}

.royalBlue {
  border: solid 1px white;
  background-color: easilRoyalBlue;
  color: white;
}

.royalBlueSolid {
  border: solid 1px easilRoyalBlue;
  background-color: easilRoyalBlue;
  color: white;
}

.royalBlueOutline {
  border: solid 1px easilRoyalBlue;
  background-color: white;
  color: easilRoyalBlue;
}

.royalBlueWithTransition {
  border: none;
  background-color: easilRoyalBlue;
  color: white;
  line-height: 1;
  transition: background-color 100ms ease;
}

.royalBlueWithTransition:hover {
  background-color: easilRoyalBlueHover;
}

.blackSolid {
  border: solid 1px easilBlackLight;
  background-color: easilBlackLight;
  color: white;
}

.blueLight {
  border: solid 1px easilBlue;
  background-color: white;
  color: easilBlue;
  transition: 300ms all ease-in-out;
}

.blueLight:hover {
  background-color: easilBlue;
  color: #fff;
}

.red {
  border: solid 1px white;
  background-color: easilRed;
  color: white;
}

.redSolid {
  border: solid 1px easilRed;
  background-color: easilRed;
  color: white;
}

.redSolid:hover {
  border: solid 1px easilRedSolidHover;
  background-color: easilRedSolidHover;
}

.redLight {
  border: solid 1px easilRed;
  background-color: white;
  color: easilRed;
}

.white {
  border: solid 1px white;
  background-color: #fff;
  color: easilBlack;
}

.greenSolid {
  border: solid 1px easilGreen;
  background-color: easilGreen;
  color: white;
}

.greenSolid:hover {
  border: solid 1px easilGreenSolidHover;
  background-color: easilGreenSolidHover;
}

.greenMidSolid {
  border: solid 1px easilGreenMid;
  background-color: easilGreenMid;
  color: white;
}

.actionbar {
  height: 32px;
  border: solid 1px easilGrayXLight;
  background-color: transparent;
  color: easilGrayXDark;
  width: 100%;
}

.actionbarNarrow {
  height: 32px;
  border: solid 1px easilGrayXLight;
  background-color: transparent;
  color: easilGrayXDark;
  padding: 0px 8px 0px 8px;
}

.actionbarNarrow[data-is-active="true"] {
  border: solid 1px easilGrayXDark;
}

.actionbar[data-disabled="true"] {
  border: solid 1px easilGrayXLight;
  background-color: transparent;
  color: easilGrayXLight;
  cursor: not-allowed;
}

.canvas {
  height: 40px;
  border-radius: 8px;
  border: solid 1px #c5c8ca;
  background-color: transparent;
  line-height: 2.86;
  letter-spacing: normal;
  color: #a1a6aa;
  box-sizing: content-box;
  width: auto !important;
}

.disabledBlue[data-disabled="true"] {
  background-color: easilBlue;
  border-color: easilBlue;
  opacity: 1;
}

.loginSignupButtons {
  padding: 0 24px !important;
  height: 48px;
}

.facebook {
  composes: loginSignupButtons;
  color: #ffffff;
  background-color: #1877f2;
  border-color: #1877f2;
}

.facebook:hover {
  background-color: easilBlueSolidHover;
  border-color: easilBlueSolidHover;
}

.google {
  composes: loginSignupButtons;
  color: rgba(0, 0, 0, 0.54);
  background-color: #ffffff;
  border: none;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.24), 0 0 1px 0 rgba(0, 0, 0, 0.12);
}

.google:hover {
  background-color: hoverColor;
}

.NoBackgroundNoBorder {
  background-color: rgba(0, 0, 0, 0);
  border: none;
}

.greyFontBorderTransparentBackground {
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #ffffff;
  color: #ffffff;
}

/*
  Standard more button styling
  MoreButton component should be preferred to directly referencing this style
*/
.loadMoreButton {
  min-width: 211px !important;
  width: auto !important;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.5;
  color: white;
  justify-content: center !important;
  margin-top: 16px;
  background-color: #3184FC;
  transition: background-color 0.15s ease;
}

.loadMoreButton:hover {
  background-color: #2E7DEF;
}
