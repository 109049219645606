@value colors: "~views/colors.css";
@value easilBlack, easilGrayXXXLight from colors;
@value globalVars: "~views/style.css";
@value layoutWidth from globalVars;

.container {
  align-items: center;
  color: easilBlack;
  display: flex;
  flex-direction: column;
  font-size: 15px;
  min-width: 725px;
  height: 100%;
}

.top {
  align-items: center;
  background: white;
  box-shadow: 0 1px 0 0 rgba(28, 29, 30, 0.04);
  display: flex;
  height: 83px;
  min-height: 83px;
  margin-bottom: 40px;
  width: 100%;
}

.bottom {
  display: flex;
  width: layoutWidth;
  height: 100%;
}

.bottom > .left {
  min-width: 262px;
}

.bottom > .right {
  flex: 1 1 auto;
  position: relative;
  width: 100vw;
  height: fit-content;
  min-height: 100%;
  max-width: calc(75vw - 262px);
}
