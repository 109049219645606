.fontModalDivider {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 13px;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 1px;
  color: #1c1d1e;
}

.fontModalDividerLine:before {
  opacity: 1;
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  border-top: 1px solid #e6e8ea;
  width: 100%;
  transform: translateY(-50%);
  z-index: 2;
}

.fontModalDividerText {
  background-color: white;
  z-index: 3;
  padding: 16px;
}
