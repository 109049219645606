@value colors: "../../colors.css";
@value easilBlack, easilGrayXDark, easilGrayDark from colors;

.priceSummary {
  border-top: 1px solid #d1d4d6;
  padding-top: 12px;
}

.subtotal, .grandTotal {
  display: flex;
  align-items: center;
}

.subtotal {
  padding-bottom: 12px;
}

.grandTotal {
  border-top: 1px solid #d1d4d6;
  padding-top: 16px;
}

.col1 {
  flex: 2;
}

.col1 div:not(:first-child) {
  padding-top: 8px;
}

.col2 {
  flex: 3;
  text-align: end;
}

.col2 div:not(:first-child) {
  padding-top: 8px;
}

.col1, .col2 {
  display: flex;
  flex-direction: column;
  color: easilBlack;
}

.shippingCost {
  color: #7a7d81;
}

.total {
  font-size: 20px;
  font-weight: 500;
}

.couponCode {
  color: #11b992;
  font-size: 15px;
  font-weight: 500;
}