.wrapper {
  width: 100%;
  height: 100%;
}

.title > h1 {
  width: 170px;
  height: 38px;
  font-family: Roboto;
  font-size: 32px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  color: #1c1d1e;
  margin: 0;
}

.description > p {
  width: 486px;
  height: 48px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #57595d;
  margin: 16px 0px 0px;
}

.description > p > a {
  color: #1c1d1e;
}

.teamListTitle > p {
  width: 43px;
  height: 24px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #1c1d1e;
  margin: 24px 0px 15px 0px;
}

.card {
  padding: 32px !important;
  width: 550px !important;
  height: auto !important;
  max-height: 558px !important;
  border-radius: 4px !important;
  background-color: #ffffff !important;
}

.returnLink {
  align-items: center;
  display: flex;
  font-weight: 500;
  margin-bottom: 12px;
  margin-top: -8px;
}

.returnLinkIcon {
  margin-right: 20px;
  transform: rotate(180deg);
}

.returnLink,
.listItemInner {
  cursor: pointer;
}

.searchInput {
  width: 100%;  
  margin-bottom: 16px;
}