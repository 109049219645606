@value colors: "~views/colors.css";
@value easilBlack, easilBlue, easilBlueSolidHover, easilGrayXDark from colors;

.modal {
  background-position: center right;
  background-repeat: no-repeat;
  color: easilBlack;
  padding: 32px;
  width: 1000px
}

.header {
  font-size: 24px;
  font-weight: 500;
  letter-spacing: -0.24px;
  line-height: 32px;
}

.intro {
  color: easilGrayXDark;
}

.container {
  font-size: 15px;
  margin-top: -20px;
  width: 50%;
}
.container > ul {
  margin: 26px 0;
}
.container > ul > li {
  align-items: center;
  display: flex;
  height: 32px;
}
.container > ul > li > svg {
  margin-right: 12px;
}

.buttons {
  justify-content: flex-start;
}

.buttons > button {
  background-color: easilBlue;
  width: 436px;
}

.buttons > button:hover {
  background-color: easilBlueSolidHover;
}
