@value colors: "../style.css";
@value (
  easilBlack,
  easilGrayXLight,
  easilGrayXXLight,
  easilGrayXDark,
  easilBlueDark,
  easilRed,
  fontDisabled,
  backgroundDisabled
) from colors;

.label {
  color: easilBlack;
  font-size: 13px;
  line-height: 1.85;
  text-align: left;
  padding-bottom: 9px;
}


.dropdown {
  position: relative;
  display: inline-block;
  width: 100%;
}

.dropdown * {
  cursor: pointer;
}

.inputDiv {
  display: flex;
  align-items: center;
  font-size: 14px;
  border: 1px solid easilGrayXLight;
  border-radius: 4px;
  line-height: 20px;
  height: 40px;
  padding: 10px 12px 10px 12px;
  cursor: pointer;
}

.inputDiv input {
  color: easilBlack;
  outline: none;
  border: none;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  pointer-events: none;
}

.inputDiv input[disabled] {
  background-color: transparent;
  color: #757575;
}

.inputDiv[data-disabled="true"] input[disabled]{
  cursor: not-allowed;
}
.inputDiv[data-error="true"]{
  border: 1px solid easilRed;
}

.inputDiv[data-disabled="true"] {
  background-color: backgroundDisabled;
  color: fontDisabled;
  cursor: not-allowed;
}

.option:hover {
  background-color: easilGrayXXLight;
}
.content {
  width: 100%;
  max-height: 200px;
  overflow: scroll;
  border: 1px solid easilGrayXLight;
  border-radius: 4px;
  display: block;
  position: absolute;
  top: 39px;
  background-color: white;
  min-width: 160px;
  padding: 16px 16px 16px 16px;
  z-index: 1;
}

.doneButton {
  width: 100%;
}

.error {
  position: absolute;
  color: easilRed;
  font-size: 12px;
  left: 5px;
  top: 100%;
}

.options {
  background-color: #fff;
  border: 1px solid easilGrayXLight;
  border-radius: 4px;
  display: none;
  height: 200px;
  left: 0;
  overflow-y: scroll;
  padding: 16px 0;
  position: absolute;
  top: 116%;
  width: 100%;
  z-index: 6;
}

.optionsOpened {
  display: block;
}