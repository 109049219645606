@value colors: "~views/style.css";
@value (easilBlack,
 easilGrayXLight,
 easilGrayXDark,
 easilBlueDark,
 easilRed) from colors;

.label {
  color: easilBlack;
  font-size: 13px;
  line-height: 1.85;
  text-align: left;
  padding-bottom: 9px;
}

.scrollbar {
  width: 5px !important;
  border-radius: 2.5px;
  background-color: #7d7d7d;
  overflow-x: hidden;
  margin-right: -5px;
}

.scrollContentClassName {
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
}

.dropdownScrollable { 
  max-height: 182px;
  width: calc(100% + 20px) !important;
  padding-right: 20px;
  position: relative;
    overflow: hidden;
    height: 100%;
  
}

.dropdownScrollable > div:first-of-type {
  max-height: inherit;
}

.dropdown {
  position: relative;
  display: inline-block;
  width: 100%;
}

.dropdown * {
  cursor: pointer;
}

.inputDiv {
  display: flex;
  align-items: center;
  font-size: 14px;
  border-radius: 4px;
  line-height: 20px;
  height: 40px;
  padding: 10px 12px 10px 12px;
  cursor: pointer;
}

.inputDiv input {
  color: easilBlack;
  outline: none;
  border: none;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  pointer-events: none;
}

.inputDiv input[disabled] {
  background-color: transparent;
  color: easilGrayXDark;
}

.inputDiv[data-error="true"] {
  border: 1px solid easilRed;
}

.inputDiv[data-disabled="true"] {
  background-color: easilGrayXLight;
  cursor: not-allowed;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  margin-top: 4px;
  width: 100%;
  overflow: hidden;
  border: 1px solid #d1d4d6;
  border-radius: 4px;
  position: absolute;
  top: 39px;
  min-width: 160px;
  padding: 4px 24px 2px 24px;
  z-index: 1;
  background-color: white;
}

.content {
  margin-top: 4px;
  overflow: auto;
  top: 39px;
  min-width: 160px;
  z-index: 1;
  width: calc(100% + 30px);
  margin-left: -15px;
  padding-left: 15px;
  padding-right: 15px;
  overflow-y: hidden;
}

.doneButton {
  margin-top: 10px;
}

.doneButtonContainer {
  margin-top: 10px;
}

.error {
  position: absolute;
  color: easilRed;
  font-size: 12px;
  left: 5px;
  top: 100%;
}

.inputTitle {
  opacity: 0.64;
  font-family: Roboto;
  font-size: 14px;
  line-height: 1.71;
  color: #ffffff;
  margin-right: 6px;
}