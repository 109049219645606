@value colors: "../../colors.css";
@value easilBlack, easilGrayXDark, easilGrayDark from colors;

.outer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 64px;
}

.topRow {
  flex: 1 0 100%;
  padding: 64px 0px 64px 0px;
}
.col1 {
  padding-right: 16px;
  flex: 1 0 50%;
}

.col2 {
  padding-left: 16px;
  flex: 1 0 50%;
}

@media screen and (max-width: 950px) {
  .col1,
  .col2 {
    flex: 1 0 100%;
    padding: 0;
  }

  .col1 {
    margin-bottom: 32px;
  }
}
