.wrapper > :nth-last-child(1) {
  margin-bottom: 0;
}

.wrapper {
  max-height: 270px;
  overflow-y: scroll;
}

.teamOption {
  width: 486px;
  height: 56px;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 var(--dark-grey-8);
  border: solid 1px #d6d6d6;
  background-color: rgba(255, 255, 255, 0.4);
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  padding: 16px 16px 16px 12px;
}

.teamName {
  width: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.teamName > p {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #1c1d1e;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 300px;
  overflow: hidden;
  margin: 0;
}

.meta {
  align-items: center;
  display: flex;
  font-weight: normal;
}

.meta small {
  color: easilGrayDark;
  font-size: 13px;
  margin-right: 8px;
}

.meta strong {
  margin-right: 4px;
}

.meta > * {
  cursor: pointer;
}

.teamAvatar {
  margin-right: 16px;
}

.teamOptionAction {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
}

.teamOptionAction > p {
  width: 77px;
  height: 24px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: right;
  color: #73767a;
  margin: 0px 10px;
}
