@value colors: "~views/style.css";
@value easilBlack from colors;

.roleFilterWrapper {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 307px;
  border-radius: 8px;
  border: 1px solid #d1d4d6;
  padding: 24px;
  background-color: white;
  z-index: 999;
  margin-top: 8px;
  left: -101px;
}

.rolePicker {
  display: flex;
  flex-direction: column;
}

.memberFilterRoles {
  width: 105px;
  margin-left: 8px;
}

.roleFilterInput {
  padding: 8px 16px 8px 24px;
  border-radius: 8px;
}

.roleFilterInput > input {
  font-family: 'Roboto';
  color: easilBlack !important;
  font-weight: 500;
  font-size: 16px;
}

.titles {
  font-size: 12px;
  margin-bottom: 8px;
}

.teamRole {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 40px;
  cursor: pointer;
}

.teamRole > label {
  left: 12px;
}

.roleIcon {
  padding: 0 8px;
  font-size: 12px;
  font-weight: 500;
  color: easilBlack;
  background-color: #e5e8eb;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  border-radius: 4px;
}

.parentTeamToggleWrapper {
  margin-top: 16px;
}

.parentTeamToggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.parentTeamToggle > div:first-child {
  font-size: 16px;
  font-weight: 500;
  color: #57595d;
}

.buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
}

.buttons > button:first-of-type {
  margin-right: 8px;
}

.buttons > button {
  width: 100%;
}

.roleCheckbox {
  width: 20px;
  height: 20px;
  top: -9px;
  left: -5px;
}

.roleCheckbox:after {
  top: 8px !important;
  left: 6px !important;
  transform: scale(1.3);
}

.parentTeamToggle > svg {
  transform: scale(1.2);
}