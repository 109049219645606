@value colors: "~views/colors.css";
@value easilBlack, easilBlueDeep, easilGray, easilGrayXDark, easilGrayXXXLight, hoverColor from colors;

:root {
  --gif-canvas-width: 64px;
  --gif-canvas-height: 64px;
  --gif-canvas-left: 0px;
  --gif-canvas-top: 0px;
  --gif-div-height: auto;
  --gif-div-width: auto;
}

/* TITLES */

.contentTitle,
.contentTitleForSearch {
  font-size: 20px;
  margin-bottom: 40px;
}
.contentTitleForSearch {
  color: #262f30;
}

/* BREADCRUMB */

.breadcrumb {
  display: flex;
  font-size: 16px;
  margin: -16px 0 40px 0;
}

.breadcrumb > a {
  border-bottom: 1px solid rgba(122, 125, 129, 0.16);
  color: #777d82;
}

.breadcrumbSpacer {
  align-items: center;
  display: flex;
  height: 24px;
  justify-content: center;
  margin: 0 8px;
  width: 24px;
}

.breadcrumbMenu {
  position: relative;
}

.breadcrumbMenuDropDown {
  cursor: pointer;
  display: flex;
}
.breadcrumbMenuDropDown:hover {
  border-bottom: 1px solid #afb3b6;
}
.breadcrumbMenuDropDown > svg {
  margin: 6px 0 6px 10px;
}

/* WHITE ROW */

.whiteRow {
  background: white;
  border: 1px solid easilGrayXXXLight;
  border-radius: 8px;
  margin-bottom: 12px;
  padding: 20px;
}

.whiteRowTop {
  display: flex;
  justify-content: space-between;
}

/* WHITE ROW - BUTTONS */

.whiteRowButtons {
  display: flex;
  position: relative;
}

.whiteRowButton {
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  height: 24px;
  justify-content: center;
  margin-left: 16px;
  width: 24px;
}
.whiteRowButton:hover {
  background: whitesmoke;
}

/* WHITE ROW, UPLOAD BUTTON (ERROR MSG.) */

.uploadButtonPrependError {
  display: flex;
  flex-direction: row-reverse;
}
.uploadButtonPrependError > div:nth-child(3) {
  font-weight: 500;
  line-height: 16px;
  margin: 0 8px 0 0;
  max-width: 87px;
}

/* IMAGE LIST */

.imageListTitles {
  border-bottom: 1px solid rgba(226, 229, 232, 0.88);
  color: easilGray;
  display: flex;
  font-size: 14px;
  margin-bottom: 16px;
  padding-bottom: 12px;
}

.imageList {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}

.imageListFolderRow,
.imageListRow {
  display: flex;
  
}

.imageListFolderRow:hover {
  cursor: pointer;
}

.imageListRowRight {
  align-items: center;
  border-top: 1px solid rgba(226, 229, 232, 0.88);
  display: flex;
  flex: 1 1 auto;
  justify-content: space-between;
}
.imageListFolderRow:first-child .imageListRowRight,
.imageListRow:first-child .imageListRowRight {
  border-top: none;
}

.imageListRowTitle {
  flex: 1 1 0px;
  font-weight: 500;
  max-width: 340px;
  margin-right: 10px
}
.imageListFolderRow:hover .imageListRowTitle {
  text-decoration: underline;
}

.imageListTile {
  width: 64px;
  height: 64px;
  background-color: #FEFEFE;
  background-image: linear-gradient(45deg, #DFDFDFBF 25%, #DFDFDF00 25%, #DFDFDF00 75%, #DFDFDFBF 75%, #DFDFDFBF), linear-gradient(45deg, #DFDFDFBF 25%, #DFDFDF00 25%, #DFDFDF00 75%, #DFDFDFBF 75%, #DFDFDFBF);
  background-size: 10px 10px;
  background-position: 0 0, 15px 15px;
  border-radius: 4px;
  margin-right: 24px;
  margin-bottom: 8px;
  margin-top: 8px;
}

.logoListRowImage {
  background-color: #FEFEFE;
  background-position: center center;
  background-size: cover;
  border-radius: 4px;
  height: 64px;
  width: 64px;
  margin: 8px 24px 8px 0;
}

.logoListRowFolder {
  width: 64px;
  height: 64px;
  align-items: center;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.imageListWrapper {
  width: 64px;
  height: 64px;
  align-items: center;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.folderTile {
  width: 64px;
  height: 64px;
  background-color: white;
  margin-right: 24px;
  margin-bottom: 8px;
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(21, 21, 22, 0.12);
}

.imageListWrapper img {
  display: block;
  object-fit: cover;
  border-radius: 2px;
  position: relative;
  z-index: 1;
}

.imageListWrapper > div {
  height: var(--gif-div-height);
  width: var(--gif-div-width);
}


.imageListWrapper > div > canvas {
  width: var(--gif-canvas-width);
  height: var(--gif-canvas-height);
  left: var(--gif-canvas-left) !important;
  border-radius: 4px;
}

.imageListFolderRow .imageListRowImage {
  align-items: center;
  box-shadow: 0 1px 2px 0 rgba(21, 21, 22, 0.12);
  background-color: #ffffff;
  display: flex;
  justify-content: center;
}

.folderTile {
  width: 64px;
  height: 64px;
  background-color: white;
  margin-right: 24px;
  margin-bottom: 8px;
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(21, 21, 22, 0.12);
}

.imageListRowColumn {
  width: 150px;
}
.imageListRow .imageListRowColumn {
  color: #616569;
}

.imageListRowMenu {
  position: relative;
  width: 24px;
}

.imageListRowEllipse {
  border-radius: 4px;
  cursor: pointer;
  display: flex;
}

.imageListRowEllipse:hover {
  background: rgba(0, 0, 0, 0.1);
}

.imageListRowMenuPopout {
  right: 0;
  top: 100%;
}

/* POPOUT MENU */

.popoutItemMenu {
  right: 0;
  top: 24px;
}

.brandKitAnimationsWrapper {
  width: 100%;
  height: fit-content;
  min-height: 100%;
  position: relative;
}

.brandAnimationsList {
  width: 100%;
  margin-top: 40px !important;
  padding-top: 0px !important;
}

.brandAnimationsList > div:first-of-type {
  padding-top: 0px;
}

.brandAnimationRow {
  width: 100%;
}

.brandAnimationRow > div:last-child {
  padding-right: 0px;
}

.brandAnimationRow > div:last-child > a {
  width: 340px;
}

.animationsUploadButtonWrapper {
  display: flex;
  align-items: center;
}

.brandAnimationsUploadButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 46px;
  padding: 12px 24px 12px 16px;
}

.brandAnimationBreadCrumb {
  width: 762px;
  padding-top: 0px !important;
  margin-bottom: 40px !important;
  z-index: 2;
}

.brandAnimationFolderRow > div:last-child {
  padding-right: 0px;
}

.searchResultsTitle {
  display: block;
  width: auto;
  margin-bottom: 20px;
}

.searchResultsTitle > div:first-of-type {
  margin-top: 5px;
}
