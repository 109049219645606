@value colors: "~views/style.css";
@value easilRed from colors;

.modal2Field {
  width: 500px;
  height: 100vh;
  max-height: 352px;
  border-radius: 8px;
  background-color: #ffffff;
  padding: 32px;
  transition: max-height 1s ease
}

.modal3Field {
  composes: modal2Field;
  max-height: 452px;
}

.inputTitle {
  font-family: Roboto;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: #7a7d81;
  margin-bottom: 8px;
}

.input {
  width: 436px;
  height: 40px;
  border-radius: 4px;
  border: solid 1px #d1d4d6;
  background-color: #ffffff;
}

.inputContainer {
  overflow: hidden;
  padding-bottom: 16px;
  max-height: 100px;
  transition: max-height 1s ease;
}

.inputContainer:last-of-type {
  height: 120px;
  padding-bottom: 0px;
  margin-bottom: 16px;
}

.inputContainer[data-is-password-required="false"] {
  max-height: 0px;
}

.buttons {
  margin-top: 0px;
}

.buttons > button {
  height: 40px;
}

.error {
  color: easilRed;
  margin-top: -2px;
  position: absolute;
}
