@value colors: "~views/colors.css";
@value easilBlack, easilGrayXDark from colors;

.brandKitMenuOptions {
  display: flex;
  flex-direction: column;
}

.brandKitMenuOption {
  color: easilGrayXDark;
  display: flex;
  line-height: 24px;
  padding: 12px 0;
}
.brandKitMenuOption:hover,
.brandKitMenuOption[data-active="true"] {
  color: easilBlack;
  font-weight: 500;
}

.brandKitMenuOptionIcon {
  height: 24px;
  margin-right: 16px;
  width: 24px;
}

.brandKitMenuOption:first-child {
  margin-top: -12px;
}
