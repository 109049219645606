@value colors: "~views/style.css";
@value easilBlack, easilBlue, easilGrayXLight from colors;

.wrapper {
  margin: 40px 0;
}

.ordersList {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
}

.ordersListRow {
  display: flex;
  flex-direction: row;
  border-top: 1px solid #e2e5e8;
  padding-top: 16px;
  padding-bottom: 16px;
}

.ordersListRow:first-of-type {
  border-top: none;
}

.ordersListRow:first-of-type > div {
  font-size: 14px;
  color: #8e9294;
}

.ordersListRow:nth-of-type(2) {
  padding-top: 24px;
}

.ordersListRow > div {
  font-family: Roboto-Regular;
  font-size: 15px;
  color: #56595e;
}

.ordersListRow > div[data-column-key="id"] {
  color: easilBlack;
  font-family: Roboto-Medium;
}

.ordersListRow > div[data-column-key="viewDetails"] {
  color: #2c6fcf;
  font-family: Roboto-Medium;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.ordersListRow > div[data-column-key="viewDetails"] > div {
  cursor: pointer;
}

.ordersListRow > div[data-column-key="viewDetails"] > div > svg {
  margin-left: 8px;
}

