.optionWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: fit-content;
  height: 24px;
  margin: 0 8px 0 8px;
  padding: 3px 8px 3px 8px;
  border-radius: 4px;
  background-color: #e5e8eb;
}
.option {
  width: 100%;
  height: 18px;
  margin: 0 8px 0 0;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #2c2e2f;
}
.icon {
  display: flex;
  align-items: center;
  justify-content: center;
}