.tableTextField {
  width: 100%;
  box-sizing: border-box;
  overflow-wrap: inherit;
  white-space: normal;
}

.tableTextField::selection {
  background: rgba(156,196,254, 0.48);
}
