/* export css globalVars into module so they can be imported in javascript */
@value globalVars: "~views/style.css";
@value cardSize, gutterSize, landscapeCardSize, landscapeGutterSize from globalVars;
@value colors: "./colors.css";
@value (
  easilGrayDark,
  easilBlack,
  easilBlackLight,
  easilRed,
  easilOrange,
  easilGreen,
  easilGreenDarkAlpha,
  easilGreenDarkAlphaHover,
  easilBlue,
  easilBlueDark,
  easilBlueLight,
  easilBlueDeep,
  easilRoyalBlue,
  easilRoyalBlueHover,
  easilBlueDeepWithAlpha,
  easilBlueDeepWithAlphaHover,
  easilGray,
  easilGrayDark,
  easilGrayXDark,
  easilGrayLight,
  easilGrayMidLight,
  easilGrayXLight,
  easilGrayXXLight,
  easilGrayXXXLight,
  easilGrayXXXXLight,
  backgroundDisabled,
  fontDisabled,
  hoverColor
) from colors;

/* below style disables the viewing of scroll bars without removing the scroll functionality from an element */
.no-scroll-bars {
  -ms-overflow-style: none;  /* IE and Edge no scroll bar */
  scrollbar-width: none;  /* Firefox no scroll bar */
}

.no-scroll-bars::-webkit-scrollbar {
  display: none; /* Chrome Safari and Opera no scroll bar */
}/*"views/style.module.css"*/