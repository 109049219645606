@value colors: "~views/style.css";
@value easilBlack, easilGrayDark from colors;

.wrapper {
  justify-content: center;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.heading {
  color: easilBlack;
  font-size: 24px;
  line-height: 1.33;
  margin-bottom: 8px;
}

.content {
  color: easilGrayDark;
  font-size: 16px;
  line-height: 1.63;
}

.button {
  display: inline-block !important;
}
