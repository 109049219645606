@value colors: "~views/colors.css";
@value easilBlack, easilBlueDeep, easilGray, easilGrayXDark, easilGrayXXXLight from colors;

:root {
  --gif-canvas-width: 64px;
  --gif-canvas-height: 64px;
  --gif-canvas-left: 0px;
  --gif-canvas-top: 0px;
  --gif-div-height: auto;
  --gif-div-width: auto;
}

/* TITLES */

.contentTitle {
  font-size: 20px;
  margin-bottom: 32px;
}
.contentTitleForSearch {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  color: #262f30;
  height: 24px;
  font-family: Roboto;
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  color: #57595d;
}

/* BREADCRUMB */

.breadcrumb {
  display: flex;
  font-size: 16px;
  margin: -16px 0 40px 0;
  margin-top: 40px;
}

.breadcrumb > a {
  border-bottom: 1px solid rgba(122, 125, 129, 0.16);
  color: #777d82;
}

.breadcrumbSpacer {
  align-items: center;
  display: flex;
  height: 24px;
  justify-content: center;
  margin: 0 8px;
  width: 24px;
}

.breadcrumbMenu {
  position: relative;
}

.breadcrumbMenuDropDown {
  cursor: pointer;
  display: flex;
}
.breadcrumbMenuDropDown:hover {
  border-bottom: 1px solid #afb3b6;
}
.breadcrumbMenuDropDown > svg {
  margin: 6px 0 6px 10px;
}

/* WHITE ROW */

.whiteRow {
  background: white;
  border: 1px solid easilGrayXXXLight;
  border-radius: 8px;
  margin-bottom: 12px;
  padding: 20px;
}

.whiteRowTop {
  display: flex;
  justify-content: space-between;
}

.whiteRowTop > div:first-of-type {
  max-width: calc(100% - 80px);
}

/* WHITE ROW - BUTTONS */

.whiteRowButtons {
  display: flex;
  position: relative;
}

.whiteRowButton {
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  height: 24px;
  justify-content: center;
  margin-left: 16px;
  width: 24px;
}
.whiteRowButton:hover {
  background: whitesmoke;
}

/* WHITE ROW, UPLOAD BUTTON (ERROR MSG.) */

.uploadButtonPrependError {
  display: flex;
  flex-direction: row-reverse;
}
.uploadButtonPrependError > div:nth-child(3) {
  font-weight: 500;
  line-height: 16px;
  margin: 0 8px 0 0;
  max-width: 87px;
}

/* IMAGE LIST */

.imageListTitles {
  border-bottom: 1px solid rgba(226, 229, 232, 0.88);
  color: easilGray;
  display: flex;
  font-size: 14px;
  margin-bottom: 16px;
  padding-bottom: 12px;
}

.imageList {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}

.imageListFolderRow,
.imageListRow {
  display: flex;
  
}

.imageListFolderRow:hover {
  cursor: pointer;
}

.imageListRowRight {
  align-items: center;
  border-top: 1px solid rgba(226, 229, 232, 0.88);
  display: flex;
  flex: 1 1 auto;
  justify-content: space-between;
}
.imageListFolderRow:first-child .imageListRowRight,
.imageListRow:first-child .imageListRowRight {
  border-top: none;
}

.imageListRowTitle {
  width: calc((0.6 * (75vw - 226px)) - 112px );
  font-weight: 500;
  padding-right: 10px;
  max-width: calc((0.6 * (75vw - 226px)) - 112px );
}
.imageListFolderRow:hover .imageListRowTitle {
  text-decoration: underline;
}

.imageListTile {
  width: 64px;
  height: 64px;
  background-color: #FEFEFE;
  background-image: linear-gradient(45deg, #DFDFDFBF 25%, #DFDFDF00 25%, #DFDFDF00 75%, #DFDFDFBF 75%, #DFDFDFBF), linear-gradient(45deg, #DFDFDFBF 25%, #DFDFDF00 25%, #DFDFDF00 75%, #DFDFDFBF 75%, #DFDFDFBF);
  background-size: 10px 10px;
  background-position: 0 0, 15px 15px;
  border-radius: 4px;
  margin-right: 24px;
  margin-bottom: 8px;
  margin-top: 8px;
}

.logoListRowImage {
  background-color: #FEFEFE;
  background-position: center center;
  background-size: cover;
  border-radius: 4px;
  height: 64px;
  width: 64px;
  min-height: 64px;
  min-width: 64px;
  margin: 8px 24px 8px 0;
}

.logoListRowFolder {
  width: 64px;
  height: 64px;
  align-items: center;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.imageListWrapper {
  width: 64px;
  height: 64px;
  align-items: center;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.folderTile {
  width: 64px;
  height: 64px;
  background-color: white;
  margin-right: 24px;
  margin-bottom: 8px;
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(21, 21, 22, 0.12);
}

.imageListWrapper img {
  display: block;
  object-fit: cover;
  border-radius: 2px;
  position: relative;
  z-index: 1;
}

.imageListWrapper > div {
  height: var(--gif-div-height);
  width: var(--gif-div-width);
}


.imageListWrapper > div > canvas {
  width: var(--gif-canvas-width);
  height: var(--gif-canvas-height);
  left: var(--gif-canvas-left) !important;
  border-radius: 4px;
}

.imageListFolderRow .imageListRowImage {
  align-items: center;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
}

.folderTile {
  width: 64px;
  height: 64px;
  background-color: white;
  margin-right: 24px;
  margin-bottom: 8px;
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(21, 21, 22, 0.12);
}

.imageListRowColumn {
  width: calc(0.2 * (75vw - 226px - 88px));
  min-width: calc(0.2 * (75vw - 226px - 88px));
}
.imageListRow .imageListRowColumn {
  color: #616569;
}

.imageListRowMenu {
  position: relative;
  width: 24px;
  min-width: 24px;
}

.imageListRowEllipse {
  border-radius: 4px;
  cursor: pointer;
  display: flex;
}

.imageListRowEllipse:hover {
  background: rgba(0, 0, 0, 0.1);
}

.imageListRowMenuPopout {
  right: 0;
  top: 100%;
}

/* POPOUT MENU */

.popoutItemMenu {
  right: 0;
  top: 24px;
}

.sortDropdownWrapper {
  width: auto !important;
  padding-right: 8px;
}

.sortDropdown {
  width: 168px;
  height: 48px !important;
  border-radius: 8px !important;
}

.sortDropdownPopout {
  width: 211px;
  height: auto;
  padding: 6px 0 !important;
}

.sortDropdownOptions {
  display: flex;
  flex-direction: column;
  margin: 0px 6px;
}

.sortDropdownOption {
  padding: 8px 12px;
  border-radius: 4px;
  display: flex;
  height: 32px;
  align-items: center;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #2c2e2f;
}

.sortDropdownOption > *:first-child {
  margin-right: 12px;
  min-width: 20px;
}

.sortDropdownOption:hover {
  background-color:#f9f9fa;
}
.sortDropdownOption[data-disabled="true"] {
  border: solid 1px easilGrayXLight;
  background-color: easilGrayXLight;
  color: easilGrayXLight;
  cursor: not-allowed;
}

.sortDropdownLabel {
  display: flex;
  align-items: center;
  width: 100%;
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #56595e;
}

.sortDropdownLabel > *:first-child {
  margin-right: 8px;
  min-width: 22px;
}

.sortFiltersWrapper {
  margin-top: 10px;
  padding: 0 14px 0 14px;
}

.filtersText {
  width: 33px;
  height: 20px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
}

.sortSmartImages {
  width: 199px;
  height: 32px;
  display: flex;
  align-items: center;
  color: #2c2e2f;
}

.smartImageOnly { 
  margin-right: 20px;
}

.fileDropWrapper {
  height: 100%;
  top: 0;
  left: 0;
  width: 100vw;
}

.imageListTitle {
  width: calc(75vw - 262px - 88px - 24px);
}

.imageListTitle > div:first-of-type {
  max-width: calc(75vw - 262px - 88px - 24px );
  width: calc(75vw - 262px - 88px - 24px );
}

.imageRowTitle {
  width: calc(0.4 * (75vw - 226px - 88px));
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.imageRowRightWrapper {
  display: flex;
  flex-direction: row;
}