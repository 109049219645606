@value colors: "../style.css";
@value (
  easilBlack,
  easilGrayXLight,
  easilGrayXXLight,
  easilGrayXDark,
  easilBlueDark,
  easilRed,
  fontDisabled,
  backgroundDisabled
) from colors;

.label {
  color: easilBlack;
  font-size: 13px;
  line-height: 1.85;
  text-align: left;
  padding-bottom: 9px;
}

.dropdown {
  position: relative;
  display: inline-block;
  width: auto;
  border-radius: 8px;
  margin-top: 24px;
}

.dropdown * {
  cursor: pointer;
}

.inputDiv {
  display: flex;
  align-items: center;
  font-size: 14px;
  border: 1px solid easilGrayXLight;
  height: 48px;
  padding: 12px 16px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  color: #2c2e2f;
  line-height: 1.5;
  width: auto;
  border-radius: 8px;
}

.selected {
  margin-right: 4px
}

.inputDiv[data-error="true"]{
  border: 1px solid easilRed;
}

.inputDiv[data-disabled="true"] {
  background-color: backgroundDisabled;
  color: fontDisabled;
  cursor: not-allowed;
}

.option {
  height: 36px;
  border-radius: 8px;
  padding-left: 12px;
}

.option:hover {
  color: #2c2e2f;
  background-color: easilGrayXXLight;
}
.content {
  border: 1px solid easilGrayXLight;
  display: block;
  position: absolute;
  top: 39px;
  background-color: white;
  min-width: 160px;
  z-index: 1;
  overflow: auto;
  border-radius: 8px;
  max-height: 288px;
  margin-top: 16px;
  width: 250px;
  padding: 16px 12px;
  line-height: 36px;
  font-size: 16px;
  color: #57595d;
  box-shadow: 0 2px 4px 0 rgba(15,15,15, 0.08);
}

.doneButton {
  width: 100%;
}

.error {
  position: absolute;
  color: easilRed;
  font-size: 12px;
  left: 5px;
  top: 100%;
}
