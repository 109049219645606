@value colors: "~views/colors.css";
@value easilBlack, easilGrayDark from colors;

.section {
  width: 100%
}

.toggleLabel {
  height: 24px;
  font-size: 14px;
  line-height: 1.71;
  color: easilBlack;
  display: flex;
  align-items: center;
  justify-content: center;
}

.padlock {
  display: flex;
  align-items: center;
  margin-right: 8px;
}

.toggleSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.toggle {
  opacity: 1;
}

.toggle[data-disabled="true"] {
  opacity: 0.36;
}

.description {
  width: 100%;
  height: 48px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: easilGrayDark;
  cursor: pointer;
}

.learnMore {
  text-decoration: underline;
  color: easilBlack;
}
