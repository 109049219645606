@value colors: "../style.css";
@value easilBlack, easilGrayXLight, easilGrayXDark from colors;

.alert {
  display: flex;
  align-items: center;
  border-radius: 4px;
  border: solid 1px easilGrayXLight;
  padding: 16px 20px 16px 20px;
  color: easilGrayXDark;
}

.icon {
  display: inline-flex;
  margin: 0px 20px 0px 0px;
  cursor: default;
}

.message {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  margin-right: auto
}

.button {
  margin-left: 20px;
}
