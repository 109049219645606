@value colors: "~views/style.css";
@value (
  easilBlack,
  easilGrayXLight,
  easilGrayXDark,
  easilBlueDark,
  easilRed
) from colors;

.label {
  color: easilBlack;
  font-size: 13px;
  line-height: 1.85;
  text-align: left;
  padding-bottom: 9px;
}

.dropdownCustom {
  position: relative;
  display: inline-block;
}

.inputDiv {
  display: flex;
  align-items: center;
  font-size: 14px;
  border: 1px solid easilGrayXLight;
  border-radius: 4px;
  line-height: 20px;
  height: 40px;
  padding: 10px 12px 10px 12px;
  cursor: pointer;
}

.inputDiv input {
  color: easilBlack;
  outline: none;
  border: none;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  pointer-events: none;
}

.inputDiv input[disabled] {
  background-color: transparent;
  color:   easilGrayXDark;
}

.inputDiv[data-error="true"]{
  border: 1px solid easilRed;
}

.inputDiv[data-disabled="true"] {
  background-color: easilGrayXLight;
  cursor: not-allowed;
}

.error {
  position: absolute;
  color: easilRed;
  font-size: 12px;
  left: 5px;
  top: 100%;
}