.popoutItemMenu {
  width: 300px;
  border-radius: 8px;
}

.guardWrapper {
  cursor: default;
}

.loadingContent {
  padding: 20px 0px;
}