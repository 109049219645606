.toggleWrapper {
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  width: 226px;
  height: 60px;
  border-radius: 8px;
  border: solid 1px #58595a;
  background-color: #323334;
  margin-bottom: 32px;
  align-items: center;
  position: relative;
}

.toggleSwitch {
  width: 105px;
  height: 44px;
  border-radius: 8px;
  background-color: #ffffff;
  margin-left: 8px;
  margin-right: 8px;
  position: absolute;
  transition: transform 0.25s;
}

.toggle {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  flex: 1;
  z-index: 2;
  cursor: pointer;
  color: #ffffff;
  height: 100%;
}

.toggle[data-toggle-left="true"] {
  margin-left: 8px;
}

.toggle[data-toggle-right="true"] {
  margin-right: 8px;
}

.toggle[data-is-active="true"] {
  color: #2c2e2f;
}

.toggle[data-is-disabled="true"] {
  cursor: auto !important;
}

.toggle[data-is-disabled="true"] > div:last-of-type {
  opacity: 0.72;
}

.toggleSwitchLeft {
  transform: translate(0px, 0px);
}

.toggleSwitchRight {
  transform: translate(105px, 0px);
}

.defaultSubHeader {
  height: 48px;
}

.lockIcon {
  margin-right: 8px;
  width: 20px;
  height: 30px;
}

#lockedTabTip {
  width: 200px;
  height: 72px;
  border-radius: 8px;
  background-color: #111212 !important;
  display: flex;
  align-items: center;
  padding: 16px;
  font-family: Roboto;
  font-size: 14px;
  line-height: 1.43;
  color: #ffffff;
  opacity: 1 !important;
}

#lockedTabTip::after {
  border-bottom-color: #111212 !important;
}

.foldersTabTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 130px;
  font-size: 16px;
  line-height: 1.5;
  color: #ffffff;
  cursor: pointer;
}

.foldersTabTitle[data-short-title="true"] {
  width: 100px;
}

.headerPadding {
  height: 0;
  margin-top: 48px;
}

.subHeaderWrapper {
  position: relative;
}

.error {
  color: red;
  padding-bottom: 10px;
}

.modalFormLabel {
  margin-bottom: 9px;
}