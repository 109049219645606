@value colors: "../style.css";
@value easilGrayXXLight, easilRed from colors;

.dropzone {
  width: 128px;
  height: 128px;
  border-radius: 50%;
  background-color: easilGrayXXLight;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropzone img {
  width: 128px;
  height: 128px;
  border-radius: 50%;
}

.dropzone[data-is-dragover="true"] {
  border: 2px dashed black;
}

.component {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon {
  pointer-events: none;
}

.description {
  margin-top: 32px;
  font-weight: 300;
}

.inputfile {
  display: none
}

.inputfile  ~ label {
  text-decoration: underline;
  cursor: pointer;
}

.error {
  margin-top: 8px;
  text-align: center;
  color: easilRed;
  font-size: 12px;
  animation: fade 0s ease-in 5s forwards;
  animation-fill-mode: forwards;
}

@keyframes fade {
  to {
    width:0;
    height:0;
    overflow:hidden;
  }
}
@-webkit-keyframes fade {
  to {
    width:0;
    height:0;
    visibility:hidden;
  }
}
