.colorSample {
  border-radius: 100%;
  width: 24px;
  height: 24px;
  border: solid 1px rgba(0, 0, 0, 0.12);
  margin: 4px;
  cursor: pointer;
  position: relative
}

.colorSampleTransparent {
  background: linear-gradient(135deg, #ffffff 47%,#ffffff 47%,#ff0000 47%,#000000 50%,#ffffff 50%) !important;
}

.colorSampleSquare {
  border-radius: 20%;
}
