@value colors: "../../colors.css";
@value easilBlack, easilGrayXDark, easilGrayDark from colors;

.outer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.topRow {
  flex: 1 0 100%;
  padding: 32px 0px 40px;
  width: 100%;
}
.col1 {
  margin-right: auto;
  width: 100%;
}

.col2 > div {
  width: 196px
}

@media screen and (max-width: 950px) {
  .col1 {
    max-width: none;
  }
}
