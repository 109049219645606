@value colors: "~views/style.css";
@value easilGrayXXXLight, easilGrayXXXXLight, easilBlack, easilGrayXLight from colors;

.circlecheck {
  position: relative;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circlecheck > svg {
  z-index: 10;
}

.circlecheck > div:first-of-type {
  position: absolute;
  width: 38px;
  height: 38px;
  border: 2px solid #2c2e2f;
  border-radius: 50%;
  z-index: 10;
} 

.circlecheck > div:last-of-type {
  position: absolute;
  background-color: #fce068;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  transform: translate(4px, 4px);
  z-index: 1;
}

.heading {
  margin: 19px 0 16px;
}

.successMessage {
  color: #54595e;
  font-size: 15px;
  line-height: 1.6;
  margin-bottom: 24px;
}

.supportEmail {
  text-decoration: underline;
  color: easilBlack;
}

.orderStatusBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: easilGrayXXXXLight;
  border: 1px solid easilGrayXXXLight;
  border-radius: 8px;
  width: 300px;
  height: 74px;
}

.orderStatusBox > div {
  color: easilBlack;
  font-size: 15px;
  line-height: 1.33;
  margin-left: 16px;
}

.orderStatusBox > div > strong {
  font-weight: 500;
}

.orderHeading {
  width: 100%;
  margin: 40px 0px 0px;
  border-bottom: 1px solid easilGrayXLight;
  padding-bottom: 12px;
}

.buttonSection {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 40px;
}

.priceSummarySection {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.priceSummarySection > div {
  border-top: none;
  width: 512px;
}

.workspaceButton {
  float: right;
}

.breadCrumb {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 24px;
}

.breadCrumb > div {
  font-size: 16px;
  color: #2a2e2f;
}

.breadCrumb > div:first-of-type {
  margin-right: 15px;
  color: #797d81;
  border-bottom: 1px solid rgba(122, 125, 129, 0.16);
  cursor: pointer;
}

.breadCrumb > svg {
  margin-right: 15px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}

.title {
  margin-bottom: 32px;
}

.billingAndPaymentSection {
  display: flex;
  flex-direction: row;
}

.billingAndPaymentSection > div {
  width: 100%;
}

.billingRow {
  display: flex;
  flex-direction: row;
}

.billingRow:first-of-type {
  margin-top: 24px;
}

.billingRow:not(:last-of-type) {
  margin-bottom: 4px;
}

.billingRowLeft {
  width: 131px;
  font-size: 14px;
  font-weight: 500;
  color: #57595d;
}

.billingRowRight {
  font-size: 14px;
  color: #57595d;
}

.orderDesigns {
  border-top: 1px solid easilGrayXLight;
}

.orderDesigns > div:last-of-type {
  margin-top: 12px !important;
  border-bottom: 2px solid easilGrayXLight;
}