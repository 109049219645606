@value colors: "../style.css";
@value easilBlack from colors;

:root {
  --row-spacing-x: 16px;
  --row-spacing-y: 16px;
}

.title {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2;
  color: #2c2e2f;
  margin-bottom: 24px;
  margin-top: 48px;
}

.inputRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow: visible;
  transition: height 0.5s ease, margin 0.3s ease, opacity 0.3s ease 0.5s;
}

.inputRow:not(:last-of-type) {
  margin-bottom: var(--row-spacing-y, 16px);
}

.input {
  height: 44px;
  background-color: #ffffff;
}

/* had an input before it */
.input + .input {
  margin-left: var(--row-spacing-x, 16px);
}

.input > div[aria-label="dropdown"] > input {
  padding-top: 12px;
  color: easilBlack;
  margin-right: 10px;
  border-right: 1px solid #e2e5e8;
}

.input > div[aria-label="dropdown"] > div {
  position: absolute;
  margin-top: -16px;
  font-size: 10px;
  padding-left: 3px;
}

.input > div[aria-label="dropdown"][data-disabled="true"] > input {
  color: #2c2e2f;
}

.input > div[aria-label="dropdown"][data-disabled="true"] > div {
  color: #767e83;
}

.dropdownWrapper {
  height: 100%;
}

.dropdownPopout {
  height: auto;
}

.warn > div:first-of-type {
  border: solid 1px #FFD34A !important;
}

.error > div:first-of-type {
  border: solid 1px #c01818 !important;
}