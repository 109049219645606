@value colors: "../style.css";
@value easilBlack from colors;

.content {
  background: #fff;
  overflow: visible;
  webkit-overflow-scrolling: touch;
  border-radius: 4px;
  outline: none;
  padding: 24px;
  width: 750px;
}

.overlay {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(28, 29, 30, 0.88);
  z-index: 99;
}

.bodyOpen {
  overflow: hidden;
}

.closeIcon {
  margin-left: 10px;
  cursor: pointer;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px
}

.subHeader {
  margin-top: 16px;
  height: 24px;
  font-size: 14px;
  line-height: 1.71;
  letter-spacing: normal;
  color: #73767a;
  width: 100%;
  color: #73767a;
}

.headerTitle {
  color: easilBlack;
  font-size: 20px;
  line-height: 28px;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
}

.buttons button {
  margin-right: 12px
}

.buttons button:last-child {
  margin-right: 0px
}
