@import url("../dropdown/style.module.css");

.search {
  margin: 0 24px;
}

.search > div > input {
  padding-left: 8px;
}

.countryDivider {
  margin: 8px 24px;
}