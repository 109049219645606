.wrapper {
  align-items: center;
  cursor: pointer;
  display: flex;
}

.icon {
  margin-left: 8px;
  stroke: #fff;
  transform: rotate(90deg) !important;
  width: 6px;
}