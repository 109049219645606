.teamSettingsButtonWrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.teamSettingsTitle {
  color: #57595d;
  font-weight: 500;
  margin-left: 4px;
}

.teamSettingsButtonWrapper[data-is-divider="true"] {
  border-right: 1px solid #d1d4d6;
  padding-right: 16px;
}