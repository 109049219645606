@value colors: "../../colors.css";
@value easilBlueDark, easilGrayDark, easilBlueDeep from colors;

.pageWrapper {
  background-color: rgb(244, 244, 245);
}

.divider {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 8px 0 -4px !important;
}

/* Remove divider from all except first instance */
.divider~.divider {
  display: none;
}

.line {
  width: 83px;
  height: 1px;
  background-color: #e2e5e8;
}

.text {
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  color: #73767a;
}

.freeEdgeNotice {
  font-size: 15px;
  font-weight: 500;
  color: #272829;
}

.easilEdgeLink {
  cursor: pointer;
  color: easilBlueDark;
}

.disclaimer {
  text-align: center;
  font-size: 8px;
  color: easilGrayDark;
  line-height: 12px;
}

.disclaimerLink {
  color: easilBlueDeep;
}

.buyCollectionButton > span {
  font-size: 11px;
}

.titleClassName {
  margin: 0px;
}