@value colors: "~views/style.css";
@value easilBlue, easilBlack, easilGrayXDark from colors;

.teamLogoButtonWrapper {
  border-radius: 50%;
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d1d4d6;
  border: 2px solid white;
  cursor: pointer;
}

.teamLogoButtonWrapper[data-has-logo="false"] {
  background-color: easilBlue;
}

.popout {
  left: 0px;
  top: 30px;
  width: auto !important;
}

.popoutMenu {
  width: auto !important;
  color: easilBlack;
}

.popoutMenu > div:first-of-type {
  width: max-content;
  padding: 12px;
}

.popoutActions > div > * {
  font-size: 14px !important;
  font-weight: 500;
  height: auto;
}