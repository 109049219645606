.modal {
  width: 550px !important;
  height: 256px !important;
}

/* modal cross */
.modal > :nth-child(1) > :nth-child(2) {
  margin-top: -9px;
  margin-right: -9px;
}

/* modal text */
.modal > :nth-child(2) > div > div {
  font-family: Roboto;
  font-size: 16px;
  line-height: 1.5;
  color: #57595d;
}
