@value colors: "../style.css";
@value easilBlack from colors;

.collectionDetails {
  width: 100%;
  height: 141px;
  display: flex;
}

.designPreviewWindow {
  width: 100px;
  height: 141px;
}

.designThumbnail {
  border-radius: 8px;
  max-width: 100%;
  max-height:100%;
  display: flex;
  justify-content: center;
  margin: 0 auto;
}

.collectionInformation {
  width: 100%;
  max-height: 145px;
  margin-left: 24px;
  display: flex;
  flex-direction: column;
}

.collectionHeaderPrice {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.designTitle {
  width: 374px;
  /* max height is set to 3 lines of text */
  max-height: 72px;
  font-size: 18px;
  font-weight: 500;
  display: inline-block;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: easilBlack;
}

.collectionIncludes {
  line-height: 20px;
}

.collectionPrice {
  float: right;
  font-size: 18px;
  color: #272829;
  font-weight: 500;
}

.collectionPrice > span {
  font-size: 11px;
  color: #272829;
  font-weight: 500;
}

.designSizeOptions {
  /* make the style disable scroll bar visibility */
  composes: no-scroll-bars from '~views/style.module.css';
  display: flex;
  flex-wrap: wrap;
  margin-top: 8px;
  max-height: 100%;
  overflow-y: scroll;
}

.scrollbar {
  margin-left: 12px;
  width: 5px;
  height: 17px !important;
  border-radius: 2.5px;
  background-color: #7d7d7d;
}

.scrollContentClassName {
  position: absolute;
}