@value colors: "~views/colors.css";
@value easilBlack, easilGray, easilGrayXLight  from colors;

.inputContainer {
  display: flex;
  justify-content: space-between;
  height: 40px;
  align-items: center;
}

.inputContainer > div:nth-child(1) {
  height: 24px;
  line-height: 1.71;
  color:  easilGray;
}

.inputContainer > div:nth-child(2) {
  width: 80px;
  height: 32px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding: 0px 13px;
  border-radius: 4px;
  border: solid 1px easilGrayXLight;
}

.inputContainer::after {
  content: "s";
  position: absolute;
  right: 8%;
  top: 50%;
}

@-moz-document url-prefix() {
  .inputContainer::after {
    content: "s";
    position: absolute;
    right: 8%;
    top: 59%;
  }
}

.inputField {
  color: easilBlack;
  text-align: center;
}
