@value colors: "~views/colors.css";
@value easilGrayXLight, easilBlack, easilGrayXDark from colors;

.dropdownItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: easilGrayXDark;
  padding: 0px 16px;
  cursor: pointer;
}

/* previous element was a dropdownItem */
.dropdownItem + .dropdownItem > .optionContents {
  border-top: 1px solid #e6e8ea;
}

.dropdownItem > span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropdownItem[data-is-current-default="true"] {
  height: 48px;  color: easilBlack;
  background-color: #fafafb;
  cursor: unset;
}


.dropdownItem:hover {
  background-color: #fafafb
}

.optionContents {
  padding: 16px 0px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.optionLeft {
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.optionLeft > p {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.71;
  color: #1c1d1e;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 300px;
  overflow: hidden;
  margin: 0;
}

.optionRight {
  height: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
}

.optionRight > p {
  width: 125px;
  height: 24px;
  font-family: Roboto;
  font-size: 14px;
  line-height: 1.71;
  text-align: right;
  color: #73767a;
  margin: 0px 10px;
}

.meta {
  align-items: center;
  display: flex;
  font-weight: normal;
}

.meta small {
  color: easilGrayDark;
  font-size: 13px;
  margin-right: 8px;
}

.meta strong {
  margin-right: 4px;
}

.meta > * {
  cursor: pointer;
}