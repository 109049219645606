@value colors: "~views/style.css";
@value easilBlack, easilRed, easilGrayXLight, easilGrayXDark, easilGrayDark, easilGrayXXLight from colors;

.wrapper {
  margin-top: 40px;
}

.pageHeader {
  color: easilBlack;
  font-size: 24px;
  font-weight: normal;
}

.sectionHeading {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
  min-height: 36px;
}

.sectionHeading h2 {
  color: easilBlack;
  font-size: 18px;
  font-weight: normal;
  margin: 0;
}

.tablist {
  margin: 32px 0 64px;
}

.editableInput {
  justify-content: space-between;
  width: 100%;
}

.formGroup + .formGroup {
  margin-top: 24px;
}

.formGroupInline + .formGroup {
  margin-top: 24px;
}

.formGroupInline {
  display: flex;
  margin-top: 24px;
}

.formGroupInline .formGroup {
  flex: 1 1 auto;
}

.formGroupInline .formGroup + .formGroup {
  margin-top: 0;
  margin-left: 16px;
}

.formGroupLabel {
  color: easilBlack;
  margin-bottom: 23px;
}

.fieldError {
  color: easilRed;
  font-size: 12px;
  margin-top: 2px;
}

.addImageButton {
  margin-right: 20px;
}

.addImageButton > label:last-of-type > div > img {
  height: 100% !important;
}

.teamLogoUpload {
  display: flex;
  align-items: center;
}

.removeLogoLink {
  color: easilBlack;
  cursor: pointer;
}

.teamTab {
  display: flex;
  margin-bottom: 32px;
}

.teamTabCol {
  flex: 1 1 50%;
}

.teamTabCol:first-child {
  margin-right: 120px;
}

.billingEditButtonIcon {
  margin-right: 8px;
}

.billingInvoiceEmailCallout {
  background-color: easilGrayXXLight;
  border-radius: 4px;
  color: easilGrayDark;
  display: flex;
  padding: 24px;
}

.billingInvoiceEmailCalloutContent {
  line-height: 1.71;
  margin-left: 16px;
}

.billingInvoiceEmailCalloutContent span {
  color: easilGrayXDark;
}

.billingInvoiceEmailInfo {
  color: easilGrayXDark;
  margin-top: 31px;
}

.billingInvoiceEmailInfo span {
  color: easilBlack;
  cursor: pointer;
}
