@value colors: "~views/style.css";
@value easilGrayDark, easilGrayXDark from colors;

.padLock {
  margin-right: 4px;
  height: 16px;
  width: 16px;
}

.contents {
  display: flex;
  align-items: center;
}

.linkDisabled {
  padding-bottom: 2px;
  color: easilGrayDark;
  cursor: default !important;
}

#lockedTabTip {
  width: 247px;
  height: 72px;
  border-radius: 4px;
  background-color: #1c1d1e;
  display: flex;
  align-items: center;
  padding: 16px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #ffffff;
}

#lockedTabTip::after {
  border-bottom-color: transparent !important;
  border-radius: 4px;
  font-size: 12px;
  color: white;
}
