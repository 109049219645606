@value colors: "~views/style.css";
@value easilBlack, easilGrayXLight, easilGrayDark from colors;

.wrapper {
  display: flex;
}

.tab,
.active {
  border-bottom: 1px solid easilGrayXLight;
  color: easilGrayDark;
  cursor: pointer;
  font-size: 16px;
  padding: 8px 16px;
  transition: 300ms all ease-in-out;
}

.active,
.tab:hover {
  border-bottom-color: easilBlack;
  color: easilBlack;
}
