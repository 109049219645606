@value colors: "~views/style.css";
@value globalVars: "~views/style.css";
@value easilBlack, easilGray, easilGrayXLight, easilGrayXDark, hoverColor from colors;
@value cardSize, gutterSize, landscapeCardSize, landscapeGutterSize, layoutWidth from globalVars;


.masonryWrapper {
  position: relative;
  width: layoutWidth;
  margin: 40px auto auto auto;
}

.grid {
  width: 100%;
}

.gridSizer {
  width: var(--masonry-design-card-width, cardSize);
}

.gutterSizer {
  width: gutterSize;
}

.item {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
  width: var(--masonry-design-card-width, cardSize);
  margin-bottom: gutterSize;
  overflow: visible;
}

.item:hover {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
}

.landscapeGridSizer {
  width: var(--masonry-design-card-width, landscapeCardSize);
}

.landscapeGutterSizer {
  width: landscapeGutterSize;
}

.itemLandscape {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
  overflow: visible;
  width: var(--masonry-design-card-width, landscapeCardSize);
  margin-bottom: landscapeGutterSize;
}

.item > img {
  width: 100%;
}

.cardBottom {
  height: 72px;
  padding: 0px 12px 0px 12px;
}

.cardBottomRow {
  display: flex;
  align-items: center;
  position: relative;
}

.popout {
  position: absolute;
  width: 300px;
  bottom: 25px;
  z-index: 1;
  border: solid 1px #e4e4e4;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
}

.designTitle {
  color: easilBlack;
  margin-bottom: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.caretWrapper {
  display: flex;
}

.image {
  border-radius: 2px 2px 0px 0px;
}

.popoutContentTop {
  border-bottom: 1px solid lightgray;
  padding: 16px;
}

.popoutContentTop1Row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 11px;
}

.popoutContentTop1Row > div:first-child {
  padding-right: 10px;
  font-size: 15px;
  color: easilBlack;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.popoutContentTop1RowEditting {
  align-items: center;
  display: flex;
  margin-bottom: 8px;
}

.popoutContentTop1RowEditting > svg:last-child {
  margin-left: 12px;
}

.editDesignName {
  height: 36px;
  margin-right: 16px;
}

.popoutContentTop > div + div {
  font-size: 13px;
  color: easilGray;
  margin-bottom: 0px;
}

.popoutContentMiddle {
  border-bottom: 1px solid lightgray;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 16px 0px 16px 0px;
}

.popoutContentMiddle > ul > li {
  display: flex;
  align-items: center;
  line-height: 1.71;
  color: easilGrayXDark;
  margin-bottom: 10px;
  padding: 0px 16px 0px 16px;
  cursor: pointer;
}

.popoutContentMiddle > ul > li:last-child {
  margin-bottom: 0px;
}

.popoutContentMiddle > ul > li:hover {
  background-color: hoverColor;
}

.popoutContentMiddle > ul > li > div {
  padding-left: 12px;
}

.popoutContentBottom {
  border-bottom: 1px solid lightgray;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 16px;
}

.topRowLeftItem {
  margin-left: 32px;
}

.folderMenu {
  margin-top: 60px;
}

.folderMenuWrapper {
  position: relative;
}

.folderMenuLabelWrapper {
  align-items: center;
  cursor: pointer;
  display: flex;
  user-select: none;
}

.folderMenuLabel {
  color: easilBlack;
  font-size: 15px;
  margin: 0 12px 0 8px;
}

.folderMenuDropdown {
  background-color: #fff;
  border: 1px solid easilGrayXLight;
  border-radius: 4px;
  left: 0;
  padding: 16px;
  position: absolute;
  top: 40px;
  z-index: 2;
}

.folderMenuDropdownOption {
  color: easilBlack;
  cursor: pointer;
  user-select: none;
}

.emptyStateContainer {
  display: flex;
  align-items: center;
  min-height: 300px;
  padding-top: 156px;
  flex-direction: column;
  padding-bottom: 206px;
}

.emptyStateIcons {
  margin-bottom: 32px;
}

.emptyStateTitle {
  font-size: 24px;
  margin-bottom: 16px;
  color: easilBlack;
}

.emptyStateDescription {
  white-space: pre-line;
  text-align: center;
  font-size: 15px;
  margin-bottom: 24px;
}

.imagesEmptyContainer {
  padding-top: 74px;
}

.smartImageEmptyContainer {
  padding: 0;
  min-height: 0;
}

.brandAnimationsEmptyContainer {
  position: relative;
  padding-top: 20px;
}

.designsPlaceholder {
  min-height: 1000px;
}