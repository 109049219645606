.overflowText {
  max-width: 640px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.teamFontName {
  composes: overflowText;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 8px;

}

.fontFamily {
  composes: overflowText;
  color: #8f9295;
}
