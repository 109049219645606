@value colors: "~views/colors.css";
@value easilBlack, easilGrayXDark, easilGrayXLight, easilBlueDeep from colors;

:root {
  --payment-option-height-offset-difference: 0px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  /* height: 100%; */
  width: 100%;
  margin-top: 24px;
}

.title {
  color: easilBlack;
  margin-bottom: 16px;
  font-family: Roboto;
  font-size: 18px;
  line-height: 1.44;
  color: #2c2e2f;
}

.paymentOptions {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.paymentOption {
  border: 1px solid easilGrayXLight;
  width: 100%;
  max-height: 52px;
  overflow: hidden;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  transition: max-height 0.3s ease;
}

.paymentOption + .paymentOption {
  margin-left: 16px;
}

.paymentOption[data-is-active="true"] {
  max-height: calc(190px + var(--payment-option-height-offset-difference));
}

.paymentOption[data-is-active="false"] > .paymentOptionHeader {
  cursor: pointer;
}

.paymentOptionHeader {
  display: flex;
  flex-direction: row;
  height: 52px;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  cursor: pointer;
}

.paymentOptionHeaderLeft {
  display: flex;
  flex-direction: row;
  color: easilBlack;
  align-items: center;
}

.selectionCircle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid easilGrayXLight;
  margin-right: 12px;
  transition: border-width 0.1s ease-in-out;
}

.selectionCircle[data-is-active="true"] {
  border: 7px solid easilBlueDeep;
}

.paymentOptionHeaderRight {
  display: flex;
  flex-direction: row;
}

.paymentOptionHeaderRight > img:not(:last-of-type) {
  margin-right: 12px;
}

.logo {
  width: 36px;
  height: 22px;
  object-fit: contain;
}

.paymentOptionContent {
  display: flex;
  flex-direction: column;
  padding: 16px;
  background-color: #fcfcfc;
  min-height: 138px;

  border: 1px solid easilGrayXLight;
  width: 100%;
  border-radius: 8px;
  margin-top: 8px;
}

.paypalContent {
  height: 104px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: easilGrayXDark;
}

.headerSection {
  display: flex;
  flex-direction: row;
}

.contentWrapper {
  max-height: calc(147px + var(--payment-option-height-offset-difference));;
  overflow: hidden;
  transition: max-height 0.3s ease 0s;
}

.contentWrapper[data-is-content-shown="false"] {
  max-height: 0px;
  transition: max-height 0.3s ease 0.2s;
}

.contentWrapper > .paymentOptionContent {
  opacity: 1;
  transition: opacity 0.2s ease 0.3s
}

.contentWrapper[data-is-content-shown="false"] > .paymentOptionContent {
  opacity: 0;
  transition: opacity 0.2s ease 0s
}

.paypalLogo {
  width: 83px;
  height: 22px;
  object-fit: contain;
}