:root {
  --marker-width: 45px;
  --font-size: 10;
  --paragraph-spacing: 100;
  --line-height: 1.4;
}

.contentEditable {
  user-select: text;
}

.textCursorAnchor a {
  cursor: text;
}

.moveCursorAnchor a {
  cursor: move;
}

.EditorTextbox ul {
  margin-left: calc(1px * (var(--font-size)));
}

.EditorTextbox ul[class*="numeric"] {
  counter-reset: tab;
}

.EditorTextbox ul[class*="numeric"] > li::before {
  counter-increment: tab;
}

.EditorTextbox > div > ul[class*="numeric"] > li::before {
  content: counter(tab) ".";
}

.EditorTextbox > div > ul > li > ul[class*="numeric"] > li::before {
  content: counter(tab, lower-alpha) ".";
}

.EditorTextbox > div > ul > li > ul ul[class*="numeric"] > li::before {
  content: counter(tab, lower-roman) ".";
}

.EditorTextbox ul[class*="numeric"] li::before {
  position: absolute;
  right: calc(100% - (45px - (var(--font-size) * 1px)));
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-width: var(--marker-width);
}

.EditorTextbox li {
  position: relative;
}

.EditorTextbox ul[class*="bullet-bullet"] > li::before {
  content: '•';
  position: absolute;
  right: calc(100% - (45px - (var(--font-size) * 1px)));
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-width: var(--marker-width);
}

.EditorTextbox ul[class*="bullet-square"] > li::before {
  content: '■';
  position: absolute;
  right: calc(100% - (45px - (var(--font-size) * 1px)));
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-width: var(--marker-width);
}

.EditorTextbox > div > ul[class*="disc"] {
  list-style-type: disc;
}

.EditorTextbox > div > ul[class*="disc"] ul {
  list-style-type: revert;
}

.EditorTextbox li:empty:before {
  content: ".";
  color: transparent;
  cursor: text;
}

.EditorTextbox li:empty:focus:before {
  content: "";
}

/* styling <mark/> tags for rich text
when selection is changed */
mark {
  background-color: #ACCEF7;
  color: inherit;
}

.textBoundaryLock {
  overflow-wrap: normal;
  overflow: hidden;
}

.editorInputAutoComplete {
  width: 225px;
  /* height: 204px; */
}

sup {
  font-size: 56%;
}