/* TODO: review if Montserrat is part of defautl fonts in the editor */
@import url("https://fonts.googleapis.com/css?family=Montserrat");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700");

@import "../node_modules/normalize.css/normalize.css";

html {
  display: flex;
  flex-direction: column;
}

html,
body,
#root {
  height: 100vh
}

/* Hide UserEngage widget Icon. More efficient than handling in userChat.js */
#usercom-launcher-dot-frame {
  visibility: hidden;
}

.usercom-launcher-counter {
  visibility: hidden;
}