.tableRowDropZone {
  z-index: 3;
  left: 0;
  width: 100%;
  height: 50%;
  position: absolute;
}

.tableRowDropZone[data-position="top"] {
  top: 0%;
}

.tableRowDropZone[data-position="bottom"] {
  top: 50%;
}
