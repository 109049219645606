.wrapper {
  height: 123px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.text {
  margin-top: 10px;
  width: 337px;
  height: 24px;
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  color: #57595d;
}
