.detailRow {
  display: flex;
  margin-bottom: 12px;
}

.iconBox {
  height: 24px;
  width: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
}

.detailText {
  min-height: 24px;
  font-family: Roboto;
  font-size: 15px;
  line-height: 1.6;
  color: #57595d;
}
