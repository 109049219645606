@value colors: "../style.css";
@value easilGrayXXLight, easilBlack from colors;

.option {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 6px 24px;
}
.option:hover {
  background-color: easilGrayXXLight;
}

.selected {
  line-height: 1;
  margin-left: 16px;
}

.countryNameFlagWrapper {
  display: flex;
  align-items: center;
}

.name {
  color: easilBlack;
  margin-left: 12px;
}