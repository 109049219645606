.printingOptions {
  display: flex;
  margin-top: 8px;
  /* button styling overide as per design */
  height: 24px !important;
  padding: 0 4px 0 12px;
}

.printingOptions > span {
  font-size: 12px;
  font-weight: 500;
  line-height: 2;
}