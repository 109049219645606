.tableCellWrapper {
  padding: 0px;
  box-sizing: border-box;
  width: auto;
}

.selectedCellStyle {
  border: 1px solid rgb(49,132,252);
  position: absolute;
  width: 0px;
  height: 0px;
  top: inherit;
  left: inherit;
  z-index: 5;
  pointer-events: none;
}

.tableCell {
  width: 100%;
  height: 100%;
}

.tableCell[data-is-selected="true"] {
  outline: 2px solid rgb(49,132,252);
}

.lockingIndicator {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  background-color: white;
  right: 0;
  box-shadow: 0 1px 2px 0 rgba(21, 21, 22, 0.12);
  animation: bounce-in 0.3s;
}

@keyframes bounce-in {
  0% {
    opacity: 0;
    transform: scale(.7);
  }
  50% { transform: scale(1.02); }
  100% { transform: scale(1); }
}