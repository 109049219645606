@value colors: "../style.css";
@value easilBlack from colors;

:root {
  --label-float-height: 44px;
  --label-float-margin-x: 12px;
  --label-float-margin-y: 12px;
}

.floatLabel > div {
  position: absolute;
  /* V Test styling, will be overwritten */
  border-radius: 4px;
  border: solid 1px #d1d4d6;
  width: 100%;
  height: var(--label-float-height, 44px);
}

.floatLabel > div[id^="number"] {
  border-radius: none;
  border: none;
}

.floatLabel > div[id^="number"] > div {
  position: absolute;
  /* V Test styling, will be overwritten */
  border-radius: 4px;
  border: solid 1px #d1d4d6;
  width: 100%;
  height: var(--label-float-height, 44px);
}

.floatLabel > div[id^="number"] > div:focus-within {
  border: solid 1px #3184FC;
}

.floatLabel > div > input {
  position: absolute;
  margin: 15px 12px 15px 12px;
  
  color: inherit;
  background-color: transparent;
  outline: none;
  width: calc(100% - (var(--label-float-margin-x, 12px) * 2));

  font-size: 14px;

  height: auto;
}

.floatLabel > div > textarea {
  position: absolute;
  margin: 15px 12px 15px 12px;
  
  color: inherit;
  background-color: transparent;
  outline: none;
  width: calc(100% - (var(--label-float-margin-x, 12px) * 2));

  font-size: 14px;

  border: none;
  padding: 0;
  height: calc(100% - (var(--label-float-margin-y, 15px) + 4px));
  resize: none;
}

.floatLabel > div[id^="number"] > div > input {
  position: absolute;
  margin: 15px 12px 15px 12px;
  
  color: inherit;
  background-color: transparent;
  outline: none;
  width: calc(100% - (var(--label-float-margin-x, 12px) * 2));

  font-size: 14px;
  text-align: left;

  height: auto;
}

.floatLabel {
  position: relative;
  height: var(--label-float-height, 44px);
  width: 100%;
}

.floatLabel > div > label {
  position: absolute;
  transform: translate(var(--label-float-margin-x), calc(var(--label-float-margin-y) * 0.75)) scale(1);
  transition: all 0.2s ease-out;
  pointer-events: none;
  transform-origin: 0% 0%;
}

.floatLabel > div[id^="number"] > div > label {
  position: absolute;
  transform: translate(var(--label-float-margin-x), calc(var(--label-float-margin-y) * 0.75)) scale(1);
  transition: all 0.2s ease-out;
  pointer-events: none;
  transform-origin: 0% 0%;
}

.floatLabel > div > input {
  transition: all 0.2s ease-out;
  color: easilBlack;
}

.floatLabel > div[id^="number"] > div > input {
  transition: all 0.2s ease-out;
  color: easilBlack;
}

.floatLabel > div > textarea {
  transition: all 0.2s ease-out;
  color: easilBlack;
}

.floatLabel:focus-within > div > label {
  transform: translate(var(--label-float-margin-x, 12px), calc(var(--label-float-margin-y, 15px) / 3)) scale(0.67) !important;
}

.floatLabel:focus-within > div[id^="number"] > div > label {
  transform: translate(var(--label-float-margin-x, 12px), calc(var(--label-float-margin-y, 15px) / 3)) scale(0.67) !important;
}

.floatLabel:focus-within > div > input {
  margin-top: calc(var(--label-float-margin-y, 15px) + 4px);
  margin-bottom: 0;
}

.floatLabel:focus-within > div[id^="number"] > div > input {
  margin-top: calc(var(--label-float-margin-y, 15px) + 4px);
  margin-bottom: 0;
}

.floatLabel:focus-within > div > textarea {
  margin-top: calc(var(--label-float-margin-y, 15px) + 4px);
  margin-bottom: 0;
}

.activeText > div > label {
  transform: translate(var(--label-float-margin-x, 12px), calc(var(--label-float-margin-y, 15px) / 3)) scale(0.67) !important;
}

.activeText > div > input {
  margin-top: calc(var(--label-float-margin-y, 15px) + 4px);
  margin-bottom: 0;
}

.activeText > div[id^="number"] > div > label {
  transform: translate(var(--label-float-margin-x, 12px), calc(var(--label-float-margin-y, 15px) / 3)) scale(0.67) !important;
}

.activeText > div[id^="number"] > div > input {
  margin-top: calc(var(--label-float-margin-y, 15px) + 4px);
  margin-bottom: 0;
}

.activeText > div > textarea {
  margin-top: calc(var(--label-float-margin-y, 15px) + 4px);
  margin-bottom: 0;
}

.error {
  margin-top: calc(var(--label-float-height, 44px) + 4px);
  border: none !important;
  color: #c01818;
  margin-left: var(--label-float-margin-x);
}