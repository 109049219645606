@value colors: "~views/colors.css";
@value (
  easilBlack,
  easilGrayXDark,
  easilBlueDeepWithAlpha,
  easilBlueDeepWithAlphaHover,
  easilGrayXXXLight,
  easilGrayXXXXLight,
  easilGrayXDark
) from colors;

.removeBGModalWrapper {
  width: 650px;
  border-radius: 8px;
  background-color: white;
  padding: 32px;
  max-height: 95vh;
  display: flex;
  flex-direction: column;
}

.removeBGModalWrapper > div:first-of-type {
  font-size: 20px;
  line-height: 1.2;
  color: easilBlack;
  margin-bottom: 24px;
}

.removeBGModalWrapper > div:last-of-type {
  overflow: hidden;
  overflow-y: auto;
  flex-direction: column;
  display: flex;
  height: 100%;
}

.gifAnimationWrapper {
  width: 586px;
  height: 225px;
  border-radius: 4px;
  background-color: #eeeeee;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.featureDescription {
  font-size: 15px;
  line-height: 1.6;
  color: easilGrayXDark;
  margin-top: 24px;
}

.upgradeBoxWrapper {
  width: 586px;
  border-radius: 8px;
  border: solid 1px #abd0e8;
  background-color: #ecf5fa;
  margin-top: 32px;
  padding: 16px;
}

.upgradeBoxTitle {
  font-size: 18px;
  line-height: 1.56;
  color: easilBlack;
}

.upgradeBoxDescription {
  font-size: 15px;
  line-height: 1.6;
  color: easilGrayXDark;
  margin: 16px 0px 16px 0px;
}

.creditBreakdownBoxWrapper {
  width: 586px;
  height: 84px;
  border-radius: 8px;
  border: solid 1px easilGrayXXXLight;
  background-color: easilGrayXXXXLight;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 24px;
  padding: 0px 20.5px 0px 4px;
  justify-content: space-around;
}

.creditBreakdownDetail {
  height: 52px;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.detailSection {
  display: flex;
  width: 100%;
}

.verticalDivider {
  width:0px;
  height: 52px;
  border-right: 1px solid #dddddd;
}

.creditBreakdownDetailTitle {
  font-size: 14px;
  line-height: 1.43;
  display: flex;
  align-items: center;
  color: easilGrayXDark;
}

.creditBreakdownDetailTitle > div > svg {
  margin-left: 6px;
}

.creditBreakdownDetailValue {
  font-size: 20px;
  line-height: 1.4;
  color: easilBlack;
}

.info {
  align-items: flex-start !important;
  margin-top: 24px;
}

.info > div {
  font-size: 14px;
  line-height: 1.57 !important;
  color: #2c2e2f;
}

strong[data-is-link="true"] {
  color: #2c6fcf;
  cursor: pointer;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 40px;
}

.applyButton {
  margin-left: 16px;
  min-width: 197px;
}

.loadingData {
  margin-top: 40px;
}
