@value colors: "../../colors.css";
@value easilBlack from colors;

.content {
  margin: auto;
  min-width: 870px;
  max-width: 958px;
  display: flex;
}

.col1 {
  width: 79%
}

.col2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 21%
}

.content .title {
  margin-bottom: 48px;
}

.teamName {
  width: 309px;
  display: inline-block;
  margin-right: 12px;
}

.tableName {
  font-size: 22px;
  font-weight: 300;
  margin-bottom: 32px;
}

.tableSection {
  margin: 64px 0px 48px 0px;
}
