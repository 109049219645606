@value colors: "../style.css";
@value (
  easilGrayXLight,
  easilGrayXXXLight,
  easilGrayDark,
  easilGrayXDark
) from colors;

.wrapper {
  position: relative;
  align-items: center;
  border-radius: 8px;
  display: flex;
  border: 1px solid #f5f5f8;
  background-color: #f5f5f8;
}

.wrapper[data-is-focused="true"] {
  background-color: #fcfcfd;
  border: 1px solid easilGrayXLight;
}
.icon {
  margin-left: 12px;
}

.input {
  border: none;
  height: 40px;
  line-height: 40px;
  padding-top: 0;
  padding-bottom: 0;
  color: #73767a;
}

.searchSuggestions {
  position: absolute;
  width: 100%;
  max-height: 350px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(15, 15, 16, 0.1);
  border: solid 1px easilGrayXXXLight;
  background-color: white;
  top: 54px;
  padding: 10px;
}

.searchSuggestions > :last-child {
  margin-bottom: 0;
}

.searchSuggestionsTitle {
  display: flex;
  color: easilGrayXXXLight;
  margin-bottom: 8px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: easilGrayDark;
  padding: 6px;
}

.suggestion {
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: easilGrayXDark;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 6px;
  padding: 6px;
}

.suggestion:hover {
  cursor: pointer;
}

.suggestion[data-is-highlighted="true"] {
  border-radius: 4px;
  background-color: #f4f4f6;
  cursor: pointer;
}

.suggestion[data-is-highlighted="true"] > svg {
  visibility: visible;
}

.suggestion[data-is-highlighted="false"] > svg {
  visibility: hidden;
}

.suggestionIcon {
  margin-left: 12px;
}
