@value colors: "~views/colors.css";
@value easilBlack from colors;

.wrapper {
  padding: 32px;
  width: 550px !important;
  border-radius: 8px;
}

.comment {
  width: 486px;
  max-height: 72px;
  min-height: 52px;
  border-radius: 8px;
  border: solid 1px #e2e5e8;
  background-color: #fcfcfd;
  padding: 16px;
  white-space: pre-line;
  overflow: auto;
}

.commentSection {
  margin: 24px auto;
}

.commentLabel {
  height: 24px;
  font-size: 15px;
  line-height: 1.6;
  color: #57595d;
  margin: 24px auto 8px auto;
}

.notification {
  position: relative;
}

.notification > div {
  position: absolute;
  top:48px;
}

.notification > div > span {
  color: #2c2e2f;
}

.textarea {
  /* make the style disable scroll bar visibility */
  composes: no-scroll-bars from '~views/style.module.css';
  resize: none;
  width: 486px;
  height: 40px;
  padding: 8px 12px;
  white-space: pre-line;
  overflow: auto;
  font-size: 15px;
  color: easilBlack;
  line-height: 1.6;
  border-radius: 4px;
  border: solid 1px #d1d4d6;
  background-color: #ffffff;
}

.textarea:focus {
  outline: none;
}
