@value colors: "~views/colors.css";
@value easilGrayXDark from colors;

.fontDescritpionSection {
  margin: 24px 0px 32px 0px;
  padding-bottom: 32px;
  border-bottom: 1px solid #e6e8ea;
}

.inputAndToggle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.heading {
  color: easilGrayXDark;
  display: block;
  margin-bottom: 8px;
}

.toggleSection {
  display: flex;
  justify-content: space-between;
  width: 32%;
  align-items: center;
}

.inputField {
  width: 63%
}

.toggleCheckBox span{
  background-color: #DDE1E3;
}
