@value colors: "../style.css";
@value easilBlack from colors;

.wrapper {
  padding: 12px 0;
}

.wrapper + .wrapper {
  border-top: 1px solid #e4e4e4;
}

.labelWrapper {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}

.label {
  font-size: 14px;
  user-select: none;
}

.icon {
  margin-left: 12px;
}

.content {
  color: easilBlack;
}