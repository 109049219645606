@value colors: "../../colors.css";
@value easilBlack from colors;

.wrap {
  padding: 13px 24px 13px 24px;
}

.header {
  height: 120px;
}
