@value colors: "../style.css";
@value easilGrayDark, easilBlack, easilGrayXXXLight from colors;

.breadCrumbWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
}

.sharedFolderIcon {
  padding-right: 8px;
}

.crumb {
  display: flex;
  align-items: center;
}

.crumb[data-has-action="true"] {
  cursor: pointer;
}

.crumbText {
  margin-right: 8px;
  font-family: Roboto;
  font-size: 16px;
  line-height: 1.5;
  color: easilGrayDark;
}

.crumbText[data-is-last="true"] {
  color: easilBlack;
}

.crumbText[data-is-first="true"] {
  text-decoration: underline;
}

.crumbCaret {
  padding-right: 8px;
  height: 24px;
  display: flex;
  width: 24px;
  align-items: center;
}

.crumbDropdown {
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(21, 21, 22, 0.08);
  border: solid 1px easilGrayXXXLight;
  background-color: white;
}

.crumbAndDropdownWrapper {
  position: relative;
}