@value colors: "~views/colors.css";
@value easilGrayXLight, easilBlack, easilGrayXDark from colors;

.dropdownPopout {
  background-color: #FFFFFF;
  position: absolute;
  margin: 8px auto 16px;
  width: 300px;
  border-radius: 4px;
  border: 1px solid easilGrayXLight;
  box-shadow: 0px 2px 3px 0px rgba(28, 29, 30, 0.08);
  padding: 16px 0px;
  max-height: 370px;
  overflow: scroll;
  /* make the popout come through the top */
  bottom: 30px;
}

.dropdownPopoutTitle {
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0;
  color: easilBlack;
  margin-bottom: 16px;
  padding: 0px 16px;
}

.currentDefaultTeam {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: easilGrayXDark;
  padding: 0px 16px;
  cursor: pointer;
  height: 48px;  color: easilBlack;
  background-color: #fafafb;
  cursor: unset;
}

.currentDefaultTeam > span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.searchWrapper {
  padding: 0px 16px;
  margin-bottom: 16px;
}

.returnLink {
  align-items: center;
  display: flex;
  font-weight: 500;
  margin-bottom: 8px;
  padding: 0 16px;
}

.returnLinkIcon {
  margin-right: 20px;
  transform: rotate(180deg);
}

.returnLink {
  cursor: pointer;
}