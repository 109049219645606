@value colors: "../../colors.css";
@value easilBlack, easilRed, easilGrayXLight from colors;

.card {
  width: 100%;
}

.logo {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  justify-content: center;
}

.title {
  color: easilBlack;
  font-size: 24px;
  margin-bottom: 16px;
}

.columns {
  display: flex;
  justify-content: center;
}

.col {
  width: 50%;
  flex: auto;
  border-right: 1px solid easilGrayXLight;
}

.form {
  width: 100%;
}

.form > div ~ div {
  margin-top: 16px;
}

.links {
  align-items: center;
  display: flex;
}

.links a {
  color: easilBlack;
}

.footer {
  display: flex;
  flex-wrap: wrap;
  font-size: 13px;
  line-height: 21px;
}

.footer a {
  margin:0px 3px 0px 3px;
  color: easilGrayDark;
}

.login {
  margin-top: 62px;
}

.login a {
  margin:0px 3px 0px 3px;
  color: easilBlack;
}

.errors {
  margin-top: 16px;
}

.error {
  color: easilRed;
}

.error + .error {
  margin-top: 4px;
}

.actions {
  align-content: center;
  display: flex;
  justify-content: space-between;
}
