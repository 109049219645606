@value colors: "~views/colors.css";
@value easilBlack, easilGrayDark from colors;


.defaultTeamToggle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 24px auto 24px;
  border: 1px solid #e2e5e8;
  border-radius: 4px;
  padding: 16px;
}

.title {
  height: 24px;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.6;
  color: easilBlack;
}

.description {
  font-size: 14px;
  line-height: 1.71;
  color: easilGrayDark;
}
