@value colors: "../style.css";
@value (
  easilBlack,
  easilGrayXLight,
  easilBlue,
  easilRed,
  backgroundDisabled,
  fontDisabled,
  easilGray
) from colors;

.div {
  position: relative;
  border: 1px solid easilGrayXLight;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  border-radius: 4px;
}

.div[data-disabled="true"] {
  background-color: backgroundDisabled;
  cursor: not-allowed;
}

.div input[disabled] {
  background-color: backgroundDisabled;
  color: fontDisabled;
  cursor: not-allowed;
}

.div input {
  background-color: inherit;
  padding: 0px 12px 0px 12px;
  font-size: 15px;
  line-height: 24px;
  color: easilBlack;
  width: 100%;
  border: none;
  border-radius: inherit;
  height: 100%;
  vertical-align: top;
}

.div input:focus {
  outline: none;
}

.div[data-error="true"] {
  border: 1px solid easilRed;
}

.label {
  color: easilBlack;
  font-size: 13px;
  line-height: 1.85;
  text-align: left;
  padding-bottom: 9px;
}

.error {
  position: absolute;
  color: easilRed;
  font-size: 12px;
  top: 100%;
  left: 5px;
}

.clearCross {
  cursor: pointer;
  background-color: easilBlue;
  height: 28px;
  width: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}

.alertToolTip {
  font-size: 12px;
  width: 226px;
}