@value colors: "~views/style.css";
@value easilBlueDeep, easilRed from colors;

.buyCollectionModalContainer {
  width: 650px;
  height: auto;
  padding: 32px;
  border-radius: 8px;
  max-height: 90vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.buyCollectionModalContainer > div[aria-label="Modal Body"] {
  display: flex;
  flex-direction: column;
  /* max-height: 100%; */
  height: auto;
  max-height: 100%;
  overflow: hidden;
  padding-right: 15px;
  margin-right: -15px;
}

.content {
  overflow: scroll;
  overflow-x: hidden;
  flex: 1 1 auto;
  padding-right: 15px;
  margin-right: -15px;
}

/* reduce header margin-bottom */
.buyCollectionModalContainer > div {
  margin-bottom: 24px;
}

.collectionNotification {
  width: 100%;
  margin-top: 24px;
}

.paymentDetails{
  border: 2px solid blue;
  width: 100%;
  height: 106px;
  margin-top: 24px;
}

.promoCode {
  border: 2px solid green;
  width: 100%;
  height: 56px;
  margin-top: 24px;
}

.payButton {
  z-index: 3;
}

.notificationAlert {
  justify-content: flex-start;
  align-items: flex-start;
}

.notificationAlertContent {
  font-family: Roboto;
  font-size: 14px;
  line-height: 1.71;
  color: #2c2e2f;
}

.pollingErrorAlert {
  text-align: center;
  position: relative;
  color: easilRed;
  max-height: 0px;
  margin-top: 12px;
  transition: max-height 0.2s ease;
}

.pollingErrorAlert[data-has-error="true"] {
  max-height: 21px;
}

.buttonHoverWrapper {
  position: relative;
  display: flex;
  justify-content: flex-end;
  min-height: 48px;
  margin-top: 24px;
  height: 48px;
}

.buttonHoverWrapper[data-is-paypal="true"]:hover > button {
  background-color: easilGreenDarkAlphaHover;
}

.buttonBacking {
  background-color: #ffffff;
  pointer-events: none;
  z-index: 2;
  width: 100%;
  height: 100%;
  position: absolute;
}

.paypalButton {
  overflow: hidden;
  border-radius: 8px;
  position: absolute;
  height: 48px;
  width: 100%;
  z-index: 1;
}

.paypalButton > div {
  transform: scale(3);
}