@value colors: "../style.css";
@value easilBlack, easilBlue from colors;

.transferSmartImageModal {
  width: 836px;
  height: 545px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 8px;
}

/* hide render of transfer modal when upload modal is selected */
.uploadingTransferSmartAssetModal {
  display: none;
}

.transferSmartImageModal > div:first-of-type {
  margin-bottom: 24px;
}

.transferModalWrapper {
}

.tansferScrollSection {
  position: relative;
  bottom: 0;
  height: 100%;
}

.scrollbar {
  background: #c5c8ca5d !important;
  right: 0;
}

.foldersSection {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  bottom: 0;
  gap: 0 8px;
}

.folderButton {
  width: 252px;
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(21, 21, 22, 0.12);
  cursor: pointer;
  height: 64px;
  justify-content: flex-start !important;
  padding: 20px 22px;
  margin: 0 0 8px;
}

.folderButton:nth-child(n + 4) {
  margin-right: 0;
}

.folderButtonTitle {
  margin-left: 22px;
  color: easilBlack;
}

.modalButtons {
  margin-top: 24px;
}

.tranferRootPathClass {
  font-family: Roboto;
  font-size: 16px;
  line-height: 1.5;
  color: #8f9297;
}

.folderPathClass {
  font-family: Roboto;
  font-size: 16px;
  line-height: 1.5;
  color: easilBlack;
}

.emptyGrid {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.emptyGrid > div:first-of-type {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.emptyGrid button {
  display: none;
}

.assetCards {
  display: flex;
  flex-wrap: wrap;
  gap: 0 8px;
  align-items: center;
}

.topSection {
  display: flex;
}

.search {
  height: 48px;
  border-radius: 8px;
  width: 100%;
  z-index: 9;
}

.searchInput {
  height: 34px;
  line-height: 34px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.searchInput > input {
  font-family: Roboto;
  background-color: inherit;
  font-size: 16px;
  padding: 0px 12px 0px 12px;
}

.smartImageSuccessToast {
  font-family: Roboto;
  font-size: 14px;
  line-height: 1.43;
  height: 92px
}

.smartImageSuccessToast > div:first-child {
  margin: 0;
}

.smartImageSuccessToast > div:first-child > div:first-child {
  height: 100%;
}