@value colors: "../../colors.css";
@value easilGrayXLight from colors;

.wrapper {
  background-color: #fff;
  border: 1px solid easilGrayXLight;
  border-radius: 4px;
  box-shadow: 0 2px 3px 0 rgba(28, 29, 30, 0.08);
  position: absolute;
  width: 300px;
  z-index: 30;
}