@value colors: "../style.css";
@value easilBlack, easilGrayXLight, easilGrayXDark from colors;

.alert {
  color: easilBlack;
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: solid 1px #abd0e8;
  background-color: #ecf5fa;
  font-size: 15px;
  line-height: 24px;
  padding: 16px 18px;
}

.green {
  background-color: #e7f8f3;
  border: solid 1px rgba(59, 179, 141, 0.32);
}

.red {
  background-color: #fcefee;
  border: solid 1px #f2bfb4;
  color: #992a3b;
}

.red > * {
  color: #992a3b !important;
}

.yellow {
  background-color: #fff5db;
  border: solid 1px #ffd34a;
}

.blue {
  background-color: #ecf5ff;
  border: solid 1px #a1c6ff;
}

.icon {
  display: inline-flex;
  margin: 0px 10px 0px 0px;
  cursor: default;
}

