/* subHeaderTabs */
.subHeaderTabsWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
}

/* subHeaderTab */
.subHeaderTabWrapper {
  position: relative;
  flex: 0 0 auto;
  z-index: 0;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: #2c2e2f;
  color: white;
  height: 44px;
  font-family: Roboto;
  font-weight: 400;
}

.subHeaderTabWrapper > span {
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  left: 0;
  bottom: 12px;
  position: absolute;
  width: 1px;
  height: 20px;
}

.subHeaderTabWrapper:first-of-type > span {
  border-left: none;
}

.subHeaderTabWrapper[id="non-nav-tab"] {
  bottom: -3px;
}

.tabText {
  padding: 10px 14px;
  text-align: center;
  font-size: 16px;
  line-height: 1.5;
  opacity: 0.72;
}

.selectedTab {
  background: white;
  color: black;
  z-index: 1;
  opacity: 1;
  font-family: Roboto !important;
  font-weight: 500;
}

.selectedTab::after {
  position: absolute;
  content:" ";
  background: white;
  width:10px;
  height: 10px;
  display: block;
  bottom: 0;
  right: -10px;
  z-index: 1;
}

.selectedTab::before {
  position: absolute;
  content:" ";
  background: white;
  width:10px;
  height: 10px;
  display: block;
  bottom: 0;
  left: -10px;
  z-index: 1;
}

.leftTabBumper {
  position: absolute;
  height: 0px;
  width: 0px;
  left: -20px;
  bottom: 0;
  z-index:2;
  border-left: 10px solid transparent;
  border-top: 10px solid transparent;
  border-right: 10px solid #2c2e2f;
  border-bottom: 10px solid #2c2e2f;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.rightTabBumper {
  position: absolute;
  height: 0px;
  width: 0px;
  right: -20px;
  bottom: 0;
  z-index:2;
  border-right: 10px solid transparent;
  border-top: 10px solid transparent;
  border-left: 10px solid #2c2e2f;
  border-bottom: 10px solid #2c2e2f;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
