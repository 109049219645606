@value colors: "~views/colors.css";
@value easilBlack from colors;

.countryFlagsDropdown {
  justify-content: space-between;
}

.countryOptions {
  /* set height range to allow for dropdown to shrink with minimum search results */
  height: auto !important;
  min-height: 200px;
  max-height: 300px;
}

.countryNameFlagWrapper {
  display: flex;
  align-items: center;
}

.name {
  margin-left: 12px;
  color: easilBlack;
}