@value colors: "../style.css";
@value easilGrayXXLight from colors;

.option {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 4px 16px;
}
.option:hover {
  background-color: easilGrayXXLight;
}

.selected {
  line-height: 1;
  margin-left: 16px;
}

.avatarOption {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  height: 56px;
  margin: 0 0px 8px 0;
  padding: 8px;
  border-radius: 8px;
  margin-right: 34px;
  overflow-y: hidden !important;
}

.avatarOption[data-is-selected="true"] {
  border: solid 1px #abd0e8;
  background-color: #ecf5fa;
}

.avatarOption[data-is-in-scrollable="false"] {
  width: 100%;
  margin-right: 12px;
}

.avatarOption:hover {
  background-color: easilGrayXXLight;
}

.avatarName {
  margin-left: 12px;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.33;
  color: #2c2e2f;
  width: 100%;
  margin-right: 24px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.cross {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.cross > svg {
  cursor: pointer;
}