:root {
  --replace-button-width: 48px;
  --replace-offset: 0px;
}

.menuWrapper {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  top: calc(-35px + var(--replace-offset));
  width: 100%;
}

.menuWrapperLeft {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  left: calc(-35px + var(--replace-offset));
  width: 24px;
  height: 100%;
  flex-direction: column;
}

.menuWrapperRight {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  width: 24px;
  left: calc(100% + 10px - var(--replace-offset));
  height: 100%;
  flex-direction: column;
}

.menuWrapperBottom {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  top: calc(100% + 10px - var(--replace-offset));
  width: 100%;
}

.tableMenuWrapper {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  top: -35px;
  left: 20px;
  width: 100%;
}

.tableMenuWrapperLeft {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  left: -35px;
  width: 24px;
  height: 100%;
  flex-direction: column;
}

.tableMenuWrapperRight {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  width: 24px;
  left: calc(100% + 27px);
  height: 100%;
  flex-direction: column;
}

.tableMenuWrapperBottom {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  top: calc(100% + 10px);
  left: -20px;
  width: 100%;
}

.table2MenuWrapper {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  top: calc(-58px + var(--replace-offset));
  width: 100%;
}

.table2MenuWrapperLeft {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  left: calc(-61px + var(--replace-offset));
  width: 24px;
  height: 100%;
  flex-direction: column;
}

.table2MenuWrapperRight {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  width: 24px;
  left: calc(100% + 36px - var(--replace-offset));
  height: 100%;
  flex-direction: column;
}

.table2MenuWrapperBottom {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  top: calc(100% + 36px - var(--replace-offset));
  width: 100%;
}

.ellipsisBox {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 24px;
  height: 24px;
  padding: 2px;
  border-radius: 6px;
  box-shadow: 0 1px 2px 0 rgba(21, 21, 22, 0.12);
  background-color: var(--grey-white);
}

.ellipsisBox[data-image-replace="true"] {
  width: calc(24px + var(--replace-button-width));
}

.ellipsisLockBox {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 44px;
  height: 24px;
  border-radius: 6px;
  box-shadow: 0 1px 2px 0 rgba(21, 21, 22, 0.12);
  background-color: var(--grey-white);
}

.ellipsisLockBox[data-image-replace="true"] {
  width: calc(44px + var(--replace-button-width));
}

.ellipsis {
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 4px;
  pointer-events: all !important;
}

.ellipsislockEnabled {
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 2px 4px 4px 2px;
  pointer-events: all !important;
  height: 20px;
  width: 20px;
}

.padlockEnabled {
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 4px 2px 2px 4px;
  pointer-events: all !important;
  height: 20px;
  width: 20px;
}

.replaceButton {
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 2px 2px 2px 2px;
  pointer-events: all !important;
  height: 20px;
  width: var(--replace-button-width);
  font-family: Roboto;
  font-size: 11px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #2c2e2f;
}

.replaceButton:first-child {
  border-radius: 4px 2px 2px 4px;
}

.ellipsis:hover {
  background: #f5f5f8;
}
.padlockEnabled:hover {
  background: #f5f5f8;
}
.ellipsislockEnabled:hover {
  background: #f5f5f8;
}
.replaceButton:hover {
  background: #f5f5f8;
}

.suggestionWrapper {
  display: flex;
  flex-direction: column;
  top: calc(-35px + var(--replace-offset));
}

.suggestionWrapperLeft {
  display: flex;
  flex-direction: column;
  left: calc(-35px + var(--replace-offset));
}

.suggestionWrapperRight {
  display: flex;
  flex-direction: column;
  left: calc(100% + 10px - var(--replace-offset));
  flex-direction: column;
}

.suggestionWrapperBottom {
  display: flex;
  flex-direction: column;
  top: calc(100% + 10px - var(--replace-offset));
}