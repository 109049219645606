@value colors: "~views/colors.css";
@value easilBlack, easilGrayXXXLight, easilGrayXDark from colors;

:root {
  --gif-canvas-width: 64px;
  --gif-canvas-height: 64px;
  --gif-canvas-left: 0px;
  --gif-canvas-top: 0px;
  --gif-div-height: auto;
  --gif-div-width: auto;
}

.imageListRowGutters {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  flex-direction: column;
  position: relative;
}

.imageList {
  width: 100%;
  min-height: 500px;
  height: 100%;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 20px 0px 20px 0px;
  margin-bottom: 40px;
  position: relative;
}

.titleRow {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 24px;
  border-bottom: 1px solid rgba(226, 229, 232, 0.88);
  padding-bottom: 12px;
  padding-top: 20px;
  font-family: Roboto;
  font-size: 14px;
  line-height: 1.43;
  color: #8f9294;
}

.titleRowRight {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 22px;
}

.titleRowRightColumn {
  display: flex;
  align-items: center;
  width: 85px;
  font-size: 14px;
  line-height: 1.43;
  color: #8f9294;
  justify-content: flex-start;
  width: 150px;
}

.titleRowLeft {
  margin-right: 24px;
  display: flex;
  align-items: center;
}

.imageRow {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}

.folderRow {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  cursor: pointer;
  position: relative;
  width: 100%;
}

.folderRow:hover {
  text-decoration: underline;
}

.imageRowRight {
  min-width: calc(75vw - 262px - 412px);
  border-bottom: 1px solid rgba(226, 229, 232, 0.88);
  width: 100%;
  height: 72px; /* 64 height plus 8px offset for border bottom */
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  justify-content: space-between;
  padding-right: 10px;
  max-width: 100%;
}

.imageRowRight[data-is-last="true"] {
  border-bottom: none;
}

.imageDetails {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 1025px) {
  .imageDetailsColumn:nth-of-type(1) {
    width: 75px !important;
    min-width: 75px !important;
  }
  .imageDetailsColumn:nth-of-type(2) {
    width: 100px !important;
    min-width: 100px !important;
  }

  .titleRowRightColumn:nth-of-type(1) {
    width: 75px !important;
    min-width: 75px !important;
  }
  .titleRowRightColumn:nth-of-type(2) {
    width: 100px !important;
    min-width: 100px !important;
  }
}

@media screen and (max-width: 880px) {
  .imageDetailsColumn:nth-of-type(1) {
    display: none;
  }
  .titleRowRightColumn:nth-of-type(1) {
    display: none;
  }
}

@media screen and (max-width: 750px) {
  .imageDetailsColumn:nth-of-type(2) {
    display: none;
  }
  .titleRowRightColumn:nth-of-type(2) {
    display: none;
  }
}

.imageDetailsColumn {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 150px;
  min-width: 150px;
}

.imageDetailsColumn > p {
  margin-right: 80px;
  max-width: 100px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.folderTile {
  width: 64px;
  height: 64px;
  background-color: white;
  margin-right: 24px;
  margin-bottom: 8px;
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(21, 21, 22, 0.12);
}

.imageTile {
  position: relative;
  width: 64px;
  height: 64px;
  background-color: #FEFEFE;
  background-image: linear-gradient(45deg, #DFDFDFBF 25%, #DFDFDF00 25%, #DFDFDF00 75%, #DFDFDFBF 75%, #DFDFDFBF), linear-gradient(45deg, #DFDFDFBF 25%, #DFDFDF00 25%, #DFDFDF00 75%, #DFDFDFBF 75%, #DFDFDFBF);
  background-size: 10px 10px;
  background-position: 0 0, 15px 15px;
  border-radius: 4px;
  margin-right: 24px;
  margin-bottom: 8px;
}

.imageWrapper {
  width: 64px;
  height: 64px;
  align-items: center;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.imageWrapper img, .imageWrapper video {
  display: block;
  object-fit: cover;
  border-radius: 2px;
  position: relative;
  z-index: 1;
}

.imageTitle {
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.6;
  color: easilBlack;
  padding-right: 10px;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  display: flex;
  min-width: 50px;
}

.folderTitle {
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.6;
  color: easilBlack;
  padding-bottom: 8px;
}

.imageRowPopoutCaretWrapper {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.imageRowPopoutCaret {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex: 1 0 auto;
}

.imageRowPopoutCaret:hover {
  opacity: 0.8;
}

.menu {
  display: flex;
  margin-left: auto;
  border-radius: 4px;
  padding-bottom: 8px;
}

.menu:hover > div > svg rect {
  fill: easilGrayXXXLight !important;
}

.menu:hover > div > svg circle {
  fill: easilGrayXDark !important;
}

.crumbWrapper {
  width: 1025px;
  padding-top: 40px;
  padding-bottom: 0px;
  margin-bottom: -20px;
  height: 100%;
  display: flex;
  z-index: 5;
}

.loader {
  margin-top: 80px;
}

.popout {
  left: -330px;
  bottom: 24px;
}

.folderPopout {
  left: -350px;
  bottom: 20px;
  position: absolute;
}

.nameSection {
  min-height: 64px;
  height: auto;
}

.nameBox {
  overflow-wrap: break-word;
  min-height: 36px;
  height: auto;
}

.nameBox > div {
  text-overflow: initial;
}

.resultsTitleGutters {
  width: 100vw;
  display: flex;
  justify-items: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: -16px;
}

.resultsTitle {
  width: 1025px;
  margin-top: 40px;
  font-size: 20px;
  line-height: 1.2;
  color: easilBlack;
}

.resultsTitle > span {
  font-weight: 500;
}

.imageOverlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--image-result-card-border-radius);
  z-index: 3;
  width: 100%;
  height: 100%;
}

.imageOverlay > div:first-child {
  background-color: #0f0f10;
  opacity: 0.2;
  width: 100%;
  height: 100%;
  position: absolute;
  pointer-events: none;
  border-radius: var(--image-result-card-border-radius);
}

.imageListRowEllipse {
  border-radius: 4px;
  cursor: pointer;
  display: flex;
}

.imageListRowEllipse:hover {
  background: easilGrayXXXLight;
  opacity: 1;
}

.multiSelectCheckbox {
  width: 16px;
  height: 16px;
  position: absolute;
  top: calc(50% - 16px / 2);
  margin-left: calc(-22px + -16px);
}

.contentTitleForSearch {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  color: #262f30;
  height: 24px;
  font-family: Roboto;
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  color: #57595d;
}