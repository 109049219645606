.wrapper {
  width: 700px;
  padding: 32px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 8px 16px 0 rgba(15, 15, 16, 0.16);
}

.cross {
  display: none;
}

.sectionTitle {
  font-family: Roboto;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #2c2e2f;
}

.title {
  font-family: Roboto;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #2c2e2f;
  margin-bottom: 12px;
}

.title {
  font-family: Roboto;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #2c2e2f;
  margin-bottom: 12px;
}

.textContent {
  display: flex;
  flex-direction: row;
  font-family: Roboto;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: #57595d;
}

.textContent > a {
  font-weight: 500;
  color: #1c1d1e;
  margin-left: 4px;
}

.buttons {
  margin-top: 32px;
}