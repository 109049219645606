@value colors: "../../colors.css";
@value easilBlack, easilGrayXDark, easilGrayDark, easilGreyMidLight from colors;

.orderSummary {
  max-width: 512px;
  padding: 40px 0 40px 32px;
  background-color: #fbfcfc;
}

.orderHeading {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  color: easilBlack;
  font-size: 20px;
}

.couponInput {
  display: flex;
  align-items: center;
  padding-right: 5px;
}

.couponInput > div {
  background-color: white;
}

.couponPromoCodeAlert {
  color: #c01818;
  position: relative;
  overflow: hidden;
  font-size: 14px;
  margin: -15px 0 16px;
  max-height: 0px;
  transition: max-height 0.2s ease;
}

.couponPromoCodeAlert[data-has-error="true"] {
  max-height: 20px;
  margin: -7px 0 10px;
}

.loading {
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.coupon {
  height: 72px;
  display: flex;
  align-items: center;
}

.promoCodeButton {
  margin-left: 12px;
}