@value editorActionbarStyle: "../actionbar/style.module.css";
@value height from editorActionbarStyle;

.canvasBackground {
  text-align: center;
  position: absolute;
  top: height;
  width: 100%;
  height: calc(100% - 48px);
  overflow: auto;
  /*   padding: 25px; */
  animation: fadeIn 1000ms ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.canvasBackground[data-is-locked="true"] * {
  cursor: not-allowed;
}

.canvasBackgroundButton {
  margin: 24px auto 24px auto;
  display: flex;
  justify-content: center;
  height: 48px;
  margin-bottom: var(--calculated-canvas-button-margin-bottom, 24px)
}
