@value (
  easilBlack,
  easilGrayXDark,
  easilGray,
  easilGrayXLight,
  easilGrayXXLight
) from "~views/colors.css";

:export {
  easilGray: easilGray;
  easilGrayXDark: easilGrayXDark;
}

ul.contextMenuContainer {
  background: white;
  border: solid 1px easilGrayXLight;
  box-shadow: 0 1px 2px 0 rgba(21, 21, 22, 0.12);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  position: absolute;
  z-index: 10;
}

ul.contextMenuContainer > li {
  align-items: center;
  color: easilGray;
  display: flex;
  padding: 4px 16px;
  pointer-events: none;
  font-weight: 500;
}

ul.contextMenuContainer > li:not([disabled]) {
  color: easilBlack;
  cursor: pointer;
  pointer-events: unset;
}

ul.contextMenuContainer > li:not([disabled]):hover {
  background: whitesmoke;
}

ul.contextMenuContainer > li > svg {
  margin-right: 16px;
}

ul.contextMenuContainer > hr {
  border-color: #eee;
  border-style: solid;
  border-width: 1px 0 0 0;
  margin: 8px 0;
}

.beta {
  opacity: 0.4;
}

.icon {
  height: 16px;
  width: 16px;
  margin-right: 18px;
  margin-left: -2px;
  margin-bottom: 5px;
}
