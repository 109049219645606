.text {
  font-family: Roboto;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

.title {
  composes: text;
  font-size: 24px;
  line-height: 1.33;
  color: #2c2e2f;
}

.breadCrumbList {
  margin-bottom: 32px;
}

.wrapper {
  width: 100%;
  height: 100%;
}

.nameLabel {
  composes: text;
  font-size: 14px;
  line-height: 1.43;
  color: #8f9297;
  margin-top: 20px;
}

.inputWrapper {
  width: 100%;
  height: 40px;
  padding: 10px 12px;
  border-radius: 4px;
  border: solid 1px #d1d4d6;
  margin-top: 8px;
}

.input {
  composes: text;
  width: 100%;
  height: 20px;
  font-size: 15px;
  line-height: 1.33;
  color: #2c2e2f;
}

.inputPlaceholder {
  composes: input;
  color: #8f9297;
}

.noticeText {
  composes: text;
  font-size: 15px;
  line-height: 1.33;
  color: #8f9297;
  margin-top: 12px;
}

.noticeText > span {
  font-weight: 500;
  color: #2c2e2f;
}

.divider {
  width: 100%;
  height: 0px;
  border-bottom: 1px solid #d1d4d6;
  margin-top: 32px;
  margin-bottom: 32px;
}

.teamToggle {
  border: none;
  flex-direction: row-reverse;
  justify-content: flex-end;
  padding: 0;
}

.teamToggle > div:last-of-type {
  margin-right: 24px;
}

.teamToggle div[class*="title"] {
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #57595d;
}

.teamToggle div[class*="description"] {
  font-family: Roboto;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #7a7d81;
}

.info {
  align-items: flex-start;
}

.info > span > a {
  font-weight: 500;
  color: #2c2e2f;
}