@value colors: "../style.css";
@value (
  easilBlack,
  easilGrayXLight,
  easilGrayXXLight,
  easilGrayXDark,
  easilBlueDark,
  easilRed,
  fontDisabled,
  backgroundDisabled
) from colors;

.label {
  color: easilBlack;
  font-size: 13px;
  line-height: 1.85;
  text-align: left;
  padding-bottom: 9px;
}


.dropdown {
  position: relative;
  display: inline-block;
  width: 100%;
}

.dropdown * {
  cursor: pointer;
}

.inputDiv {
  align-items: center;
  border: 1px solid easilGrayXLight;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  min-height: 40px;
  justify-content: space-between;
  line-height: 20px;
  padding: 10px 12px 10px 12px;
}

.inputDiv > svg {
  min-width: 8px;
  min-height: 8px;
}

.tagList {
  display: flex;
  flex-wrap: wrap;
}

.tagList > * {
  margin: 0 8px 8px 0;
}

.option:hover {
  background-color: easilGrayXXLight;
}
.content {
  width: 100%;
  max-height: 200px;
  overflow: auto;
  border: 1px solid easilGrayXLight;
  border-radius: 4px;
  display: block;
  position: absolute;
  top: 39px;
  background-color: white;
  min-width: 160px;
  padding: 16px 16px 16px 16px;
  z-index: 1;
}

.doneButton {
  width: 100%;
}

.error {
  position: absolute;
  color: easilRed;
  font-size: 12px;
  left: 5px;
  top: 100%;
}

.options {
  background-color: #fff;
  border: 1px solid easilGrayXLight;
  border-radius: 8px;
  display: none;
  max-height: 380px;
  left: 0;
  padding: 16px 0;
  position: absolute;
  top: 116%;
  width: 100%;
  z-index: 2;
  overflow: hidden;
}

.optionList {
  overflow-y: auto;
  max-height: 235px;
  margin-right: 24px;
  margin-left: 16px;
}

.optionsOpened {
  display: flex;
  flex-direction: column;
  z-index: 4;
}

.placeholder {
  width: 100%;
}

.footer {
  height: 48px;
  display: flex;
  justify-content: flex-end;
  padding: 12px 23px;
  border-top: 1px solid #d1d4d6;
}

.tagRemoveIcon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}