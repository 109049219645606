@value colors: "~views/colors.css";
@value easilGrayXLight, easilBlack, easilGrayXDark from colors;

.wrapper {
  position: relative;
  marginTop: 10px;
  width: 300px;
}

.dropDownTop {
  height: 40px;
  width: 300px;
  border: 1px solid silver;
  padding: 12px ;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.currentDefaultTeam {
  color: easilBlack;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
