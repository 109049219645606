.wrapper {
  align-items: center;
  display: flex;
  justify-content: space-between;
  font-size: 15px;
}

.label {
  color: #616569;
  margin-right: 8px;
}

.actions {
  align-items: center;
  display: flex;
}

.actions * {
  cursor: pointer;
  margin-left: 8px;
}

.actions svg:not(:first-child) {
  margin-left: 16px;
}

.input {
  height: 42px;
  padding-top: 0;
  padding-bottom: 0;
}

.input > input {
  height: 40px;
  line-height: 40px;
}
