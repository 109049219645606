@value colors: "~views/style.css";
@value easilBlack, easilRed, easilGrayXLight, easilGrayXDark, easilGrayDark, easilGrayXXLight from colors;

.wrapper {
  margin-top: 40px;
}

.pageHeader {
  color: easilBlack;
  font-weight: normal;
  font-family: Roboto;
  font-size: 24px;
  line-height: 1.17;
}

.sectionHeading {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  min-height: 36px;
}

.sectionHeading h2 {
  color: easilBlack;
  margin: 0;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.44;
}

.tablist {
  margin: 32px 0 64px;
}

.editableInput {
  justify-content: space-between;
  width: 100%;
}

.formGroup {
  padding-bottom: 24px;
}

.formGroup + .formGroup {
  padding-top: 24px;
  border-top: 1px solid #e2e5e8;
}

.formGroupInline + .formGroup {
  margin-top: 24px;
}

.formGroupInline {
  display: flex;
  margin-top: 24px;
}

.formGroupInline .formGroup {
  flex: 1 1 auto;
}

.formGroupInline .formGroup + .formGroup {
  margin-top: 0;
  margin-left: 16px;
}

.formGroupLabel {
  margin-bottom: 8px;
  font-family: Roboto;
  font-size: 15px;
  line-height: 1.6;
  color: #7a7d81;
}

.formGroupDefaultTeamSubText {
  composes: formGroupLabel;
  margin-top: 12px;
}

.formGroupDefaultTeamHeader {
  margin-bottom: 24px;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  color: #2c2e2f;
}

.fieldError {
  color: easilRed;
  font-size: 12px;
  margin-top: 2px;
}

.addImageButton {
  margin-right: 20px;
}

.addImageButton > label:last-of-type > div > div > img {
  height: 100% !important;
  width: 100% !important;
}

.LogoUpload {
  display: flex;
  align-items: center;
}

.removeLogoLink {
  color: easilBlack;
  cursor: pointer;
}

.Tab {
  display: flex;
}

.TabCol {
  flex: 1 1 50%;
}

.TabCol:first-child {
  margin-right: 120px;
}

.billingEditButton {
  background-color: white;
  border: 1px solid easilGrayXLight;
  color: easilGrayXDark;
}

.billingEditButtonIcon {
  margin-right: 8px;
}

.billingInvoiceEmailCallout {
  background-color: easilGrayXXLight;
  border-radius: 4px;
  color: easilGrayDark;
  display: flex;
  padding: 24px;
}

.billingInvoiceEmailCalloutContent {
  line-height: 1.71;
  margin-left: 16px;
}

.billingInvoiceEmailCalloutContent span {
  color: easilGrayXDark;
}

.billingInvoiceEmailInfo {
  color: easilGrayXDark;
  margin-top: 31px;
}

.billingInvoiceEmailInfo span {
  color: easilBlack;
  cursor: pointer;
}

.changeLogoText {
  cursor: pointer;
}

.teamChooser {
  width: 100% !important;
}

.teamChooser > div {
  width: 100% !important;
}

.changePasswordButton {
  margin-top: 16px;
}

.removePictureButton {
  width: 160px;
  height: 24px;
  font-family: Roboto;
  font-size: 16px;
  line-height: 1.5;
  color: #2c2e2f;
}

.removePictureButton > span {
  font-weight: 500;
  border-bottom: 1px solid rgba(44,46,47, 0.24);
}

.defaultTeamContentWrapper {
  display: flex;
  align-items: center;
}

.teamUpdateStatus {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ;
  margin-left: 20px;
}