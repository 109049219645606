.label {
  margin-bottom: 8px;
}

.form {
  display: flex;
}

.form + .form {
  margin-top: 16px;
}

.form > * + * {
  margin-left: 16px;
}

.group {
  width: 100%;
}

.error {
  color: #fc3131;
  font-size: 11px;
  margin-top: 2px;
}