@value colors: "../style.css";
@value (
  easilBlack,
  easilGrayXLight,
  easilGrayXXLight
) from colors;

.wrapper {
  align-items: center;
  background-color: easilGrayXXLight;
  border-radius: 16px;
  display: flex;
  line-height: 28px;
  justify-content: space-between;
  padding: 0 10px;
  max-width: calc(100% - 24px);
}

.wrapper > * + * {
  margin-left: 8px;
}

.withAvatar {
  padding-left: 6px;
}

.avatarLabel {
  cursor: auto;
  font-size: 10px;
}

.label {
  color: easilBlack;
  font-size: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.remove {
  cursor: pointer;
}

.tagInput {
  border: 1px solid easilGrayXLight;
  border-radius: 4px;
  display: flex;
  flex-wrap: wrap;
  padding: 12px 8px 4px 12px;
}

.tagInputTag {
  background: #eee;
  margin: 0 4px 8px 0;
  max-width: 100%;
}

.tagInputTextfield {
  border: none;
  border-radius: 4px;
  flex: 1 1;
  height: 28px;
  line-height: 28px;
  margin: 0 4px 8px 0;
  min-width: 50px;
  outline: none;
  padding: 0;
}

.tagInputHelper {
  display: block;
  font-size: 11px;
  margin-top: 8px;
  text-align: right;
}
