@value colors: "~views/colors.css";
@value easilGrayDark from colors;

.scrollFold {
  position: absolute;
  height: 100%;
}

.scrollFoldContent {
  position: relative;
  height: 100%;
}

.fold {
  position: absolute;
  pointer-events: none;
}