
@value colors: "~views/colors.css";
@value easilBlue, easilGrayLight from colors;

:root {
  --grey-white: #ffffff;
}

.canvas {
  display:block;
  position: relative;
}

.canvas[data-has-transparent-bg="true"] {
  background-position: 0px 0px, 0px 10px, 10px -10px, -10px 0px !important;
  background-size: 20px 20px !important;
  background-image: linear-gradient(45deg, rgb(223, 223, 223) 25%, transparent 25%), linear-gradient(-45deg, rgb(223, 223, 223) 25%, transparent 25%), linear-gradient(45deg, transparent 75%, rgb(223, 223, 223) 75%), linear-gradient(-45deg, transparent 75%, rgb(223, 223, 223) 75%) !important
}

.canvas > div[id^="PAGE_CANVAS"] {
  margin-top: var(--calculated-canvas-margin-top, 36px) !important;
}

.canvas ~ .canvas > div[id^="PAGE_CANVAS"] {
  margin-top: 0px !important;
}

#sideOptionsTip::after {
  border-right-color: transparent !important;
  border-radius: 4px;
  font-size: 12px;
  color: white;
  height: 20px;
  width: 20px;
}

.canvasOptions {
  height: 187px;
  width: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 500;
  color: #8f9297;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(21, 21, 22, 0.12);
}

.animationControlContainer {
  position: absolute;
  height: 100%;
  width: 77px;
  left: calc(-20% - 10px);
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 3;
}

.canvasOptionsContainer {
  position: absolute;
  height: 100%;
  width: 77px;
  left: calc(100% + 10px);
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 3;
}

.canvasPage {
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.canvasOptions > div:not(.canvasOptionsSelectedDot):not(.pageNumbersSmall):not(.lineBreak):not(.pageNumbersBig)  {
  cursor: pointer;
  width: 20px;
  height: 20px;
}

.canvasOptionsSelectedDot {
  width: 6px;
  height: 6px;
  background-color: easilBlue;
  border-radius: 100%;
  margin: 10px !important;
  margin-bottom: 21px !important;
}

.canvasOptionsDot {
  background-size: cover;
  height: 48px;
  margin: 0 !important;
  width: 48px;
}

.pageNumbersBig {
  display: flex;
  font-size: 18px;
  font-weight: 500;
  max-height: 21px;
  margin-bottom: 4px;
}

.pageNumbersSmall {
  display: flex;
  font-size: 14px;
  font-weight: 500;
  max-height: 21px;
  margin-bottom: 4px;
}

.animationControl {
  width: 32px;
  height: 77px;
  margin: 0 12px 12px 0;
  padding: 10px 6px 8px;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(21, 21, 22, 0.12);
  background-color: var(--grey-white);
}

.animationPlayBox {
  width: 20px;
  height: 20px;
  padding: 4px 4px 3px 5px;
  border-radius: 4px;
  background-color: greenyellow;
}


.animationPlayButton {
  width: 20px;
  height: 20px;
  border: none;
  border-radius: 4px;
}

.animationPlayButton:hover {
  background: #f5f5f8;
}

.animationOptionsButton {
  width: 20px;
  height: 20px;
  border-radius: 4px;
}

.animationOptionsButton:hover {
  background: #f5f5f8;
}

.animationControlDivide {
  width: 20px;
  height: 1px;
  margin: 10px 0 8px;
  background-color: #e5e8eb;
}

.animationDurationBox {
  width: 20px;
  height: 20px;
  margin: 8px 0 0;
  padding: 9px 3px 8px 4px;
  border-radius: 4px;
}

.popoutDuration {
  position: absolute;
  width: 253px;
  height: 88px;
  margin: 15px 0 0 4px;
  padding: 12px 16px;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(21, 21, 22, 0.12);
  border: solid 1px #d1d4d6;
  background-color: #fff;
  top: -65px;
  left: 25px;
  overflow: hidden;
}

.durationRangeInput > label {
  display: none;
}

.durationRangeInput > div:first-of-type {
  padding-left: 0px !important;
  width: 100% !important;
}

.durationRangeInput > div:last-of-type {
  width: 60px !important;
}

.durationLabel {
  text-align: left;
}

.numberInput {
  margin: 3px 0px 0px -7px;
}

.lineBreak {
  width: 20px;
  height: 1px;
  margin-top: 4px;
  margin-bottom: 8px;
  background-color: #e5e8eb;
  pointer-events: none;
}

.settingsPopoutWrapper {
  width: 197px;
  min-height: 92px;
  height: auto;
  padding: 12px 16px;
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(21, 21, 22, 0.12);
  border: solid 1px #d1d4d6;
  background-color: #ffffff;
  left: 36px;
  top: -46px;
  position: absolute;
  display: flex;
  flex-direction: column;
}

.measurementUnitChanger {
  display: flex;
  flex-direction: row;
  margin-bottom: 4px;
}

.unitChangerTitle {
  margin-right: 51px;
  font-family: Roboto;
  font-size: 14px;
  color: #8f9297;
}

.unitNameLabel {
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  color: #afb3b6;
  text-transform: uppercase;
}

.unitNameLabel[data-is-selected="true"] {
  color: #2c2e2f;
}

.unitNameLabel[data-is-selected="false"] {
  cursor: pointer;
}

.unitNameLabel:not(:last-of-type) {
  margin-right: 15px;
}

.snappingToggle {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.snappingToggle > label > svg {
  left: 0;
  transform: scale(1.25);
}

.snappingToggle > label > input:checked + span {
  background-color: #3184fc;
}

.gridSnap {
  margin-top: 8px;
}

.arrowOption {
  display: flex;
  align-items: center;
  justify-content: center;
}

.guideHash {
  height: 20px;
  width: 20px;
  margin-top: 8px;
  margin-bottom: 4px;
}

.guideHash:hover {
  background: #f5f5f8;
  border-radius: 4px;
}

.duplicatePage:hover {
  background: #f5f5f8;
  border-radius: 4px;
}

.deletePage:hover {
  background: #f5f5f8;
  border-radius: 4px;
}

.arrowOption:hover {
  background: #f5f5f8;
  border-radius: 4px;
}

.arrowDownOption {
  margin-bottom: 4px;
  margin-top: 4px;
}

.duplicatePage {
  margin-bottom: 8px;
}

.bleedInner{
  /* pointer-events: all; */
  /* background-color: red;; */
}

.bleedOutline {
  display: flex;
  position: absolute;
  background-color: #3184fc;
  pointer-events: all;
}

.bleedInnerTop {
  composes: bleedOutline;
  width: 100%;
  height: 1px;
  top: 0px;
}

.bleedInnerBottom {
  composes: bleedOutline;
  width: 100%;
  height: 1px;
  bottom: 0px;
  left: 0px;
}

.bleedInnerLeft {
  composes: bleedOutline;
  width: 1px;
  height: 100%;
  top: 0px;
  left: 0px;
}

.bleedInnerRight {
  composes: bleedOutline;
  width: 1px;
  height: 100%;
  top: 0px;
  right: 0px;
}

.areaLabel {
  position: absolute;
  top: calc(var(--data-offset-top, 0px) + 4px);
  left: calc(var(--data-offset-left, 0px) + 4px);
  padding: 2px 4px;
  font-family: Roboto;
  font-size: 10px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: white;
  border-radius: 2px 4px 4px 4px;
}

.safeAreaLabel {
  composes: areaLabel;
  background-color: #3184fc;
  width: 52px;
  height: 15px;
}

.safeAreaBorder {
  background-color: #3184fc;
  opacity: 0.3;
}

.safeAreaBorder[data-is-hovered="true"] {
  opacity: 1;
}

.designAreaLabel {
  composes: areaLabel;
  width: 63px;
  height: 15px;
  background-color: #2c2e2f;
}

.designAreaBorder {
  background-color: #2c2e2f;
  opacity: 0.3;
}

.designAreaBorder[data-is-hovered="true"] {
  background-color: #2c2e2f;
  opacity: 1;
}

.bleedAreaLabel {
  composes: areaLabel;
  width: 63px;
  height: 15px;
  background-color: #c01818;
  position: absolute;
  z-index: 5;
}

.bleedAreaBorder {
  background-color: #c01818;
  opacity: 0.3;
  z-index: 3;
}

.bleedAreaBorder[data-is-hovered="true"] {
  background-color: #c01818;
  opacity: 1;
}