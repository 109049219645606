.assetCard {
  display: flex;
  width: 252px;
  height: 64px;
  padding: 12px;
  cursor: pointer;
  margin-bottom: 8px;
  border-radius: 8px;
  background-color: white;
  transition: background-color 300ms ease-out;
  transition: border 300ms ease-out;
  border: solid 1px white;
}

.assetCard:hover[data-is-disabled="false"] {
  background-color: #f5f5f8;
}

.assetCard[data-is-selected="true"] {
  border: solid 1px #a1c6ff;
  background-color: #ecf5ff;
}

.assetCard[data-is-disabled="true"] {
  opacity: 0.3;
  cursor: pointer;
  cursor: unset;
}

.asset {
  border-radius: 8px;
  margin-right: 12px;
}

.asset > img {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  display: block;
  object-fit: cover;
  position: relative;
  z-index: 1;
}

.assetInfo {
  display: flex;
  flex-direction: column;
  height: 40px;
}

.assetName {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  color: #57595d;
  max-width: 176px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.smartAssetName {
  composes: assetName;
  margin-left: 4px;
}

.smartAssetLabel {
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.5;
  color: #2c2e2f;
  width: fit-content;
  padding: 3px 8px;
  border-radius: 4px;
  background-color: #e5e8eb;
  max-width: 176px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.info {}