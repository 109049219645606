:root {
  --canvas-transform: "";
  --canvas-opacity: 1;
  --canvas-top: 0px;
  --canvas-left: 0px;
  --canvas-filter: none;
}

.originSvgWrapper {
  opacity: 1;
  height: 100%;
  position: relative;
}

.originSvgWrapper > svg {
  position: absolute;
  top: 0;
  left: 0;
}

.originSvgWrapper canvas {
  transform: var(--canvas-transform);
  opacity: var(--canvas-opacity);
  top: var(--canvas-top) !important;
  left: var(--canvas-left) !important;
  filter: var(--canvas-filter);
}