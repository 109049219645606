.dot {
  height: 12px;
  width: 12px;
  border-radius: 100%;
  border: 1px solid #3184FC;
  background-color : #fff;
  z-index: 5;
  pointer-events: auto;
  box-shadow: 0 0 4px rgba(15,15,16,.08);
}

.croppingDot {
  composes: dot;
  position: absolute;
  height: 20px;
  width: 20px;
}

.dotDummy {
  composes: dot;
  cursor: default;
  pointer-events:  none;
}

/* hide resize handles when textbox is in edit mode */
.dot[data-is-editing="true"], .dotWrapper[data-is-editing="true"] {
  display: none;
}

.croppingDot[data-position="NW"] {
  cursor: nwse-resize;
  top: -10px;
  left: -10px;
}

.dotWrapper[data-position="NW"] {
  cursor: nwse-resize;
  top: -8px;
  left: -8px;
}

.dotWrapper[data-position="NW"][data-min-height="true"] {
  top: -12px;
}

.dotWrapper[data-position="NW"][data-min-width="true"] {
  left: -12px;
}

.dotWrapper[data-position="NW"][data-min-height="true"][data-x-handles="true"] {
  top: -22px;
}

.dotWrapper[data-position="NW"][data-min-width="true"][data-y-handles="true"] {
  left: -22px;
}

/* reassign handle position when crop handles are too close */
.dotWrapper[data-position="NW"][data-x-proximity="true"] {
  left: -22px;
}
.dotWrapper[data-position="NW"][data-y-proximity="true"] {
  top: -22px;
}

.croppingDot[data-position="NE"] {
  cursor: nesw-resize;
  top: -10px;
  right: -10px;
}

.dotWrapper[data-position="NE"] {
  cursor: nesw-resize;
  top: -8px;
  right: -8px;
}

.dotWrapper[data-position="NE"][data-min-height="true"] {
  top: -12px;
}

.dotWrapper[data-position="NE"][data-min-width="true"] {
  right: -12px;
}

.dotWrapper[data-position="NE"][data-min-height="true"][data-x-handles="true"] {
  top: -22px;
}

.dotWrapper[data-position="NE"][data-min-width="true"][data-y-handles="true"] {
  right: -22px;
}

/* reassign handle position when crop handles are too close */
.dotWrapper[data-position="NE"][data-x-proximity="true"] {
  right: -22px;
}
.dotWrapper[data-position="NE"][data-y-proximity="true"] {
  top: -22px;
}

.croppingDot[data-position="SW"] {
  cursor:  nesw-resize;
  bottom: -10px;
  left: -10px;
}

.dotWrapper[data-position="SW"] {
  cursor:  nesw-resize;
  bottom: -8px;
  left: -8px;
}

.dotWrapper[data-position="SE"][data-min-height="true"] {
  bottom: -12px;
}

.dotWrapper[data-position="SE"][data-min-width="true"] {
  right: -12px;
}

.dotWrapper[data-position="SE"][data-min-height="true"][data-x-handles="true"] {
  bottom: -22px;
}

.dotWrapper[data-position="SE"][data-min-width="true"][data-y-handles="true"] {
  right: -22px;
}

/* reassign handle position when crop handles are too close */
.dotWrapper[data-position="SE"][data-x-proximity="true"] {
  right: -22px;
}
.dotWrapper[data-position="SE"][data-y-proximity="true"] {
  bottom: -22px;
}

.dotWrapper[data-position="SW"][data-min-height="true"] {
  bottom: -12px;
}


.dotWrapper[data-position="SW"][data-min-width="true"] {
  left: -12px;
}

.dotWrapper[data-position="SW"][data-min-height="true"][data-x-handles="true"] {
  bottom: -22px;
}

.dotWrapper[data-position="SW"][data-min-width="true"][data-y-handles="true"] {
  left: -22px;
}

/* reassign handle position when crop handles are too close */
.dotWrapper[data-position="SW"][data-x-proximity="true"] {
  left: -22px;
}
.dotWrapper[data-position="SW"][data-y-proximity="true"] {
  bottom: -22px;
}

.croppingDot[data-position="SE"] {
  cursor: nwse-resize;
  bottom: -10px;
  right: -10px;
}

.dotWrapper[data-position="SE"] {
  cursor: nwse-resize;
  bottom: -8px;
  right: -8px;
}

.croppingDot[data-position="E"], .dot[data-position="E"] {
  top: calc(50% - 8px);
  right: calc(100% - 3px);
  cursor: ew-resize;
  width: 6px;
  height: 16px;
  border-radius: 50px;
  box-shadow: 0 0 4px rgba(15,15,16,.08);
}

.dotWrapper[data-position="E"] {
  top: calc(50% - 10px);
  right: calc(100% - 8px);
  cursor: ew-resize;
  width: 16px;
  height: 20px;
  border-radius: 50px;
  box-shadow: 0 0 4px rgba(15,15,16,.08);
}

.croppingDot[data-position="W"], .dot[data-position="W"] {
  top: calc(50% - 8px);
  right: calc(0% - 3px);
  cursor: ew-resize;
  width: 6px;
  height: 16px;
  border-radius: 50px;
  box-shadow: 0 0 4px rgba(15,15,16,.08);
}

.dotWrapper[data-position="W"] {
  top: calc(50% - 10px);
  right: calc(0% - 8px);
  cursor: ew-resize;
  width: 16px;
  height: 20px;
  border-radius: 50px;
  box-shadow: 0 0 4px rgba(15,15,16,.08);
}

.croppingDot[data-position="N"], .dot[data-position="N"] {
  top: calc(0% - 3px);
  right: calc(50% - 8px);
  cursor: ns-resize;
  width: 16px;
  height: 6px;
  border-radius: 50px;
  box-shadow: 0 0 4px rgba(15,15,16,.08);
}

.dotWrapper[data-position="N"] {
  top: calc(0% - 8px);
  right: calc(50% - 10px);
  cursor: ns-resize;
  width: 20px;
  height: 16px;
  border-radius: 50px;
  box-shadow: 0 0 4px rgba(15,15,16,.08);
}

.croppingDot[data-position="S"], .dot[data-position="S"] {
  top: calc(100% - 3px);
  right: calc(50% - 8px);
  cursor: ns-resize;
  width: 16px;
  height: 6px;
  border-radius: 50px;
  box-shadow: 0 0 4px rgba(15,15,16,.08);
}

.dotWrapper[data-position="S"] {
  top: calc(100% - 8px);
  right: calc(50% - 10px);
  cursor: ns-resize;
  width: 20px;
  height: 16px;
  border-radius: 50px;
  box-shadow: 0 0 4px rgba(15,15,16,.08);
}

.croppingDot[data-is-crop="true"], .dotWrapper[data-is-crop="true"] {
  background-color: transparent;
  border: none;
  transform: translate(2px, 2px);
}

.croppingDot[data-is-crop="true"][data-position="NE"], .dotWrapper[data-is-crop="true"][data-position="NE"] {
  transform: rotate(90deg) translate(2px, 2px);;
}
.croppingDot[data-is-crop="true"][data-position="SE"], .dotWrapper[data-is-crop="true"][data-position="SE"] {
  transform: rotate(180deg) translate(2px, 2px);;
}
.croppingDot[data-is-crop="true"][data-position="SW"], .dotWrapper[data-is-crop="true"][data-position="SW"] {
  transform: rotate(270deg) translate(2px, 2px);;
}

.lowerHandles, .upperHandles {
  position: absolute;
  top: 5px;
  left: 5px;
}

.lowerHandles > .verticalHandle {
  width: 6px;
  height: 16px;
  background-color: #3184FC;;
  position: absolute;
  z-index: 2;
  border-radius: 4px;
}

.lowerHandles > .horizontalHandle {
  width: 16px;
  height: 6px;
  background-color: #3184FC;;
  position: absolute;
  z-index: 1;
  border-radius: 4px;
}

.upperHandles > .verticalHandle {
  width: 4px;
  height: 14px;
  top: 1px;
  left: 1px;
  background-color: #fff;
  position: absolute;
  z-index: 4;
  border-radius: 4px;
}

.upperHandles > .horizontalHandle {
  width: 14px;
  height: 4px;
  top: 1px;
  left: 1px;
  background-color: #fff;
  position: absolute;
  z-index: 3;
  border-radius: 4px;
}

.dotWrapper {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 16px;
  width: 16px;
  pointer-events: auto;
  z-index: 4;
}