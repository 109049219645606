@value colors: "~views/style.css";
@value easilBlack from colors;

.wrapper {
  align-items: flex-start;
  background-color: rgba(49, 133, 252, 0.03);
  border: 1px solid #abd0e8;
  border-radius: 4px;
  display: flex;
  padding: 24px;
}

.label {
  color: easilBlack;
  flex: 1 1 auto;
  font-size: 14px;
  line-height: 1.71;
  margin: 0 16px;
}

.updateButton {
  display: flex;
  flex: 1 1 136px;
  height: 40px;
  justify-content: flex-end;
}
