.wrapper {
  border: 1px solid #d1d4d6;
  border-radius: 4px;
  padding: 20px;
}

.header {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  user-select: none;
}

.inactive {
  cursor: default;
}

.headerContent {
  margin-right: 20px;
  width: 100%;
}

.toggle {
  align-items: center;
  display: flex;
}

.content {
  border-top: 1px solid #e2e5e8;
  margin-top: 20px;
  padding-top: 20px;
}