@value colors: "~views/style.css";
@value (
  easilBlack,
  easilGrayXLight,
  easilGrayXDark,
  easilBlueDark,
  easilRed
) from colors;

.rolesDropdownWrapper {
  position: absolute;
  margin-top: 8px;
  padding: 32px;
  left: -510px;
  width: 840px;
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid #d1d4d6;
  /* padding: 32px 0 24px; */
  z-index: 999;
}

.container {
  width: 100%;
  height: 100%;  
}

.teamRolesWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 96px;
}

.roleWrapper {
  height: 100%;
  cursor: pointer;
}

.roleWrapper > label {
  display: unset;
}

.roleWrapper:not(:first-of-type) {
  padding-left: 24px;
  border-left: 1px solid #d1d4d6;
}

.roleWrapper:not(:last-of-type) {
  margin-right: 16px;
}

.roleLabel {
  margin-top: 12px;
  color: easilBlack;
  font-weight: 500;
}

.roleContent {
  margin-top: 8px;
  font-size: 13px;
  max-width: 150px;
}

.roleLink {
  margin-top: 28px;
}

.roleLink > a {
  color: easilBlack;
  font-weight: 500;
  cursor: pointer;
}

.teamRoleContentWrapper {
  padding-bottom: 32px;
  margin-bottom: 32px;
  border-bottom: 1px solid #d1d4d6;
}

.inviteRoleMessage {
  margin-top: 24px;
  display: flex;
}

.inviteRoleMessage > div {
  margin-right: 4px;
}

.teamRoleContent {
  font-size: 15px;
  line-height: 24px;
}

.teamRoleContent > a {
  color: easilBlack;
  font-weight: 500;
}

.memberAvatar {
  display: flex;
}

.userDetails {
  margin: 0 0 32px 16px;
}

.memberName {
  color: easilBlack;
  font-weight: 500;
}

.rolePickerTitle {
  composes: memberName;
  margin-bottom: 12px;
  color: #57595d;
}

.memberModal {
  width: 792px;
  padding: 32px;
  border-radius: 8px;
}

.memberModal > div:first-of-type {
  margin-bottom: 12px;
}

.editButtons {
  margin-top: 32px;
}

.rolesDropdown {
  width: inherit;
}