@value colors: "~views/colors.css";
@value easilGrayXXXLight from colors;

.nameSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  height: 64px;
  border-bottom: 1px solid easilGrayXXXLight;
}

.nameSectionEditing {
  composes: nameSection;
  justify-content: flex-start;
}

.nameBox {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 36px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: #2c2e2f;
}

.nameBox:hover {
  background-color: #f9f9fa;
}

.nameBox[data-is-name-editable="false"]:hover {
  background-color: inherit;
}

.nameBox > div {
  overflow: hidden;
  text-overflow: ellipsis;
}

.nameInput {
  width: 230px;
  margin-left: 12px;
}

.icon {
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.cancelIcon {
  composes: icon;
  margin-left: 16px
}

.saveIcon {
  composes: icon;
  margin-left: 8px
}
