:root {
  --invoice-month-list-selected-max-height: 50px
}

.column {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.sectionTitle {
  font-size: 20px;
  line-height: 1.4;
  color: #2c2e2f;
  padding-bottom: 12px;
  border-bottom: 1px solid rgba(226, 229, 232, 0.64);
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
}

.sectionTitle:not(:first-of-type) {
  margin-top: 40px;
}

.columnWrapper {
  width: 100%;
  margin-right: 100px;
}

.downloadButton {
  box-shadow: none;
  justify-content: flex-start !important;
  padding: 0px;
  height: auto;
}

.titleButton {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.6;
  color: #57595d;
  margin-left: 8px;
}

.invoicesListWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.loadingWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.emptySectionWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: 80px;
}

.emptySectionText {
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  color: #57595d;
  margin-top: 24px;
}

.invoiceYears {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 24px;
  margin-bottom: 16px;
}

.invoiceYear {
  display: flex;
  font-size: 15px;
  line-height: 1.6;
  margin-right: 20px;
  color: #7a7d81;
  cursor: pointer;
}

.invoiceYear[data-is-selected="true"] {
  color: #2c2e2f;
  border-bottom: 2px solid #2c2e2f;
  font-weight: 500;
}

.invoiceMonths {
  display: flex;
  flex-direction: column;

}

.invoiceMonthInvoiceList {
  height: auto;
  max-height: 0px;
  transition: max-height 300ms ease, border-bottom-color 100ms ease 200ms;
  border-bottom: 1px solid rgba(226, 229, 232, 0);
  border-bottom-color: rgba(226, 229, 232, 0);
  overflow: hidden;
}

.invoiceMonthInvoiceList[data-is-selected="true"] {
  max-height: var(--invoice-month-list-selected-max-height);
  border-bottom-color: rgba(226, 229, 232, 0.48);
  transition: max-height 300ms ease, border-bottom-color 100ms ease 0s;
}

.invoiceMonthLabel {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.6;
  color: #2c2e2f;
  min-height: 48px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e2e5e8;
  cursor: pointer;
  padding-right: 6px;
}

.invoiceListHeaders {
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  margin-bottom: 16px;
}

.invoiceListValueRow {
  display: flex;
  flex-direction: row;
  margin-top: 12px;
  padding-bottom: 12px;
}

.invoiceListValueRow:first-of-type {
  margin-top: none;
}

.invoiceListValueRow:not(:last-of-type) {
  border-bottom: 1px solid rgba(226, 229, 232, 0.48);
}

.invoiceListHeader {
  font-size: 14px;
  line-height: 1.43;
  color: #8f9294;
}

.invoiceListTotal {
  width: 25%;
}

.invoiceListTotalHeader {
  composes: invoiceListTotal;
  composes: invoiceListHeader;
}

.invoiceListTotalValue {
  composes: invoiceListTotal;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.71;
  color: #57595d;
}

.invoiceListInvoiceId {
  width: 30%;
}

.invoiceListInvoiceIdHeader {
  composes: invoiceListInvoiceId;
  composes: invoiceListHeader;
}

.invoiceListInvoiceIdValue {
  composes: invoiceListInvoiceId;
  font-size: 14px;
  line-height: 1.71;
  color: #57595d;
}

.invoiceListDate {
  width: 40%;
}

.invoiceListDateHeader {
  composes: invoiceListDate;
  composes: invoiceListHeader;
}

.invoiceListDateValue {
  composes: invoiceListDate;
  font-size: 14px;
  line-height: 1.71;
  color: #8f9295;
}

.invoiceListDownloadButton {
  cursor: pointer;
}

.downloadAllLoading {
  height: 26.48px;
  width: 142.77px;
  display: flex;
  justify-content: center;
  align-items: center;
}