@value colors: "../../colors.css";
@value easilBlack, easilGrayXDark, easilGrayDark, easilGray, easilBlackDark from colors;

.col1Wrapper {
  width: 100%
}

.col1 {
  display: flex;
  flex: 1 0 100%;
  flex-wrap: wrap;
}

.row {
  flex: 1 0 100%;
}

.teamTitles {
  display: flex;
}

.tableTitle {
  margin-right: 24px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 4px;
}

.tableTitle:nth-of-type(1) {
  margin-left: 0px;
}

.tableSection {
  width: 100%;
  margin-bottom: 32px
}

.pendingEmpty {
  width: 100%;
  text-align: center;
  margin-top: 48px;
}

.menuWrapper {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.menu {
  display: flex;
  margin-left: auto;
  border-radius: 4px;
  padding-bottom: 8px;
  position: relative;
}

.menu > svg {
  cursor: pointer;
}

.menu:hover > svg rect {
  fill: #e2e5e8 !important;
}

.menu:hover > svg circle {
  fill: #57595d !important;
}

.teamTitleSearchRow {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 24px 0 32px;
}

.teamSearchWrapper {
  width: 337px;
}

.searchTitleResults {
  font-size: 18px;
  line-height: 32px;
  font-family: Roboto;
  color: easilBlackDark;
  margin-bottom: 24px;
}

.searchTitleResults > span {
  font-weight: 500;
}

.teamMembersTitle {
  font-size: 20px;
  font-weight: 500;
  color: easilBlack;
}

.memberTable > div:first-child {
  border-bottom: 2px solid #e5e8eb;
  padding-bottom: 32px;
}

.searchFilter {
  display: flex;
}

.searchFilter:first-child {
  margin-right: 8px;
}