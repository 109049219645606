@value colors: "~views/colors.css";
@value easilBlue, easilBlack from colors;

.messageContainer {
  background-color: #ecf5ff;
  border: 1px solid #a1c6ff;
  border-radius: 4px;
  color: easilBlack;
  display: flex;
  line-height: 1.71;
  margin-bottom: 40px;
  padding: 16px;
  color: easilBlack;
}

.messageIcon {
  margin-top: 2px;
}

.messageContent {
  flex: 1 1;
  margin-left: 12px;
}

.messageContent a {
  color: easilBlue;
  font-weight: bold;
}
